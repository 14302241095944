import request from './axiosServices';
import {
  URL_ALL_ORDER_BY_ADMIN,
  URL_ALL_ORDER_OF_CUSTOMERS,
  URL_ORDER_INFO_BY_ADMIN,
  URL_EXPORT_CSV_ORDER,
  URL_UPDATE_MANY_ORDER,
} from '../graphql';

interface requestTypes {
  token?: string;
  limit?: number;
  page?: number;
  id?: string;
  ids?: [];
  shippingStatus?: string;
  updateAll?: boolean;
}
export function getAllOrderByAdminAPI({ token, limit, page }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_ALL_ORDER_BY_ADMIN,
      variables: { limit, page },
    },
    token,
  });
}
export function getAllOrderOfCustomerAPI({ token, limit, page }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_ALL_ORDER_OF_CUSTOMERS,
      variables: { limit, page },
    },
    token,
  });
}
export function getOrderInfoByAdminAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_ORDER_INFO_BY_ADMIN,
      variables: { id },
    },
    token,
  });
}
export function exportCSVOrderAPI({ token }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_EXPORT_CSV_ORDER,
      variables: {},
    },
    token,
  });
}
export function updateManyOrderAPI({ token, ids, shippingStatus, updateAll }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_UPDATE_MANY_ORDER,
      variables: { ids, shippingStatus, updateAll },
    },
    token,
  });
}
