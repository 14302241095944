import { UserTypes } from '../models';
import request from './axiosServices';

import {
  URL_LOGIN,
  URL_USER_INFO,
  URL_GET_LOGS,
  URL_ALL_USER_BY_ADMIN,
  URL_DELETE_USER_BY_ADMIN,
  URL_USER_INFO_BY_ADMIN,
  URL_USER_ADMIN,
  URL_USER_ADMIN_INFO,
  URL_ADD_USER_ADMIN,
  URL_DELETE_USER_ADMIN,
  URL_SEARCH_USER,
  URL_EXPORT_CSV_USER,
} from 'graphql';

export enum genderTypes {
  male,
  female,
  other,
}
interface requestTypes {
  token?: string;
  params?: Object;
  [key: string]: any;
  id?: string;
  limit?: number;
  page?: number;
  type?: string;
  searchText?: string;
  inputs?: {
    userName?: string;
    email?: string;
    password?: string;
    gender?: genderTypes;
    firstName?: string;
    lastName?: string;
    birthday?: string;
    phone?: string;
    address?: string;
    postalCode?: string;
    role?: string;
    avatar?: string;
  };
}

export function loginAPI({ token, params }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_LOGIN,
      variables: params,
    },
  });
}
export function userInfoAPI({ token, params }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_USER_INFO,
      variables: params,
    },
    token,
  });
}
export function getLogsAPI({ token, limit, type }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_GET_LOGS,
      variables: { limit, type },
    },
    token,
  });
}
export function getAllUserByAdminAPI({ token, limit, page }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_ALL_USER_BY_ADMIN,
      variables: { limit, page },
    },
    token,
  });
}
export function deleteUserByAdminAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_DELETE_USER_BY_ADMIN,
      variables: { id },
    },
    token,
  });
}
export function getUserInfoByAdminAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_USER_INFO_BY_ADMIN,
      variables: { id },
    },
    token,
  });
}
export function getUserAdminAPI({ token, limit, page }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_USER_ADMIN,
      variables: { limit, page },
    },
    token,
  });
}
export function getUserAdminInfoAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_USER_ADMIN_INFO,
      variables: { id },
    },
    token,
  });
}
export function addUserAdminAPI({ token, inputs }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_ADD_USER_ADMIN,
      variables: { inputs },
    },
    token,
  });
}
export function deleteUserAdminAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_DELETE_USER_ADMIN,
      variables: { id },
    },
    token,
  });
}
export function searchUserAPI({ token, limit, page, searchText }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_SEARCH_USER,
      variables: { limit, page, searchText },
    },
    token,
  });
}
export function exportCSVUsersAPI({ token }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_EXPORT_CSV_USER,
      variables: {},
    },
    token,
  });
}
