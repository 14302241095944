import { Checkbox, DatePicker, Input, Modal, Radio, RadioChangeEvent, Select } from 'antd';
import { ProductTypes, TypesProductUpdate } from 'models';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  changeReviewStatus,
  fetchProductBrands,
  fetchProductReviewById,
  updateProduct,
} from 'slices/productSlice';
import { useDispatch } from 'react-redux';
import { getToken } from 'helpers';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useAppSelector } from 'redux/hooks';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import * as cts from '../../constants/ui/svg';
import { fetchAllCategory } from 'slices/categorySlice';
import { fetchProductTag } from 'slices/tagProductsSlice';

interface Props {
  record: any;
  viewSlc: string;
  productUpdate: any;
  setProductUpdate: React.Dispatch<React.SetStateAction<any>>;
  idReview: string;
  arrNew: any;
  data: any;
  setExpandedKey: any;
}
const ContentTableProduct: React.FC<Props> = ({
  idReview,
  record,
  viewSlc,
  productUpdate,
  setProductUpdate,
  arrNew,
  data,
  setExpandedKey,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = getToken();
  const categories = useAppSelector((state) => state.categories.categories);
  const tagProduct = useAppSelector((state) => state.productTags.productTags);
  const productReviewById = useAppSelector((state) => state.products.productReviewsById);
  const productBrand = useAppSelector((state) => state.products.productBrands);
  const [arrTagBefore, setArrTagBefore] = useState<any>();
  const [arrCateBefore, setArrCateBefore] = useState<any>();
  const [openModalSpam, setOpenModalSpam] = useState(false);
  const [idCmt, setIdCmt] = useState('');
  const [valueBrand, setValueBrand] = useState<any>(record?.brandId ? record?.brandId?.id : null);
  const arrTag = tagProduct?.map((item: any) => {
    return {
      tag: {
        name: item?.name,
        id: item?.id,
      },
    };
  });
  const arr = categories?.map((item: any) => {
    return {
      cate: {
        name: item?.name,
        id: item?._id,
      },
    };
  });
  const onChangeCheckBoxTag = (checkedValues: CheckboxValueType[]) => {
    // const arrNewCheck = arrTag?.map((item: any) => item?.tag);
    // const arr3 = checkedValues
    //   .map((item: any) => {
    //     const index = arrNewCheck?.findIndex((item2: any) => item2?.name === item);
    //     return {
    //       name: arrNewCheck[index]?.name,
    //     };
    //   })
    //   .map((item1: any) => item1?.name);
    // console.log('arr3', arr3);
    // console.log('checkedValues', checkedValues);
    setArrTagBefore(checkedValues);
    // setRecordTagBefore(arr3);
    // setProductUpdate({ ...productUpdate, listTag: checkedValues as [] });
  };
  const onChangeCheck = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
    setProductUpdate({ ...productUpdate, reviewAble: e.target.checked });
  };
  const onChangeCheckBox = (checkedValues: CheckboxValueType[]) => {
    const arrNewCheck = arr?.map((item: any) => item?.cate);
    const arr3 = checkedValues
      .map((item: any) => {
        const index = arrNewCheck?.findIndex((item2: any) => item2?.name === item);
        return {
          id: arrNewCheck[index]?.id,
        };
      })
      .map((item1: any) => item1?.id);
    setArrCateBefore(arr3);
    // setProductUpdate({
    //   ...productUpdate,
    //   categoryId: checkedValues as [],
    // });
    // setRecordCateBefore(arr3);
  };

  const onValueChange = (value: string, name: string, record: any) => {
    const foundProduct = productUpdate?.find((product: any) => product?._id === record?._id);
    let newObj = { ...foundProduct };
    newObj[name] = value;
    const newArray = productUpdate?.map((product: any) =>
      product?._id === foundProduct?._id ? newObj : product
    );
    setProductUpdate(newArray);
    // console.log('productUpdate', productUpdate);
  };
  const onChangeBrand = (e: CheckboxChangeEvent) => {
    setValueBrand(e.target.value);
  };
  useEffect(() => {
    if (arrNew?.length > 0) {
      setProductUpdate(arrNew);
    } else {
      setProductUpdate(data);
    }
  }, [arrNew?.length]);
  useEffect(() => {
    dispatch(fetchAllCategory({ token, limit: 100 }));
  }, [dispatch, token]);
  useEffect(() => {
    dispatch(fetchProductTag({ token, limit: 100 }));
  }, [dispatch, token]);
  useEffect(() => {
    if (idReview) {
      dispatch(fetchProductReviewById({ token, limit: 10, page: 1, productId: idReview }));
    }
  }, [idReview]);
  useEffect(() => {
    dispatch(fetchProductBrands({ token }));
  }, [dispatch, token]);

  return (
    <>
      {(viewSlc === 'edit' && (
        <div key={record?.key} className="px-[1%] flex w-full pt-[10px] pb-[40px]">
          <div className="w-[40%] flex flex-col pr-[1%]">
            <span className="text-[14px] mplus1pextrabold">
              {t('QUICK')}
              {t('EDIT')}
            </span>
            <div className="flex items-center justify-between mt-[20px]">
              <span className="text-[14px] w-[30%] mplus1pnormal">{t('TITLE')}</span>
              <Input
                defaultValue={record?.name}
                onChange={(e) => onValueChange(e.target.value, 'name', record)}
                // onChange={(e) => {
                //   // setState({ ...state, name: e.target.value });
                //   setProductUpdate({ ...productUpdate, name: e.target.value });
                // }}
                className="h-[30px] w-[70%] border border-[rgba(0,0,0,0.4) rounded-[5px]"
              />
            </div>
            <div className="flex items-center justify-between mt-[15px]">
              <span className="text-[14px] w-[30%] mplus1pnormal">{t('SLUG')}</span>
              <Input
                defaultValue={record?.slug}
                onChange={(e) => onValueChange(e.target.value, 'slug', record)}
                // value={productUpdate?.slug}
                // onChange={(e) => {
                //   setProductUpdate({ ...productUpdate, slug: e.target.value });
                // }}
                className="h-[30px] w-[70%] border border-[rgba(0,0,0,0.4) rounded-[5px]"
              />
            </div>
            <div className="flex items-center justify-between mt-[15px]">
              <span className="text-[14px] w-[30%] mplus1pnormal">{t('DATE')}</span>
              <div className="w-[70%] flex items-center">
                <DatePicker
                  // placeholder={moment(record?.createdAt).format('YYYY')}
                  format="YYYY"
                  className="w-[59px] h-[30px] date_picker"
                  picker="year"
                  value={dayjs(record?.createdAt)}
                />
                <span className="text-[12px] mplus1pnormal ml-[5px] mr-[10px]">{t('YEAR')}</span>
                <Select
                  className="w-[100px] mr-[15px]"
                  // defaultValue={moment(record?.createdAt).format('MM月 (MM月)')}
                  value={moment(record?.createdAt).format('MM月 (MM月)')}
                  options={[
                    { value: '1月 (1月)', label: '1月 (1月)' },
                    { value: '2月 (2月)', label: '2月 (2月)' },
                    { value: '3月 (3月)', label: '3月 (3月)' },
                    { value: '4月 (4月)', label: '4月 (4月)' },
                    { value: '5月 (5月)', label: '5月 (5月)' },
                    { value: '6月 (6月)', label: '6月 (6月)' },
                    { value: '7月 (7月)', label: '7月 (7月)' },
                    { value: '8月 (8月)', label: '8月 (8月)' },
                    { value: '9月 (9月)', label: '9月 (9月)' },
                    { value: '10月 (10月)', label: '10月 (10月)' },
                    { value: '11月 (11月)', label: '11月 (11月)' },
                    { value: '12月 (12月)', label: '12月 (12月)' },
                  ]}
                  onChange={(value: string) => {
                    console.log(`selected ${value}`);
                  }}
                />
                <DatePicker
                  format="DD"
                  picker="date"
                  value={dayjs(record?.createdAt)}
                  className="date_picker h-[30px] w-[48px]"
                />
              </div>
            </div>
            <div className="flex items-center justify-between mt-[15px]">
              <span className="text-[14px] w-[30%] mplus1pnormal">{t('PASSWORD')}</span>
              <div className="w-[70%] flex items-center">
                <div className="w-[50%]">
                  <Input className="h-[30px] border border-[rgba(0,0,0,0.4) rounded-[5px]" />
                </div>
                <div className="w-[50%] flex items-center">
                  <span className="text-[14px] text-[rgba(0,0,0,0.5)] mplus1pnormal mx-[10px]">
                    –または–{' '}
                  </span>
                  <Checkbox />
                  <span className="text-[14px] text-[rgba(0,0,0,0.5)] mplus1pnormal">非公開</span>
                </div>
              </div>
            </div>
            <span className="text-[14px] mplus1pextrabold mt-[30px]">{t('PRODUCT_DATA')}</span>
            <div className="flex items-center justify-between mt-[15px]">
              <span className="text-[14px] w-[30%] mplus1pnormal">SKU</span>
              <Input className="h-[30px] w-[70%] border border-[rgba(0,0,0,0.4) rounded-[5px]" />
            </div>
            <div className="flex items-center justify-between mt-[15px]">
              <span className="text-[14px] w-[30%] mplus1pnormal">{t('AMOUNT_OF_MONEY')}</span>
              <Input
                // value={productUpdate?.price}
                defaultValue={record?.price}
                onChange={(e) => onValueChange(e.target.value, 'price', record)}
                type="number"
                // onChange={(e) => {
                //   setProductUpdate({ ...productUpdate, price: Number(e.target.value) || 0 });
                // }}

                className="h-[30px] w-[70%] border border-[rgba(0,0,0,0.4) rounded-[5px]"
              />
            </div>
            <div className="flex items-center justify-between mt-[15px]">
              <span className="text-[14px] w-[30%] mplus1pnormal">{t('SALE')}</span>
              <Input
                // value={productUpdate?.marketPrice}
                defaultValue={record?.marketPrice}
                onChange={(e) => onValueChange(e.target.value, 'marketPrice', record)}
                type="number"
                // onChange={(e) => {
                //   setProductUpdate({ ...productUpdate, marketPrice: Number(e.target.value) });
                // }}
                placeholder="セール値段"
                className="h-[30px] w-[70%] border border-[rgba(0,0,0,0.4) rounded-[5px]"
              />
            </div>
            <div className="flex items-center justify-between mt-[15px]">
              <span className="text-[14px] w-[30%] mplus1pnormal">{t('TAXABLE_STATUS')}</span>
              <div className="w-[70%]">
                <Select
                  defaultValue="課税"
                  options={[{ value: '課税', label: '課税' }]}
                  className="w-[85px]"
                />
              </div>
            </div>
            <div className="flex items-center justify-between mt-[15px]">
              <span className="text-[14px] w-[30%] mplus1pnormal">{t('TAX_CLASSIFICATION')}</span>
              <div className="w-[70%]">
                <Select
                  defaultValue="標準"
                  options={[{ value: '標準', label: '標準' }]}
                  className="w-[85px]"
                />
              </div>
            </div>
            <div className="flex items-center justify-between mt-[15px]">
              <span className="text-[14px] w-[30%] mplus1pnormal">{t('WEIGHT')}</span>
              <Input
                // value={productUpdate?.weight}
                defaultValue={record?.weight}
                onChange={(e) => onValueChange(e.target.value, 'weight', record)}
                type="number"
                // onChange={(e) => {
                //   setProductUpdate({ ...productUpdate, weight: Number(e.target.value) });
                // }}
                className="h-[30px] w-[70%] border border-[rgba(0,0,0,0.4) rounded-[5px]"
              />
            </div>
            <div className="flex items-center justify-between mt-[15px]">
              <span className="text-[14px] w-[30%] mplus1pnormal">
                {t('LENGTH')}/{t('WIDTH')}/{t('HEIGHT')}
              </span>
              <div className="flex items-center h-[30px] w-[70%] border border-[rgba(0,0,0,0.4) rounded-[5px]">
                <Input
                  // value={productUpdate?.length}
                  defaultValue={record?.length}
                  onChange={(e) => onValueChange(e.target.value, 'length', record)}
                  type="number"
                  // onChange={(e) => {
                  //   setProductUpdate({ ...productUpdate, length: Number(e.target.value) });
                  // }}
                  className="w-[33%] py-[0px] border-none"
                />{' '}
                /
                <Input
                  // value={productUpdate?.width}
                  defaultValue={record?.width}
                  onChange={(e) => onValueChange(e.target.value, 'width', record)}
                  type="number"
                  // onChange={(e) => {
                  //   setProductUpdate({ ...productUpdate, width: Number(e.target.value) });
                  // }}
                  className="w-[33%] py-[0px] border-none"
                />{' '}
                /
                <Input
                  // value={productUpdate?.height}
                  defaultValue={record?.height}
                  onChange={(e) => onValueChange(e.target.value, 'height', record)}
                  type="number"
                  // onChange={(e) => {
                  //   setProductUpdate({ ...productUpdate, height: Number(e.target.value) });
                  // }}
                  className="w-[33%] py-[0px] border-none"
                />
              </div>
            </div>
            <div className="flex items-center justify-between mt-[15px]">
              <span className="text-[14px] w-[30%] mplus1pnormal">{t('SHIPPING_CLASS')}</span>
              <div className="w-[70%]">
                <Select
                  defaultValue="配送クラスなし"
                  options={[{ value: '配送クラスなし', label: '配送クラスなし' }]}
                  className="w-[154px]"
                />
              </div>
            </div>
            <div className="flex items-center justify-between mt-[15px]">
              <span className="text-[14px] w-[30%] mplus1pnormal">{t('DISPLAY_STATE')}</span>
              <div className="w-[70%] flex flex-col">
                <div className="flex items-center">
                  <Select
                    defaultValue="カタログ検索 "
                    options={[{ value: 'カタログ検索 ', label: 'カタログ検索 ' }]}
                    className="w-[154px] mr-[20px]"
                  />
                  <Checkbox />
                  <span className="text-[14px] mplus1pnormal text-[rgba(0,0,0,0.5)] ml-[5px]">
                    {t('ATTENTION')}
                  </span>
                </div>
                <div>
                  <Checkbox className="mt-[10px]" />
                  <span className="text-[14px] mplus1pnormal text-[rgba(0,0,0,0.5)] ml-[5px]">
                    在庫を管理しますか ?
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between mt-[15px]">
              <span className="text-[14px] w-[30%] mplus1pnormal">{t('STOCK_AVAILABLE')}</span>
              <div className="w-[70%]">
                <Select
                  defaultValue="変更なし"
                  options={[{ value: '変更なし', label: '変更なし' }]}
                  className="w-[154px]"
                />
              </div>
            </div>
            <div className="flex mt-[20px]">
              <span
                onClick={() => {
                  const foundProduct = productUpdate?.find(
                    (product: any) => product?._id === record?._id
                  );
                  const params = {
                    name: foundProduct?.name,
                    slug: foundProduct?.slug,
                    price: Number(foundProduct?.price),
                    marketPrice: Number(foundProduct?.marketPrice),
                    weight: Number(foundProduct?.weight),
                    length: Number(foundProduct?.length),
                    width: Number(foundProduct?.width),
                    height: Number(foundProduct?.height),
                    reviewAble: foundProduct?.reviewAble,
                    listTag: arrTagBefore
                      ? arrTagBefore
                      : foundProduct?.tags?.map((i: any) => i?.name),
                    categoryId: arrCateBefore
                      ? arrCateBefore
                      : foundProduct?.categoryId?.map((i: any) => i?._id),
                    brandId: valueBrand ? valueBrand : foundProduct?.brandId?.id,
                  };
                  dispatch(
                    updateProduct({
                      token,
                      id: record?._id,
                      inputs: params,
                    })
                  );
                }}
                className="h-[39px] mplus1pextrabold mr-[15px] text-[14px] w-[54px] text-white flex justify-center items-center rounded-[10px] cursor-pointer bg-[#3E6100]"
              >
                {t('UPDATE')}
              </span>
              <span
                onClick={(e) => {
                  setExpandedKey(null);
                }}
                className="h-[39px] mplus1pmedium text-[14px] border border-[#3E6100] w-[96px] text-[#3E6100] flex justify-center items-center rounded-[10px] cursor-pointer bg-white"
              >
                {t('CANCEL')}
              </span>
            </div>
          </div>
          <div className="w-[30%] flex flex-col px-[1%]">
            <span className="text-[14px] mplus1pnormal">
              {t('MERCHANDISE')}
              {t('TAG')}
            </span>
            <div className="mt-[22px] border pl-[22px] border-[#d9d9d9] rounded-[5px] flex flex-col py-[22px]">
              <Checkbox.Group
                options={arrTag?.map((item: any) => item?.tag?.name)}
                defaultValue={record?.tags?.map((i: any) => i?.name)}
                onChange={onChangeCheckBoxTag}
                className="flex flex-col ckb_gr"
              />
            </div>
            {/* <TextArea
                    // value={productUpdate?.listTag}
                    // value={`${record?.tags?.map((item: any) => '#' + item.name.replace(/\s/g, ''))}`}
                    onChange={(e) => {
                      console.log(e.target.value);
                      // setProductUpdate({
                      //   ...productUpdate,
                      //   listTag: e.target.value.split('#').filter((x) => x !== ''),
                      // });
                    }}
                    className="mt-[20px]"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  /> */}
            <span className="text-[rgba(0,0,0,0.5)] text-[14px] mplus1pnormal">
              タグが複数ある場合はコンマで区切ってください
            </span>
            <div className="flex items-center mt-[10px]">
              <Checkbox onChange={onChangeCheck} checked={record?.reviewAble === true} />
              <span className="text-[14px] mplus1pnormal ml-[20px]">{t('ENABLE_REVIEWS')}</span>
            </div>
            <div className="flex mt-[30px] items-center">
              <span className="text-[14px] mplus1pnormal mr-[14px]">{t('STATUS')}</span>
              <Select className="w-[105px]" />
            </div>
            <div className="mt-[50px] flex items-center">
              <Checkbox />
              <span className="text-[14px] mplus1pnormal ml-[30px] mr-[50px]">
                {t('PAID_RANKING')}
              </span>
              <Select
                defaultValue="1"
                options={[
                  { value: '1', label: '1' },
                  { value: '2', label: '2' },
                  { value: '3', label: '3' },
                  { value: '4', label: '4' },
                  { value: '5', label: '5' },
                ]}
                className="w-[58px]"
              />
            </div>
          </div>
          <div className="w-[30%] pl-[1%] flex flex-col">
            <span className="mplus1pnormal text-[14px]">{t('PRODUCT_CATE')}</span>
            <div className="mt-[22px] border pl-[22px] border-[#d9d9d9] rounded-[5px] flex flex-col py-[22px]">
              <Checkbox.Group
                options={arr?.map((i: any) => {
                  return {
                    label: i.cate.name,
                    value: i.cate.name,
                  };
                })}
                defaultValue={record?.categoryId?.map((i: any) => i?.name)}
                onChange={onChangeCheckBox}
                className="flex flex-col ckb_gr"
              />
            </div>
            <span className="text-[14px] mplus1pnormal mt-[20px]">{t('PRODUCT_BRAND_NAME')}</span>
            <div className="mt-[22px] border pl-[22px] border-[#d9d9d9] rounded-[5px] flex flex-col py-[22px]">
              {productBrand?.map((x: any) => {
                return (
                  <div className="flex flex-col">
                    <Checkbox
                      key={x?.id}
                      onChange={onChangeBrand}
                      checked={x?.id == valueBrand}
                      value={x?.id}
                    >
                      {x?.name}
                    </Checkbox>
                  </div>
                );
              })}

              {/* <Radio.Group className="flex flex-col" onChange={onChangeBrand} value={valueBrand}>
                {productBrand?.map((x: any) => {
                  return <Radio value={x?.id}>{x?.name}</Radio>;
                })}
              </Radio.Group> */}
            </div>
          </div>
        </div>
      )) ||
        (viewSlc === 'see_product' && (
          <div
            key={record?.key}
            className="pt-[30px] pb-[50px] flex flex-col px-[5%] bg-[#F5F5F5] border-l border-r border-[rgba(0,0,0,0.2)]"
          >
            <span className="text-[14px] mplus1pextrabold mb-[20px]">{t('PRODUCT_REVIEW')}</span>
            <div
              className={`${
                productReviewById?.count > 0 ? 'h-[650px]' : 'h-full'
              } rounded-[5px] border border-[rgba(0,0,0,0.5)] flex flex-col`}
            >
              <div className="py-[10px] border-b border-[rgba(0,0,0,0.5)] rounded-t-[5px] flex items-center justify-between px-[2%] bg-[#D9D9D9]/50">
                <span className="flex">
                  <span className="flex items-center">
                    {(4.5 <= productReviewById?.avgPoint && productReviewById?.avgPoint <= 5 && (
                      <>
                        <span>{cts.iconStarActive}</span>
                        <span>{cts.iconStarActive}</span>
                        <span>{cts.iconStarActive}</span>
                        <span>{cts.iconStarActive}</span>
                        <span>{cts.iconStarActive}</span>
                      </>
                    )) ||
                      (3.5 <= productReviewById?.avgPoint && productReviewById?.avgPoint <= 4.4 && (
                        <>
                          <span>{cts.iconStarActive}</span>
                          <span>{cts.iconStarActive}</span>
                          <span>{cts.iconStarActive}</span>
                          <span>{cts.iconStarActive}</span>
                          <span>{cts.iconStarDis}</span>
                        </>
                      )) ||
                      (2.5 <= productReviewById?.avgPoint && productReviewById?.avgPoint <= 3.4 && (
                        <>
                          <span>{cts.iconStarActive}</span>
                          <span>{cts.iconStarActive}</span>
                          <span>{cts.iconStarActive}</span>
                          <span>{cts.iconStarDis}</span>
                          <span>{cts.iconStarDis}</span>
                        </>
                      )) ||
                      (1.5 <= productReviewById?.avgPoint && productReviewById?.avgPoint <= 2.4 && (
                        <>
                          <span>{cts.iconStarActive}</span>
                          <span>{cts.iconStarActive}</span>
                          <span>{cts.iconStarDis}</span>
                          <span>{cts.iconStarDis}</span>
                          <span>{cts.iconStarDis}</span>
                        </>
                      )) ||
                      (0.5 <= productReviewById?.avgPoint && productReviewById?.avgPoint <= 1.4 && (
                        <>
                          <span>{cts.iconStarActive}</span>
                          <span>{cts.iconStarDis}</span>
                          <span>{cts.iconStarDis}</span>
                          <span>{cts.iconStarDis}</span>
                          <span>{cts.iconStarDis}</span>
                        </>
                      )) ||
                      (productReviewById?.avgPoint <= 0 && (
                        <>
                          <span>{cts.iconStarDis}</span>
                          <span>{cts.iconStarDis}</span>
                          <span>{cts.iconStarDis}</span>
                          <span>{cts.iconStarDis}</span>
                          <span>{cts.iconStarDis}</span>
                        </>
                      ))}
                  </span>
                  <span className="text-[14px] mplus1pnormal ml-[12px]">
                    ({productReviewById?.count || 0})
                  </span>
                </span>
                <span className="text-[14px] mplus1pnormal">
                  total review {productReviewById?.count || 0}{' '}
                </span>
              </div>
              <div
                className={`${
                  productReviewById?.count > 0 && ' overflow-y-auto pb-[50px]'
                } bg-white review_tbl flex flex-col px-[4%] rounded-b-[5px]`}
              >
                {productReviewById?.count > 0 ? (
                  productReviewById?.result?.map((item: any, idx: any) => {
                    return (
                      <div
                        key={idx}
                        className=" border-b border-[rgba(0,0,0,0.5)] flex pt-[35px] pb-[45px]"
                      >
                        <div className="w-[60%] flex pl-[3%]">
                          <span className="mr-[30px] bg-[#D9D9D9] h-[50px]">{cts.iconUser}</span>
                          <div className="flex flex-col">
                            <span className="text-[14px] mplus1pbold">
                              {item?.customerId?.firstName}
                              {item?.customerId?.lastName}{' '}
                              <span className="ml-[5px]">reviewed a product</span>
                            </span>
                            <span className="mt-[35px] flex mb-[10px]">
                              <span className="flex items-center">
                                {(item?.point === 5 && (
                                  <>
                                    <span>{cts.iconStarActive}</span>
                                    <span>{cts.iconStarActive}</span>
                                    <span>{cts.iconStarActive}</span>
                                    <span>{cts.iconStarActive}</span>
                                    <span>{cts.iconStarActive}</span>
                                  </>
                                )) ||
                                  (item?.point === 4 && (
                                    <>
                                      <span>{cts.iconStarActive}</span>
                                      <span>{cts.iconStarActive}</span>
                                      <span>{cts.iconStarActive}</span>
                                      <span>{cts.iconStarActive}</span>
                                      <span>{cts.iconStarDis}</span>
                                    </>
                                  )) ||
                                  (item?.point === 3 && (
                                    <>
                                      <span>{cts.iconStarActive}</span>
                                      <span>{cts.iconStarActive}</span>
                                      <span>{cts.iconStarActive}</span>
                                      <span>{cts.iconStarDis}</span>
                                      <span>{cts.iconStarDis}</span>
                                    </>
                                  )) ||
                                  (item?.point === 2 && (
                                    <>
                                      <span>{cts.iconStarActive}</span>
                                      <span>{cts.iconStarActive}</span>
                                      <span>{cts.iconStarDis}</span>
                                      <span>{cts.iconStarDis}</span>
                                      <span>{cts.iconStarDis}</span>
                                    </>
                                  )) ||
                                  (item?.point === 1 && (
                                    <>
                                      <span>{cts.iconStarActive}</span>
                                      <span>{cts.iconStarDis}</span>
                                      <span>{cts.iconStarDis}</span>
                                      <span>{cts.iconStarDis}</span>
                                      <span>{cts.iconStarDis}</span>
                                    </>
                                  ))}
                              </span>
                            </span>
                            <span>{item?.content}</span>
                            <span className="text-[14px] mplus1pmedium mt-[10px] text-[#9F9F9F]">
                              投稿日：{moment(item?.updatedAt).format('YYYY/MM/DD')}
                            </span>
                          </div>
                        </div>
                        <div className="w-[40%] flex justify-center pl-[3%]">
                          <span className="cursor-pointer flex mr-[20px] items-center border border-[rgba(0,0,0,0.5)] justify-center h-[39px] px-[10px] rounded-[10px]">
                            返信
                          </span>
                          {/* <span className="cursor-pointer flex items-center border border-[rgba(0,0,0,0.5)] justify-center h-[39px] px-[10px] rounded-[10px]">
                            {t('HIDE_REVIEW')}
                          </span> */}
                          <span
                            onClick={() => {
                              setOpenModalSpam(true);
                              setIdCmt(item?.id);
                            }}
                            className="cursor-pointer flex items-center border border-[#C70000] justify-center h-[39px] px-[10px] rounded-[10px]"
                          >
                            {t('TO_SPAM')}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span className="flex items-center justify-center italic py-[20px]">No data</span>
                )}
              </div>
            </div>
            <span
              onClick={() => setExpandedKey(null)}
              className="cursor-pointer border mt-[30px] border-[#3E6100] h-[39px] w-[54px] flex items-center justify-center bg-white rounded-[10px] text-[#3E6100] text-[14px] mplus1pmedium"
            >
              閉じる
            </span>
          </div>
        ))}
      <Modal
        mask={false}
        centered
        open={openModalSpam}
        className="modal_delete h-[270px] !w-[375px]"
        //   onOk={() => setOpenModal(false)}
        //   onCancel={() => setOpenModal(false)}
      >
        <div className="flex flex-col justify-between items-center h-full">
          <span>{cts.iconWarning}</span>
          <span className="text-[14px] mplus1pmedium">
            この製品のレビューをゴミ箱に移動してもよろしいですか?
          </span>
          <div className="flex items-center w-full">
            <span
              className="h-[34px] rounded-[5px] flex items-center justify-center bg-[#9B0101] cursor-pointer text-white text-[14px] mplus1pextrabold w-full mr-[5px]"
              onClick={() => {
                dispatch(
                  changeReviewStatus({
                    token,
                    id: idCmt,
                    status: 'trash',
                  })
                );
                setOpenModalSpam(false);
              }}
            >
              {t('DELETE')}
            </span>
            <span
              className="h-[34px] rounded-[5px] flex items-center justify-center bg-[#3E6100] cursor-pointer text-white text-[14px] mplus1pextrabold w-full ml-[5px]"
              onClick={() => setOpenModalSpam(false)}
            >
              {t('CANCEL')}
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ContentTableProduct;
