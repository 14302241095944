import { Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';
import { Link, useHistory } from 'react-router-dom';
import TableBlog from './TableBlog';

const BlogPublicPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const allBlogByAdmin = useAppSelector((state) => state.blogs.blogByAdminList);
  return (
    <div className="pt-[26px] px-[35px] mb-[100px]">
      <span className="text-[20px] mplus1pextrabold">{t('BLOG')}</span>
      <div className="flex items-center justify-between mt-[68px]">
        <div className="flex items-center ">
          <span
            onClick={() => history.push('/posts')}
            className="text-[14px] flex mplus1pnormal text-[#0038FF] underline mr-[15px] cursor-pointer"
          >
            {t('ALL')}
            <span className="text-black underline">({allBlogByAdmin?.countAll})</span>{' '}
          </span>

          <span className="text-[14px] mr-[15px] flex mplus1pnormal underline text-[#2E543E] cursor-pointer">
            {t('PUBLISHED_1')}
            <span className="text-black underline">({allBlogByAdmin?.countPublic})</span>{' '}
          </span>

          <span
            onClick={() => history.push('/blog-draft')}
            className="text-[14px] mr-[15px] flex mplus1pnormal underline text-[#0038FF] cursor-pointer"
          >
            {t('DRAFT')}
            <span className="text-black underline">({allBlogByAdmin?.countDraft})</span>{' '}
          </span>
          <span
            onClick={() => history.push('/blog-trash')}
            className="text-[14px] flex mplus1pnormal underline text-[#0038FF] cursor-pointer"
          >
            {t('TO_TRASH')}
            <span className="text-black underline">({allBlogByAdmin?.countTrash})</span>{' '}
          </span>
        </div>
        <div className="flex items-center max-1350px:justify-center justify-end ctn_btn_merchandise w-[60%] pb-[10px] h-full overflow-auto overflow-y-hidden">
          <Select
            defaultValue="一括操作"
            className="w-[112px] slc_merchandise mr-[10px]"
            options={[{ value: '一括操作', label: '一括操作' }]}
          />
          {/* <Button className="bg-white h-[30px] border-[0.5px] text-[14px] mplus1pnormal border-black mr-[10px]">
            {t('APPLY')}
          </Button> */}
          <Select
            defaultValue="日付"
            className="w-[84px] slc_merchandise mr-[10px]"
            options={[{ value: '日付', label: '日付' }]}
          />
          <Select
            defaultValue="カテゴリー一覧"
            className="w-[154px] slc_merchandise mr-[10px]"
            options={[{ value: 'カテゴリー一覧 ', label: 'カテゴリー一覧' }]}
          />
          <Select
            defaultValue="全て FORMATS"
            className=" slc_merchandise mr-[10px] w-[153px]"
            // onChange={handleChange}
            options={[{ value: '全て FORMATS ', label: '全て FORMATS' }]}
          />
          <Button className="bg-white h-[30px] border-[0.5px] text-[14px] mplus1pnormal border-black">
            {t('FILTER')}
          </Button>
        </div>
        {/* <div className="w-[30%] pl-[2%] pb-[10px]">
            <div className="flex items-center justify-end">
              <span className="h-[40px] w-[48px] border-[0.5px] rounded-l-[10px] border-black/50 bg-[#D9D9D9] flex justify-center items-center">
              {cts.iconSearch}
            </span>
            <Input
              placeholder="SEARCH PRODUCT"
              className="w-[70%] h-[40px] border-[0.5px] border-black/50 rounded-r-[10px] rounded-l-[0px]"
            />
            </div>
          </div> */}
      </div>
      <TableBlog total={allBlogByAdmin?.countPublic} data={allBlogByAdmin?.result} statusBlog={'public'} />
    </div>
  );
};
export default BlogPublicPage;
