import React, { useState, useEffect } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { Router, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SidebarPage = () => {
  const [openKeys, setOpenKeys] = useState<Array<string>>([]);
  const history = useHistory();
  const { t } = useTranslation();
  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    // icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem {
    return {
      key,
      // icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem(
      <span className="text-white text-[16px] mplus1pextrabold">
        {t('MERCHANDISE')}
        {t('MANAGEMENT')}
      </span>,
      'merchandise-mana',
      [
        getItem(
          <span className="text-white text-[16px] mplus1pnormal">{t('MERCHANDISE')}</span>,
          'products'
        ),
        getItem(
          <span className="text-white text-[16px] mplus1pnormal">{t('ADD_NEW')}</span>,
          'add-new'
        ),
        getItem(
          <span className="text-white text-[16px] mplus1pnormal">{t('CATEGORY')}</span>,
          'category'
        ),
        getItem(<span className="text-white text-[16px] mplus1pnormal">{t('TAG')}</span>, 'tag'),
        getItem(<span className="text-white text-[16px] mplus1pnormal">ブランド</span>, 'brand'),
        getItem(
          <span className="text-white text-[16px] mplus1pnormal">{t('REVIEW')}</span>,
          'preview-products'
        ),
      ]
    ),
    getItem(
      <span className="text-white text-[16px] mplus1pextrabold">
        {t('SALES')}
        {t('MANAGEMENT')}
      </span>,
      'sale-mana',
      [
        getItem(
          <span className="text-white text-[16px] mplus1pnormal">{t('ORDER')}</span>,
          'orders'
        ),
        getItem(
          <span className="text-white text-[16px] mplus1pnormal">{t('CLIENT')}</span>,
          'client'
        ),
        // getItem(
        //   <span className="text-white text-[16px] mplus1pnormal">{t('REPORT')}</span>,
        //   'report'
        // ),
        // getItem(
        //   <span className="text-white text-[16px] mplus1pnormal">{t('SETTINGS')}</span>,
        //   'setting'
        // ),
      ]
    ),
    getItem(<span className="text-white text-[16px] mplus1pextrabold">{t('BLOG')}</span>, 'blog', [
      getItem(<span className="text-white text-[16px] mplus1pnormal">{t('POST')}</span>, 'posts'),
      getItem(
        <span className="text-white text-[16px] mplus1pnormal">{t('ADD_NEW')}</span>,
        'blog-add'
      ),
      // getItem(
      //   <span className="text-white text-[16px] mplus1pnormal">{t('BLOG_CATE')}</span>,
      //   'blog-cate'
      // ),
      getItem(
        <span className="text-white text-[16px] mplus1pnormal">{t('BLOG_TAG')}</span>,
        'blog-tag'
      ),
    ]),
    getItem(
      <span className="text-white text-[16px] mplus1pextrabold">
        {t('USER')}
        {t('MANAGEMENT')}
      </span>,
      'user-mana',
      [
        getItem(<span className="text-white text-[16px] mplus1pnormal">{t('USER')}</span>, 'users'),
        // getItem(
        //   <span className="text-white text-[16px] mplus1pnormal">{t('ADD_NEW')}</span>,
        //   'user-add'
        // ),
        // getItem(
        //   <span className="text-white text-[16px] mplus1pnormal">{t('REVIEW')}</span>,
        //   'review'
        // ),
      ]
    ),
    // getItem(
    //   <span className="text-white text-[16px] mplus1pextrabold">
    //     {t('SHOP')}
    //     {t('MANAGEMENT')}
    //   </span>,
    //   'shop-mana',
    //   [
    //     getItem(
    //       <span className="text-white text-[16px] mplus1pnormal">shop list</span>,
    //       'shop-list'
    //     ),
    //     getItem(
    //       <span className="text-white text-[16px] mplus1pnormal">{t('ADD_NEW')}</span>,
    //       'shop-add'
    //     ),
    //     getItem(
    //       <span className="text-white text-[16px] mplus1pnormal">{t('REVIEW')}</span>,
    //       'review-shop'
    //     ),
    //   ]
    // ),
    getItem(
      <span className="text-white text-[16px] mplus1pextrabold">{t('MARKETING')}</span>,
      'maketing',
      [
        // getItem(
        //   <span className="text-white text-[16px] mplus1pnormal">{t('COUPON')}</span>,
        //   'coupon'
        // ),
        getItem(
          <span className="text-white text-[16px] mplus1pnormal">{t('BANNER')}</span>,
          'banner'
        ),
        // getItem(
        //   <span className="text-white text-[16px] mplus1pnormal">
        //     {t('PAID_RANKING')}
        //     {t('MANAGEMENT')}
        //   </span>,
        //   'paid-ranking-mana'
        // ),
      ]
    ),
    getItem(
      <span className="text-white text-[16px] mplus1pextrabold">{t('SETTINGS')}</span>,
      'settings',
      [
        // getItem(
        //   <span className="text-white text-[16px] mplus1pnormal">Square ログイン</span>,
        //   'square'
        // ),
        getItem(
          <span className="text-white text-[16px] mplus1pnormal">アドミン</span>,
          'profile-admin'
        ),
        getItem(
          <span className="text-white text-[16px] mplus1pnormal">アドミン追加</span>,
          'add-new-admin'
        ),
      ]
    ),
    // getItem(
    //   <span className="text-white text-[16px] mplus1pextrabold">MEDIA GALLERY</span>,
    //   'media'
    // ),
  ];

  const rootSubmenuKeys = [
    'merchandise-mana',
    'sale-mana',
    'blog',
    'user-mana',
    'shop-mana',
    'maketing',
    'media',
    'settings',
  ];
  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const onSelectTab = (e: any) => {
    history.push('/' + e.key);
  };
  useEffect(() => {
    if (history.location.pathname === '/') {
      setOpenKeys([]);
    }
  }, [history.location.pathname]);
  return (
    <>
      <Menu
        onClick={onSelectTab}
        className="menu_sidebar bg-[#2D4600] mb-[20%]"
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        items={items}
      />
    </>
  );
};
export default SidebarPage;
