import { getToken } from 'helpers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { fetchAllOrderByAdmin } from 'slices/orderSlice';
import { fetchProductReviewByAdmin } from 'slices/productSlice';
import { fetchGetLogs } from 'slices/userSlice';
const HomePage = () => {
  const { t } = useTranslation();
  const arr = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }];
  const productReviewByAdmin = useAppSelector((state) => state.products.ProductReviewsByAdminList);
  const getLogs = useAppSelector((state) => state.users.getLogs);
  const allOrderByAdmin = useAppSelector((state) => state.orders.orderByAdminList);
  const token = getToken();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProductReviewByAdmin({ token, limit: 3, page: 1, type: 'get_all' }));
  }, [token, dispatch]);
  useEffect(() => {
    dispatch(fetchGetLogs({ token, limit: 8, type: 'login' }));
  }, [dispatch, token]);
  useEffect(() => {
    dispatch(fetchAllOrderByAdmin({ token, limit: 5, page: 1 }));
  }, [token, dispatch]);

  return (
    <div className="py-[26px] px-[5%] overflow-auto flex mb-[20%]">
      <div className="flex-1 flex flex-col pr-[2%]">
        <div className="flex flex-col">
          <span className="text-[16px] mplus1pextrabold mb-[10px]">{t('RECENT_REVIEW')}</span>
          <div className="border-[0.5px] border-black/40 bg-[#FFFFFF] h-[413px] flex flex-col">
            {productReviewByAdmin?.map((item: any, idx: any) => {
              return (
                <div
                  key={idx}
                  className={`${
                    idx !== 2 && 'border-b'
                  } w-full py-[10px] h-[33%] justify-between flex flex-col px-[2%] border-[#C1C1C1]`}
                >
                  <span className="text-[12px] mplus1pnormal">
                    From
                    <span className="text-[12px] text-[#0038FF] ml-[5px] underline">
                      （
                      {item?.customerId?.userName ||
                        item?.customerId?.firstName + item?.customerId?.lastName}
                      ）
                    </span>
                    on
                    <span className="text-[12px] text-[#0038FF] underline ml-[5px]">
                      {item?.productId?.name}
                    </span>
                  </span>
                  <span
                    dangerouslySetInnerHTML={{ __html: item?.content }}
                    className="text-[12px] mplus1pnormal"
                  ></span>
                  <div className="w-full flex justify-end">
                    <span className="text-[12px] mplus1pnormal">
                      {t('NOT_APPROVE')} | 返信する |
                      <span className="text-[#C70000]"> {t('TO_SPAM')}</span>
                    </span>
                  </div>
                </div>
              );
            })}

            {/* <div className="w-full border-b border-[#C1C1C1] h-[33%]"></div>
            <div className="w-full h-[33%]"></div> */}
          </div>
        </div>
        <div className="flex flex-col mt-[12px]">
          <span className="text-[16px] mplus1pextrabold mb-[10px]">{t('RECENT_UPDATE')}</span>
          <div className="border-[0.5px] flex flex-col pt-[30px] border-black/40 bg-[#FFFFFF] h-[413px]">
            {getLogs?.map((item: any, idx: any) => {
              return (
                <div key={idx} className="flex w-full pb-[30px] px-[2%]">
                  <div className="w-[25%] text-center">
                    <span className="text-[14px] mplus1pnormal">
                      {moment(item?.createdAt).format('MM/DD/YYYY')}
                    </span>
                  </div>
                  <div className="w-[25%] text-center">
                    <span className="text-[14px] mplus1pnormal">
                      {moment(item?.createdAt).format('HH:MM')}
                    </span>
                  </div>
                  <div className="w-[25%] text-center">
                    <span className="text-[14px] mplus1pnormal text-[#0038FF]">
                      {item?.content?.objectType}
                    </span>
                  </div>
                  <div className="w-[25%] text-center">
                    <span className="text-[14px] mplus1pnormal">
                      by{' '}
                      <span className="text-[14px] mplus1pnormal text-[#0038FF]">
                        {item?.content?.adminInfo}
                      </span>{' '}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col pl-[2%]">
        <div className="flex flex-col">
          <span className="text-[16px] mplus1pextrabold mb-[10px]">{t('RECENT_ORDER')}</span>
          <div className="border-[0.5px] border-black/40 bg-[#FFFFFF] h-[413px] flex flex-col">
            {allOrderByAdmin?.result?.map((item: any, idx: any) => {
              return (
                <div
                  key={idx}
                  className="w-full border-b h-[20%] flex items-center py-[13px] border-[#C1C1C1]"
                >
                  <div className="flex flex-col flex-1 pl-[4%]">
                    <span className="text-[12px] mplus1pnormal text-[#0038FF] underline">
                      オーダー　# {item?.code}　詳細
                    </span>
                    <span className="text-[12px] mplus1pnormal mt-[10px]">
                      {t('ORDER_NUMBER')}{' '}
                    </span>
                  </div>
                  <div className="flex flex-col flex-1">
                    <span className="text-[12px] mplus1pnormal mt-[10px]">
                      支払日 {moment(item?.updatedAt).format('MM/DD/YYYY')}　
                      {moment(item?.updatedAt).format('HH:MM')}{' '}
                    </span>
                    <span className="text-[12px] mplus1pnormal mt-[10px]">
                      顧客のIP　{item?._id}
                    </span>
                  </div>
                </div>
              );
            })}
            {/* <div className="w-full border-b flex items-center py-[13px] border-[#C1C1C1]">
              <div className="flex flex-col flex-1 pl-[4%]">
                <span className="text-[12px] mplus1pnormal text-[#0038FF] underline">
                  オーダー　#30015　詳細
                </span>
                <span className="text-[12px] mplus1pnormal mt-[10px]">{t('ORDER_NUMBER')} </span>
              </div>
              <div className="flex flex-col flex-1">
                <span className="text-[12px] mplus1pnormal mt-[10px]">
                  支払日　12/23/2022 07:24AM{' '}
                </span>
                <span className="text-[12px] mplus1pnormal mt-[10px]">
                  顧客のIP　000.000.000.000
                </span>
              </div>
            </div>
            <div className="w-full border-b flex items-center py-[13px] border-[#C1C1C1]">
              <div className="flex flex-col flex-1 pl-[4%]">
                <span className="text-[12px] mplus1pnormal text-[#0038FF] underline">
                  オーダー　#30014　詳細
                </span>
                <span className="text-[12px] mplus1pnormal mt-[10px]">{t('ORDER_NUMBER')} </span>
              </div>
              <div className="flex flex-col flex-1">
                <span className="text-[12px] mplus1pnormal mt-[10px]">
                  支払日　12/23/2022 07:24AM{' '}
                </span>
                <span className="text-[12px] mplus1pnormal mt-[10px]">
                  顧客のIP　000.000.000.000
                </span>
              </div>
            </div>
            <div className="w-full border-b flex items-center py-[13px] border-[#C1C1C1]">
              <div className="flex flex-col flex-1 pl-[4%]">
                <span className="text-[12px] mplus1pnormal text-[#0038FF] underline">
                  オーダー　#30013　詳細
                </span>
                <span className="text-[12px] mplus1pnormal mt-[10px]">{t('ORDER_NUMBER')} </span>
              </div>
              <div className="flex flex-col flex-1">
                <span className="text-[12px] mplus1pnormal mt-[10px]">
                  支払日　12/23/2022 07:24AM{' '}
                </span>
                <span className="text-[12px] mplus1pnormal mt-[10px]">
                  顧客のIP　000.000.000.000
                </span>
              </div>
            </div>
            <div className="w-full border-b flex items-center py-[13px] border-[#C1C1C1]">
              <div className="flex flex-col flex-1 pl-[4%]">
                <span className="text-[12px] mplus1pnormal text-[#0038FF] underline">
                  オーダー　#30012　詳細
                </span>
                <span className="text-[12px] mplus1pnormal mt-[10px]">{t('ORDER_NUMBER')} </span>
              </div>
              <div className="flex flex-col flex-1">
                <span className="text-[12px] mplus1pnormal mt-[10px]">
                  支払日　12/23/2022 07:24AM{' '}
                </span>
                <span className="text-[12px] mplus1pnormal mt-[10px]">
                  顧客のIP　000.000.000.000
                </span>
              </div>
            </div>
            <div className="w-full flex items-center py-[13px]">
              <div className="flex flex-col flex-1 pl-[4%]">
                <span className="text-[12px] mplus1pnormal text-[#0038FF] underline">
                  オーダー　#30011　詳細
                </span>
                <span className="text-[12px] mplus1pnormal mt-[10px]">{t('ORDER_NUMBER')} </span>
              </div>
              <div className="flex flex-col flex-1">
                <span className="text-[12px] mplus1pnormal mt-[10px]">
                  支払日　12/23/2022 07:24AM{' '}
                </span>
                <span className="text-[12px] mplus1pnormal mt-[10px]">
                  顧客のIP　000.000.000.000
                </span>
              </div>
            </div> */}
            {/* <div className="w-full border-b border-[#C1C1C1] h-[33%]"></div>
            <div className="w-full h-[33%]"></div> */}
          </div>
        </div>
        <div className="flex flex-col mt-[12px]">
          <span className="text-[16px] mplus1pextrabold mb-[10px]">{t('SALES_RANKING')}</span>
          <div className="border-[0.5px] border-black/40 bg-[#FFFFFF] h-[413px] w-full flex justify-center items-center">
            <span className="text-[14px] mplus1pnormal">{t('SALES_RANKING_TEXT')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
