import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { RootState } from 'redux/store';
import { TagTypes, TypeTagList } from 'models/tag';
export interface tagTypes {
  productTags: TagTypes[];
  getAllProductTagsByAdmin: TypeTagList;
}
const initialState: tagTypes = {
  productTags: [],
  getAllProductTagsByAdmin: {
    count: 0,
    result: [] as TagTypes[],
  },
};

const productTagSlice = createSlice({
  name: 'productTags',
  initialState,
  reducers: {
    fetchProductTag(state, action) {},
    fetchProductTagSuccess(state, action) {
      state.productTags = action.payload;
    },
    addProductTag(state, action) {},
    addProductTagSuccess(state, action) {
      state.getAllProductTagsByAdmin.result.push(action.payload);
      state.productTags.push(action.payload);
    },
    fetchAllProductTagsByAdmin(state, action) {},
    fetchAllProductTagsByAdminSuccess(state, action) {
      state.getAllProductTagsByAdmin = action.payload;
    },
    updateProductTag(state, action) {},
    updateProductTagSuccess(state, action) {
      state.getAllProductTagsByAdmin.result = state.getAllProductTagsByAdmin.result.map(
        (item: any) => (item.id === action.payload.id ? action.payload : item)
      );
    },
    deleteProductTag(state, action) {},
    deleteProductTagSuccess(state, action) {
      state.getAllProductTagsByAdmin.result = state.getAllProductTagsByAdmin.result.filter(
        (x) => x.id !== action.payload
      );
    },
  },
});

// Actions
export const {
  fetchProductTag,
  fetchProductTagSuccess,
  addProductTag,
  addProductTagSuccess,
  fetchAllProductTagsByAdmin,
  fetchAllProductTagsByAdminSuccess,
  updateProductTag,
  updateProductTagSuccess,
  deleteProductTag,
  deleteProductTagSuccess,
} = productTagSlice.actions;
// Selectors
export const selectTagProduct = (state: RootState) => state.productTags;

// Reducer
const productTagReducer = productTagSlice.reducer;
export default productTagReducer;
