import { Button, Checkbox, Input, Select, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';

import * as cts from '../../constants/ui/svg';
interface DataType {
  key: React.Key;
  img: any;
  name: string;
  price: string;
  stock: string;
  category: string;
  ranking: string;
  review: string;
  date: string;
}
const ProductListOfShopPage = () => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);
  const columns: ColumnsType<DataType> = [
    {
      title: <Checkbox disabled={true} className="flex justify-center w-full ckb_table"></Checkbox>,
      key: 'operation',
      fixed: 'left',
      width: 50,
      render: () => <Checkbox className="flex justify-center w-full ckb_table"></Checkbox>,
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">{t('IMAGE')}</span>
      ),
      key: 'img',
      dataIndex: 'img',
      width: 80,
      render: (value: any) => (
        <span className="flex justify-center w-full">
          <span className="bg-[#c1c1c1] w-[30px]">{cts.iconHome1}</span>
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal">{t('GIVEN_NAME')}</span>,
      width: 180,
      dataIndex: 'product',
      key: 'product',
      render: (value: any) => (
        <div className="flex flex-col">
          <span className="text-[#0038FF] text-[14px] mplus1pmedium">{value.name}</span>
          <span className="text-[10px] mplus1pnormal text-[#444444]">{value.id}</span>
          <div className="flex items-center">
            <span className="text-[10px] mplus1pnormal text-[#444444] cursor-pointer">view</span>
            <span className="text-[#444444] mx-[5px]">|</span>
            <span className="text-[10px] mplus1pnormal text-[#444444] cursor-pointer">
              {t('EDIT')}
            </span>
            <span className="text-[#444444] mx-[5px]">|</span>
            <span className="text-[10px] mplus1pnormal text-[#444444] cursor-pointer">
              {t('QUICK')}
              {t('EDIT')}
            </span>
            <span className="text-[#444444] mx-[5px]">|</span>
            <span className="text-[10px] mplus1pnormal text-[#444444] cursor-pointer">
              {t('DUPLICATION')}
            </span>
            <span className="text-[#444444] mx-[5px]">|</span>
            <span className="text-[10px] mplus1pnormal text-[#C70000] cursor-pointer">
              {t('DELETE')}
            </span>
          </div>
        </div>
      ),
    },
    // Table.EXPAND_COLUMN,
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">
          {t('STOCK_AMOUNT')}
        </span>
      ),
      width: 100,
      dataIndex: 'price',
      key: 'price',
      render: (value: any) => (
        <span className="text-[12px] flex justify-center mplus1pnormal">{value}</span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">{t('STOCK')}</span>
      ),
      dataIndex: 'stock',
      key: 'stock',
      width: 100,
      render: (value: any) => (
        <span className="flex justify-center mplus1pmedium text-[#3E6100] text-[12px] w-full">
          {value}
        </span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">{t('PRICE')}</span>
      ),
      dataIndex: 'category',
      key: 'category',
      width: 100,
      render: (value: any) => (
        <span className="text-[12px] mplus1pnormal flex justify-center w-full">{value}</span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal">{t('CATEGORY')}</span>,
      dataIndex: 'ranking',
      key: 'ranking',
      width: 100,
      render: (value: any) => (
        <span className="text-[12px] mplus1pnormal text-[#0038FF]">有料ランキング</span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal">{t('TAG')}</span>,
      dataIndex: 'review',
      key: 'review',
      width: 80,
      render: (value: any) => (
        <span className="text-[12px] mplus1pnormal text-[#0038FF]">{value}</span>
      ),
    },
    // {
    //   title: <span className="text-[14px] mplus1pnormal">total sales amount</span>,
    //   dataIndex: 'total_sale',
    //   key: 'total_sale',
    //   width: 100,
    //   render: (value: any) => <span>{value}</span>,
    // },
    {
      title: <span className="text-[14px] mplus1pnormal">{t('DATE')}</span>,
      dataIndex: 'date',
      key: 'date',
      fixed: 'right',
      width: 180,
      render: (value: any) => (
        <div className="flex flex-col">
          <span>公開済み</span>
          <span>2021/10/25 at 3:47 pm</span>
        </div>
      ),
    },
  ];
  const data: DataType[] = [
    {
      key: 1,
      img: 'img',
      name: 'Product 1',
      price: '¥6,600',
      stock: 'in ストック',
      category: 'スキンケア',
      review: '10',
      ranking: '１位',
      date: '公開済み',
    },
    {
      key: 2,
      img: 'img',
      name: 'Product 2',
      price: '¥6,600',
      stock: 'in ストック',
      category: 'スキンケア',
      review: '10',
      ranking: '１位',
      date: '公開済み',
    },
    {
      key: 3,
      img: 'img',
      name: 'Product 3',
      price: '¥6,600',
      stock: 'in ストック',
      category: 'スキンケア',
      review: '10',
      ranking: '１位',
      date: '公開済み',
    },
    {
      key: 4,
      img: 'img',
      name: 'Product 4',
      price: '¥6,600',
      stock: 'in ストック',
      category: 'スキンケア',
      review: '10',
      ranking: '１位',
      date: '公開済み',
    },
  ];
  const newData = data.map((item) => {
    return {
      ...item,
      product: {
        name: item.name,
        id: item.key,
      },
    };
  });
  const _renderContentTable = () => {
    return (
      <div className="flex px-[30px] pt-[10px] pb-[30px] bg-[#F5F5F5] border-r border-l border-black/30">
        <div className="flex-1 flex flex-col pr-[5%]">
          <span className="text-[14px] mplus1pextrabold ">{t('PRODUCT_DATA')}</span>
          <div className="flex mt-[20px] items-center">
            <span className="text-[14px] mplus1pnormal w-[25%]">{t('TITLE')}</span>
            <Input className="w-[75%] h-[30px] border border-black/50" />
          </div>
          <div className="flex mt-[20px] items-center">
            <span className="text-[14px] mplus1pnormal w-[25%]">{t('LINK_NAME')}</span>
            <Input className="w-[75%] h-[30px] border border-black/50" />
          </div>
          <div className="flex mt-[20px] items-center">
            <span className="text-[14px] mplus1pnormal w-[25%]">{t('DATE')}</span>
            <div className="w-[75%]">
              <Input className="w-[59px] h-[30px] border border-black/50" />
              <span className="text-[12px] mplus1pnormal ml-[10px]">{t('YEAR')}</span>
              <Select className="ml-[10px] w-[105px] mr-[5px]" />
              <Input className="w-[48px] h-[30px] border border-black/50" />
            </div>
          </div>
          <div className="flex mt-[20px] items-center">
            <span className="text-[14px] mplus1pnormal w-[25%]">
              {t('PRICE')}({t('TAX_INCLUDED')})
            </span>
            <Input className="w-[75%] h-[30px] border border-black/50" />
          </div>
          <div className="flex mt-[20px] items-center">
            <span className="text-[14px] mplus1pnormal w-[25%]">
              {t('PRICE')}({t('WITHOUT_TAX')})
            </span>
            <Input className="w-[75%] h-[30px] border border-black/50" />
          </div>
          <div className="flex mt-[47px] items-center">
            <Checkbox />
            <span className="text-[14px] mplus1pnormal ml-[25px] mr-[50px]">
              {t('PAID_RANKING')}
            </span>
            <Select className="w-[58px]" />
          </div>
          <div className="mt-[20px] flex flex-col">
            <div className="bg-[#FBF8F1] border border-black/50 rounded-t-[5px] h-[44px]"></div>
            <div className="bg-white border-l border-r py-[5px] border-black/50 flex items-center justify-center">
              <span className="bg-white rounded-[10px] px-[10px] border border-black/50 py-[10px] flex flex-col items-center">
                <span className="text-[14px] mplus1pnormal">average monthly sales</span>
                <span className="text-[20px] mplus1pextrabold mt-[15px]">100</span>
              </span>
              <span className="bg-white rounded-[10px] px-[10px] ml-[10px] border border-black/50 py-[10px] flex flex-col items-center">
                <span className="text-[14px] mplus1pnormal">Sales rank</span>
                <span className="text-[20px] mplus1pextrabold mt-[15px]">1</span>
              </span>
              <span className="bg-white rounded-[10px] px-[10px] ml-[10px] border border-black/50 py-[10px] flex flex-col items-center">
                <span className="text-[14px] mplus1pnormal">Total review</span>
                <span className="text-[20px] mplus1pextrabold mt-[15px]">3</span>
              </span>
            </div>
            <div className="flex border border-black/50 bg-[#FBF8F1] h-[44px]">
              <span className="flex-1 flex items-center justify-center text-[12px] mplus1pmedium border-r border-black/50">
                Organic ranking
              </span>
              <span className="flex-1 flex items-center justify-center text-[12px] mplus1pmedium">
                Paid ranking
              </span>
            </div>
            <div className="flex border-b border-l border-r border-black/50 bg-white h-[44px]">
              <span className="flex-1 flex items-center justify-center text-[12px] mplus1pmedium border-r border-black/50">
                <span className="w-[25%] flex justify-end">1</span>
                <span className="text-[#0038FF] ml-[10px] w-[75%]"> フェイシャルオイル AP</span>
              </span>
              <span className="flex-1 flex items-center justify-center text-[12px] mplus1pmedium">
                <span className="w-[25%] flex justify-end">1</span>
                <span className="text-[#0038FF] ml-[10px] w-[75%]"> フェイシャルオイル AP</span>
              </span>
            </div>
            <div className="flex border-b border-l border-r border-black/50 bg-[#FBF8F1] h-[44px]">
              <span className="flex-1 flex items-center justify-center text-[12px] mplus1pmedium border-r border-black/50">
                <span className="w-[25%] flex justify-end">2</span>
                <span className="text-[#0038FF] ml-[10px] w-[75%]"> フェイシャルオイル AP</span>
              </span>
              <span className="flex-1 flex items-center justify-center text-[12px] mplus1pmedium">
                <span className="w-[25%] flex justify-end">2</span>
                <span className="text-[#0038FF] ml-[10px] w-[75%]"> フェイシャルオイル AP</span>
              </span>
            </div>
            <div className="flex border-b border-l border-r border-black/50 bg-white h-[44px]">
              <span className="flex-1 flex items-center justify-center text-[12px] mplus1pmedium border-r border-black/50">
                <span className="w-[25%] flex justify-end">3</span>
                <span className="text-[#0038FF] ml-[10px] w-[75%]"> フェイシャルオイル AP</span>
              </span>
              <span className="flex-1 flex items-center justify-center text-[12px] mplus1pmedium">
                <span className="w-[25%] flex justify-end">3</span>
                <span className="text-[#0038FF] ml-[10px] w-[75%]"> </span>
              </span>
            </div>
            <div className="flex border-b border-l border-r border-black/50 bg-[#FBF8F1] h-[44px]">
              <span className="flex-1 flex items-center justify-center text-[12px] mplus1pmedium border-r border-black/50">
                <span className="w-[25%] flex justify-end">4</span>
                <span className="text-[#0038FF] ml-[10px] w-[75%]"> フェイシャルオイル AP</span>
              </span>
              <span className="flex-1 flex items-center justify-center text-[12px] mplus1pmedium">
                <span className="w-[25%] flex justify-end">4</span>
                <span className="text-[#0038FF] ml-[10px] w-[75%]"> </span>
              </span>
            </div>
            <div className="flex border-b border-l border-r rounded-b-[10px] border-black/50 bg-white h-[44px]">
              <span className="flex-1 flex items-center justify-center text-[12px] mplus1pmedium border-r border-black/50">
                <span className="w-[25%] flex justify-end">5</span>
                <span className="text-[#0038FF] ml-[10px] w-[75%]"> フェイシャルオイル AP</span>
              </span>
              <span className="flex-1 flex items-center justify-center text-[12px] mplus1pmedium">
                <span className="w-[25%] flex justify-end">5</span>
                <span className="text-[#0038FF] ml-[10px] w-[75%]"> </span>
              </span>
            </div>
          </div>
          <span
            onClick={() => setIsExpanded(false)}
            className="cursor-pointer text-[14px] mplus1pextrabold text-white rounded-[10px] h-[39px] flex items-center justify-center w-[63px] bg-[#3E6100] mt-[45px]"
          >
            close
          </span>
        </div>
        <div className="flex-1 flex flex-col">
          <span className="text-[14px] mplus1pextrabold text-[#C70000]">Product review</span>
          <div className="mt-[25px] flex flex-col">
            <div className="flex items-center bg-[#FBF8F1] justify-between px-[15px] h-[44px] border border-black/50 rounded-t-[5px]">
              <span className="flex items-center">{cts.iconGrStar} (3)</span>
              <span className="text-[14px] mplus1pnormal">total review 3 </span>
            </div>
            <div className="flex flex-col px-[21px] bg-white border-l border-r border-b pt-[10px] border-black/50 rounded-b-[5px] pb-[100px]">
              <div className=" overflow-auto ctn_overflow_shopmana h-[447px]">
                <div className="flex pt-[39px] items-center border-b border-black/50 pb-[22px]">
                  <div className="w-[25%] flex flex-col items-center">
                    <span>{cts.iconUserProfile}</span>
                    <span className="text-[14px] mplus1pextrabold mt-[10px]">タロウさん</span>
                  </div>
                  <div className="w-[75%] ml-[5%] flex flex-col">
                    <span>{cts.iconGrStar}</span>
                    <span className="mt-[15px] text-[14px] mplus1pmedium">
                      この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。この文章はダミーです。
                    </span>
                    <span className="mt-[21px] text-[14px] mplus1pmedium text-[#9F9F9F]">
                      投稿日：2020/04/13
                    </span>
                  </div>
                </div>
                <div className="flex pt-[39px] items-center border-b border-black/50 pb-[22px]">
                  <div className="w-[25%] flex flex-col items-center">
                    <span>{cts.iconUserProfile}</span>
                    <span className="text-[14px] mplus1pextrabold mt-[10px]">タロウさん</span>
                  </div>
                  <div className="w-[75%] ml-[5%] flex flex-col">
                    <span>{cts.iconGrStar}</span>
                    <span className="mt-[15px] text-[14px] mplus1pmedium">
                      この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。この文章はダミーです。
                    </span>
                    <span className="mt-[21px] text-[14px] mplus1pmedium text-[#9F9F9F]">
                      投稿日：2020/04/13
                    </span>
                  </div>
                </div>
                <div className="flex pt-[39px] items-center border-b border-black/50 pb-[22px]">
                  <div className="w-[25%] flex flex-col items-center">
                    <span>{cts.iconUserProfile}</span>
                    <span className="text-[14px] mplus1pextrabold mt-[10px]">タロウさん</span>
                  </div>
                  <div className="w-[75%] ml-[5%] flex flex-col">
                    <span>{cts.iconGrStar}</span>
                    <span className="mt-[15px] text-[14px] mplus1pmedium">
                      この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。この文章はダミーです。
                    </span>
                    <span className="mt-[21px] text-[14px] mplus1pmedium text-[#9F9F9F]">
                      投稿日：2020/04/13
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="text-[20px] mplus1pextrabold">{t('PRODUCT_LIST_OF_SHOP')}</span>
        <div className="w-[30%] pl-[2%] pb-[10px]">
          <div className="flex items-center justify-end">
            <span className="h-[40px] w-[48px] border-[0.5px] rounded-l-[10px] border-black/50 bg-[#D9D9D9] flex justify-center items-center">
              {cts.iconSearch}
            </span>
            <Input
              placeholder="SEARCH ユーザー管理S"
              className="w-[70%] h-[40px] border-[0.5px] border-black/50 rounded-r-[10px] rounded-l-[0px]"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center mt-[20px]">
        <span className="text-[14px] mplus1pnormal underline text-[#0038FF]">
          {t('ALL')}
          <span className="text-black underline">(4)</span>{' '}
        </span>
        <span className="ml-[14px] mr-[22px] w-px h-[20px] bg-black"></span>
        <span className="text-[14px] mplus1pnormal underline text-[#0038FF]">
          ADMINISTRATOR
          <span className="text-black underline">(0)</span>{' '}
        </span>
        <span className="ml-[22px] mr-[17px] w-px h-[20px] bg-black"></span>
        <span className="text-[14px] mplus1pnormal underline text-[#0038FF]">
          {t('SHOP')}
          <span className="text-black underline">(4)</span>{' '}
        </span>
        <span className="ml-[16px] w-px h-[20px] bg-black"></span>
      </div>
      <div className="flex mt-[20px] w-full items-center justify-end">
        <Select
          defaultValue="一括操作"
          className="w-[112px] slc_merchandise mr-[10px]"
          options={[
            { value: '一括操作', label: '一括操作' },
            { value: 'Yiminghe', label: 'yiminghe' },
          ]}
        />
        {/* <Button className="bg-white h-[30px] border-[0.5px] text-[14px] mplus1pnormal border-black mr-[10px]">
          {t('APPLY')}
        </Button> */}
        <Select
          defaultValue="filter by stock"
          className="w-[158px] slc_merchandise mr-[10px]"
          options={[
            { value: 'filter by stock', label: 'filter by stock' },
            { value: 'Yiminghe', label: 'yiminghe' },
          ]}
        />
        <Select
          defaultValue="カテゴリー一覧"
          className="w-[158px] slc_merchandise mr-[10px]"
          options={[
            { value: 'カテゴリー一覧', label: 'カテゴリー一覧' },
            { value: 'Yiminghe', label: 'yiminghe' },
          ]}
        />
        <Select
          defaultValue=" 商品 のranking type"
          className="w-[191px] slc_merchandise mr-[10px]"
          options={[
            { value: ' 商品 のranking type', label: ' 商品 のranking type' },
            { value: 'Yiminghe', label: 'yiminghe' },
          ]}
        />
      </div>
      <div className="mt-[20px] mb-[100px]">
        <Table
          className="table_merchandise"
          columns={columns}
          dataSource={newData}
          scroll={{ x: 800 }}
          expandable={{
            showExpandColumn: false,
            expandRowByClick: true,
            onExpand: () => {
            },
            rowExpandable: () => isExpanded,
            expandedRowRender: () => _renderContentTable(),
          }}
          onRow={(record, index) => {
            return {
              onClick: () => {
                setIsExpanded(true);
              },
            };
          }}
        />
      </div>
    </div>
  );
};
export default ProductListOfShopPage;
