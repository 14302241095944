import { useEffect, useState } from 'react';
import { Table, Input, Select, Button, Checkbox, Pagination, DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import type { ColumnsType } from 'antd/es/table';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import * as cts from '../../constants/ui/svg';
import type { PaginationProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { getToken } from 'helpers';
import { useDispatch } from 'react-redux';
import { exportCSVOrder, fetchOrderOfCustomerList } from 'slices/orderSlice';
import { useAppSelector } from 'redux/hooks';
import { OrderOfCustomerTypes } from '../../models/order';
interface DataType {
  key: React.Key;
  order: string;
  name: any;
  user_name: any;
  last_active: any;
  stregistration_date: any;
  email_only: any;
  total_spending: any;
  average_order_value: any;
}

const { Search } = Input;

const onSearch = (value: string) => console.log(value);
const Client = () => {
  const { t } = useTranslation();
  const date = moment().format('MM-DD-YYYY');
  const token = getToken();
  const dispatch = useDispatch();
  const pageSize = 10;
  const allOrderOfCustomer = useAppSelector((state) => state.orders.orderOfCustomerList);
  const urlExport = useAppSelector((state) => state.orders.exportCSVOrderURL);
  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const columns: ColumnsType<OrderOfCustomerTypes> = [
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-start pl-[10%] w-full">
          {t('NAME')}
        </span>
      ),
      width: 180,
      dataIndex: 'firstName',
      fixed: 'left',
      key: 'firstName',
      render: (value: any) => (
        <span className="w-full flex justify-start text-[#0038FF] pl-[10%]">{value}</span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">
          {t('USERNAME')}
        </span>
      ),
      dataIndex: 'userName',
      key: 'userName',
      width: 200,
      render: (value: any) => <span className="flex justify-center w-full">{value || '--'}</span>,
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-start w-full">
          {t('LAST_ACTIVE')}
        </span>
      ),
      dataIndex: 'lastOrderDay',
      key: 'lastOrderDay',
      width: 180,
      render: (value: any) => (
        <span className="text-[12px] mplus1pmedium flex justify-start w-full">
          {moment(value).format('MM月DD日')} {moment(value).format('YYYY年')}
        </span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-start w-full">
          {t('REGISTRATION_DATE')}
        </span>
      ),
      dataIndex: 'registerDay',
      key: 'registerDay',
      width: 100,
      render: (value: any) => (
        <span className=" flex justify-start w-full">{moment(value).format('YYYY/MM/DD')}</span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center ">{t('EMAIL_ONLY')}</span>
      ),
      dataIndex: 'email',
      key: 'email',
      width: 150,
      render: (value: any) => (
        <span className="text-[12px] mplus1pnormal flex text-start justify-center text-[#0038FF]">
          {value}
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center ">{t('ORDER')}</span>,
      dataIndex: 'numberOfOrder',
      key: 'numberOfOrder',
      width: 150,
      render: (value: any) => (
        <span className="text-[12px] mplus1pnormal flex text-start justify-center">{value}</span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center ">
          {t('TOTAL_SPENDING')}
        </span>
      ),
      dataIndex: 'totalPay',
      key: 'totalPay',
      width: 150,
      render: (value: any) => (
        <span className="text-[12px] mplus1pnormal flex text-start justify-center">
          ¥{Number(value).toLocaleString()}
        </span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center ">
          {t('AVERAGE_ORDER_VALUE')}
        </span>
      ),
      dataIndex: 'avgPayment',
      key: 'avgPayment',
      fixed: 'right',
      width: 150,
      render: (value: any) => (
        <span className="text-[12px] mplus1pnormal flex text-start justify-center">
          ¥{Number(value).toLocaleString()}
        </span>
      ),
    },
  ];

  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a>
          <Button className="flex items-center shadow-none focus:shadow-none justify-center border-none hover:text-black prev_btn">
            <span className="text-[10px] mplus1pnormal">to previous page</span>
          </Button>
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a>
          <Button className="flex items-center shadow-none justify-center border-none hover:text-black prev_btn">
            <span className="text-[10px] mplus1pnormal">to next page</span>
          </Button>
        </a>
      );
    }
    return originalElement;
  };
  const [pagi, setPagi] = useState<any>({
    allOrderOfCustomer: [],
    totalPage: allOrderOfCustomer?.count / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });

  const handleChange = (page: any) => {
    setPagi({
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
  };
  // const dataNew = data.map((item, ind) => {
  //   if (ind >= pagi.minIndex && ind < pagi.maxIndex) {
  //     return item;
  //   }
  // });
  // const arr = [dataNew];
  useEffect(() => {
    dispatch(fetchOrderOfCustomerList({ token, limit: pageSize, page: pagi.current }));
  }, [dispatch, pagi.current, token]);
  useEffect(() => {
    dispatch(exportCSVOrder({ token }));
  }, [dispatch, token]);

  return (
    <div className="pt-[26px] px-[35px] flex flex-col pb-[100px]">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <span className="text-[20px] mplus1pextrabold mr-[54px]">{t('CUSTOMER')}</span>
        </div>
      </div>
      <div className="flex justify-end herder-client">
        <Button
          onClick={() => window.open(urlExport)}
          className="bg-[#3692E7] flex mr-[40px] btn-download"
          type="primary"
          icon={cts.iconCLoud}
          size={'large'}
        >
          {t('DOWNLOAD')}
        </Button>
        <div className="flex items-center">
          {/* <span className="h-[40px] w-[48px] border-[0.5px] rounded-l-[10px] border-black/50 bg-[#D9D9D9] flex justify-center items-center">
            {cts.iconSearch}
          </span>
          <Input
            placeholder="SEARCH PRODUCT"
            className="w-[252px] h-[40px] border-[0.5px] border-black/50 rounded-r-[10px] rounded-l-[0px]"
          /> */}
        </div>
      </div>

      <div className="mt-[25px] mb-[100px]">
        <Table
          className="table-client"
          columns={columns}
          dataSource={allOrderOfCustomer?.result}
          scroll={{ x: 1000 }}
          pagination={{
            showSizeChanger: false,
            total: allOrderOfCustomer?.count,
            pageSize: 10,
            current: pagi.current,

            onChange: (page: any) => {
              setPagi({
                current: page,
                minIndex: (page - 1) * pageSize,
                maxIndex: page * pageSize,
              });
            },
          }}
        />
        {/* <div className="w-full flex justify-end mt-[40px]">
          <Pagination
            className="pagi_merchandise"
            itemRender={itemRender}
            current={pagi.current}
            onChange={handleChange}
            total={data.length}
            pageSize={pageSize}
          />
        </div> */}
      </div>
    </div>
  );
};
export default Client;
