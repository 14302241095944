import { Checkbox, Input, Modal, Popconfirm, Select, Spin, Upload } from 'antd';
import { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getToken } from 'helpers';
import { addBlog, addBlogTag, fetchAllCategoryBlog, fetchBlogsTag } from 'slices/blogSlice';
import { useAppSelector } from 'redux/hooks';
import { fetchAllCategory } from 'slices/categorySlice';
import { fetchProductTag } from 'slices/tagProductsSlice';
import { successToast, errorToast, failedToast } from 'helpers';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useHistory } from 'react-router-dom';
import BlogPreviewPage from './BlogPreviewPage';
import { uploadImageAPI } from 'api';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import * as cts from '../../constants/ui/svg';

const BlogAddPage = () => {
  const { Option } = Select;
  const inputRef = useRef<any>();
  const categories = useAppSelector((state) => state.blogs.getAllCategoryBlog);
  const tagProduct = useAppSelector((state) => state.blogs.blogTags);
  const dispatch = useDispatch();
  const token = getToken();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [previewBlog, setPreviewBlog] = useState(false);
  const [dataBlog, setDataBlog] = useState<any>({
    name: '',
    content: '',
    listTag: [],
    isHot: false,
  });
  const handleChangeSelect = (value: string[]) => {
    const arrNew: any = [value];
    const arr3 = arrNew[0]
      .map((item: any) => {
        const index = categories.findIndex((item2: any) => item2.name === item);
        return {
          id: categories[index]?.id,
        };
      })
      ?.map((item1: any) => item1.id);

    setDataBlog({ ...dataBlog, categoryId: arr3 });
  };
  const handleChangeSelectTag = (value: string[]) => {
    const arrNew: any = [value];
    const arr3 = arrNew[0]
      .map((item: any) => {
        const index = tagProduct.findIndex((item2: any) => item2.name === item);
        return {
          id: tagProduct[index]?.id,
        };
      })
      ?.map((item1: any) => item1.id);

    setDataBlog({ ...dataBlog, listTag: arr3 });
  };
  const loading = useAppSelector((state) => state.common.loading);
  const [btnLoad, setBtnLoad] = useState<any>({ load: loading, status: '' });
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  const [value, setValue] = useState('');
  const formats = ['header', 'underline', 'bold', 'italic', 'bullet', 'indent'];
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['underline', 'bold', 'italic'],
      [{ indent: '-1' }, { indent: '+1' }],
    ],
  };
  const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
  const [fileUpload, setFileUpload] = useState<any>();
  const onChangeCheck = (e: CheckboxChangeEvent) => {
    setDataBlog({ ...dataBlog, isHot: e.target.checked });
  };

  const changeHandler = (e: any) => {
    const file = e.target.files[0];
    if (!file?.type?.match(imageMimeType)) {
      alert('Image mime type is not valid');
      return;
    }
    setFileUpload(file);
  };
  useEffect(() => {
    let fileReader = new FileReader(),
      isCancel = false;
    if (fileUpload) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(fileUpload);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileUpload]);
  const onSaveBlog = (status: string) => {
    if (
      dataBlog.name !== '' &&
      dataBlog.content !== '' &&
      fileUpload !== undefined &&
      fileUpload !== null
    ) {
      setBtnLoad({ ...btnLoad, status: status });
      const param = {
        name: dataBlog.name,
        isHot: dataBlog.isHot,
        listTag: dataBlog.listTag,
        content: dataBlog.content,
        status: status,
      };
      dispatch(addBlog({ token, inputs: param, fileUpload, history }));
    } else {
      errorToast('Please input enough value');
    }
  };
  const { t } = useTranslation();

  const [fileDataURL, setFileDataURL] = useState();
  const onChangeCheckBox = (checkedValues: CheckboxValueType[]) => {
    const arrNew: any = [checkedValues];
    const arr3 = arrNew[0]
      .map((item: any) => {
        const index = tagProduct.findIndex((item2: any) => item2.name === item);
        return {
          id: tagProduct[index]?.id,
        };
      })
      ?.map((item1: any) => item1.id);

    // const arr3 = checkedValues
    //   .map((item: any) => {
    //     const index = categories?.findIndex((item2: any) => item2?.name === item);
    //     return {
    //       id: categories[index]?._id,
    //     };
    //   })
    //   .map((item1: any) => item1?.id);
    // setValueCate(arr3);
    setDataBlog({ ...dataBlog, listTag: arr3 as [] });
  };

  useEffect(() => {
    if (loading === false) {
      setBtnLoad({ ...btnLoad, load: false });
    } else {
      setBtnLoad({ ...btnLoad, load: true });
    }
  }, [loading]);
  useEffect(() => {
    dispatch(fetchAllCategoryBlog({ token, limit: 100 }));
  }, [dispatch, token]);
  useEffect(() => {
    dispatch(fetchBlogsTag({ token, limit: 100 }));
  }, [dispatch, token]);
  // useEffect(() => {
  //   dispatch(addBlogTag({ token, inputs: { ...dataBlog } }));
  // }, [token, dispatch]);
  return (
    <>
      {previewBlog ? (
        <BlogPreviewPage
          onSaveBlog={onSaveBlog}
          load={btnLoad.load}
          loadStatus={btnLoad.status}
          setPreviewBlog={setPreviewBlog}
          dataBlog={dataBlog}
          fileDataURL={fileDataURL}
        />
      ) : (
        <div className="pt-[26px] px-[35px] mb-[100px]">
          <span className="text-[20px] mplus1pextrabold">ブログ 新規追加</span>
          <div className="flex flex-col mt-[25px]">
            <div className="flex items-center">
              <span className="w-[25%] text-[14px] mplus1pnormal">{t('BLOG_NAME')}</span>
              <div className="w-[75%]">
                <Input
                  value={dataBlog.name}
                  onChange={(e) => setDataBlog({ ...dataBlog, name: e.target.value })}
                  className="w-[80%] h-[44px] rounded-[0px] border border-black/50"
                />
              </div>
            </div>
            <div className="flex  mt-[30px]">
              <span className="w-[25%] text-[14px] mplus1pnormal">{t('BLOG_TEXT')}</span>
              <div className="w-[75%]">
                <ReactQuill
                  className="form_add_merchandise border border-black/50 bg-white mt-[10px] mb-[20px] w-[80%]"
                  value={dataBlog.content}
                  onChange={(e) => setDataBlog({ ...dataBlog, content: e })}
                  theme="snow"
                  formats={formats}
                  modules={modules}
                />
              </div>
            </div>
            <div className="flex mt-[30px]">
              <span className="w-[25%] text-[14px] mplus1pnormal">{t('IMAGE')}</span>
              <div className="w-[75%]">
                <div className="w-[100%] px-[8px] h-[145px] border border-[#C1C1C1] bg-white flex items-center">
                  {/* {fileDataURL ? (
                    <img className="h-[132px] w-[132px] mr-[10px]" src={fileDataURL} />
                  ) : null}
                  <div className="relative flex items-center h-[132px] w-[132px] rounded-[5px] border border-[rgba(0,0,0,0.3)]">
                  
                    <span className="text-[12px] cursor-pointer mplus1pnormal text-[#0038FF] absolute w-[132px] flex justify-center">
                      + upload new image
                      <Input
                        ref={inputRef}
                        accept=".png, .jpg, .jpeg .webp"
                        type="file"
                       
                        onChange={changeHandler}
                        className="h-[132px] w-[132px] absolute text-white bg-white inp_upload opacity-0"
                      />
                    </span>
                  </div> */}

                  {fileUpload ? (
                    <div className="h-[130px] w-[130px] relative mx-[5px] border border-black/30">
                      <img className="h-[130px] w-[130px]  absolute" src={fileDataURL} />

                      <div className="absolute h-full w-full pt-[4px] pr-[4px] opacity-0 hover:opacity-100 flex justify-end">
                        <span
                          onClick={() => {
                            setOpenModal(true);
                          }}
                          className=" svg_close_img h-[22px] w-[22px] rounded-[11px] flex items-center justify-center bg-[#D9D9D9]/50 border border-black/20"
                        >
                          {cts.iconClose}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="relative flex items-center h-[132px] w-[132px]">
                      <div className="absolute h-[132px] w-[132px] border border-black/30">
                        <span className="text-[12px]  h-full items-center cursor-pointer mplus1pnormal text-[#0038FF] absolute w-[132px] flex justify-center">
                          + upload new image
                          <Input
                            ref={inputRef}
                            accept=".png, .jpg, .jpeg .webp"
                            type="file"
                            onChange={changeHandler}
                            className="h-[132px] w-[132px] absolute text-white bg-white inp_upload opacity-0"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center mt-[70px]">
              <span className="w-[25%] text-[14px] mplus1pnormal">{t('BLOG_TAG')}</span>
              <div className="w-[75%] flex items-center">
                <div className=" w-[215px]">
                  <Popconfirm
                    title="aaa"
                    placement="bottom"
                    description={() => {
                      return (
                        <div className="bg-white pop_blog_add flex flex-col rounded-[5px] px-[1px] py-[2px] border border-[rgba(0,0,0,0.2)]">
                          <Checkbox.Group
                            options={tagProduct?.map((item: any) => item?.name)}
                            onChange={onChangeCheckBox}
                            className="flex flex-col ckb_gr ckb_tag_product"
                          />
                        </div>
                      );
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <div className="h-[29px] bg-white px-[2px] border border-[rgba(0,0,0,0.5)] flex-1 rounded-[5px] flex justify-between items-center">
                      <span className="text-[12px] text-[rgba(0,0,0,0.3)]">カテゴリー選択</span>
                      <span>{cts.iconDownRes}</span>
                    </div>
                  </Popconfirm>
                </div>

                <span className="text-[12px] mplus1pnormal ml-[15px] text-[#0038FF] cursor-pointer">
                  + {t('ADD_NEW_TAG')}
                </span>
              </div>
            </div>
            <div className="flex items-center mt-[70px]">
              <span className="w-[25%] text-[14px] mplus1pnormal">{t('PIN_BLOG')}</span>
              <div className="w-[75%]">
                <Checkbox onChange={onChangeCheck} />
              </div>
            </div>
            <div className="flex items-center mt-[30px] mb-[100px]">
              <div className="relative w-[104px] flex items-center justify-center mr-[50px]">
                {btnLoad.load === true && btnLoad.status === 'draft' && (
                  <Spin className="absolute w-[104px] h-full items-center flex justify-center" />
                )}
                <span
                  onClick={() => onSaveBlog('draft')}
                  className={`${
                    btnLoad.load === true && btnLoad.status === 'draft'
                      ? ' opacity-20 absolute'
                      : ''
                  } h-[39px] px-[10px] cursor-pointer rounded-[10px] flex items-center justify-center text-white bg-[#3692E7] text-[14px] mplus1pextrabold`}
                >
                  {t('PRESERVE')}
                </span>
              </div>
              {/* <span
                onClick={() => onSaveBlog('draft')}
                className="h-[39px] px-[10px] cursor-pointer rounded-[10px] flex items-center justify-center text-white bg-[#3692E7] text-[14px] mplus1pextrabold mr-[50px]"
              >
                {t('PRESERVE')}
              </span> */}
              {/* <span
                onClick={() => onSaveBlog('public')}
                className="h-[39px] px-[20px] cursor-pointer rounded-[10px] flex items-center justify-center text-white bg-[#3E6100] text-[14px] mplus1pextrabold mr-[50px]"
              >
                {t('RELEASE')}
              </span> */}
              <div className="relative w-[68px] flex items-center justify-center mr-[50px]">
                {btnLoad.load === true && btnLoad.status === 'public' && (
                  <Spin className="absolute w-[68px] h-full items-center flex justify-center" />
                )}
                <span
                  onClick={() => onSaveBlog('public')}
                  className={`${
                    btnLoad.load === true && btnLoad.status === 'public'
                      ? 'opacity-20 absolute'
                      : ''
                  } h-[39px] px-[20px] cursor-pointer rounded-[10px] flex items-center justify-center text-white bg-[#3E6100] text-[14px] mplus1pextrabold`}
                >
                  {t('RELEASE')}
                </span>
              </div>
              <span
                onClick={() => setPreviewBlog(true)}
                className="h-[39px] cursor-pointer px-[10px] rounded-[10px] flex items-center justify-center text-white bg-[#DBB312] text-[14px] mplus1pextrabold border mr-[50px]"
              >
                {t('PREVIEW')}
              </span>
              <div
                onClick={() => history.push('/posts')}
                className="h-[39px] cursor-pointer text-[#3E6100] px-[10px] rounded-[10px] mr-[50px] border border-[#3E6100] flex items-center mplus1pextrabold text-[14px]"
              >
                {t('CANCEL')}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        mask={false}
        centered
        open={openModal}
        className="modal_delete h-[270px] !w-[375px]"
        //   onOk={() => setOpenModal(false)}
        //   onCancel={() => setOpenModal(false)}
      >
        <div className="flex flex-col justify-between items-center h-full">
          <span>{cts.iconWarning}</span>
          <span className="text-[14px] mplus1pmedium">このイメージを削除してもよろしいですか?</span>
          <div className="flex items-center w-full">
            <span
              className="h-[34px] rounded-[5px] flex items-center justify-center bg-[#9B0101] cursor-pointer text-white text-[14px] mplus1pextrabold w-full mr-[5px]"
              onClick={() => {
                setFileUpload(null);
                setOpenModal(false);
              }}
            >
              {t('DELETE')}
            </span>
            <span
              className="h-[34px] rounded-[5px] flex items-center justify-center bg-[#3E6100] cursor-pointer text-white text-[14px] mplus1pextrabold w-full ml-[5px]"
              onClick={() => setOpenModal(false)}
            >
              {t('CANCEL')}
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default BlogAddPage;
