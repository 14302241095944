import { useEffect, useRef, useState } from 'react';
import {
  Table,
  Input,
  Select,
  Button,
  Checkbox,
  Pagination,
  DatePicker,
  Modal,
  Dropdown,
  Space,
  Upload,
} from 'antd';
import moment, { Moment } from 'moment';
import type { ColumnsType } from 'antd/es/table';
import type { DatePickerProps } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import * as cts from '../../constants/ui/svg';
import type { PaginationProps, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getToken } from 'helpers';
import { useAppSelector } from 'redux/hooks';
import {
  addUserAdmin,
  deleteUserAdmin,
  fetchUserAdmin,
  fetchUserAdminInfo,
} from 'slices/userSlice';
import { UserAdminTypes } from 'models';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useHistory } from 'react-router-dom';
import ModalConfirm from 'components/Common/ModalConfirm';
interface DataType {
  key: React.Key;
  banner: string;
  size: any;
  location: any;
  presentation_type: string;
  last_updated: any;
}
// export enum genderTypes {
//   'male',
//   'female',
//   'other',
// }

const ProfileAdminPage = () => {
  const { t } = useTranslation();

  const [openForm, setOpenForm] = useState(false);
  const [dataQuickView, setDataQuickView] = useState();
  const date = moment().format('MM-DD-YYYY');
  const dispatch = useDispatch();
  const token = getToken();
  const history = useHistory();
  const usersAdmin = useAppSelector((state) => state.users.usersAdmin);
  const userAdminInfo = useAppSelector((state) => state.users.userAdminInfo);
  const [idRecord, setIdRecord] = useState<any>();
  const [fileList, setFileList] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const recordRef = useRef('');
  const [record, setRecord] = useState<any>();

  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <div onClick={() => setOpenForm(true)}>view admin profile</div>,
    },
    {
      key: '2',
      label: (
        <span
          onClick={() => {
            setOpenModal(true);
            recordRef.current = record;
          }}
        >
          {' '}
          delete
        </span>
      ),
    },
  ];
  const onDelete = () => {
    dispatch(deleteUserAdmin({ token, id: idRecord }));
  };
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: UserAdminTypes[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: UserAdminTypes) => ({
      // disabled: record.name === 'Disabled User', // Column configuration not to be checked
      // name: record.name,
    }),
  };
  const columns: ColumnsType<UserAdminTypes> = [
    {
      title: <span></span>,
      key: 'operation',
      fixed: 'left',
      width: 0,
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">名</span>,
      width: 120,
      dataIndex: 'userName',
      key: 'userName',
      render: (value: any) => (
        <div className="flex items-center">
          <span className="w-full flex justify-center text-[#0038FF] text-[14px] mplus1pextrabold">
            {value || '--'}
          </span>
        </div>
      ),
    },
    {
      title: <span></span>,
      dataIndex: '',
      key: '',
      width: 50,
      render: (value, record) => (
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          placement="bottomLeft"
          className="bg-[#DCBA78]/50  flex justify-center items-center"
        >
          <Button
            onClick={() => {
              setIdRecord(record.id);
              setRecord(record);
            }}
            className="w-[20px] h-[20px] rounded-[2px] p-0 "
          >
            <span className="!flex items-center mb-[6px]">...</span>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">DISPLAY 名 </span>
      ),
      dataIndex: 'lastName',
      key: 'lastName',
      width: 100,
      render: (value: any) => <span className="flex justify-center w-full">{value || '--'}</span>,
    },

    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">E-MAIL</span>,
      dataIndex: 'email',
      key: 'email',
      width: 180,
      render: (value: any) => (
        <span className="text-[12px] mplus1pmedium text-[#0038FF] flex justify-center w-full">
          {value}
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">電話番号</span>,
      dataIndex: 'phone',
      key: 'phone',
      width: 100,
      render: (value: any) => <span className=" flex justify-center w-full">{value || '--'}</span>,
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center ">ROLE</span>,
      dataIndex: 'role',
      key: 'role',
      width: 150,
      fixed: 'right',
      render: (value: any) => (
        <span className="text-[12px] mplus1pnormal flex text-start justify-center">
          {value || '--'}
        </span>
      ),
    },
  ];
  const arrProfile = usersAdmin?.result?.map((item: any, idx: any) => {
    return {
      ...item,
      key: idx,
    };
  });
  const pageSize = 10;
  const [pagi, setPagi] = useState<any>({
    usersAdmin: [],
    totalPage: usersAdmin?.count / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });

  useEffect(() => {
    dispatch(fetchUserAdmin({ token, limit: pageSize, page: pagi.current }));
  }, [token, dispatch, pagi.current]);
  useEffect(() => {
    if (idRecord) {
      dispatch(fetchUserAdminInfo({ token, id: idRecord }));
    }
  }, [idRecord, token, dispatch]);
  return (
    <div className="pt-[26px] px-[35px] flex flex-col pb-[100px]">
      {!openForm ? (
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <span className="text-[20px] mplus1pextrabold mr-[54px]">アドミン一覧</span>
            </div>
            <div className="flex items-center">
              {/* <span className="h-[40px] w-[48px] border-[0.5px] rounded-l-[10px] border-black/50 bg-[#DCBA78]/50 flex justify-center items-center">
                {cts.iconSearch}
              </span>
              <Input className="w-[252px] h-[40px] border-[0.5px] border-black/50 rounded-r-[10px] rounded-l-[0px]" /> */}
            </div>
          </div>

          <div className="flex w-full justify-between pt-[30px]">
            <div className="flex items-center">
              <span className="underline text-[14px] mplus1pnormal text-[#0038FF] ">
                {t('ALL')} <span className="text-black underline">({usersAdmin?.count})</span>
              </span>
              {/* <span className="underline text-[14px] mplus1pnormal text-[#0038FF] ml-[50px] pr-[30px] border-r border-[#000000]/25" >
                            {t("SHIPPING")}{' '}
                            <span className="text-black underline">
                                ({data.length})
                            </span>
                        </span>
                        <span className="underline text-[14px] mplus1pnormal text-[#0038FF] ml-[10px] " >
                            {t("DELIVERED")}{' '}
                            <span className="text-black underline">
                                ({data.length})
                            </span>
                        </span> */}
            </div>
            {/* <div className="flex items-center ctn_btn_merchandise  pr-[10px] pb-[10px] h-full overflow-auto overflow-y-hidden ">
                        <Select
                            defaultValue="一括操作"
                            className=" slc_merchandise mr-[10px]"
                            // onChange={handleChange}
                            options={[
                                { value: '一括操作', label: '一括操作' },
                                { value: 'Yiminghe', label: 'yiminghe' },
                            ]}
                        />
                        <Button className="bg-white h-[30px] border-[0.5px] !border-[#0038FF] text-[14px] mplus1pnormal border-black ">
                            {t('APPLY')}
                        </Button>
                        <Select
                            defaultValue="日付"
                            className=" slc_merchandise ml-[10px]"
                            // onChange={handleChange}
                            options={[
                                { value: '一括操作', label: '一括操作' },
                                { value: 'Yiminghe', label: 'yiminghe' },
                            ]}
                        />
                        <Button className="bg-white h-[30px] border-[0.5px] ml-[10px] !border-[#0038FF] text-[14px] mplus1pnormal text-[#000000]/40 border-black ">
                            {t('Filter_By_Registered_Customers')}
                        </Button>
                        <Button className="bg-white h-[30px] border-[0.5px] ml-[10px] !border-[#0038FF] text-[14px] mplus1pnormal border-black ">
                            {t('FILTER')}
                        </Button>
                    </div> */}
          </div>

          <div className="mt-[25px] mb-[100px]">
            <Table
              className="table_merchandise table-order"
              columns={columns}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              dataSource={arrProfile}
              scroll={{ x: 1000 }}
              pagination={{
                showSizeChanger: false,
                total: usersAdmin?.count,
                pageSize: 10,
                current: pagi.current,

                onChange: (page: any) => {
                  setPagi({
                    current: page,
                    minIndex: (page - 1) * pageSize,
                    maxIndex: page * pageSize,
                  });
                },
              }}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col mb-[100px]">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <span className="text-[20px] mplus1pextrabold mr-[54px]">アドミンロフィール</span>
              <div
                onClick={() => history.push('/add-new-admin')}
                className="h-[40px] ml-[70px] cursor-pointer focus:bg-[#DBB312]/80 rounded-[10px] bg-[#DBB312] w-[110px] flex items-center justify-center"
              >
                <span className="text-[14px] mplus1pextrabold text-white">アドミン追加</span>
              </div>
            </div>
          </div>
          <div className="pt-[50px] flex w-[100%]">
            <div className="w-[20%] ">{cts.viewUser}</div>
            <div className="flex flex-col w-[80%]">
              <div className="flex items-center">
                <div className="flex flex-col mr-[5%]">
                  <span className="mb-[10px] text-[16px] mplus1pbold">Admin name </span>
                  <div className="w-[430px] bg-[white] p-[8px] border border-[black] mb-[20px]">
                    {userAdminInfo?.userName || '--'}
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="mb-[10px] text-[16px] mplus1pbold">Role </span>
                  <div className="w-[150px] bg-[white] p-[8px] border border-[black] mb-[20px]">
                    {userAdminInfo?.role || '--'}
                  </div>
                </div>
              </div>
              <span className="mb-[10px] text-[16px] mplus1pbold">E-mail</span>
              <div className="w-[430px] bg-[white] p-[8px] border border-[black]">
                {userAdminInfo?.email || '--'}
              </div>
              <div className="flex mt-[30px]">
                <div className="flex flex-col">
                  <span className="mb-[10px] text-[16px] mplus1pbold">First 名</span>
                  <div className="w-[210px] bg-[white] p-[8px] border border-[black]">
                    {userAdminInfo?.firstName || '--'}
                  </div>
                </div>
                <div className="ml-[50px] flex flex-col">
                  <span className="mb-[10px] text-[16px] mplus1pbold">Last 名</span>
                  <div className="w-[210px] bg-[white] p-[8px] border border-[black]">
                    {userAdminInfo?.lastName || '--'}
                  </div>
                </div>
              </div>
              <div className="flex mt-[20px]">
                <div className="flex flex-col">
                  <span className="mb-[10px] text-[16px] mplus1pbold">生年月日</span>
                  <div className="w-[210px] bg-[white] p-[8px] border border-[black]">
                    {userAdminInfo?.birthday
                      ? moment(userAdminInfo?.birthday).format('YYYY/MM/DD')
                      : '--/--/--'}
                  </div>
                </div>
                <div className="ml-[50px] flex flex-col">
                  <span className="mb-[10px] text-[16px] mplus1pbold">性別</span>
                  <div className="w-[210px] bg-[white] p-[8px] border border-[black]">
                    {userAdminInfo?.gender || '--'}
                  </div>
                </div>
              </div>
              <div className="flex mt-[20px]">
                <div className="flex flex-col">
                  <span className="mb-[10px] text-[16px] mplus1pbold">郵便番号</span>
                  <div className="w-[117px] bg-[white] p-[8px] border border-[black]">
                    {userAdminInfo?.postalCode || '---'}
                  </div>
                </div>
                <div className="ml-[145px] flex flex-col">
                  <span className="mb-[10px] text-[16px] mplus1pbold">住所</span>
                  <div className="w-[430px] bg-[white] p-[8px] border border-[black]">
                    {userAdminInfo?.address || '---'}
                  </div>
                </div>
              </div>
              <span className="mb-[10px] text-[16px] mplus1pbold mt-[20px]">
                お問い合わせ電話番号
              </span>
              <div className="w-[430px] bg-[white] p-[8px] border border-[black]">
                {userAdminInfo?.phone || '--'}
              </div>

              <span className="mb-[10px] text-[16px] mplus1pbold mt-[20px]">Website Language</span>
              <Select
                className="select-language"
                defaultValue="Site Default"
                style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  { value: 'jack', label: 'Jack' },
                  { value: 'lucy', label: 'Lucy' },
                  { value: 'Yiminghe', label: 'yiminghe' },
                ]}
              />
              <span className="mb-[10px] text-[16px] mplus1pbold mt-[20px]">
                Send ユーザー管理 notification
              </span>
              <Checkbox onChange={onChange}>
                Send the new ユーザー管理 an email about their account.
              </Checkbox>
            </div>
          </div>
          <button
            className="bg-[#3E6100] w-[50px] text-[white] p-[10px] rounded-[10px] mplus1pbold"
            onClick={() => {
              setOpenForm(false);
              setIdRecord(null);
            }}
          >
            戻る
          </button>
        </div>
      )}
      <ModalConfirm
        openModal={openModal}
        setOpenModal={setOpenModal}
        onConfirm={onDelete}
        record={recordRef.current}
        text="この管理者アカウントを削除してもよろしいですか?"
      />
    </div>
  );
};
export default ProfileAdminPage;
