import { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { push } from 'connected-react-router';
import { ENCODE_SECRET_KEY } from '../constants';
import {
  productWithFilterAPI,
  searchProductByAdminAPI,
  deleteProductAPI,
  cloneProductAPI,
  updateProductAPI,
  addProductAPI,
  productReviewsByAdminAPI,
  productReviewsByIdAPI,
  productInfoByAdminAPI,
  productBrandsAPI,
  addProductBrandAPI,
  updateProductBrandAPI,
  deleteProductBrandAPI,
  changeReviewStatusAPI,
  deleteProductReviewByAdminAPI,
  changeManyReviewsAPI,
  getAllProductBrandAPI,
  updateProductReviewByAdminAPI,
  addProductReviewByAdminAPI,
  deleteProductPreviewByAdminAPI,
} from '../api';
import { successToast, errorToast, failedToast } from 'helpers';
import {
  updateProductReviewByAdmin,
  updateProductReviewByAdminSuccess,
  addProductReviewByAdmin,
  addProductReviewByAdminSuccess,
  fetchProductsWithFilter,
  fetchProductsWithFilterSuccess,
  fetchSearchProductByAdmin,
  fetchSearchProductByAdminSuccess,
  deleteProduct,
  deleteProductSuccess,
  cloneProduct,
  cloneProductSuccess,
  updateProduct,
  updateProductSuccess,
  addProduct,
  addProductSuccess,
  fetchProductReviewByAdmin,
  fetchProductReviewByAdminSuccess,
  fetchProductReviewById,
  fetchProductReviewByIdSuccess,
  fetchProductInfoByAdmin,
  fetchProductInfoByAdminSuccess,
  updateProductStatus,
  updateProductStatusSuccess,
  deleteProductBrand,
  deleteProductBrandSuccess,
  updateProductBrand,
  updateProductBrandSuccess,
  addProductBrand,
  addProductBrandSuccess,
  fetchProductBrands,
  fetchProductBrandsSuccess,
  fetchProductReviewByAdminList,
  fetchProductReviewByAdminListSuccess,
  changeReviewStatus,
  changeReviewStatusSuccess,
  deleteProductReviewByAdmin,
  deleteProductReviewByAdminSuccess,
  changeManyReviews,
  changeManyReviewsSuccess,
  fetchAllProductBrand,
  fetchAllProductBrandSuccess,
  addProductBrandByAdmin,
  addProductBrandByAdminSuccess,
  deleteProductPreviewByAdmin,
  deleteProductPreviewByAdminSuccess,
} from 'slices/productSlice';
import { showLoading, hideLoading } from '../slices/commonSlice';

function* productWithFilterSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(productWithFilterAPI, {
      page: action.payload.page,
      limit: action.payload.limit,
      token: action.payload.token,
      inputs: action.payload.inputs,
    });
    const { data, errors } = res.data;
    yield put(fetchProductsWithFilterSuccess(data?.productsWithFilter[0]));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* searchProductByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(searchProductByAdminAPI, {
      page: action.payload.page,
      limit: action.payload.limit,
      token: action.payload.token,
      searchText: action.payload.searchText,
    });
    const { data, errors } = res.data;
    yield put(fetchSearchProductByAdminSuccess(data?.searchProductByAdmin[0]));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* deleteProductSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(deleteProductAPI, {
      token: action.payload.token,
      id: action.payload.id,
    });
    const { data, errors } = res.data;
    if (res?.data?.data?.deleteProduct?.ok === true) {
      successToast('Delete product success');
      yield put(deleteProductSuccess(data?.deleteProduct?.data?._id));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* cloneProductSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(cloneProductAPI, {
      token: action.payload.token,
      id: action.payload,
    });
    const { data, errors } = res.data;
    if (res?.data?.data?.cloneProduct?.ok === true) {
      successToast('Duplicate product success');
      yield put(cloneProductSuccess(data?.cloneProduct?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* updateProductSaga(action: any) {
  try {
    yield put(showLoading(true));
    const res: AxiosResponse = yield call(updateProductAPI, action.payload);
    const { data, errors } = res.data;

    // if (res?.data?.data?.updateProduct?.ok === true) {
    //   successToast('Update product success');
    // }
    if (data?.updateProduct?.ok === true) {
      successToast(data?.updateProduct?.error);
      yield put(updateProductSuccess(data?.updateProduct?.data));

      yield put(showLoading(false));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* updateProductStatusSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(updateProductAPI, {
      token: action.payload.token,
      id: action.payload.id,
      inputs: action.payload.inputs,
    });
    const { data, errors } = res.data;

    // if (res?.data?.data?.updateProduct?.ok === true) {
    //   successToast('Update product success');
    // }
    if (data?.updateProduct?.error !== '') {
      successToast(data?.updateProduct?.error);
      yield put(updateProductStatusSuccess(data?.updateProduct?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* addProductSaga(action: any) {
  try {
    yield put(showLoading(true));
    const res: AxiosResponse = yield call(addProductAPI, action.payload);
    const { data, error } = res.data;
    if (res?.data?.data?.addProduct?.ok === true) {
      successToast('Add product success');
      yield put(addProductSuccess(data?.addProduct));
      yield put(showLoading(false));
    } else {
      errorToast(res?.data?.data?.addProduct?.error);
      yield put(showLoading(false));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(true));
    errorToast(error?.message);
  }
}
function* productReviewByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(productReviewsByAdminAPI, {
      page: action.payload.page,
      limit: action.payload.limit,
      token: action.payload.token,
      productId: action.payload.productId,
      point: action.payload.point,
      status: action.payload.status,
    });
    const { data, errors } = res.data;
    yield put(fetchProductReviewByAdminSuccess(data?.getProductReviewsByAdmin?.result));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* productReviewByAdminListSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(productReviewsByAdminAPI, {
      page: action.payload.page,
      limit: action.payload.limit,
      token: action.payload.token,
      productId: action.payload.productId,
      point: action.payload.point,
      status: action.payload.status,
    });
    const { data, errors } = res.data;
    yield put(fetchProductReviewByAdminListSuccess(data?.getProductReviewsByAdmin));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* productReviewsByIdSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(productReviewsByIdAPI, {
      page: action.payload.page,
      limit: action.payload.limit,
      token: action.payload.token,
      productId: action.payload.productId,
    });
    const { data, errors } = res.data;
    yield put(fetchProductReviewByIdSuccess(data?.productReviewsById));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* productInfoByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(productInfoByAdminAPI, {
      token: action.payload.token,
      id: action.payload.id,
    });
    const { data, errors } = res.data;
    yield put(fetchProductInfoByAdminSuccess(data?.productInfoByAdmin));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* productBrandsSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(productBrandsAPI, {
      limit: action.payload.limit,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(fetchProductBrandsSuccess(data?.productBrands));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* addProductBrandSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(addProductBrandAPI, {
      inputs: action.payload.inputs,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    if (data?.addProductBrand?.ok === true) {
      successToast('新しいブランドを追加しました');
      yield put(addProductBrandSuccess(data?.addProductBrand?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* addProductBrandByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(addProductBrandAPI, {
      inputs: action.payload.inputs,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    if (data?.addProductBrand?.ok === true) {
      successToast('新しいブランドを追加しました');
      yield put(addProductBrandByAdminSuccess(data?.addProductBrand?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* updateProductBrandSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(updateProductBrandAPI, {
      inputs: action.payload.inputs,
      id: action.payload.id,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    if (data?.updateProductBrand?.ok === true) {
      successToast('ブランドアップデートも成功');
      yield put(updateProductBrandSuccess(data?.updateProductBrand?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* deleteProductBrandSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(deleteProductBrandAPI, {
      id: action.payload.id,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    if (data?.deleteProductBrand?.ok === true) {
      successToast('ブランドが正常に削除されました');
      yield put(deleteProductBrandSuccess(data?.deleteProductBrand?.data?.id));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* changeReviewStatusSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(changeReviewStatusAPI, {
      token: action.payload.token,
      id: action.payload.id,
      status: action.payload.status,
    });
    const { data, errors } = res.data;
    if (data?.changeReviewStatus?.message !== '') {
      successToast(data?.changeReviewStatus?.message);
      yield put(changeReviewStatusSuccess(data?.changeReviewStatus?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* deleteProductReviewByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(deleteProductReviewByAdminAPI, {
      token: action.payload.token,
      id: action.payload.id,
    });
    const { data, errors } = res.data;

    if (data?.deleteProductReviewByAdmin?.ok === true) {
      successToast('Delete preview product success');
      yield put(deleteProductReviewByAdminSuccess(data?.deleteProductReviewByAdmin?.data?.id));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* changeManyReviewsSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(changeManyReviewsAPI, {
      token: action.payload.token,
      ids: action.payload.ids,
      status: action.payload.status,
    });
    const { data, errors } = res.data;

    if (data?.changeManyReviews?.message !== '') {
      successToast(data?.changeManyReviews?.message);
      yield put(changeManyReviewsSuccess(data?.changeManyReviews?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* getAllProductBrandSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(getAllProductBrandAPI, {
      token: action.payload.token,
      limit: action.payload.limit,
      page: action.payload.page,
    });
    const { data, errors } = res.data;
    yield put(fetchAllProductBrandSuccess(data?.getAllProductBrand));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* addProductReviewByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(addProductReviewByAdminAPI, action.payload);
    const { data, errors } = res.data;
    if (data?.addProductReviewByAdmin?.ok === true) {
      successToast('コメントが正常に追加されました!');
      yield put(addProductReviewByAdminSuccess(data?.addProductReviewByAdmin?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* updateProductReviewByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(updateProductReviewByAdminAPI, action.payload);
    const { data, errors } = res.data;
    if (data?.updateProductReviewByAdmin?.ok === true) {
      successToast('コメントが正常に編集されました!');
      yield put(updateProductReviewByAdminSuccess(data?.updateProductReviewByAdmin?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* deleteProductPreviewByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(deleteProductPreviewByAdminAPI, action.payload);
    const { data, errors } = res.data;

    if (data?.deleteProductReviewByAdmin?.ok === true) {
      successToast('Delete preview product success');
      yield put(deleteProductPreviewByAdminSuccess(data?.deleteProductReviewByAdmin?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
export default function* productSaga() {
  yield takeEvery(deleteProductPreviewByAdmin.type, deleteProductPreviewByAdminSaga);
  yield takeEvery(addProductReviewByAdmin.type, addProductReviewByAdminSaga);
  yield takeEvery(updateProductReviewByAdmin.type, updateProductReviewByAdminSaga);
  yield takeLatest(fetchProductsWithFilter.type, productWithFilterSaga);
  yield takeEvery(fetchSearchProductByAdmin.type, searchProductByAdminSaga);
  yield takeEvery(deleteProduct.type, deleteProductSaga);
  yield takeEvery(cloneProduct.type, cloneProductSaga);
  yield takeLatest(updateProduct.type, updateProductSaga);
  yield takeLatest(updateProductStatus.type, updateProductStatusSaga);
  yield takeLatest(addProduct.type, addProductSaga);
  yield takeLatest(fetchProductReviewByAdmin.type, productReviewByAdminSaga);
  yield takeLatest(fetchProductReviewById.type, productReviewsByIdSaga);
  yield takeLatest(fetchProductInfoByAdmin.type, productInfoByAdminSaga);
  yield takeLatest(fetchProductBrands.type, productBrandsSaga);
  yield takeEvery(addProductBrand.type, addProductBrandSaga);
  yield takeEvery(updateProductBrand.type, updateProductBrandSaga);
  yield takeEvery(deleteProductBrand.type, deleteProductBrandSaga);
  yield takeLatest(fetchProductReviewByAdminList.type, productReviewByAdminListSaga);
  yield takeEvery(changeReviewStatus.type, changeReviewStatusSaga);
  yield takeLatest(deleteProductReviewByAdmin.type, deleteProductReviewByAdminSaga);
  yield takeLatest(changeManyReviews.type, changeManyReviewsSaga);
  yield takeEvery(fetchAllProductBrand.type, getAllProductBrandSaga);
  yield takeLatest(addProductBrandByAdmin.type, addProductBrandByAdminSaga);
}
