import { Checkbox, Input, Modal, Popconfirm, Select, Spin, Upload } from 'antd';
import { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getToken } from 'helpers';
import * as cts from '../../constants/ui/svg';

import { fetchBlogInfoByAdmin, fetchBlogsTag, updateBlog } from 'slices/blogSlice';
import { useAppSelector } from 'redux/hooks';
import { errorToast } from 'helpers';
import { useHistory, useParams } from 'react-router-dom';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import BlogPreviewPage from './BlogPreviewPage';
import { URL_UPLOAD } from '../../constants';
import img from '../../assets/images/blog_img.png';
import img1 from '../../assets/images/blog_1.png';
import img2 from '../../assets/images/blog_2.png';
import img3 from '../../assets/images/blog_3.png';
interface ParamTypes {
  id: string;
}
const EditBlogPage = () => {
  const { Option } = Select;
  const blogInfoByAdmin = useAppSelector((state) => state.blogs.getBlogInfoByAdmin);
  const tagProduct = useAppSelector((state) => state.blogs.blogTags);
  const dispatch = useDispatch();
  const token = getToken();
  const [openModal, setOpenModal] = useState(false);
  const [fileUpload, setFileUpload] = useState<any>();
  const [fileDataURL, setFileDataURL] = useState();
  const inputRef = useRef<any>();
  const loading = useAppSelector((state) => state.common.loading);
  const [btnLoad, setBtnLoad] = useState<any>({ load: loading, status: '' });
  const params = useParams<ParamTypes>();
  const history = useHistory();
  const [dataBlog, setDataBlog] = useState<any>({});
  const [previewBlog, setPreviewBlog] = useState(false);
  const [checkHot, setCheckHot] = useState(false);
  const handleChangeSelectTag = (value: string[]) => {
    const arrNew: any = [value];
    const arr3 = arrNew[0]
      .map((item: any) => {
        const index = tagProduct.findIndex((item2: any) => item2.name === item);
        return {
          id: tagProduct[index]?.id,
        };
      })
      ?.map((item1: any) => item1.id);

    setDataBlog({ ...dataBlog, listTag: value });
  };

  const formats = ['header', 'underline', 'bold', 'italic', 'bullet', 'indent'];
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['underline', 'bold', 'italic'],
      [{ indent: '-1' }, { indent: '+1' }],
    ],
  };
  const [contentBlog, setContentBlog] = useState(blogInfoByAdmin?.content);

  const changeHandler = (e: any) => {
    const file = e.target.files[0];
    setFileUpload(file);
  };
  const handleDeleImg = () => {
    // const newData = { ...dataBlog };
    // const test = newData.multiImages.filter((img: any, index: any) => index !== indxImg);
    // setDataBlog({
    //   ...newData,
    //   multiImages: test,
    // });
    setFileUpload(null);
    const newData = { ...dataBlog };
    setDataBlog({
      ...newData,
      image: null,
    });
    setOpenModal(false);
  };

  const onSaveBlog = (status: string) => {
    if (dataBlog.name !== '' && dataBlog.content !== '' && fileUpload !== null) {
      setBtnLoad({ ...btnLoad, status: status });
      const param = {
        name: dataBlog?.name,
        listTag: dataBlog?.listTag,
        isHot: dataBlog?.isHot,
        status: status,
      };
      dispatch(updateBlog({ token, id: params.id, inputs: param, fileUpload }));
      // if(status === 'public'){
      //   history.push('/blog-public');
      // }else{
      //   history.push('/blog-draft');
      // }
    } else {
      errorToast('Please input enough value');
    }
  };
  useEffect(() => {
    if (loading === false) {
      setBtnLoad({ ...btnLoad, load: false });
    } else {
      setBtnLoad({ ...btnLoad, load: true });
    }
  }, [loading]);
  const onChangeCheck = (e: CheckboxChangeEvent) => {
    setDataBlog({ ...dataBlog, isHot: e.target.checked });
  };

  const { t } = useTranslation();
  const changeContent = (e: any) => {
    // setDataBlog({ ...dataBlog, content: e });
  };
  const onChangeCheckBox = (checkedValues: CheckboxValueType[]) => {
    const arr3 = checkedValues
      .map((item: any) => {
        const index = tagProduct?.findIndex((item2: any) => item2?.name === item);
        return {
          id: tagProduct[index]?.id,
        };
      })
      .map((item1: any) => item1?.id);
    setDataBlog({
      ...dataBlog,
      listTag: arr3,
    });
  };
  useEffect(() => {
    let fileReader = new FileReader(),
      isCancel = false;
    if (fileUpload) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(fileUpload);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileUpload]);

  useEffect(() => {
    dispatch(fetchBlogsTag({ token }));
  }, [dispatch, token]);
  useEffect(() => {
    dispatch(fetchBlogInfoByAdmin({ token, id: params.id }));
  }, [token, dispatch, params.id]);

  useEffect(() => {
    if (blogInfoByAdmin) {
      setDataBlog({
        name: blogInfoByAdmin?.name,
        content: blogInfoByAdmin?.content,
        image: blogInfoByAdmin?.image,
        multiImages: blogInfoByAdmin?.multiImages,
        listTag: blogInfoByAdmin?.tags?.map((x: any) => x?.id),
        isHot: blogInfoByAdmin?.isHot,
      });
    }
  }, [blogInfoByAdmin, params.id]);

  return (
    <>
      {previewBlog ? (
        <>
          <BlogPreviewPage
            load={btnLoad.load}
            loadStatus={btnLoad.status}
            onSaveBlog={onSaveBlog}
            setPreviewBlog={setPreviewBlog}
            dataBlog={dataBlog}
            fileDataURL={fileUpload ? fileDataURL : `${URL_UPLOAD}/blogs/${dataBlog?.image}`}
          />
        </>
      ) : (
        <div className="pt-[26px] px-[35px] mb-[100px]">
          <span className="text-[20px] mplus1pextrabold">{t('EDIT_BLOG')}</span>
          <div className="flex flex-col mt-[25px]">
            <div className="flex items-center">
              <span className="w-[25%] text-[14px] mplus1pnormal">{t('BLOG_NAME')}</span>
              <div className="w-[75%]">
                <Input
                  value={dataBlog?.name}
                  onChange={(e) => setDataBlog({ ...dataBlog, name: e.target.value })}
                  className="w-[80%] h-[44px] rounded-[0px] border border-black/50"
                />
              </div>
            </div>
            <div className="flex  mt-[30px]">
              <span className="w-[25%] text-[14px] mplus1pnormal">{t('BLOG_TEXT')}</span>
              <div className="w-[75%]">
                <ReactQuill
                  className="form_add_merchandise border border-black/50 bg-white mt-[10px] mb-[20px] w-[80%]"
                  defaultValue={dataBlog?.content}
                  value={dataBlog?.content}
                  onChange={(e) => {
                    changeContent(e);
                    // console.log('e', e);
                    // setDataBlog({ ...dataBlog, content: e })
                  }}
                  theme="snow"
                  formats={formats}
                  modules={modules}
                />
              </div>
            </div>
            <div className="flex mt-[30px]">
              <span className="w-[25%] text-[14px] mplus1pnormal">{t('IMAGE')}</span>
              <div className="w-[75%]">
                <div className="w-[700px] px-[8px] h-[145px] border border-[#C1C1C1] bg-white flex items-center">
                  {fileUpload || dataBlog?.image ? (
                    <div className="h-[130px] w-[130px] relative mx-[5px] border border-black/30">
                      <img
                        className="h-[130px] w-[130px]  absolute"
                        src={fileUpload ? fileDataURL : `${URL_UPLOAD}/blogs/${dataBlog?.image}`}
                      />

                      <div className="absolute h-full w-full pt-[4px] pr-[4px] opacity-0 hover:opacity-100 flex justify-end">
                        <span
                          onClick={() => {
                            setOpenModal(true);
                          }}
                          className=" svg_close_img h-[22px] w-[22px] rounded-[11px] flex items-center justify-center bg-[#D9D9D9]/50 border border-black/20"
                        >
                          {cts.iconClose}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="relative flex items-center h-[132px] w-[132px]">
                      <div className="absolute h-[132px] w-[132px] border border-black/30">
                        <span className="text-[12px]  h-full items-center cursor-pointer mplus1pnormal text-[#0038FF] absolute w-[132px] flex justify-center">
                          + upload new image
                          <Input
                            ref={inputRef}
                            accept=".png, .jpg, .jpeg .webp"
                            type="file"
                            onChange={changeHandler}
                            className="h-[132px] w-[132px] absolute text-white bg-white inp_upload opacity-0"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={`${!checkHot && 'items-center'} flex mt-[70px]`}>
              <span className="w-[25%] text-[14px] mplus1pnormal h-[30px] flex items-center">
                {t('BLOG_TAG')}
              </span>
              <div className={`${!checkHot && 'items-center'} w-[75%] flex `}>
                <div className=" w-[215px]">
                  <Popconfirm
                    title="aaa"
                    placement="bottom"
                    description={() => {
                      return (
                        <div className="bg-white pop_blog_add flex flex-col rounded-[5px] px-[1px] py-[2px] border border-[rgba(0,0,0,0.2)]">
                          <Checkbox.Group
                            options={tagProduct?.map((item: any) => item?.name)}
                            defaultValue={dataBlog?.listTag
                              .map((item: any) => {
                                const index = tagProduct?.findIndex(
                                  (item2: any) => item2?.id === item
                                );
                                return {
                                  name: tagProduct[index]?.name,
                                };
                              })
                              .map((item1: any) => item1?.name)}
                            onChange={onChangeCheckBox}
                            className="flex flex-col ckb_gr ckb_tag_product"
                          />
                        </div>
                      );
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <div className="h-[29px] bg-white px-[2px] border border-[rgba(0,0,0,0.5)] flex-1 rounded-[5px] flex justify-between items-center">
                      <span className="text-[12px] text-[rgba(0,0,0,0.3)]">カテゴリー選択</span>
                      <span>{cts.iconDownRes}</span>
                    </div>
                  </Popconfirm>
                </div>
                {/* <div
                  id="div"
                  className="flex flex-col w-[150px]"
                  contentEditable={checkHot}
                  onBlur={() => setCheckHot(false)}
                  onClick={() => setCheckHot(true)}
                >
                  <div
                    onClick={() => setCheckHot(false)}
                    className="w-full bg-white cursor-pointer h-[30px] border border-[rgba(0,0,0,0.2)] rounded-[5px] px-[10px] flex items-center justify-between"
                  >
                    <span className="text-[rgba(0,0,0,0.4)]">ブログタグ</span>
                    <span>{cts.iconDown}</span>
                  </div>
                  {checkHot && (
                    <Checkbox.Group
                      options={tagProduct?.map((x: any) => x?.name)}
                      defaultValue={dataBlog?.listTag
                        .map((item: any) => {
                          const index = tagProduct?.findIndex((item2: any) => item2?.id === item);
                          return {
                            name: tagProduct[index]?.name,
                          };
                        })
                        .map((item1: any) => item1?.name)}
                      onChange={onChangeCheckBox}
                      className="flex flex-col ckb_gr check_tag_blog w-full bg-white border border-[rgba(0,0,0,0.2)] rounded-[5px] py-[3px] px-[5px]"
                    />
                  )}
                </div> */}

                <span className="text-[12px] mplus1pnormal ml-[15px] text-[#0038FF] cursor-pointer h-[30px] flex items-center">
                  + {t('ADD_NEW_TAG')}
                </span>
              </div>
            </div>

            {/* <div className="flex items-center">
              <div className="w-[25%]"></div>
              <div onClick={() => setCheckHot(true)} className="w-[75%]">
                {checkHot && (
                  <Checkbox.Group
                    options={tagProduct?.map((x: any) => x?.name)}
                    defaultValue={dataBlog?.listTag
                      .map((item: any) => {
                        const index = tagProduct?.findIndex((item2: any) => item2?.id === item);
                        return {
                          name: tagProduct[index]?.name,
                        };
                      })
                      .map((item1: any) => item1?.name)}
                    onChange={onChangeCheckBox}
                    className="flex flex-col ckb_gr check_tag_blog w-[25%] bg-white border border-[rgba(0,0,0,0.2)] rounded-[5px] py-[3px] px-[5px]"
                  />
                )}
              </div>
            </div> */}

            <div className="flex items-center mt-[70px]">
              <span className="w-[25%] text-[14px] mplus1pnormal">{t('PIN_BLOG')}</span>
              <div className="w-[75%]">
                <Checkbox onChange={onChangeCheck} checked={dataBlog?.isHot === true} />
              </div>
            </div>

            <div className="flex items-center mt-[30px] mb-[100px]">
              <div className="relative w-[104px] flex items-center justify-center mr-[50px]">
                {btnLoad.load === true && btnLoad.status === 'draft' && (
                  <Spin className="absolute w-[104px] h-full items-center flex justify-center" />
                )}
                <span
                  onClick={() => onSaveBlog('draft')}
                  className={`${
                    btnLoad.load === true && btnLoad.status === 'draft'
                      ? ' opacity-20 absolute'
                      : ''
                  } h-[39px] px-[10px] cursor-pointer rounded-[10px] flex items-center justify-center text-white bg-[#3692E7] text-[14px] mplus1pextrabold`}
                >
                  {t('PRESERVE')}
                </span>
              </div>
              <div className="relative w-[68px] flex items-center justify-center mr-[50px]">
                {btnLoad.load === true && btnLoad.status === 'public' && (
                  <Spin className="absolute w-[68px] h-full items-center flex justify-center" />
                )}
                <span
                  onClick={() => onSaveBlog('public')}
                  className={`${
                    btnLoad.load === true && btnLoad.status === 'public'
                      ? 'opacity-20 absolute'
                      : ''
                  } h-[39px] px-[20px] cursor-pointer rounded-[10px] flex items-center justify-center text-white bg-[#3E6100] text-[14px] mplus1pextrabold`}
                >
                  {t('RELEASE')}
                </span>
              </div>
              <span
                onClick={() => setPreviewBlog(true)}
                className="h-[39px] cursor-pointer mr-[50px] px-[10px] rounded-[10px] flex items-center justify-center text-white bg-[#DBB312] text-[14px] mplus1pextrabold border"
              >
                {t('PREVIEW')}
              </span>
              <div
                onClick={() => history.push('/posts')}
                className="h-[39px] cursor-pointer text-[#3E6100] px-[10px] rounded-[10px] mr-[50px] border border-[#3E6100] flex items-center mplus1pextrabold text-[14px]"
              >
                {t('CANCEL')}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        mask={false}
        centered
        open={openModal}
        className="modal_delete h-[270px] !w-[375px]"
        //   onOk={() => setOpenModal(false)}
        //   onCancel={() => setOpenModal(false)}
      >
        <div className="flex flex-col justify-between items-center h-full">
          <span>{cts.iconWarning}</span>
          <span className="text-[14px] mplus1pmedium">このイメージを削除してもよろしいですか?</span>
          <div className="flex items-center w-full">
            <span
              className="h-[34px] rounded-[5px] flex items-center justify-center bg-[#9B0101] cursor-pointer text-white text-[14px] mplus1pextrabold w-full mr-[5px]"
              onClick={handleDeleImg}
            >
              {t('DELETE')}
            </span>
            <span
              className="h-[34px] rounded-[5px] flex items-center justify-center bg-[#3E6100] cursor-pointer text-white text-[14px] mplus1pextrabold w-full ml-[5px]"
              onClick={() => setOpenModal(false)}
            >
              {t('CANCEL')}
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default EditBlogPage;
