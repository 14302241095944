import { Checkbox, Input, Popconfirm, Select, Table, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useRef, useState } from 'react';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { useDispatch } from 'react-redux';
import { getToken } from 'helpers';
import {
  addProductTag,
  deleteProductTag,
  fetchAllProductTagsByAdmin,
  updateProductTag,
} from 'slices/tagProductsSlice';
import { successToast, errorToast, failedToast } from 'helpers';
import { useAppSelector } from 'redux/hooks';
import { TagTypes } from '../../models/tag';
import * as cts from '../../constants/ui/svg';
import { AllProductBrandTypes } from 'models';
import {
  addProductBrandByAdmin,
  deleteProductBrand,
  fetchAllProductBrand,
  updateProductBrand,
} from 'slices/productSlice';
import ModalConfirm from 'components/Common/ModalConfirm';

const ProductBrandPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = getToken();
  const recordRef = useRef<any>('');
  const getAllProductBrand = useAppSelector((state) => state.products.getAllProductBrand);
  const [dataTag, setDataTag] = useState({
    name: '',
    slug: '',
    description: '',
  });
  const [openModal, setOpenModal] = useState(false);
  const [idRecord, setIdRecord] = useState('');
  const [viewSlc, setViewSlc] = useState('');
  const [expandedKey, setExpandedKey] = useState<any>(null);
  const [tags, setTags] = useState<any>();
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: AllProductBrandTypes[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: AllProductBrandTypes) => ({}),
  };
  const pageSize = 10;
  const [pagi, setPagi] = useState<any>({
    tagByAdmin: [],
    totalPage: getAllProductBrand?.count / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });
  const columns: ColumnsType<AllProductBrandTypes> = [
    {
      title: <div className="flex justify-center w-full ckb_table"></div>,
      key: 'operation',
      fixed: 'left',
      width: 0,
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">
          {t('GIVEN_NAME')}
        </span>
      ),
      width: 120,
      dataIndex: 'name',
      key: 'name',
      render: (value: any) => (
        <span className="text-[12px] mplus1pmedium text-[#0038FF] flex justify-center w-full">
          {value || '--'}
        </span>
      ),
    },
    Table.EXPAND_COLUMN,

    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">DESCRIPTION</span>
      ),
      dataIndex: 'description',
      key: 'description',
      width: 100,
      render: (value: any) => <span className="flex justify-center w-full">{value || '--'}</span>,
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">SLUG</span>,
      dataIndex: 'slug',
      key: 'slug',
      width: 100,
      render: (value: any) => (
        <span className="text-[12px] text-[#3E6100] mplus1pmedium flex justify-center w-full">
          {value || '--'}
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">COUNT</span>,
      dataIndex: 'productCount',
      key: 'productCount',
      fixed: 'right',
      width: 100,
      render: (value: any) => <span className=" flex justify-center w-full">{value || 0}</span>,
    },
  ];

  const arrTag = getAllProductBrand?.result?.map((x: any, y: any) => {
    return {
      ...x,
      key: y,
    };
  });
  const onValueChange = (value: string, name: string, record: AllProductBrandTypes) => {
    const foundCategory = tags?.find((tag: any) => tag?.id === record?.id);
    let newObj = { ...foundCategory };
    newObj[name] = value;
    const newArray = tags.map((tag: any) => (tag.id === record.id ? newObj : tag));
    setTags(newArray);
  };
  const onAddTag = () => {
    if (dataTag.name !== '' && dataTag.description !== '' && dataTag.slug !== '') {
      dispatch(addProductBrandByAdmin({ token, inputs: { ...dataTag } }));
      setTimeout(() => {
        setDataTag({
          name: '',
          description: '',
          slug: '',
        });
      }, 500);
    } else {
      errorToast('Please input enough value');
    }
  };
  const onDelete = (record: any) => {
    dispatch(deleteProductBrand({ token, id: record?.id }));
  };
  const onExpand = (_: any, { key }: any) => setExpandedKey(key);
  useEffect(() => {
    if (arrTag?.length > 0) {
      setTags(arrTag);
    } else {
      setTags(getAllProductBrand?.result);
    }
  }, [arrTag?.length]);
  useEffect(() => {
    dispatch(fetchAllProductBrand({ token, limit: pageSize, page: pagi.current }));
  }, [dispatch, token, pagi.current]);

  return (
    <div className="pt-[26px] px-[35px] mb-[100px]">
      <span className="text-[20px] mplus1pextrabold">{t('ADD_NEW_BRAND')}</span>
      <div className="flex mt-[40px] w-full pb-[100px]">
        <div className="w-[30%] flex flex-col pr-[2%]">
          <span className="text-[14px] mplus1pnormal">{t('GIVEN_NAME')}</span>
          <Input
            value={dataTag.name}
            onChange={(e) => setDataTag({ ...dataTag, name: e.target.value })}
            className="h-[39px] rounded-[0px] border border-[rgba(0,0,0,0.5)] w-full mt-[10px]"
          />
          <span className="text-[10px] text-[#444444] mplus1pnormal">
            {t('THIS_IS_NAME_APPEAR')}
          </span>
          <span className="mt-[20px] text-[14px] mplus1pnormal">{t('LINK_NAME')}</span>
          <Input
            value={dataTag.slug}
            onChange={(e) => setDataTag({ ...dataTag, slug: e.target.value })}
            className="h-[39px] rounded-[0px] border border-[rgba(0,0,0,0.5)] w-full mt-[10px]"
          />
          <span className="text-[10px] text-[#444444] mplus1pnormal">{t('TEXT_CATE')}</span>
          <span className="text-[14px] mplus1pnormal mt-[20px]">{t('EXPLANATION')}</span>
          <TextArea
            value={dataTag.description}
            onChange={(e) => setDataTag({ ...dataTag, description: e.target.value })}
            autoSize={{ minRows: 3, maxRows: 5 }}
            className="mt-[10px] rounded-[0px] border border-[rgba(0,0,0,0.5)]"
          />
          <span className="text-[10px] text-[#444444] mplus1pnormal">{t('BY_DEFAULT')}</span>

          <span
            onClick={onAddTag}
            className="h-[39px] border rounded-[10px] border-[#000000] w-[59px] bg-[#3E6100] mt-[50px] text-white text-[14px] mplus1pextrabold flex items-center justify-center cursor-pointer"
          >
            {t('ADDITON')}
          </span>
        </div>
        <div className="w-[70%] pl-[2%] flex flex-col">
          <div className="flex">
            <Select
              defaultValue="一括操作"
              className="w-[150px] slc_catego"
              options={[{ value: '一括操作', label: '一括操作' }]}
            />
            {/* <span className="h-[39px] cursor-pointer ml-[38px] w-[81px] bg-white text-[14px] mplus1pnormal border text-[#0038FF] border-[#0038FF] rounded-[10px] flex justify-center items-center">
              {t('APPLY')}
            </span> */}
          </div>
          <div className="mt-[17px]">
            <Table
              className="table_merchandise ckb_brand"
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              columns={columns}
              scroll={{ x: 500 }}
              pagination={{
                showSizeChanger: false,
                total: getAllProductBrand?.count,
                pageSize: 10,
                current: pagi.current,

                onChange: (page: any) => {
                  setPagi({
                    current: page,
                    minIndex: (page - 1) * pageSize,
                    maxIndex: page * pageSize,
                  });
                },
              }}
              dataSource={arrTag}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (record?.id === idRecord) return;
                    setIdRecord(record?.id as string);
                  }, // click row
                };
              }}
              expandable={{
                expandedRowKeys: [expandedKey],
                onExpand: (expanded, record) => onExpand(expanded, record),
                expandedRowRender: (record, index, indent, expanded) => {
                  return (
                    <div key={record?.key} className="flex flex-col pl-[10%] py-[5%]">
                      <div className="flex items-center">
                        <span className="w-[30%] flex justify-start">カテゴリー名</span>
                        <div className="w-[70%">
                          <Input
                            // value={dataUpdateCate?.name}
                            defaultValue={record.name}
                            onChange={(e) => onValueChange(e.target.value, 'name', record)}
                            className="h-[27px]"
                          />
                        </div>
                      </div>
                      <div className="flex items-center mt-[10px]">
                        <span className="w-[30%] flex justify-start">Slug</span>
                        <div className="w-[70%">
                          <Input
                            defaultValue={record?.slug}
                            onChange={(e) => onValueChange(e.target.value, 'slug', record)}
                            className="h-[27px]"
                          />
                        </div>
                      </div>
                      <div className="flex mt-[30px]">
                        <div
                          onClick={() => {
                            const foundCategory = tags?.find((tag: any) => tag?.id === record?.id);
                            dispatch(
                              updateProductBrand({
                                token,
                                id: record?.id,
                                inputs: {
                                  name: foundCategory?.name,
                                  slug: foundCategory?.slug,
                                },
                              })
                            );
                          }}
                          className="bg-[#3E6100] cursor-pointer text-white flex items-center justify-center rounded-[10px] h-[39px] w-[54px] text-[14px] mplus1pextrabold"
                        >
                          {t('UPDATE')}
                        </div>
                        <div
                          onClick={() => setExpandedKey(null)}
                          className="border cursor-pointer border-[#3E6100] text-[#3E6100] flex items-center justify-center rounded-[10px] h-[39px] px-[15px] text-[14px] mplus1pmedium ml-[20px] p-[12px]"
                        >
                          {t('CANCEL')}
                        </div>
                      </div>
                    </div>
                  );
                },
                expandIcon: ({ expanded, onExpand, record }) => (
                  <Popconfirm
                    title="aaaa"
                    open={idRecord === record?.id}
                    placement="bottom"
                    description={() => {
                      return (
                        <div className="bg-[#DCBA78] py-[5px] flex flex-col rounded-[5px]">
                          <span
                            onClick={(e) => {
                              setViewSlc('edit');
                              setIdRecord('');
                              onExpand(record, e);
                            }}
                            className={`${
                              viewSlc === 'edit' && 'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] hover:text-white hover:bg-[#3E6100] hover:rounded-[5px] hover:mx-[2px] mt-[5px] flex items-center text-[14px] mplus1pnormal`}
                          >
                            {t('EDIT')}
                          </span>

                          <span
                            onClick={(e) => {
                              setViewSlc('remove_cate');
                              recordRef.current = record;
                              setIdRecord('');
                              setOpenModal(true);
                            }}
                            className={`${
                              viewSlc === 'remove_cate' &&
                              'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] hover:text-white hover:bg-[#3E6100] hover:rounded-[5px] hover:mx-[2px] mt-[5px] flex items-center text-[14px] mplus1pnormal`}
                          >
                            削除する
                          </span>
                        </div>
                      );
                    }}
                    onConfirm={() => {
                      // message.info('Clicked on Yes.');
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button
                      onClick={() => {
                        if (idRecord === record?.id) {
                          setIdRecord('');
                        }
                      }}
                      key={record?.key}
                      className=" flex justify-center items-center w-full"
                    >
                      <span className="h-[20px] w-[20px] flex justify-center items-center rounded-[2px] bg-[#DCBA78]">
                        {' '}
                        {cts.iconDot}
                      </span>
                    </button>
                  </Popconfirm>
                ),
              }}
              // expandedRowKeys={[expended]}
            />
          </div>
        </div>
      </div>
      <ModalConfirm
        openModal={openModal}
        setOpenModal={setOpenModal}
        onConfirm={onDelete}
        record={recordRef.current}
        text="商標を削除してもよろしいですか?"
      />
    </div>
  );
};
export default ProductBrandPage;
