import { Button, Popconfirm, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ProductTypes, TypesProductUpdate } from 'models';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getToken } from 'helpers';
import {
  cloneProduct,
  deleteProduct,
  fetchProductsWithFilter,
  fetchSearchProductByAdmin,
  updateProductStatus,
} from 'slices/productSlice';
import * as cts from '../../constants/ui/svg';
import { Link } from 'react-router-dom';
import { URL_UPLOAD } from '../../constants/index';
import ContentTableProduct from './ContentTableProduct';
import ModalConfirm from 'components/Common/ModalConfirm';
interface Props {
  btnSearch: boolean;
  data: any;
  idCateSelect: any;
  setIdCateSelect: any;
  idTagSelect: any;
  setIdTagSelect: any;
  setBtnSearch: React.Dispatch<React.SetStateAction<boolean>>;
  textSearch: string;
  total: any;
  status: string;
  ref: any;
}
const TableProduct: React.FC<Props> = ({
  total,
  btnSearch,
  textSearch,
  setBtnSearch,
  data,
  idCateSelect,
  setIdCateSelect,
  idTagSelect,
  setIdTagSelect,
  status,
  ref,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = getToken();
  const recordRef = useRef('');
  const [viewSlc, setViewSlc] = useState('');
  const [idRecord, setIdRecord] = useState('');
  const [recordCate, setRecordCate] = useState([]);
  const [recordTag, setRecordTag] = useState([]);
  const [openPop, setOpenPop] = useState(false);
  const [idReview, setIdReview] = useState('');
  const [expandedKey, setExpandedKey] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [dataSearch, setDataSearch] = useState<any>({
    count: 0,
    result: [],
  });
  const [productUpdate, setProductUpdate] = useState<any>();
  const pageSize = 10;
  const [pagi, setPagi] = useState<any>({
    dataBefore: [],
    totalPage: total / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });
  const [pagiSearch, setPagiSearch] = useState<any>({
    dataAfter: [],
    totalPageSearch: total / pageSize,
    currentSearch: 1,
    minIndexSearch: 0,
    maxIndexSearch: pageSize,
  });
  const onExpand = (_: any, { key }: any) => setExpandedKey(key);
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ProductTypes[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: ProductTypes) => ({
      // disabled: record.name === 'Disabled User', // Column configuration not to be checked
      // name: record.name,
    }),
  };

  const columns: ColumnsType<ProductTypes> = [
    // {
    //   title: <Checkbox disabled={true} className="flex justify-center w-full ckb_table"></Checkbox>,
    //   key: '_id',
    //   dataIndex: '_id',
    //   fixed: 'left',
    //   width: 50,
    //   render: () => (
    //     <Checkbox className="flex justify-center w-full ckb_table" onChange={onChange}></Checkbox>
    //   ),
    // },
    {
      title: <div className="flex justify-center w-full ckb_table"></div>,
      key: 'operation',
      fixed: 'left',
      width: 0,
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">{t('IMAGE')}</span>
      ),
      width: 100,
      dataIndex: 'image',
      key: 'image',
      render: (value: any) => (
        <span className="flex justify-center w-full">
          <img className="h-[50px] w-[50px]" src={`${URL_UPLOAD}/products/${value}`} />
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal text-start">{t('GIVEN_NAME')}</span>,
      width: 170,
      dataIndex: 'product',
      key: 'product',
      render: (value: any) => (
        <div className="flex flex-col items-start">
          <span className="text-[12px] mplus1pmedium text-[#0038FF]">{value.name}</span>
          <span className="text-[10px] mplus1pnormal text-[#444444]">ID : {value._id}</span>
        </div>
      ),
    },

    Table.EXPAND_COLUMN,
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">
          {t('STOCK_AMOUNT')}
        </span>
      ),
      dataIndex: 'numberInStock',
      key: 'numberInStock',
      width: 100,
      render: (value: any) => (
        <span className="flex justify-center w-full">{value || 0} count</span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">{t('STOCK')}</span>
      ),
      dataIndex: 'stockStatus',
      key: 'stockStatus',
      width: 100,
      render: (value: any) => (
        <span className="text-[12px] text-[#3E6100] mplus1pmedium flex justify-center w-full">
          {value ? value : '--'}
        </span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">{t('PRICE')}</span>
      ),
      dataIndex: 'price',
      key: 'price',
      width: 100,
      render: (value: any) => (
        <span className=" flex justify-center w-full">
          ¥{Number(value || 0).toLocaleString()}
          {/* ¥(Number(value)).toLocaleString() */}
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal">{t('CATEGORY')}</span>,
      dataIndex: 'categoryId',
      key: 'categoryId',
      width: 150,
      render: (value: any) => (
        <Tooltip
          placement="right"
          title={
            <span className='className="text-[#0038FF] text-[12px] mplus1pnormal text-start"'>
              {value?.map((item: any) => item?.name + ' ' + ',' + ' ')}
            </span>
          }
        >
          <Button>{'--'}</Button>
        </Tooltip>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal">{t('TAG')}</span>,
      dataIndex: 'tags',
      key: 'tags',
      width: 150,
      render: (value: any) => (
        <Tooltip
          placement="right"
          title={
            <span className='className="text-[#0038FF] text-[12px] mplus1pnormal text-start"'>
              {value?.map((item: any) => '#' + item?.name + ' ' + ',' + ' ')}
            </span>
          }
        >
          <Button>{'--'}</Button>
        </Tooltip>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">ranking</span>,
      dataIndex: '',
      key: '',
      width: 100,
      render: (value: any) => (
        <span className="text-[#0038FF] text-[12px] mplus1pnormal  flex justify-center w-full">
          2
        </span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">{t('REVIEW')}</span>
      ),
      dataIndex: 'numberOfReview',
      key: 'numberOfReview',
      width: 100,
      render: (value: any) => (
        <span className="text-[#0038FF] text-[12px] mplus1pnormal  flex justify-center w-full">
          {value || 0}
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal">{t('DATE')}</span>,
      dataIndex: 'createdAt',
      fixed: 'right',
      key: 'createdAt',
      width: 180,
      render: (value: any) => (
        <div className="flex flex-col">
          <span>公開済み</span>
          <span>
            {value ? moment(value).format('YYYY/MM/DD') : '--/--/--'} at{' '}
            {value ? moment(value).format('HH:MM') : '--:--'}
          </span>
          {/* <span>2021/10/25 at 3:47 pm</span> */}
        </div>
      ),
    },
  ];

  const _renderTable = (record: any) => {
    return (
      <>
        <ContentTableProduct
          idReview={idReview}
          record={record}
          viewSlc={viewSlc}
          productUpdate={productUpdate}
          setProductUpdate={setProductUpdate}
          arrNew={arrNew}
          data={data}
          setExpandedKey={setExpandedKey}
        />
      </>
    );
  };
  const onDuplicate = (record: any) => {
    dispatch(cloneProduct({ token, id: record?._id }));
  };
  const onDelete = (record: any) => {
    dispatch(deleteProduct({ token, id: record?._id }));
  };
  const onUpdate = (record: any) => {
    dispatch(
      updateProductStatus({
        token,
        id: record?._id,
        inputs: { status: 'trash', brandId: record?.brandId?.id },
      })
    );
  };
  const onTrash = (record: any) => {
    setOpenPop(false);
    switch (status) {
      case 'trash':
        setViewSlc('remove_product');
        setOpenModal(true);
        return;
      case '':
        return;
      case 'public':
        setViewSlc('remove_product1');
        setOpenModal(true);
        return;

      // dispatch(
      //   updateProductStatus({
      //     token,
      //     id: record?._id,
      //     inputs: { status: 'trash', brandId: record?.brandId?.id },
      //   })
      // );
      case 'draft':
        setViewSlc('remove_product1');
        setOpenModal(true);
        return;

      // dispatch(
      //   updateProductStatus({
      //     token,
      //     id: record?._id,
      //     inputs: { status: 'trash', brandId: record?.brandId?.id },
      //   })
      // );
      default:
        break;
    }
  };
  const _renderButton = (onExpand: any, record: any) => {
    return (
      <div className=" bg-[#DCBA78] py-[5px] flex flex-col rounded-[5px] w-[156px]">
        {status !== 'trash' && (
          <Link
            to={`/product/${record?._id}`}
            onClick={(e) => {
              setViewSlc('quick_edit');
              setIdRecord('');
              // history.push({
              //   pathname: '/product',
              //   search: `/${record?._id}`,
              // });
            }}
            className={`${
              viewSlc === 'quick_edit' && 'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
            } cursor-pointer hover:text-white hover:bg-[#3E6100] mt-[5px] hover:mx-[2px] hover:rounded-[5px] px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
          >
            {t('EDIT')}
          </Link>
        )}
        {status === 'draft' && (
          <span
            onClick={(e) => {
              setViewSlc('public');
              dispatch(
                updateProductStatus({
                  token,
                  id: record?._id,
                  inputs: { status: 'public', brandId: record?.brandId?.id },
                })
              );
              setIdRecord('');
            }}
            className={`${
              viewSlc === 'public' && 'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
            } cursor-pointer px-[10px] h-[30px] hover:text-white hover:rounded-[5px] hover:bg-[#3E6100] hover:mx-[2px] mt-[5px] flex items-center text-[14px] mplus1pnormal `}
          >
            {t('PUBLISH')}
          </span>
        )}

        {status === 'public' && (
          <>
            <span
              onClick={(e) => {
                // setState({
                //   name: record?.name,
                //   slug: record?.slug,
                //   price: record?.price,
                //   marketPrice: record?.marketPrice,
                //   height: record?.height,
                //   weight: record?.weight,
                //   length: record?.length,
                //   width: record?.width,
                //   reviewAble: record?.reviewAble,
                //   listTag: record?.tags?.map((i: any) => i?.id),
                //   categoryId: record?.categoryId?.map((i: any) => i?._id),
                // });
                setViewSlc('edit');
                onExpand(record, e);
                setRecordCate(record?.categoryId);
                setRecordTag(record?.tags);
                // setIsExpanded(true);
                setIdRecord('');
                // setProductUpdate({
                //   name: record?.name,
                //   slug: record?.slug,
                //   price: record?.price,
                //   marketPrice: record?.marketPrice,
                //   height: record?.height,
                //   weight: record?.weight,
                //   length: record?.length,
                //   width: record?.width,
                //   reviewAble: record?.reviewAble,
                //   listTag: record?.tags?.map((i: any) => i?.name),
                //   categoryId: record?.categoryId?.map((i: any) => i?._id),
                // });
              }}
              className={`${
                viewSlc === 'edit' && 'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
              } cursor-pointer hover:text-white hover:bg-[#3E6100] hover:mx-[2px] hover:rounded-[5px] px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
            >
              {t('QUICK')}
              {t('EDIT')}
            </span>
            <span
              onClick={(e) => {
                setViewSlc('duplicate_product');
                recordRef.current = record;
                setOpenModal(true);
                setIdRecord('');
              }}
              className={`${
                viewSlc === 'duplicate_product' && 'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
              } cursor-pointer px-[10px] h-[30px] hover:text-white hover:rounded-[5px] hover:bg-[#3E6100] hover:mx-[2px] mt-[5px] flex items-center text-[14px] mplus1pnormal `}
            >
              {t('DUPLICATE_PRODUCT')}
            </span>
          </>
        )}
        {status === 'trash' && (
          <span
            onClick={(e) => {
              setViewSlc('regain');
              dispatch(
                updateProductStatus({
                  token,
                  id: record?._id,
                  inputs: { status: 'draft', brandId: record?.brandId?.id },
                })
              );
              setIdRecord('');
            }}
            className={`${
              viewSlc === 'regain' && 'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
            } cursor-pointer px-[10px] h-[30px] hover:text-white hover:rounded-[5px] hover:bg-[#3E6100] hover:mx-[2px] mt-[5px] flex items-center text-[14px] mplus1pnormal `}
          >
            取り戻す
          </span>
        )}
        {status === 'public' && (
          <span
            onClick={(e) => {
              setViewSlc('see_product');
              onExpand(record, e);
              setIdRecord('');
              setIdReview(record?._id);
            }}
            className={`${
              viewSlc === 'see_product' && 'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
            } cursor-pointer px-[10px] h-[30px] hover:text-white hover:bg-[#3E6100] hover:rounded-[5px] mt-[5px] flex items-center text-[14px] mplus1pnormal `}
          >
            {t('SEE_PRODUCT_REVIEW')}
          </span>
        )}

        <span
          onClick={() => {
            onTrash(record);
            setIdRecord('');
            recordRef.current = record;
          }}
          // onClick={(e) => {
          //   setViewSlc('remove_product');
          //   setOpenModal(true);
          //   recordRef.current = record;
          //   setOpenPop(false);
          // }}
          className={`${
            viewSlc === 'remove_product' && 'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
          } cursor-pointer px-[10px] h-[30px] hover:text-white hover:bg-[#3E6100] hover:rounded-[5px] hover:mx-[2px] mt-[5px] flex items-center text-[14px] mplus1pnormal `}
        >
          {status === 'trash'
            ? '完全に削除'
            : status === 'draft'
            ? '削除する'
            : t('REMOVE_PRODUCT')}
        </span>
      </div>
    );
  };
  const arrNew = data?.map((item: any, idx: any) => {
    return {
      ...item,
      key: idx,
      product: {
        name: item.name,
        _id: item._id,
      },
    };
  });
  useEffect(() => {
    if (arrNew?.length > 0) {
      setProductUpdate(arrNew);
    } else {
      setProductUpdate(data);
    }
  }, [arrNew?.length]);

  useImperativeHandle(ref, () => {
    return {
      pagiProduct: () => {
        return pagi;
      },
    };
  });
  useEffect(() => {
    if (!btnSearch) {
      dispatch(
        fetchProductsWithFilter({
          token,
          page: pagi.current,
          limit: pageSize,
          inputs: { categoryId: idCateSelect, tagId: idTagSelect, status: status },
        })
      );
    }
  }, [pagi.current, token, dispatch, btnSearch, idCateSelect, idTagSelect, status]);
  useEffect(() => {
    if (textSearch === '') {
      setBtnSearch(false);
    }
  }, [textSearch, btnSearch]);
  useEffect(() => {
    if (btnSearch && textSearch !== '') {
      setPagiSearch({ currentSearch: pagiSearch.currentSearch });
      dispatch(
        fetchProductsWithFilter({
          token,
          page: pagiSearch.currentSearch,
          limit: pageSize,
          inputs: {
            categoryId: idCateSelect,
            tagId: idTagSelect,
            searchText: textSearch,
            status: status,
          },
        })
      );
      setDataSearch({
        count: total,
        result: data,
      });
      // dispatch(
      //   fetchSearchProductByAdmin({
      //     token,
      //     page: pagiSearch.currentSearch,
      //     limit: pageSize,
      //     searchText: textSearch,
      //   })
      // );
    }
  }, [token, pagiSearch.currentSearch, dispatch, btnSearch, status]);

  return (
    <>
      <Table
        className="table_merchandise"
        columns={columns}
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        dataSource={arrNew}
        scroll={{ x: 1000 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (idRecord === record?._id) return;
              setIdRecord(record?._id as string);
            }, // click row
          };
        }}
        expandable={{
          expandedRowRender: (record, index, indent, expanded) => _renderTable(record),
          // rowExpandable: (record) => false,
          expandedRowKeys: [expandedKey],
          onExpand: (expanded, record) => onExpand(expanded, record),
          expandIcon: ({ expanded, onExpand, record }) => (
            <Popconfirm
              key={record._id}
              title="aaaa"
              placement="bottom"
              description={_renderButton(onExpand, record)}
              okText="Yes"
              cancelText="No"
              open={idRecord === record?._id}
            >
              <button
                // onBlur={() => setIdRecord('')}
                // tabIndex={0}
                // suppressContentEditableWarning
                // contentEditable
                // onBlur={() => setIdRecord('')}
                className=" flex justify-center items-center w-full"
              >
                <span
                  key={record.key}
                  onClick={(e: any) => {
                    if (idRecord === record?._id) {
                      setIdRecord('');
                    }
                  }}
                  className="h-[20px] w-[20px] outline-none rounded-[2px] bg-[#DCBA78] flex justify-center items-center"
                >
                  {cts.iconDot}
                </span>
              </button>
            </Popconfirm>
          ),
        }}
        pagination={{
          showSizeChanger: false,
          total: total,
          pageSize: 10,
          current: btnSearch ? pagiSearch.currentSearch : pagi.current,

          onChange: (page: any) => {
            setExpandedKey(null);
            if (btnSearch) {
              setPagiSearch({
                currentSearch: page,
                minIndexSearch: (page - 1) * pageSize,
                maxIndexSearch: page * pageSize,
              });
            } else {
              setPagi({
                current: page,
                minIndex: (page - 1) * pageSize,
                maxIndex: page * pageSize,
              });
            }
          },
        }}
      />
      <ModalConfirm
        openModal={openModal}
        setOpenModal={setOpenModal}
        onConfirm={
          viewSlc === 'duplicate_product'
            ? onDuplicate
            : viewSlc === 'remove_product'
            ? onDelete
            : onUpdate
        }
        record={recordRef.current}
        text={
          viewSlc === 'duplicate_product'
            ? 'この製品の複製を作成してもよろしいですか?'
            : viewSlc === 'remove_product'
            ? 'この商品を削除してもよろしいですか?'
            : 'この製品をゴミ箱に入れてもよろしいですか?'
        }
      />
    </>
  );
};
export default TableProduct;
