import { Checkbox, Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as cts from '../../constants/ui/svg';

const RankingControlPage = () => {
  return (
    <div className="pt-[26px] px-[35px] flex flex-col pb-[100px]">
      <span className="text-[20px] mplus1pextrabold mr-[54px]">RANKING CONTROL PANEL</span>
      <div className="flex items-center justify-between mt-[55px]">
        <div className="flex-1 flex flex-col">
          <div className="w-[95%] flex flex-col border border-black/50 rounded-[5px]">
            <span className="text-[14px] mplus1pextrabold h-[44px] border-b border-black/50 flex items-center justify-center bg-[rgba(217,217,217,0.5)]">
              ORGANIC RANKING ANALYTICS /monthly bh u weekly bhu/
            </span>
            <span className="h-[45px] bg-white pl-[20px] flex items-center text-[14px] mplus1pmedium text-[#0038FF]">
              {cts.iconBlue}
              <span className="ml-[20px]">show previous month</span>
            </span>
            <div className="flex items-center w-full bg-[rgba(217,217,217,0.5)] py-[4px] px-[4px] border-b border-t border-black/50">
              <div className="flex w-[35%] flex-col border border-black/50 bg-white rounded-[10px] py-[15px] items-center">
                <span className="text-[14px] mplus1pnormal">Showing sales rank</span>
                <span className="text-[20px] mplus1pextrabold">1-5</span>
              </div>
              <div className="flex w-[20%] flex-col border mx-[6px] border-black/50 bg-white rounded-[10px] py-[15px] items-center">
                <span className="text-[14px] mplus1pnormal">Total sales</span>
                <span className="text-[20px] mplus1pextrabold">2500cnt</span>
              </div>
              <div className="flex w-[45%] flex-col border border-black/50 bg-white rounded-[10px] py-[15px] items-center">
                <span className="text-[14px] mplus1pnormal">all products in sales </span>
                <span className="text-[20px] mplus1pextrabold">10000cnt</span>
              </div>
            </div>
            <div className="border-b h-[44px] border-black/50 bg-white w-full flex items-center">
              <span className="text-[14px] mplus1pnormal w-[20%] flex justify-end">1</span>
              <span className="text-[14px] text-[#0038FF] w-[80%] pl-[20px] mplus1pmedium">
                フェイシャルオイル AP
              </span>
            </div>
            <div className="border-b h-[44px] border-black/50 bg-[#FBF8F1] w-full flex items-center">
              <span className="text-[14px] mplus1pnormal w-[20%] flex justify-end">2</span>
              <span className="text-[14px] text-[#0038FF] w-[80%] pl-[20px] mplus1pmedium">
                ボディオイル SS　{' '}
              </span>
            </div>
            <div className="border-b h-[44px] border-black/50 bg-white w-full flex items-center">
              <span className="text-[14px] mplus1pnormal w-[20%] flex justify-end">3</span>
              <span className="text-[14px] text-[#0038FF] w-[80%] pl-[20px] mplus1pmedium">
                ゴートミルクソープ
              </span>
            </div>
            <div className="border-b h-[44px] border-black/50 bg-[#FBF8F1] w-full flex items-center">
              <span className="text-[14px] mplus1pnormal w-[20%] flex justify-end">4</span>
              <span className="text-[14px] text-[#0038FF] w-[80%] pl-[20px] mplus1pmedium">
                フェイシャルオイル RH　　{' '}
              </span>
            </div>
            <div className="rounded-b-[10px] h-[44px] border-black/50 bg-white w-full flex items-center">
              <span className="text-[14px] mplus1pnormal w-[20%] flex justify-end">5</span>
              <span className="text-[14px] text-[#0038FF] w-[80%] pl-[20px] mplus1pmedium">
                ヤクミルクソープ
              </span>
            </div>
          </div>
          <div className="mt-[15px] w-[95%] flex justify-center">
            <Checkbox>Publish organic ranking</Checkbox>
          </div>
        </div>
        <div className="flex-1 flex-col flex items-end">
          <div className="w-[95%] flex flex-col border border-black/50 rounded-[5px]">
            <span className="text-[14px] mplus1pextrabold h-[44px] border-b border-black/50 flex items-center justify-center bg-[rgba(217,217,217,0.5)]">
              PAID RANKING
            </span>
            <span className="h-[45px] bg-white pl-[20px] flex items-center text-[14px] mplus1pmedium text-[#0038FF]">
              {cts.iconBlue}
              <span className="ml-[20px]">show previous month</span>
            </span>
            <div className="flex items-center w-full bg-[rgba(217,217,217,0.5)] py-[4px] px-[4px] border-b border-t border-black/50">
              <div className="flex w-[35%] flex-col border border-black/50 bg-white rounded-[10px] py-[15px] items-center">
                <span className="text-[14px] mplus1pnormal">Showing paid rank</span>
                <span className="text-[20px] mplus1pextrabold">1-5</span>
              </div>
              <div className="flex w-[20%] flex-col border mx-[6px] border-black/50 bg-white rounded-[10px] py-[15px] items-center">
                <span className="text-[14px] mplus1pnormal">Total sales</span>
                <span className="text-[20px] mplus1pextrabold">2500cnt</span>
              </div>
              <div className="flex w-[45%] flex-col border border-black/50 bg-white rounded-[10px] py-[15px] items-center">
                <span className="text-[14px] mplus1pnormal">all products in sales </span>
                <span className="text-[20px] mplus1pextrabold">10000cnt</span>
              </div>
            </div>
            <div className="border-b h-[44px] border-black/50 bg-white w-full flex items-center">
              <span className="text-[14px] mplus1pnormal w-[20%] flex justify-end">1</span>
              <span className="text-[14px] text-[#0038FF] w-[80%] pl-[20px] mplus1pmedium">
                フェイシャルオイル AP
              </span>
            </div>
            <div className="border-b h-[44px] border-black/50 bg-[#FBF8F1] w-full flex items-center">
              <span className="text-[14px] mplus1pnormal w-[20%] flex justify-end">2</span>
              <span className="text-[14px] text-[#0038FF] w-[80%] pl-[20px] mplus1pmedium">
                ボディオイル SS　{' '}
              </span>
            </div>
            <div className="border-b h-[44px] border-black/50 bg-white w-full flex items-center">
              <span className="text-[14px] mplus1pnormal w-[20%] flex justify-end">3</span>
              <span className="text-[14px] text-[#0038FF] w-[80%] pl-[20px] mplus1pmedium">
                ゴートミルクソープ
              </span>
            </div>
            <div className="border-b h-[44px] border-black/50 bg-[#FBF8F1] w-full flex items-center">
              <span className="text-[14px] mplus1pnormal w-[20%] flex justify-end">4</span>
              <span className="text-[14px] text-[#0038FF] w-[80%] pl-[20px] mplus1pmedium">
                フェイシャルオイル RH　　{' '}
              </span>
            </div>
            <div className="rounded-b-[10px] h-[44px] border-black/50 bg-white w-full flex items-center">
              <span className="text-[14px] mplus1pnormal w-[20%] flex justify-end">5</span>
              <span className="text-[14px] text-[#0038FF] w-[80%] pl-[20px] mplus1pmedium">
                ヤクミルクソープ
              </span>
            </div>
          </div>
          <div className="mt-[15px] w-[95%] flex justify-center">
            <Checkbox>Publish paid ranking</Checkbox>
          </div>
        </div>
      </div>
      <div className="border border-black/50 flex flex-col mt-[40px] mb-[50px]">
        <div className="flex items-center bg-white h-[50px] border-b border-black/50">
          <span className="text-[14px] mplus1pnormal w-[10%] flex justify-center">Rank</span>
          <span className="text-[14px] mplus1pnormal w-[25%] flex justify-center">Product</span>
          <span className="text-[14px] mplus1pnormal w-[25%] flex justify-center">
            Ranking hugatsaa tohiruulah
          </span>
          <span className="text-[14px] mplus1pnormal w-[15%] flex justify-center">
            ranking status
          </span>
          <span className="text-[14px] mplus1pnormal w-[25%] flex justify-center">
            Last updated
          </span>
        </div>
        <div className="h-[80px] flex items-center bg-[#FBF8F1]">
          <span className="text-[14px] mplus1pmedium w-[10%] flex justify-center">1位</span>
          <div className="w-[25%] flex justify-center">
            <Select
              className="w-[70%] slc_ranking"
              defaultValue="フェイシャルオイル AP"
              options={[{ value: 'フェイシャルオイル AP', label: 'フェイシャルオイル AP' }]}
            />
          </div>
          <div className="w-[25%] flex justify-center">
            <Select
              className="w-[70%] slc_ranking"
              defaultValue={
                <span className="text-[12px] text-[#0038FF] mplus1pmedium">
                  フェイシャルオイル AP
                </span>
              }
              options={[{ value: 'フェイシャルオイル AP', label: 'フェイシャルオイル AP' }]}
            />
          </div>
          <span className="text-[12px] mplus1pmedium w-[15%] flex justify-center">active</span>
          <span className="text-[12px] mplus1pnormal w-[25%] flex justify-center">
            2022.12.21 by <span className="text-[#0038FF] underline ml-[2px]">admin01</span>
          </span>
        </div>
        <div className="h-[80px] flex items-center bg-white">
          <span className="text-[14px] mplus1pmedium w-[10%] flex justify-center">2位</span>
          <div className="w-[25%] flex justify-center">
            <Select
              className="w-[70%] slc_ranking"
              defaultValue={
                <span className="text-[12px] text-[#0038FF] mplus1pmedium">
                  フェイシャルオイル AP
                </span>
              }
              options={[{ value: 'フェイシャルオイル AP', label: 'フェイシャルオイル AP' }]}
            />
          </div>
          <div className="w-[25%] flex justify-center">
            <Select
              className="w-[70%] slc_ranking"
              defaultValue={
                <span className="text-[12px] text-[#0038FF] mplus1pmedium">
                  フェイシャルオイル AP
                </span>
              }
              options={[{ value: 'フェイシャルオイル AP', label: 'フェイシャルオイル AP' }]}
            />
          </div>
          <span className="text-[12px] mplus1pmedium w-[15%] flex justify-center">active</span>
          <span className="text-[12px] mplus1pnormal w-[25%] flex justify-center">
            2022.12.21 by <span className="text-[#0038FF] underline">admin01</span>
          </span>
        </div>
        <div className="h-[80px] flex items-center bg-[#FBF8F1]">
          <span className="text-[14px] mplus1pmedium w-[10%] flex justify-center">3位</span>
          <div className="w-[25%] flex justify-center">
            <Select
              className="w-[70%] slc_ranking"
              defaultValue={
                <span className="text-[12px] text-[#0038FF] mplus1pmedium">
                  フェイシャルオイル AP
                </span>
              }
              options={[{ value: 'フェイシャルオイル AP', label: 'フェイシャルオイル AP' }]}
            />
          </div>
          <div className="w-[25%] flex justify-center">
            <Select
              className="w-[70%] slc_ranking"
              defaultValue={
                <span className="text-[12px] text-[#0038FF] mplus1pmedium">
                  フェイシャルオイル AP
                </span>
              }
              options={[{ value: 'フェイシャルオイル AP', label: 'フェイシャルオイル AP' }]}
            />
          </div>
          <span className="text-[12px] mplus1pmedium w-[15%] flex justify-center">active</span>
          <span className="text-[12px] mplus1pnormal w-[25%] flex justify-center">
            2022.12.21 by <span className="text-[#0038FF] underline">admin01</span>
          </span>
        </div>
        <div className="h-[80px] flex items-center bg-white">
          <span className="text-[14px] mplus1pmedium w-[10%] flex justify-center">4位</span>
          <div className="w-[25%] flex justify-center">
            <Select
              className="w-[70%] slc_ranking"
              defaultValue={
                <span className="text-[12px] text-[#0038FF] mplus1pmedium">
                  フェイシャルオイル AP
                </span>
              }
              options={[{ value: 'フェイシャルオイル AP', label: 'フェイシャルオイル AP' }]}
            />
          </div>
          <div className="w-[25%] flex justify-center">
            <Select
              className="w-[70%] slc_ranking"
              defaultValue={
                <span className="text-[12px] text-[#0038FF] mplus1pmedium">
                  フェイシャルオイル AP
                </span>
              }
              options={[{ value: 'フェイシャルオイル AP', label: 'フェイシャルオイル AP' }]}
            />
          </div>
          <span className="text-[12px] mplus1pmedium w-[15%] flex justify-center">active</span>
          <span className="text-[12px] mplus1pnormal w-[25%] flex justify-center">
            2022.12.21 by <span className="text-[#0038FF] underline">admin01</span>
          </span>
        </div>
        <div className="h-[80px] flex items-center bg-[#FBF8F1]">
          <span className="text-[14px] mplus1pmedium w-[10%] flex justify-center">5位</span>
          <div className="w-[25%] flex justify-center">
            <Select
              className="w-[70%] slc_ranking"
              defaultValue={
                <span className="text-[12px] text-[#0038FF] mplus1pmedium">
                  フェイシャルオイル AP
                </span>
              }
              options={[{ value: 'フェイシャルオイル AP', label: 'フェイシャルオイル AP' }]}
            />
          </div>
          <div className="w-[25%] flex justify-center">
            <Select
              className="w-[70%] slc_ranking"
              defaultValue={
                <span className="text-[12px] text-[#0038FF] mplus1pmedium">
                  フェイシャルオイル AP
                </span>
              }
              options={[{ value: 'フェイシャルオイル AP', label: 'フェイシャルオイル AP' }]}
            />
          </div>
          <span className="text-[12px] mplus1pmedium w-[15%] flex justify-center">active</span>
          <span className="text-[12px] mplus1pnormal w-[25%] flex justify-center">
            2022.12.21 by <span className="text-[#0038FF] underline">admin01</span>
          </span>
        </div>
      </div>
    </div>
  );
};
export default RankingControlPage;
