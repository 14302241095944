import { Checkbox, Input, Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as cts from '../../constants/ui/svg';

interface Props {
  setOpenViewPage: React.Dispatch<React.SetStateAction<string>>;
}
const ViewProfileShopPage: React.FC<Props> = ({ setOpenViewPage }) => {
  const { t } = useTranslation();
  return (
    <div className="flex ">
      <div className="w-[75%] pr-[10%] flex flex-col mb-[100px]">
        <span className="text-[20px] mplus1pextrabold">
          {t('SHOP')}
          {t('PROFILE')}
        </span>
        <div className="flex mt-[57px]">
          <span>{cts.iconUserProfile}</span>
          <div className="flex flex-col pl-[8%] w-full">
            <span className="text-[16px] mplus1pextrabold">
              {t('SHOP')}
              {t('MANAGEMENT')}
              {t('GIVEN_NAME')}
            </span>
            <Input className="mt-[10px] h-[39px] w-[70%] rounded-[0px] border border-black/50" />
            <span className="text-[16px] mplus1pextrabold mt-[20px]">E-mail</span>
            <Input className="mt-[10px] h-[39px] w-[70%] rounded-[0px] border border-black/50" />
            <div className="mt-[30px] flex">
              <div className="flex-1 flex flex-col">
                <span className="text-[16px] mplus1pextrabold mt-[20px]">First 名</span>
                <Input className="mt-[10px] h-[39px] w-[70%] rounded-[0px] border border-black/50" />
              </div>
              <div className="flex-1 flex flex-col">
                <span className="text-[16px] mplus1pextrabold mt-[20px]">Last 名</span>
                <Input className="mt-[10px] h-[39px] w-[70%] rounded-[0px] border border-black/50" />
              </div>
            </div>
            <div className="mt-[20px] flex">
              <div className="flex-1 flex flex-col">
                <span className="text-[16px] mplus1pextrabold mt-[20px]">{t('DATE_OF_BIRTH')}</span>
                <Input className="mt-[10px] h-[39px] w-[70%] rounded-[0px] border border-black/50" />
              </div>
              <div className="flex-1 flex flex-col">
                <span className="text-[16px] mplus1pextrabold mt-[20px]">{t('SEX')}</span>
                <Input className="mt-[10px] h-[39px] w-[70%] rounded-[0px] border border-black/50" />
              </div>
            </div>
            <div className="mt-[20px] flex">
              <div className="flex-1 flex flex-col">
                <span className="text-[16px] mplus1pextrabold mt-[20px]">{t('POST_CODE')}</span>
                <Input className="mt-[10px] h-[39px] w-[70%] rounded-[0px] border border-black/50" />
              </div>
              <div className="flex-1 flex flex-col">
                <span className="text-[16px] mplus1pextrabold mt-[20px]">{t('ADDRESS')}</span>
                <Input className="mt-[10px] h-[39px] w-full rounded-[0px] border border-black/50" />
              </div>
            </div>
            <span className="text-[16px] mplus1pextrabold mt-[20px]">{t('INQUIRY_PHONE')}</span>
            <Input className="mt-[10px] h-[39px] w-[70%] rounded-[0px] border border-black/50" />
            <span className="text-[16px] mplus1pextrabold mt-[30px]">Website Language</span>
            <Select
              defaultValue="Site Default"
              className="mt-[10px] w-[142px]"
              options={[{ value: 'Site Default', label: 'Site Default' }]}
            />
            <span className="text-[16px] mplus1pextrabold mt-[30px]">
              Send ユーザー管理 notification
            </span>
            <Checkbox className="mt-[18px]">
              Send the new ユーザー管理 an email about their account.
            </Checkbox>
          </div>
        </div>
        <span
          onClick={() => setOpenViewPage('shop-page')}
          className="w-[54px] mt-[50px] flex items-center justify-center h-[39px] cursor-pointer rounded-[10px] text-[14px] mplus1pextrabold text-white border border-black/50 bg-[#3E6100]"
        >
          {t('RETURN')}
        </span>
      </div>
      <div className="w-[25%] flex flex-col">
        <span className="h-[39px] border border-[#C1C1C1] flex items-center justify-center text-[20px] mplus1pextrabold bg-[#DCBA78]">
          performance
        </span>
        <div className="flex flex-col border border-[#C1C1C1] bg-[#FBF8F1] items-center py-[40px]">
          <div className="bg-white border border-black/40 rounded-[5px] flex flex-col py-[10px] pl-[20px] w-[70%]">
            <span className="text-[14px] mplus1pnormal">total sales </span>
            <span className="text-[14px] mplus1pnormal mt-[20px]">00000yen</span>
          </div>
          <div className="bg-white border border-black/40 mt-[5px] rounded-[5px] flex flex-col py-[10px] pl-[20px] w-[70%]">
            <span className="text-[14px] mplus1pnormal">last month sales</span>
            <span className="text-[14px] mplus1pnormal mt-[20px]">00000yen</span>
          </div>
          <div className="bg-white border border-black/40 mt-[5px] rounded-[5px] flex flex-col py-[10px] pl-[20px] w-[70%]">
            <span className="text-[14px] mplus1pnormal">total products</span>
            <span className="text-[14px] mplus1pnormal mt-[20px]">00000 cnt</span>
          </div>
          <div className="bg-white border border-black/40 mt-[5px] rounded-[5px] flex flex-col py-[10px] pl-[20px] w-[70%]">
            <span className="text-[14px] mplus1pnormal">orders</span>
            <span className="text-[14px] mplus1pnormal mt-[20px]"> 00000 times</span>
          </div>
          <div className="bg-white border border-black/40 mt-[5px] rounded-[5px] flex flex-col py-[10px] pl-[20px] w-[70%]">
            <span className="text-[14px] mplus1pnormal">product sold</span>
            <span className="text-[14px] mplus1pnormal mt-[20px]">00000 cnt</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewProfileShopPage;
