import { Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ModalConfirm from 'components/Common/ModalConfirm';
import { getToken } from 'helpers';
import { BlogTypes } from 'models';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteBlog, fetchAllBlogByAdmin, updateBlog, updateBlogStatus } from 'slices/blogSlice';
import * as cts from '../../constants/ui/svg';

interface Props {
  total: any;
  data: any;
  statusBlog: any;
}
const TableBlog: React.FC<Props> = ({ total, data, statusBlog }) => {
  const [viewSlc, setViewSlc] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [idRecord, setIdRecord] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = getToken();
  const recordRef = useRef<any>('');

  const columns: ColumnsType<BlogTypes> = [
    {
      title: <span></span>,
      key: 'operation',
      fixed: 'left',
      width: 0,
      // render: () => <Checkbox className="flex justify-center w-full ckb_table"></Checkbox>,
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">TITLE</span>,
      width: 150,
      dataIndex: 'name',
      key: 'name',
      render: (value: any) => (
        <span className="w-full text-[#0038FF] text-[14px] mplus1pextrabold flex justify-center">
          {value}
        </span>
      ),
    },
    Table.EXPAND_COLUMN,
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">AUTHOR</span>,
      width: 100,
      dataIndex: 'author',
      key: 'author',
      render: (value: any) => (
        <span className="text-[14px] flex justify-center  mplus1pnormal">{value}</span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">CATEGORIES</span>
      ),
      dataIndex: 'name',
      key: 'name',
      width: 100,
      render: (value: any) => (
        <span className="flex justify-center mplus1pnormal text-[#0038FF] text-[12px] w-full">
          スキンケア
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">TAGS</span>,
      dataIndex: 'tags',
      key: 'tags',
      width: 100,
      render: (value: any) => (
        <span className="text-[12px] mplus1pnormal items-center text-[#0038FF] flex justify-center w-full">
          {value?.map((item: any) => ',' + item?.name)}
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">VIEW</span>,
      dataIndex: 'views',
      key: 'views',
      width: 100,
      render: (value: any) => (
        <span className="text-[12px] flex justify-center w-full">{value || 0} </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal">DATE</span>,
      dataIndex: 'createdAt',
      fixed: 'right',
      key: 'createdAt',
      width: 180,
      render: (value: any) => (
        <div className="flex flex-col">
          <span>公開済み</span>
          <span>
            {moment(value).format('YYYY/MM/DD')} at {moment(value).format('HH:MM')}
          </span>
        </div>
      ),
    },
  ];
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: BlogTypes[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: BlogTypes) => ({
      // disabled: record.name === 'Disabled User', // Column configuration not to be checked
      // name: record.name,
    }),
  };
  const arrBlog = data?.map((item: any, idx: any) => {
    return {
      ...item,
      key: idx,
    };
  });
  const onDelete = (record: any) => {
    dispatch(deleteBlog({ token, id: record?.id }));
  };
  const onUpdateTrash = (record: any) => {
    dispatch(updateBlogStatus({ token, id: record?.id, inputs: { status: 'trash' } }));
  };
  const onTrash = (record: any) => {
    setViewSlc('remove_product');
    setIdRecord('');
    switch (statusBlog) {
      case 'trash':
        setOpenModal(true);
        return;
      case '':
        return;
      case 'public':
        setOpenModal(true);

        return;
      case 'draft':
        setOpenModal(true);
        return;
      default:
        break;
    }
  };
  const pageSize = 10;

  const [pagi, setPagi] = useState<any>({
    allBlogByAdmin: [],
    totalPage: total / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });
  useEffect(() => {
    dispatch(
      fetchAllBlogByAdmin({ token, limit: pageSize, page: pagi.current, status: statusBlog })
    );
  }, [token, dispatch, pagi.current, statusBlog]);
  return (
    <div className="mt-[25px] mb-[100px]">
      <Table
        className="table_merchandise"
        columns={columns}
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (record?.id === idRecord) return;
              setIdRecord(record?.id as string);
            },
          };
        }}
        dataSource={arrBlog}
        scroll={{ x: 1000 }}
        expandable={{
          expandedRowRender: (record) => <span>aaa</span>,
          expandIcon: ({ expanded, onExpand, record }) => (
            <Popconfirm
              title="aaaa"
              open={idRecord === record?.id}
              placement="bottom"
              description={() => {
                return (
                  <div className=" bg-[#DCBA78] flex flex-col rounded-[5px] w-[156px]">
                    {(statusBlog === '' && (
                      <Link
                        to={`/post/${record?.id}`}
                        className={`${
                          viewSlc === 'edit' &&
                          'bg-[#3E6100] text-white rounded-[5px] mt-[5px] mx-[2px]'
                        } cursor-pointer hover:bg-[#3E6100] my-[5px] mx-[2px] hover:text-white hover:rounded-[5px] hover:my-[5px] hover:mx-[2px] px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                      >
                        {t('VIEW_BLOG')}
                      </Link>
                    )) ||
                      (statusBlog === 'public' && (
                        <Link
                          to={`/post/${record?.id}`}
                          className={`${
                            viewSlc === 'edit' &&
                            'bg-[#3E6100] text-white rounded-[5px] mt-[5px] mx-[2px]'
                          } cursor-pointer hover:bg-[#3E6100] my-[5px] mx-[2px] hover:text-white hover:rounded-[5px] hover:my-[5px] hover:mx-[2px] px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                        >
                          {t('VIEW_BLOG')}
                        </Link>
                      ))}

                    {statusBlog !== 'trash' && (
                      <Link
                        to={`edit-post/${record?.id}`}
                        className={`${
                          viewSlc === 'quick_edit' &&
                          'bg-[#3E6100] text-white rounded-[5px] mt-[5px] mx-[2px]'
                        } cursor-pointer hover:bg-[#3E6100] my-[5px] mx-[2px] hover:rounded-[5px] hover:my-[5px] hover:mx-[2px] hover:text-white px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                      >
                        {statusBlog === 'draft' ? t('EDIT') : t('EDIT_BLOG')}
                      </Link>
                    )}
                    {statusBlog === 'draft' && (
                      <span
                        onClick={() => {
                          setViewSlc('public');
                          dispatch(
                            updateBlogStatus({
                              token,
                              id: record?.id,
                              inputs: { status: 'public' },
                            })
                          );
                        }}
                        className={`${
                          viewSlc === 'public' &&
                          'bg-[#3E6100] text-white rounded-[5px] mt-[5px] mx-[2px]'
                        } cursor-pointer hover:bg-[#3E6100] my-[5px] mx-[2px] hover:rounded-[5px] hover:my-[5px] hover:mx-[2px] hover:text-white px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                      >
                        公開する
                      </span>
                    )}
                    {statusBlog === 'trash' && (
                      <span
                        onClick={() => {
                          setViewSlc('restore');
                          dispatch(
                            updateBlogStatus({ token, id: record?.id, inputs: { status: 'draft' } })
                          );
                        }}
                        className={`${
                          viewSlc === 'restore' &&
                          'bg-[#3E6100] text-white rounded-[5px] mt-[5px] mx-[2px]'
                        } cursor-pointer hover:bg-[#3E6100] my-[5px] mx-[2px] hover:rounded-[5px] hover:my-[5px] hover:mx-[2px] hover:text-white px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                      >
                        {t('RESTORE')}
                      </span>
                    )}
                    <span
                      onClick={() => {
                        onTrash(record);
                        recordRef.current = record;
                      }}
                      //   onClick={(e) => {
                      //     setViewSlc('remove_product');
                      //     dispatch(deleteBlog({ token, id: record?.id }));
                      //     setOpenPop(false);
                      //   }}
                      className={`${
                        viewSlc === 'remove_product' &&
                        'bg-[#3E6100] text-white rounded-[5px] my-[5px] mx-[2px]'
                      } cursor-pointer hover:bg-[#3E6100] my-[5px] mx-[2px] hover:text-white hover:rounded-[5px] hover:my-[5px] hover:mx-[2px] px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                    >
                      {statusBlog === 'trash'
                        ? '完全に削除'
                        : statusBlog === 'draft'
                        ? '削除する'
                        : t('DELETE_BLOG')}
                    </span>
                  </div>
                );
              }}
              onConfirm={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <span
                key={record.key}
                onClick={() => {
                  if (idRecord === record?.id) {
                    setIdRecord('');
                  }
                }}
                className="h-[20px] w-[20px] rounded-[2px] bg-[#DCBA78] flex justify-center items-center"
              >
                {cts.iconDot}
              </span>
            </Popconfirm>
          ),
        }}
        pagination={{
          showSizeChanger: false,
          total: total,
          pageSize: 10,
          current: pagi?.current,

          onChange: (page: any) => {
            setPagi({
              current: page,
              minIndex: (page - 1) * pageSize,
              maxIndex: page * pageSize,
            });
          },
        }}
      />
      <ModalConfirm
        openModal={openModal}
        setOpenModal={setOpenModal}
        onConfirm={statusBlog === 'trash' ? onDelete : onUpdateTrash}
        record={recordRef.current}
        text={
          statusBlog === 'trash'
            ? 'このブログ投稿を完全に削除してもよろしいですか?'
            : 'このブログ投稿をゴミ箱に移動してもよろしいですか?'
        }
      />
    </div>
  );
};
export default TableBlog;
