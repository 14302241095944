import request from './axiosServices';
import { uploadFileData } from '../helpers';

import {
  URL_GET_ALL_CATEGORY,
  URL_ADD_CATEGORY,
  URL_UPDATE_CATEGORY,
  URL_GET_ALL_CATEGORY_BY_ADMIN,
  URL_DELETE_CATEGORY,
} from 'graphql';

interface requestTypes {
  token?: string;
  limit?: number;
  page?: number;
  inputs?: {
    name?: string;
    slug?: string;
    images?: string;
    description?: string;
  };
  id?: string;
}

export function fetchAllCategoryAPI({ token, limit }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_GET_ALL_CATEGORY,
      variables: { limit },
    },
    token,
  });
}
export function addCategoryAPI({ token, inputs, fileUpload }: any) {
  let data: {} = {
    query: URL_ADD_CATEGORY,
    variables: { inputs, fileUpload: null },
  };
  data = uploadFileData(data, fileUpload);
  return request({
    method: 'POST',
    data,
    token,
  });
}
export function updateCategoryAPI({ token, inputs, id, fileUpload }: any) {
  let data: {} = {
    query: URL_UPDATE_CATEGORY,
    variables: { inputs, id, fileUpload: null },
  };

  data = uploadFileData(data, fileUpload);

  return request({
    method: 'POST',
    data,
    token,
  });
}
export function getAllCategoryByAdminAPI({ token, limit, page }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_GET_ALL_CATEGORY_BY_ADMIN,
      variables: { limit, page },
    },
    token,
  });
}
export function deleteCategoryAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_DELETE_CATEGORY,
      variables: { id },
    },
    token,
  });
}
