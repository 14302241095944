import { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { push } from 'connected-react-router';
import { ENCODE_SECRET_KEY } from '../constants';
import { getAllBannerAPI, getBannerByIdAPI, updateBannerAPI, deleteBannerAPI } from '../api';
import { successToast, errorToast, failedToast } from 'helpers';
import {
  fetchAllBanner,
  fetchAllBannerSuccess,
  fetchBannerById,
  fetchBannerByIdSuccess,
  updateBanner,
  updateBannerSuccess,
  deleteBanner,
  deleteBannerSuccess,
} from 'slices/bannerSlice';
import { showLoading, hideLoading } from '../slices/commonSlice';

function* allBannerSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(getAllBannerAPI, {
      limit: action.payload.limit,
      token: action.payload.token,
      page: action.payload.page,
    });
    const { data, errors } = res.data;
    yield put(fetchAllBannerSuccess(data?.getAllBanner));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* bannerByIdSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(getBannerByIdAPI, {
      token: action.payload.token,
      id: action.payload.id,
    });
    const { data, errors } = res.data;
    yield put(fetchBannerByIdSuccess(data?.getBannerById));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* updateBannerSaga(action: any) {
  try {
    yield put(showLoading(true));
    const res: AxiosResponse = yield call(updateBannerAPI, action.payload);
    const { data, errors } = res.data;
    if (data?.updateBanner?.ok === true) {
      successToast('Update banner success');
    yield put(updateBannerSuccess(data?.updateBanner?.data));
      yield put(showLoading(false));
    } else {
      errorToast(data?.updateBanner?.error);
      yield put(showLoading(false));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* deleteBannerSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(deleteBannerAPI, {
      token: action.payload.token,
      id: action.payload.id,
    });
    const { data, errors } = res.data;
    if (data?.deleteBanner?.ok === true) {
      successToast('Delete banner success');
    yield put(deleteBannerSuccess(data?.deleteBanner));

    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
export default function* bannerSaga() {
  yield takeLatest(fetchAllBanner.type, allBannerSaga);
  yield takeLatest(fetchBannerById.type, bannerByIdSaga);
  yield takeEvery(updateBanner.type, updateBannerSaga);
  yield takeEvery(deleteBanner.type, deleteBannerSaga);
}
