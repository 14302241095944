import { Checkbox } from 'antd';
import moment from 'moment';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { useEffect } from 'react';
import * as cts from '../../constants/ui/svg';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { useTranslation } from 'react-i18next';
import { getToken } from 'helpers';
import { useDispatch } from 'react-redux';
import { fetchUserInfoByAdmin } from 'slices/userSlice';
interface ParamTypes {
  id: string;
}
const UserDetailPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const token = getToken();
  const dispatch = useDispatch();
  const params = useParams<ParamTypes>();

  const userInfoByAdmin = useAppSelector((state) => state.users.userInfoByAdmin);

  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };
  useEffect(() => {
    dispatch(fetchUserInfoByAdmin({ token, id: params?.id }));
  }, [token, dispatch, params?.id]);
  return (
    <div className="pt-[26px] px-[35px] flex flex-col pb-[100px]">
      <div className="flex flex-col mb-[100px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-[20px] mplus1pextrabold mr-[54px]">顧客ロフィール</span>
          </div>
        </div>
        <div className="pt-[50px] flex w-[100%]">
          <div className="w-[20%] ">{cts.iconUserAva}</div>
          <div className="flex flex-col w-[80%]">
            <span className="mb-[10px] text-[16px] mplus1pbold">{t('USERNAME')}</span>
            <div className="w-[430px] bg-[white] pl-[8px] flex items-center h-[45px] border border-[black] mb-[20px]">
              {userInfoByAdmin?.userName || '--'}
            </div>
            <span className="mb-[10px] text-[16px] mplus1pbold">{t('EMAIL_ONLY')}</span>
            <div className="w-[430px] bg-[white] pl-[8px] flex items-center h-[45px] border border-[black]">
              {userInfoByAdmin?.email}
            </div>
            <div className="flex mt-[30px]">
              <div className="flex flex-col">
                <span className="mb-[10px] text-[16px] mplus1pbold">{t('GIVEN_NAME')}</span>
                <div className="w-[210px] bg-[white] pl-[8px] flex items-center h-[45px] border border-[black]">
                  {userInfoByAdmin?.firstName}
                </div>
              </div>
              <div className="ml-[50px] flex flex-col">
                <span className="mb-[10px] text-[16px] mplus1pbold">{t('姓')}</span>
                <div className="w-[210px] bg-[white] pl-[8px] flex items-center h-[45px] border border-[black]">
                  {userInfoByAdmin?.lastName}
                </div>
              </div>
            </div>
            <div className="flex mt-[20px]">
              <div className="flex flex-col">
                <span className="mb-[10px] text-[16px] mplus1pbold">生年月日</span>
                <div className="w-[210px] bg-[white] pl-[8px] flex items-center h-[45px] border border-[black]">
                  {moment(userInfoByAdmin?.birthday || '--/--/--').format('YYYY/MM/DD')}
                </div>
              </div>
              <div className="ml-[50px] flex flex-col">
                <span className="mb-[10px] text-[16px] mplus1pbold">性別</span>
                <div className="w-[210px] bg-[white] pl-[8px] flex items-center h-[45px] border border-[black]">
                  {userInfoByAdmin?.gender}
                </div>
              </div>
            </div>
            <div className="flex mt-[20px]">
              <div className="flex flex-col">
                <span className="mb-[10px] text-[16px] mplus1pbold">郵便番号</span>
                <div className="w-[117px] bg-[white] pl-[8px] flex items-center h-[45px] border border-[black]">
                  {userInfoByAdmin?.addressUser?.length > 0
                    ? userInfoByAdmin?.addressUser[0]?.postalCode
                    : '---'}
                </div>
              </div>
              <div className="ml-[145px] flex flex-col">
                <span className="mb-[10px] text-[16px] mplus1pbold">住所</span>
                <div className="w-[430px] bg-[white] pl-[8px] flex items-center h-[45px] border border-[black]">
                  {userInfoByAdmin?.addressUser?.length > 0
                    ? userInfoByAdmin?.addressUser[0]?.address
                    : '---'}
                </div>
              </div>
            </div>
            <span className="mb-[10px] text-[16px] mplus1pbold mt-[20px]">
              {t('TELEPHONE_NUMBER')}
            </span>
            <div className="w-[430px] bg-[white] pl-[8px] flex items-center h-[45px] border border-[black]">
              {userInfoByAdmin?.phone || '--'}
            </div>

            {/* <span className="mb-[10px] text-[16px] mplus1pbold mt-[20px]">Website Language</span> */}
            {/* <Select
                className="select-language"
                defaultValue="Site Default"
                style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  { value: 'jack', label: 'Jack' },
                  { value: 'lucy', label: 'Lucy' },
                  { value: 'Yiminghe', label: 'yiminghe' },
                ]}
              /> */}
            {/* <span className="mb-[10px] text-[16px] mplus1pbold mt-[20px]">
              Send ユーザー管理 notification
            </span>
            <Checkbox onChange={onChange}>
              Send the new ユーザー管理 an email about their account.
            </Checkbox> */}
          </div>
        </div>
        <button
          className="bg-[#3E6100] w-[80px] text-[white] p-[10px] mt-[100px] rounded-[10px] mplus1pbold"
          onClick={() => history.push('/users')}
        >
          戻る
        </button>
      </div>
    </div>
  );
};
export default UserDetailPage;
