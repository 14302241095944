import {
  configureStore,
  ThunkAction,
  Action,
  AnyAction,
  combineReducers,
  applyMiddleware,
  EnhancedStore,
} from '@reduxjs/toolkit';

import createSagaMiddleware, { Task } from 'redux-saga';

import rootSaga from './rootSaga';
import products from '../slices/productSlice';
import users from '../slices/userSlice';
import common from '../slices/commonSlice';
import categories from '../slices/categorySlice';
import productTags from '../slices/tagProductsSlice';
import orders from '../slices/orderSlice';
import blogs from '../slices/blogSlice';
import banners from '../slices/bannerSlice';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { history } from 'utils';

const rootReducer = combineReducers({
  router: connectRouter(history),
  common,
  users,
  products,
  categories,
  productTags,
  orders,
  blogs,
  banners,
});

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

export const store: any = configureStore({
  reducer: rootReducer,

  // Add saga to toolkit
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleware),
  devTools: true,
});

// Add root  saga client
// sagaMiddleware.run(rootSaga);

// Add root  saga
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
