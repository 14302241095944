export const URL_ALL_BLOG_BY_ADMIN = `
query getAllBlogByAdmin($limit: Int!,$page: Int!,$status: String){
	getAllBlogByAdmin(limit:$limit,page:$page,status:$status){
    countAll,
    countDraft,
    countPublic,
    countTrash
    result{
			id,   
    name,
    author,
    views,
    status,
    updatedAt,
    tags{
			id,
      name
    }
    }
  }
}
`;
export const URL_GET_BLOG_INFO_BY_ADMIN = `
query getBlogInfoByAdmin($id: ID!){
  getBlogInfoByAdmin(id:$id){
    id,
    name,
    content,
    image,
    createdAt,
    multiImages,
    isHot,
    tags{
      id,
      name
		}
  }
}
`;
export const URL_UPDATE_BLOG = `
mutation updateBlog($id: ID!,$inputs: inputUpdateBlog,$fileUpload: Upload){
	updateBlog(id:$id,inputs:$inputs,fileUpload:$fileUpload){
    data{
      id,     
    name,
    author,
    views,
    status,
    updatedAt,
    tags{
			id,
      name
    }
    }
    ok,
    error
	}
}
`;
export const URL_DELETE_BLOG = `
mutation deleteBlog($id: ID!){
	deleteBlog(id:$id){
		data {
      id,
      name
    },
    ok,
    error
  }
}
`;
export const URL_DUPLICATE_BLOG = `
mutation cloneBlog($id: ID!){
	cloneBlog(id:$id){
    data{
      id
     name
     
     tags{
       id
       name
     }
     author
     views
     updatedAt
   },
    ok
  }
}
`;
export const URL_ADD_BLOG = `
mutation addBlog($inputs:inputCreateBlog,$fileUpload:Upload){
	addBlog(inputs:$inputs,fileUpload:$fileUpload){
		data{
			id,   
    name,
    author,  
    views,
    status,
    updatedAt,
    tags{
			id,
      name
    }
    },
    ok,
    error
  }
}
`;
export const URL_ADD_CATEGORY_BLOG = `
mutation addCategoryBlog($inputs:inputCategoryBlog){
	addCategoryBlog(inputs:$inputs){
		data{
      name,
      id,
      description
    },
    ok,
    error
  }
}
`;
export const URL_ADD_BLOG_TAG = `
mutation addBlogTag($inputs:inputBlogTag){
    addBlogTag(inputs:$inputs){
           data{
            id
            name,
            slug,
            description,
            blogCount
       },
         ok,
         error
       }
   }
`;
export const URL_ALL_CATEGORY_BLOG = `
query getAllCategoryBlog($limit: Int){
  getAllCategoryBlog(limit:$limit){
		id,
    name
  }
}
`;
export const URL_BLOGS_TAG = `
query blogTags($limit: Int){
	blogTags(limit:$limit){
		id,
    name
  }
}
`;
export const URL_GET_ALL_BLOG_TAGS_BY_ADMIN = `
query getAllBlogTagsByAdmin($limit: Int,$page: Int){
	getAllBlogTagsByAdmin(limit:$limit,page:$page){
		count,
    result{
      id,
			name,
      slug,
      description,
      blogCount
    }
  }
}
`;
export const URL_DELETE_BLOG_TAG = `
mutation deleteBlogTag($id: ID!){
	deleteBlogTag(id:$id){
		data{
      id,
			name,
      slug,
      description,
      blogCount
    }
    error,
    ok
  }
}
`;
export const URL_UPDATE_BLOG_TAG = `
mutation updateBlogTag($id: ID!,$inputs: inputBlogTag){
	updateBlogTag(id:$id,inputs:$inputs){
		data{
      id,
			name,
      slug,
      description,
      blogCount
    }
    error,
    ok
  }
}
`;
export const URL_UPLOAD_IMAGE = `
mutation uploadImage($id: ID!,$fileUpload: [Upload!],$folder: String,$name: String){
	uploadImage(id:$id,fileUpload:$fileUpload,folder:$folder,name:$name){
		data,
    ok,
    error
  }
}
`;
