import { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { push } from 'connected-react-router';
import { ENCODE_SECRET_KEY } from '../constants';
import {
  getAllOrderByAdminAPI,
  getAllOrderOfCustomerAPI,
  getOrderInfoByAdminAPI,
  exportCSVOrderAPI,
  updateManyOrderAPI,
} from '../api';
import { successToast, errorToast, failedToast } from 'helpers';
import {
  fetchAllOrderByAdmin,
  fetchAllOrderByAdminSuccess,
  fetchOrderOfCustomerList,
  fetchOrderOfCustomerListSuccess,
  fetchOrderInfoByAdmin,
  fetchOrderInfoByAdminSuccess,
  exportCSVOrder,
  exportCSVOrderSuccess,
  updateManyOrder,
  updateManyOrderSuccess,
} from 'slices/orderSlice';
import { showLoading, hideLoading } from '../slices/commonSlice';

function* orderByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(getAllOrderByAdminAPI, {
      limit: action.payload.limit,
      page: action.payload.page,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(fetchAllOrderByAdminSuccess(data?.getAllOrderByAdmin));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* orderOfCustomerSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(getAllOrderOfCustomerAPI, {
      limit: action.payload.limit,
      page: action.payload.page,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(fetchOrderOfCustomerListSuccess(data?.getAllOrderOfCustomers));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* orderInfoByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(getOrderInfoByAdminAPI, {
      id: action.payload.id,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(fetchOrderInfoByAdminSuccess(data?.getOrderInfoByAdmin));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* exportCSVOrderSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(exportCSVOrderAPI, {
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(exportCSVOrderSuccess(data?.exportCSVOrder?.url));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* updateManyOrderSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(updateManyOrderAPI, {
      token: action.payload.token,
      ids: action.payload.ids,
      shippingStatus: action.payload.shippingStatus,
      updateAll: action.payload.updateAll,
    });
    const { data, errors } = res.data;
    if (data?.updateManyOrder?.ok === true) {
      successToast('成功した注文の配送ステータスを更新する');
      yield put(updateManyOrderSuccess(data?.updateManyOrder?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
export default function* orderSaga() {
  yield takeLatest(fetchAllOrderByAdmin.type, orderByAdminSaga);
  yield takeLatest(fetchOrderOfCustomerList.type, orderOfCustomerSaga);
  yield takeLatest(fetchOrderInfoByAdmin.type, orderInfoByAdminSaga);
  yield takeEvery(exportCSVOrder.type, exportCSVOrderSaga);
  yield takeEvery(updateManyOrder.type, updateManyOrderSaga);
}
