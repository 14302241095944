import { useState, useEffect, useRef } from 'react';
import { Input, Select, Button, PaginationProps } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import * as cts from '../../constants/ui/svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { ProductTypes, TypesProductUpdate } from '../../models';
import ModalConfirm from 'components/Common/ModalConfirm';
import { getToken } from 'helpers';
import TablePreviewProduct from './TablePreviewProduct';
import { changeManyReviews } from 'slices/productSlice';
const { Search } = Input;

const onSearch = (value: string) => console.log(value);

const PreviewProductPage = () => {
  const { t } = useTranslation();
  // const [state, setState] = useState<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = getToken();
  const productReviewByAdmin = useAppSelector((state) => state.products.getProductReviewsByAdmin);
  const [textSearch, setTextSearch] = useState('');
  const [btnSearch, setBtnSearch] = useState(false);
  const [point, setPoint] = useState<any>(null);
  const [idReview, setIdReview] = useState<any>([]);
  const pageSize = 10;
  const [pagi, setPagi] = useState<any>({
    productWithFilter: [],
    totalPage: productReviewByAdmin?.count / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });
  const handleChangePoint = (value: string) => {
    setPoint(value);
  };
  const handleChangeFilter = (value: string) => {
    if (idReview.length > 0) {
      dispatch(changeManyReviews({ token, ids: idReview, status: value }));
    }
  };

  return (
    <div className="pt-[26px] px-[35px] flex flex-col pb-[100px]">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <span className="text-[20px] mplus1pextrabold mr-[54px]">{t('REVIEW')}</span>
        </div>
        <div className="flex items-center">
          <span
            onClick={() => {
              setBtnSearch(true);
            }}
            className="h-[40px] w-[48px] border-[0.5px] rounded-l-[10px] border-black/50 bg-[rgba(220,186,120,0.5)] flex justify-center items-center"
          >
            {cts.iconSearch}
          </span>
          <Input
            onChange={(e) => setTextSearch(e.target.value)}
            className="w-[252px] h-[40px] border-[0.5px] border-black/50 rounded-r-[10px] rounded-l-[0px]"
          />
        </div>
      </div>
      <div className="flex mt-[60px] w-full items-center justify-between">
        <div className="flex items-center w-[50%]">
          <span className="underline text-[14px] mplus1pnormal text-[#0038FF] cursor-pointer">
            {t('DISPLAY')}{' '}
            <span className="text-black underline">
              {/* {btnSearch ? {pagiSearch.minIndexSearch + 1} -{' '}
          {pagiSearch?.currentSearch * pageSize - (pageSize - searchProduct?.result?.length)} of{' '}
          {searchProduct?.count}
          :  */}
              {pagi.minIndex + 1} -{' '}
              {pagi?.current * pageSize - (pageSize - productReviewByAdmin?.result?.length)} of{' '}
              {productReviewByAdmin?.count || 0}
            </span>
          </span>
          <span className="text-[#2E543E] cursor-pointer underline text-[14px] ml-[20px]">
            {t('PUBLISH')}
            <span className="text-black underline">({productReviewByAdmin?.count || 0})</span>
          </span>
          <span
            onClick={() => history.push('/preview-products-spam')}
            className="text-[#0038FF] cursor-pointer underline text-[14px] ml-[20px]"
          >
            spam
            <span className="text-black underline">({productReviewByAdmin?.spamCount || 0})</span>
          </span>
          <span
            onClick={() => history.push('/preview-products-trash')}
            className="text-[#0038FF] cursor-pointer underline text-[14px] ml-[20px]"
          >
            {/* {t('TO_TRASH')} */}
            trash
            <span className="text-black underline">({productReviewByAdmin?.trashCount || 0})</span>
          </span>
        </div>
        <div className="flex justify-end items-center ctn_btn_merchandise w-[50%] pr-[10px] pb-[10px] h-full overflow-auto overflow-y-hidden ">
          <Select
            defaultValue="一括操作"
            className="w-[112px] slc_merchandise mr-[10px]"
            onChange={handleChangeFilter}
            options={[
              { value: 'hide', label: 'レビューを隠す' },
              { value: 'spam', label: 'スパムへ' },
              { value: 'trash', label: 'ゴミ箱へ移動' },
            ]}
          />

          <Select
            defaultValue="レーティングで絞る"
            className="w-[189px] slc_merchandise"
            onChange={handleChangePoint}
            options={[
              {
                value: 1,
                label: (
                  <div className="flex items-center h-full">
                    <span>{cts.iconStarBlack}</span>
                  </div>
                ),
              },
              {
                value: 2,
                label: (
                  <div className="flex items-center h-full">
                    <span>{cts.iconStarBlack}</span>
                    <span>{cts.iconStarBlack}</span>
                  </div>
                ),
              },
              {
                value: 3,
                label: (
                  <div className="flex items-center h-full">
                    <span>{cts.iconStarBlack}</span>
                    <span>{cts.iconStarBlack}</span>
                    <span>{cts.iconStarBlack}</span>
                  </div>
                ),
              },
              {
                value: 4,
                label: (
                  <div className="flex items-center h-full">
                    <span>{cts.iconStarBlack}</span>
                    <span>{cts.iconStarBlack}</span>
                    <span>{cts.iconStarBlack}</span>
                    <span>{cts.iconStarBlack}</span>
                  </div>
                ),
              },
              {
                value: 5,
                label: (
                  <div className="flex items-center h-full">
                    <span>{cts.iconStarBlack}</span>
                    <span>{cts.iconStarBlack}</span>
                    <span>{cts.iconStarBlack}</span>
                    <span>{cts.iconStarBlack}</span>
                    <span>{cts.iconStarBlack}</span>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
      <div className="mt-[25px] mb-[100px]">
        <TablePreviewProduct
          btnSearch={btnSearch}
          total={productReviewByAdmin?.count}
          data={productReviewByAdmin?.result}
          setBtnSearch={setBtnSearch}
          textSearch={textSearch}
          status="public"
          point={point}
          setIdReview={setIdReview}
        />
      </div>
    </div>
  );
};
export default PreviewProductPage;
