import { Collapse, Input, Modal, Spin, Switch, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import CropperPage from 'components/Common/Cropper';
import { errorToast, getToken } from 'helpers';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-image-crop/dist/ReactCrop.css';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { fetchAllBanner, fetchBannerById, updateBanner } from 'slices/bannerSlice';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import img1 from '../../assets/images/banner1.png';
import img2 from '../../assets/images/banner2.png';
import img3 from '../../assets/images/banner3.png';
import img4 from '../../assets/images/banner4.png';
import logo from '../../assets/images/logoHENPS.png';
import * as cts from '../../constants/ui/svg';
import { URL_UPLOAD } from '../../constants';
import ReactDOM from 'react-dom';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ImgDialog from '../../components/Common/ImgDialog';
import getCroppedImg from '../../components/Common/CropImage';
import { Link, useParams } from 'react-router-dom';
export const styles = (theme: any) => ({
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
});
interface ParamTypes {
  id: string;
}
const EditBannerPage = () => {
  const [previewImage, setPreviewImage] = useState('');
  const params = useParams<ParamTypes>();
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCrop, setModalCrop] = useState(false);
  const dispatch = useDispatch();
  const token = getToken();
  const { t } = useTranslation();
  const allBanner = useAppSelector((state) => state.banners.getAllBanner);
  const bannerById = useAppSelector((state) => state.banners.getBannerById);
  const [idTab, setIdTab] = useState();
  const [isRightSwiper, setIsRightSwiper] = useState(false);
  const { Panel } = Collapse;
  const [file, setFile] = useState<any>();
  const [clickCrop, setClickCrop] = useState<any>(false);
  const [openModalImg, setOpenModalImg] = useState(false);
  // ===============================================
  const inputRef = useRef<any>();
  const [fileUpload, setFileUpload] = useState<any>();
  const [fileDataURL, setFileDataURL] = useState<any>();
  const loading = useAppSelector((state) => state.common.loading);

  // ====================================================
  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const test = allBanner?.result?.filter((x: any) => x?.presentation === 'product');
  const arrNew = allBanner?.result
    ?.filter((x: any) => x?.presentation === 'banner')
    ?.map((it: any, idx: any) => {
      return {
        ...it,
        arrChild: {
          name: it?.title + '画像編集',
          keyId: it?.id,
        },
      };
    });

  const [croppedImage, setCroppedimage] = useState<any>(null);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchBannerById({ token, id: params.id }));
    }
  }, [dispatch, token, params.id]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage1, setCroppedImage1] = useState<any>(null);

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(fileDataURL, croppedAreaPixels, rotation);
      setCroppedImage1(croppedImage);

      if (fileUpload) {
        setClickCrop(true);
        setModalCrop(true);
      }
      //  else {
      //   errorToast('変化なし');
      // }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);
  const showCroppedImageProduct = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(fileDataURL, croppedAreaPixels, rotation);
      setCroppedImage1(croppedImage);

      if (fileUpload) {
        setOpenModalImg(false);
      }
      //  else {
      //   errorToast('変化なし');
      // }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);
  const onClose = useCallback(() => {
    setCroppedImage1(null);
  }, []);
  // ============================================================
  const changeHandler = (e: any) => {
    const file = e.target.files[0];
    // if (!file?.type?.match(imageMimeType)) {
    //   alert('Image mime type is not valid');
    //   return;
    // }
    setFileUpload(file);
    setModalOpen(false);
  };
  const _renderContent = () => {
    return (
      <>
        <div className="flex flex-1 flex-col mr-[5px] items-center">
          <img alt="" className=" " src={img1} />
          <span className="text-[10px] mplus1pmedium mt-[8px]">ゴートミルクソープ </span>
          <span className="text-[10px] mt-[10px]">
            ビタミンとミネラルを豊富に含んだゴートミルク（ヤギ乳）を配合
          </span>
          <div className="flex w-full flex-col border border-black mt-[10px]">
            <span className="flex items-center justify-center bg-black text-white text-[12px] mplus1bold">
              カゴに入れる
            </span>
            <span className="flex items-center justify-center bg-white  text-[12px] mplus1bold">
              詳しく見る
            </span>
          </div>
          <span className="text-[12px] flex items-center mt-[10px]">
            2,970円 <span className="ml-[2px] mplus1pthin ">(税込)</span>
          </span>
          <span className="mt-[10px] flex items-center">
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
          </span>
        </div>
        <div className="flex flex-1 flex-col mx-[5px] items-center">
          <img alt="" className=" " src={img2} />
          <span className="text-[10px] mplus1pmedium mt-[8px]">ゴートミルクソープ </span>
          <span className="text-[10px] mt-[10px]">
            ビタミンとミネラルを豊富に含んだゴートミルク（ヤギ乳）を配合
          </span>
          <div className="flex w-full flex-col border border-black mt-[10px]">
            <span className="flex items-center justify-center bg-black text-white text-[12px] mplus1bold">
              カゴに入れる
            </span>
            <span className="flex items-center justify-center bg-white  text-[12px] mplus1bold">
              詳しく見る
            </span>
          </div>
          <span className="text-[12px] flex items-center mt-[10px]">
            2,970円 <span className="ml-[2px] mplus1pthin ">(税込)</span>
          </span>
          <span className="mt-[10px] flex items-center">
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
          </span>
        </div>
        <div className="flex flex-1 flex-col mx-[5px] items-center">
          <img alt="" className=" " src={img3} />
          <span className="text-[10px] mplus1pmedium mt-[8px]">ゴートミルクソープ </span>
          <span className="text-[10px] mt-[10px]">
            ビタミンとミネラルを豊富に含んだゴートミルク（ヤギ乳）を配合
          </span>
          <div className="flex w-full flex-col border border-black mt-[10px]">
            <span className="flex items-center justify-center bg-black text-white text-[12px] mplus1bold">
              カゴに入れる
            </span>
            <span className="flex items-center justify-center bg-white  text-[12px] mplus1bold">
              詳しく見る
            </span>
          </div>
          <span className="text-[12px] flex items-center mt-[10px]">
            2,970円 <span className="ml-[2px] mplus1pthin ">(税込)</span>
          </span>
          <span className="mt-[10px] flex items-center">
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
          </span>
        </div>
        <div className="flex flex-1 flex-col ml-[5px] items-center">
          <img alt="" className=" " src={img4} />
          <span className="text-[10px] mplus1pmedium mt-[8px]">ゴートミルクソープ </span>
          <span className="text-[10px] mt-[10px]">
            ビタミンとミネラルを豊富に含んだゴートミルク（ヤギ乳）を配合
          </span>
          <div className="flex flex-col w-full border border-black mt-[10px]">
            <span className="flex items-center justify-center bg-black text-white text-[12px] mplus1bold">
              カゴに入れる
            </span>
            <span className="flex items-center justify-center bg-white  text-[12px] mplus1bold">
              詳しく見る
            </span>
          </div>
          <span className="text-[12px] flex items-center mt-[10px]">
            2,970円 <span className="ml-[2px] mplus1pthin ">(税込)</span>
          </span>
          <span className="mt-[10px] flex items-center">
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
            <span>{cts.iconStarActive}</span>
          </span>
        </div>
      </>
    );
  };
  const _renderBox = () => {
    return (
      <>
        <div className="h-[132px] w-[132px] bg-[#D9D9D9]"></div>
        <div className="h-[132px] w-[132px] bg-[#D9D9D9]"></div>
        <div className="h-[132px] w-[132px] bg-[#D9D9D9]"></div>
        <div className="h-[132px] w-[132px] bg-[#D9D9D9]"></div>
        <div className="h-[132px] w-[132px] bg-[#D9D9D9]"></div>
        <div className="h-[132px] w-[132px] bg-[#D9D9D9]"></div>
        <div className="h-[132px] w-[132px] bg-[#D9D9D9]"></div>
      </>
    );
  };
  useEffect(() => {
    let fileReader = new FileReader(),
      isCancel = false;
    if (fileUpload) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(fileUpload);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileUpload]);
  useEffect(() => {
    if (fileUpload && bannerById?.presentation === 'product') {
      setOpenModalImg(true);
    }
  }, [fileUpload, bannerById]);
  // =====================================================================
  useEffect(() => {
    dispatch(fetchAllBanner({ token, limit: 10, page: 1 }));
  }, [token, dispatch]);
  return (
    <div className="flex border border-black/50 mb-[38px]">
      <div className="w-[25%] flex bg-[#BFBFBF] flex-col border-r border-black/50 pb-[200px]">
        <div className="flex items-center h-[48px]">
          <span
            // onClick={() => setPageBanner('mainPage')}
            className="w-[48px] h-full flex items-center cursor-pointer justify-center border-r border-black/30"
          >
            {cts.iconClose}
          </span>
          <div className=" flex justify-evenly w-full">
            <div className="relative w-[80px] flex items-center justify-center">
              {loading === true && (
                <Spin className="absolute h-full w-[80px] items-center flex justify-center" />
              )}
              <span
                onClick={() => {
                  if (fileUpload) {
                    // const croppedImage = getCroppedImg(fileDataURL, croppedAreaPixels, rotation);
                    // setFileDataURL(croppedImage);
                    dispatch(updateBanner({ token, inputs: {}, id: params.id, fileUpload }));
                    setTimeout(() => {
                      setFileUpload(null);
                    }, 1000);
                  } else {
                    errorToast('chua co su thay doi');
                  }
                }}
                className={`${
                  loading === true ? ' opacity-20 absolute' : ''
                } h-[39px] bg-[#3E6100]  rounded-[10px] px-[5px] text-[14px] mplus1pextrabold text-white cursor-pointer flex items-center`}
              >
                保存と公開
              </span>
            </div>
            <button
              onClick={showCroppedImage}
              className="h-[39px] bg-[#DBB312] rounded-[10px] justify-center text-[14px] mplus1pextrabold text-white cursor-pointer px-[2%] flex items-center"
            >
              プレビュー
            </button>
          </div>
        </div>
        <span className="h-[39px] border-b  flex items-center bg-[#8F8F8F] text-[14px] mplus1pextrabold pl-[5%] border-t border-black/50">
          MENU
        </span>
        <Collapse
          expandIconPosition="end"
          className="colap_banner rounded-[0px] border-none"
          defaultActiveKey={[params.id]}
          onChange={onChange}
        >
          {arrNew.map((item: any, idx: any) => {
            return (
              <Panel
                key={item.id}
                header={<span className="text-[14px] mplus1pmedium">{item?.title}</span>}
              >
                <Link
                  onClick={() => {
                    setFile(null);
                    setFileUpload(null);
                    setCroppedImage1(null);
                    setZoom(1);
                  }}
                  to={`/edit-banner/${item?.arrChild?.keyId}`}
                  className={`${
                    params.id === item?.arrChild?.keyId
                      ? 'bg-[#3E6100] text-white mplus1pmedium hover:text-white'
                      : 'bg-[rgba(62,97,0,0.5)] text-black hover:text-black'
                  } h-[39px] pl-[10%] cursor-pointer text-[12px] flex items-center border-b border-black/50`}
                >
                  {item?.arrChild?.name}
                </Link>
              </Panel>
            );
          })}
        </Collapse>
        <Collapse
          expandIconPosition="end"
          className="colap_banner rounded-[0px] border-none"
          defaultActiveKey={[params.id]}
          onChange={onChange}
        >
          <Panel
            key="1"
            header={<span className="text-[14px] mplus1pmedium flex flex-col">Product</span>}
          >
            {test?.map((item: any) => {
              return (
                <Link
                  onClick={() => {
                    setFile(null);
                    setFileUpload(null);
                    setCroppedImage1(null);
                    setZoom(1);
                  }}
                  to={`/edit-banner/${item?.id}`}
                  className={`${
                    params.id === item?.id
                      ? 'bg-[#3E6100] text-white mplus1pmedium hover:text-white'
                      : 'bg-[rgba(62,97,0,0.5)] text-black hover:text-black'
                  } h-[39px] pl-[10%] cursor-pointer text-[12px] flex items-center border-b border-black/50`}
                >
                  {item?.title}
                </Link>
              );
            })}
          </Panel>
        </Collapse>
      </div>
      <div className="w-[75%] flex flex-col bg-white pb-[200px]">
        <>
          <div className="h-[48px] flex items-center justify-end pr-[5%] border-b border-[#A4A4A4]">
            <span className="text-[16px]">ENG</span>
            <span className="mx-[10px] switch-lang">
              <Switch defaultChecked size="small" />
            </span>
            <span className="text-[16px]">JP</span>
          </div>
          <div className="h-[70px] flex items-center">
            <div className="w-[25%] pl-[2%]">
              <img src={logo} className="h-[40px] " />
            </div>
            <div className="w-[75%] flex items-center justify-evenly">
              <Input
                suffix={cts.iconSearch}
                className="w-[30%] h-[30px] rounded-[32px] border border-black"
              />
              <span className="mplus1pmedium text-[12px]">ログイン</span>
              <span className="mplus1pmedium text-[12px]">会員登録はこちら</span>
              <span className="mplus1pmedium text-[12px]">買い物かご</span>
              <span className="mplus1pmedium text-[12px]">クーポン</span>
            </div>
          </div>
          <div className="h-[50px] flex items-center justify-evenly bg-[rgba(220,186,120,0.5)]">
            <span className="text-[#3E6100] mplus1pbold">新発売</span>
            <span className="text-[#3E6100] mplus1pbold">注目の商品</span>
            <span className="text-[#3E6100] mplus1pbold">カテゴリー</span>
            <span className="text-[#3E6100] mplus1pbold">ランキング</span>
            <span className="text-[#3E6100] mplus1pbold">お悩みから探す</span>
            <span className="text-[#3E6100] mplus1pbold">ブログ</span>
          </div>
          {bannerById?.title === 'Product page 1' ? (
            <div className="flex flex-col items-center">
              <div className="bg-[#2E543E]/40 h-[45px] w-full flex justify-center items-center">
                <span className="text-white text-head text-[14px]">
                  9,000円以上のご注文 追跡配送料無料
                </span>
              </div>
              <span className="text-[#2E543E] mplus1pextrabold text-[20px] mt-[15px]">新販売</span>
              <div className="mt-[30px] flex w-[85%] justify-center">
                <div className="flex-1 mr-[10px] relative flex justify-center items-center swiper_slide">
                  <button
                    onClick={() => {
                      setModalOpen(true);
                      setZoom(1);
                    }}
                    className={`absolute z-[100] btn_change opacity-0 h-[46px] px-[10px] bg-[#DBB312] text-white flex justify-center items-center ${
                      file ? 'hidden' : ''
                    } `}
                    // disabled={file ? true : false}
                  >
                    {t('CHANGE_IMAGE')}
                  </button>
                  {croppedImage1 ? (
                    <img src={croppedImage1} className="h-full" alt="uploaded"></img>
                  ) : fileUpload ? (
                    <img alt="" className="h-full" src={fileDataURL} />
                  ) : (
                    <img
                      alt=""
                      className="h-full"
                      src={`${URL_UPLOAD}/banners/${bannerById?.image}`}
                    />
                  )}
                  {/* {fileUpload ? (
                    <img alt="" className="h-full" src={fileDataURL} />
                  ) : ( */}
                  {/* <img
                    alt=""
                    className="h-full"
                    src={croppedImage ? croppedImage : `${URL_UPLOAD}/banners/${bannerById?.image}`}
                  /> */}
                  {/* )} */}
                </div>
                <div className="ml-[10px] flex-[1.5_1.5_0%] flex">{_renderContent()}</div>
              </div>
            </div>
          ) : bannerById?.title === 'product page 2' ? (
            <div className="flex flex-col items-center">
              <div className="bg-[#2E543E]/40 h-[45px] w-full flex justify-center items-center">
                <span className="text-white text-head text-[14px]">
                  9,000円以上のご注文 追跡配送料無料
                </span>
              </div>
              <div className="flex w-[85%] mt-[30px]">
                <div className="flex-1 mr-[10px] relative flex justify-center items-center swiper_slide"></div>
                <div className="ml-[10px] flex-[1.5_1.5_0%] flex flex-col">
                  <span className="text-[#2E543E] mplus1pextrabold text-[20px] ">化粧品</span>
                  <div className="flex w-full justify-end">
                    <span className="mr-[10px] text-[10px]">商品数：6件</span>
                    <span className="text-[10px]">並べ替え </span>
                  </div>
                </div>
              </div>
              <div className="mt-[15px] flex w-[85%] justify-center">
                <div className="flex-1 mr-[5%] flex flex-col">
                  <div className="h-[30%] w-full relative flex justify-center items-center swiper_slide">
                    <button
                      onClick={() => {
                        setModalOpen(true);
                        setZoom(1);
                      }}
                      className={`absolute z-[100] btn_change opacity-0 h-[46px] px-[10px] bg-[#DBB312] text-white flex justify-center items-center ${
                        file ? 'hidden' : ''
                      } `}
                      // disabled={file ? true : false}
                    >
                      {t('CHANGE_IMAGE')}
                    </button>
                    {croppedImage1 ? (
                      <img src={croppedImage1} className="h-full w-full" alt="uploaded"></img>
                    ) : fileUpload ? (
                      <img alt="" className="h-full w-full" src={fileDataURL} />
                    ) : (
                      <img
                        alt=""
                        className="h-full w-full"
                        src={`${URL_UPLOAD}/banners/${bannerById?.image}`}
                      />
                    )}
                    {/* {fileUpload ? (
                      <img alt="" className="h-full w-full" src={fileDataURL} />
                    ) : ( */}
                    {/* <img
                      alt=""
                      className="h-full w-full"
                      src={`${URL_UPLOAD}/banners/${bannerById?.image}`}
                    /> */}
                    {/* )} */}
                  </div>
                  <Input
                    placeholder="SEARCH PRODUCT"
                    suffix={cts.iconSearch}
                    className="w-[80%] my-[20px] h-[25px] rounded-[32px] border border-black"
                  />
                  <span className="py-[5px] text-[12px] text-center w-[80%] border-t border-b border-black">
                    商品カテゴリーから探す
                  </span>
                  <span className="py-[5px] text-[12px] text-center w-[80%] bg-[#B0C284]/10 mt-[5px]">
                    スキンケア
                  </span>
                  <span className="py-[5px] text-[12px] text-center w-[80%] bg-[#B0C284]/10 my-[2px]">
                    食料品
                  </span>
                  <span className="py-[5px] text-[12px] text-center w-[80%] bg-[#B0C284]/30">
                    化粧品
                  </span>
                  <span className="py-[5px] text-[12px] text-center w-[80%] bg-[#B0C284]/10 mt-[2px]">
                    アルコール
                  </span>
                </div>

                <div className="ml-[5%] flex-[1.5_1.5_0%] flex">{_renderContent()}</div>
              </div>
            </div>
          ) : (
            <div className="relative">
              <div className="bg-[#2E543E]/40 h-[45px] w-full  absolute z-[100] flex justify-center items-center">
                <span className="text-white text-head text-[14px]">
                  9,000円以上のご注文 追跡配送料無料
                </span>
              </div>
              <div className="relative flex justify-center items-center swiper_slide">
                <button
                  onClick={() => setModalOpen(true)}
                  className={`absolute z-[100] btn_change opacity-0 h-[46px] px-[10px] bg-[#DBB312] text-white flex justify-center items-center ${
                    file ? '' : ''
                  } `}
                  // disabled={file ? true : false}
                >
                  {t('CHANGE_IMAGE')}
                </button>
                {fileUpload ? (
                  ''
                ) : (
                  <img
                    alt=""
                    className="w-full h-[485px]"
                    src={`${URL_UPLOAD}/banners/${bannerById?.image}`}
                  />
                )}
                {/* <img alt="" className="w-full h-[485px]" src={file ? file : imgBanner} /> */}
                {fileUpload && (
                  // <CropperPage
                  //   setBtnCrop={setClickCrop}
                  //   btnCrop={clickCrop}
                  //   imageToCrop={file}
                  //   croppedImage={(value: any) => setCroppedimage(value)}
                  // />
                  <>
                    <div className="absolute z-[100] top-[2%] left-[2%] flex items-center">
                      <span className="h-[30px] w-[30px] mr-[20px] bg-[#E8E8E8] flex items-center justify-center rounded-[15px]">
                        {cts.iconCropImg}
                      </span>
                      <span className="h-[30px] w-[30px] bg-[#E8E8E8] flex items-center justify-center rounded-[15px]">
                        {cts.iconMoveImg}
                      </span>
                    </div>
                    <div className="h-[485px] flex flex-col">
                      <div className="cropContainer">
                        <Cropper
                          image={fileDataURL}
                          crop={crop}
                          zoom={zoom}
                          aspect={4 / 3}
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      </div>
                      <div className="controls">
                        <div className="flex items-center">
                          <span
                            onClick={() => {
                              const count = zoom - 0.4;
                              setZoom(count);
                            }}
                            className="text-[20px] mplus1pextrabold mr-[10px] cursor-pointer"
                          >
                            {' '}
                            -{' '}
                          </span>
                          <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            className=""
                            onChange={(e, zoom: any) => setZoom(zoom)}
                          />
                          <span
                            onClick={() => {
                              const count = zoom + 0.4;
                              setZoom(count);
                            }}
                            className="text-[20px] mplus1pextrabold ml-[10px] cursor-pointer"
                          >
                            {' '}
                            +
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {croppedImage && (
                <img src={croppedImage} className="h-full w-full" alt="uploaded"></img>
              )}
            </div>
          )}

          <Modal
            centered
            open={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            className="flex flex-col modal_edit_img !w-fit "
          >
            <div className="h-[560px] overflow-auto">
              <div className="relative border border-[rgba(0,0,0,0.2)] h-[132px] w-[132px]">
                <span className="text-[12px] h-full items-center cursor-pointer mplus1pnormal text-[#0038FF] absolute w-[132px] flex justify-center">
                  + 画像のアップロード
                  <Input
                    ref={inputRef}
                    accept=".png, .jpg, .jpeg .webp"
                    type="file"
                    onChange={changeHandler}
                    className="h-[132px] w-[132px] absolute text-white bg-white inp_upload opacity-0"
                  />
                </span>
              </div>
              <div className="flex mt-[25px] flex-col">
                <span className="text-[12px]">最近追加された画像</span>
                <div className="mt-[8px] grid grid-cols-7 gap-4">{_renderBox()}</div>
              </div>

              <div className="flex mt-[25px] flex-col">
                <span className="text-[12px]">アルバム</span>
                <div className="mt-[8px] grid grid-cols-7 gap-4">
                  {_renderBox()}
                  {_renderBox()}
                  {_renderBox()}
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            centered
            open={modalCrop}
            onOk={() => setModalCrop(false)}
            onCancel={() => setModalCrop(false)}
          >
            <img src={croppedImage1 ? croppedImage1 : fileDataURL} />
          </Modal>
          <Modal
            centered
            open={openModalImg}
            // onOk={() => setOpenModalImg(false)}
            // onCancel={() => setOpenModalImg(false)}
            className="modal_open_img"
            footer={[
              <>
                <div className="controls_1 flex flex-col !right-[264px]">
                  <div className="flex items-center">
                    <span
                      onClick={() => {
                        const count = zoom - 0.4;
                        setZoom(count);
                      }}
                      className="text-[20px] mplus1pextrabold mr-[10px] cursor-pointer"
                    >
                      {' '}
                      -{' '}
                    </span>
                    <Slider
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      className=""
                      onChange={(e, zoom: any) => setZoom(zoom)}
                    />
                    <span
                      onClick={() => {
                        const count = zoom + 0.4;
                        setZoom(count);
                      }}
                      className="text-[20px] mplus1pextrabold ml-[10px] cursor-pointer"
                    >
                      {' '}
                      +
                    </span>
                  </div>
                </div>
                <div className="flex !absolute items-center !top-[90%] ml-[274px]">
                  <span className="h-[30px] w-[30px] mr-[20px] bg-[rgba(0,0,0,0.2)] flex items-center justify-center rounded-[15px]">
                    {cts.iconCropImg}
                  </span>
                  <span className="h-[30px] w-[30px] bg-[rgba(0,0,0,0.2)] flex items-center justify-center rounded-[15px]">
                    {cts.iconMoveImg}
                  </span>
                </div>
                <div className="!absolute flex items-center w-[200px] !top-[100%] ml-[220px]">
                  <span
                    onClick={showCroppedImageProduct}
                    className="cursor-pointer h-[41px] border border-black rounded-[10px] bg-[#3E6100] text-white flex items-center justify-center w-full px-[10px] mr-[10px]"
                  >
                    保存する
                  </span>
                  <span
                    onClick={() => {
                      setOpenModalImg(false);
                      setModalOpen(true);
                      setFileUpload(null);
                      setCroppedImage1(null);
                    }}
                    className="cursor-pointer h-[41px] border border-black rounded-[10px] bg-[#DCBA78] flex items-center justify-center w-full px-[10px]"
                  >
                    キャンセル
                  </span>
                </div>
              </>,
            ]}
          >
            <div className=" flex flex-col">
              <div className="crop_img_product">
                <Cropper
                  image={fileDataURL}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 3}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
            </div>
            {croppedImage && (
              <img src={croppedImage} className="h-[560px] w-[470px]" alt="uploaded"></img>
            )}
          </Modal>
        </>
      </div>
    </div>
  );
};
export default EditBannerPage;
