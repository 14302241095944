import {
  Button,
  Checkbox,
  DatePicker,
  DatePickerProps,
  Input,
  Select,
  Upload,
  UploadProps,
} from 'antd';
import { errorToast, getToken } from 'helpers';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addUserAdmin } from 'slices/userSlice';
import * as cts from '../../constants/ui/svg';
export interface dataTpyes {
  userName: string;
  email: string;
  password: string;
  gender: 'male' | 'female' | 'other' | string;
  firstName: string;
  lastName: string;
  birthday: string;
  phone: string | number;
  address: string;
  postalCode: string;
  avatar: string;
  role: string;
}
const AddNewAdminPage = () => {
  const [dataAdd, setDataAdd] = useState<dataTpyes>({
    userName: '',
    email: '',
    password: '',
    gender: '',
    firstName: '',
    lastName: '',
    birthday: '',
    phone: '',
    address: '',
    postalCode: '',
    avatar: '',
    role: '',
  });
  const [fileList, setFileList] = useState<any>();
  const token = getToken();
  const dispatch = useDispatch();
  const handleChangeRole = (value: string) => {
    console.log(`selected ${value}`);
    setDataAdd({ ...dataAdd, role: value });
  };
  const onChangeUpload: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    setDataAdd({ ...dataAdd, birthday: dateString });
  };
  const handleChangeGender = (value: 'male' | 'female' | 'other') => {
    setDataAdd({ ...dataAdd, gender: value });
  };
  const onSave = () => {
    if (
      dataAdd.userName !== '' &&
      dataAdd.role !== '' &&
      dataAdd.email !== '' &&
      dataAdd.firstName !== '' &&
      dataAdd.lastName !== '' &&
      dataAdd.birthday !== '' &&
      dataAdd.gender !== '' &&
      dataAdd.postalCode !== '' &&
      dataAdd.address !== '' &&
      dataAdd.phone !== '' &&
      dataAdd.password !== ''
    ) {
      dispatch(addUserAdmin({ token, inputs: { ...dataAdd } }));
    } else {
      errorToast('Please input enough value');
    }
  };
  useEffect(() => {
    if (fileList?.length >= 0) {
      setDataAdd({ ...dataAdd, avatar: fileList[0]?.name });
    }
  }, [fileList]);
  return (
    <div className="pt-[26px] px-[35px] flex flex-col pb-[100px]">
      <div className="flex flex-col mb-[100px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-[20px] mplus1pextrabold mr-[54px]">アドミン追加</span>
          </div>
        </div>
        <div className="pt-[50px] flex w-[100%]">
          {/* <div className="w-[20%] flex flex-col"> */}{' '}
          <div className="w-[20%] relative">
            <div className="h-[120px] absolute w-[120px]">
              {fileList?.length > 0 ? '' : cts.iconAvaAdmin}
            </div>
            <Upload
              className="upload_img_admin"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture"
              onChange={onChangeUpload}
              //   defaultFileList={[...fileList]}
            >
              <Button className="border-none text-[#0038FF] shadow-none !px-[0px] pt-[140px]">
                + add profile image
              </Button>
            </Upload>
          </div>
          {/* </div> */}
          <div className="flex flex-col w-[80%]">
            <div className="flex items-center">
              <div className="flex flex-col mr-[5%]">
                <span className="mb-[10px] text-[16px] mplus1pbold">Admin name </span>
                <Input
                  value={dataAdd?.userName}
                  onChange={(e) => setDataAdd({ ...dataAdd, userName: e.target.value })}
                  className="w-[430px] h-[40px] bg-[white] border border-[black] mb-[20px]"
                />
              </div>
              <div className="flex flex-col">
                <span className="mb-[10px] text-[16px] mplus1pbold">Role </span>
                <Select
                  className="w-[150px] slc_role bg-[white] h-[40px]  mb-[20px]"
                  onChange={handleChangeRole}
                  options={[
                    { value: 'master', label: 'master' },
                    { value: 'admin', label: 'admin' },
                  ]}
                />
              </div>
            </div>
            <span className="mb-[10px] text-[16px] mplus1pbold">E-mail</span>
            <Input
              value={dataAdd?.email}
              onChange={(e) => setDataAdd({ ...dataAdd, email: e.target.value })}
              className="w-[430px] h-[40px] bg-[white]border border-[black]"
            />

            <div className="flex mt-[30px]">
              <div className="flex flex-col">
                <span className="mb-[10px] text-[16px] mplus1pbold">First 名</span>
                <Input
                  value={dataAdd?.firstName}
                  onChange={(e) => setDataAdd({ ...dataAdd, firstName: e.target.value })}
                  className="w-[210px] h-[40px] bg-[white] border border-[black]"
                />
              </div>
              <div className="ml-[50px] flex flex-col">
                <span className="mb-[10px] text-[16px] mplus1pbold">Last 名</span>
                <Input
                  value={dataAdd?.lastName}
                  onChange={(e) => setDataAdd({ ...dataAdd, lastName: e.target.value })}
                  className="w-[210px] h-[40px] bg-[white] border border-[black]"
                />
              </div>
            </div>
            <div className="flex mt-[20px]">
              <div className="flex flex-col">
                <span className="mb-[10px] text-[16px] mplus1pbold">生年月日</span>
                <DatePicker
                  className="w-[210px] h-[40px] bg-[white] border border-[black]"
                  onChange={onChangeDate}
                />
              </div>
              <div className="ml-[50px] flex flex-col">
                <span className="mb-[10px] text-[16px] mplus1pbold">性別</span>
                <Select
                  className="w-[210px] h-[40px] bg-[white] slc_role"
                  onChange={handleChangeGender}
                  options={[
                    { value: 'male', label: 'male' },
                    { value: 'female', label: 'female' },
                    { value: 'other', label: 'other' },
                  ]}
                />
              </div>
            </div>
            <div className="flex mt-[20px]">
              <div className="flex flex-col">
                <span className="mb-[10px] text-[16px] mplus1pbold">郵便番号</span>
                <Input
                  value={dataAdd?.postalCode}
                  onChange={(e) => setDataAdd({ ...dataAdd, postalCode: e.target.value })}
                  className="w-[117px] h-[40px] bg-[white] border border-[black]"
                />
              </div>
              <div className="ml-[145px] flex flex-col">
                <span className="mb-[10px] text-[16px] mplus1pbold">住所</span>
                <Input
                  value={dataAdd?.address}
                  onChange={(e) => setDataAdd({ ...dataAdd, address: e.target.value })}
                  className="w-[430px] bg-[white] h-[40px] border border-[black]"
                />
              </div>
            </div>
            <span className="mb-[10px] text-[16px] mplus1pbold mt-[20px]">
              お問い合わせ電話番号
            </span>
            <Input
              value={dataAdd?.phone}
              onChange={(e) => setDataAdd({ ...dataAdd, phone: e.target.value })}
              className="w-[430px] bg-[white] h-[40px] border border-[black]"
            />
            <span className="mb-[10px] text-[16px] mplus1pbold mt-[20px]">Password</span>
            <Input
              value={dataAdd?.password}
              onChange={(e) => setDataAdd({ ...dataAdd, password: e.target.value })}
              className="w-[430px] bg-[white] h-[40px] border border-[black]"
            />
            <span className="mb-[10px] text-[16px] mplus1pbold mt-[20px]">Website Language</span>
            <Select
              className="select-language"
              defaultValue="Site Default"
              style={{ width: 120 }}
              // onChange={handleChange}
              options={[
                { value: 'jack', label: 'Jack' },
                { value: 'lucy', label: 'Lucy' },
                { value: 'Yiminghe', label: 'yiminghe' },
              ]}
            />
            <span className="mb-[10px] text-[16px] mplus1pbold mt-[20px]">
              Send ユーザー管理 notification
            </span>
            <Checkbox>Send the new ユーザー管理 an email about their account.</Checkbox>
          </div>
        </div>
        <button
          className="bg-[#3E6100] w-[100px] text-[white] p-[10px] rounded-[10px] mplus1pbold"
          onClick={onSave}
        >
          保存する
        </button>
      </div>
    </div>
  );
};
export default AddNewAdminPage;
