import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { AuthSate, UserTypes } from 'models';

export interface LoginPayload {
  email: string;
  password: string;
  role?: number;
  remember_me?: boolean;
}

export interface LogoutPayload {
  token: string;
  notification_token: string;
}

const initialState: AuthSate = {
  isLoggedIn: false,
  logging: false,
  currentUser: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<{ state: LoginPayload }>) {
      state.logging = true;
    },

    loginSuccess(state, action: PayloadAction<UserTypes>) {
      state.isLoggedIn = true;
      state.logging = false;
      state.currentUser = action.payload;
    },

    logout(state, action: PayloadAction<{ params: { notification_token: string } }>) {},
    logoutSuccess(state) {
      Cookies.remove('EC_ADMIN_TOKEN');
      state.currentUser = null;
      state.isLoggedIn = false;
    },
  },
});

// Actions
export const { login, loginSuccess, logout, logoutSuccess } = authSlice.actions;

// Selectors
export const selectLoginUser = (state: any) => state.auth;
// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
