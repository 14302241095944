import { useEffect, useState } from 'react';
import {
  Table,
  Input,
  Select,
  Button,
  Checkbox,
  Pagination,
  DatePicker,
  Modal,
  Dropdown,
  Space,
} from 'antd';
import moment, { Moment } from 'moment';
import type { ColumnsType } from 'antd/es/table';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import * as cts from '../../constants/ui/svg';
import type { PaginationProps, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import EditBannerPage from './EditBannerPage';
import EditProductImagePage from './EditProductImagePage';
import { useDispatch } from 'react-redux';
import { getToken } from 'helpers';
import { useAppSelector } from 'redux/hooks';
import { fetchAllBanner } from 'slices/bannerSlice';
import { BannerTypes } from '../../models/banner';
import { Link } from 'react-router-dom';
interface Props {
  // idProduct: any;
}
interface DataType {
  key: React.Key;
  banner: string;
  size: any;
  location: any;
  presentation_type: string;
  last_updated: any;
}

const { Search } = Input;

const onSearch = (value: string) => console.log(value);
const Banner = () => {
  const { t } = useTranslation();
  const [openForm, setOpenForm] = useState(false);
  const [pageBanner, setPageBanner] = useState('mainPage');
  const [dataQuickView, setDataQuickView] = useState();
  const date = moment().format('MM-DD-YYYY');
  const dispatch = useDispatch();
  const token = getToken();
  const allBanner = useAppSelector((state) => state.banners.getAllBanner);
  const [idBanner, setIdBanner] = useState<any>();
  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <div>view banner</div>,
    },
    {
      key: '2',
      label: <Link to={`/edit-banner/${idBanner}`}>edit banner</Link>,
    },
    {
      key: '3',
      label: <span>delete banner</span>,
    },
  ];
  const columns: ColumnsType<BannerTypes> = [
    {
      title: <span></span>,
      key: 'operation',
      fixed: 'left',
      width: 0,
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">Banner</span>,
      width: 120,
      dataIndex: 'title',
      key: 'title',
      render: (value: any) => (
        <div className="flex items-center">
          <span className="w-full flex justify-center text-[#0038FF] text-[14px] mplus1pextrabold">
            {value}
          </span>
        </div>
      ),
    },
    {
      title: <span></span>,
      dataIndex: '',
      key: '',
      width: 50,
      render: (value, record) => (
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          placement="bottomLeft"
          className="bg-[#DCBA78]/50  flex justify-center items-center"
        >
          <Button
            onClick={() => setIdBanner(record?.id)}
            className="w-[20px] h-[20px] rounded-[2px] p-0 "
          >
            <span className="!flex items-center mb-[6px]">...</span>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">Size</span>,
      dataIndex: 'size',
      key: 'size',
      width: 100,
      render: (value: any) => <span className="flex justify-center w-full">{value}</span>,
    },

    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">Location</span>,
      dataIndex: 'location',
      key: 'location',
      width: 180,
      render: (value: any) => (
        <span className="text-[12px] mplus1pmedium flex justify-center w-full">{value}</span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">
          Presentation Type
        </span>
      ),
      dataIndex: 'presentation',
      key: 'presentation',
      width: 100,
      render: (value: any) => <span className=" flex justify-center w-full">{value}</span>,
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center ">Last updated</span>,
      dataIndex: 'banner',
      key: 'banner',
      fixed: 'right',
      width: 150,
      render: (value: any) => (
        <span className="text-[12px] mplus1pnormal flex text-start justify-center">
          {moment(value?.date).format('YYYY.MM.DD')} by
          <span className="text-[#0038FF] underline ml-[5px]">{value?.name}</span>
        </span>
      ),
    },
  ];
  const arr = allBanner?.result?.map((x: any, idx: any) => {
    return {
      ...x,
      key: idx,
      banner: {
        date: x?.updatedAt,
        name: x?.updateBy?.userName || x?.updateBy?.firstName + x?.updateBy?.lastName,
      },
    };
  });

  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: BannerTypes[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: BannerTypes) => ({
      // disabled: record.name === 'Disabled User', // Column configuration not to be checked
      // name: record.name,
    }),
  };
  const pageSize = 10;
  const [pagi, setPagi] = useState<any>({
    allBanner: [],
    totalPage: allBanner?.count / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });
  useEffect(() => {
    dispatch(fetchAllBanner({ token, limit: pageSize, page: pagi.current }));
  }, [token, dispatch, pagi.current]);
  return (
    <div className="pt-[26px] px-[35px] flex flex-col pb-[100px]">
      {pageBanner === 'mainPage' && (
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <span className="text-[20px] mplus1pextrabold mr-[54px]">{t('BANNER')}</span>
            </div>
            <div className="flex items-center">
              {/* <span className="h-[40px] w-[48px] border-[0.5px] rounded-l-[10px] border-black/50 bg-[#DCBA78]/50 flex justify-center items-center">
                  {cts.iconSearch}
                </span>
                <Input
                  placeholder="SEARCH PRODUCT"
                  className="w-[252px] h-[40px] border-[0.5px] border-black/50 rounded-r-[10px] rounded-l-[0px]"
                /> */}
            </div>
          </div>

          <div className="flex w-full justify-between pt-[30px]">
            <div className="flex items-center">
              <span className="underline text-[14px] mplus1pnormal text-[#0038FF] ">
                {t('ALL')}{' '}
                <span className="text-black underline">({allBanner?.result?.length || 0})</span>
              </span>
              {/* <span className="underline text-[14px] mplus1pnormal text-[#0038FF] ml-[50px] pr-[30px] border-r border-[#000000]/25" >
                            {t("SHIPPING")}{' '}
                            <span className="text-black underline">
                                ({data.length})
                            </span>
                        </span>
                        <span className="underline text-[14px] mplus1pnormal text-[#0038FF] ml-[10px] " >
                            {t("DELIVERED")}{' '}
                            <span className="text-black underline">
                                ({data.length})
                            </span>
                        </span> */}
            </div>
            {/* <div className="flex items-center ctn_btn_merchandise  pr-[10px] pb-[10px] h-full overflow-auto overflow-y-hidden ">
                        <Select
                            defaultValue="一括操作"
                            className=" slc_merchandise mr-[10px]"
                            // onChange={handleChange}
                            options={[
                                { value: '一括操作', label: '一括操作' },
                                { value: 'Yiminghe', label: 'yiminghe' },
                            ]}
                        />
                        <Button className="bg-white h-[30px] border-[0.5px] !border-[#0038FF] text-[14px] mplus1pnormal border-black ">
                            {t('APPLY')}
                        </Button>
                        <Select
                            defaultValue="日付"
                            className=" slc_merchandise ml-[10px]"
                            // onChange={handleChange}
                            options={[
                                { value: '一括操作', label: '一括操作' },
                                { value: 'Yiminghe', label: 'yiminghe' },
                            ]}
                        />
                        <Button className="bg-white h-[30px] border-[0.5px] ml-[10px] !border-[#0038FF] text-[14px] mplus1pnormal text-[#000000]/40 border-black ">
                            {t('Filter_By_Registered_Customers')}
                        </Button>
                        <Button className="bg-white h-[30px] border-[0.5px] ml-[10px] !border-[#0038FF] text-[14px] mplus1pnormal border-black ">
                            {t('FILTER')}
                        </Button>
                    </div> */}
          </div>

          <div className="mt-[25px] mb-[100px]">
            <Table
              className="table_merchandise table-order"
              columns={columns}
              dataSource={arr}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              scroll={{ x: 1000 }}
              pagination={{
                showSizeChanger: false,
                total: allBanner?.count,
                pageSize: 10,
                current: pagi.current,

                onChange: (page: any) => {
                  setPagi({
                    current: page,
                    minIndex: (page - 1) * pageSize,
                    maxIndex: page * pageSize,
                  });
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default Banner;
