import { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { push } from 'connected-react-router';
import { ENCODE_SECRET_KEY } from '../constants';
import {
  fetchProductTagAPI,
  addProductTagAPI,
  fetchAllProductTagsByAdminAPI,
  updateProductTagAPI,
  deleteProductTagAPI,
} from '../api';
import { successToast, errorToast, failedToast } from 'helpers';
import {
  fetchProductTag,
  fetchProductTagSuccess,
  addProductTag,
  addProductTagSuccess,
  fetchAllProductTagsByAdmin,
  fetchAllProductTagsByAdminSuccess,
  updateProductTag,
  updateProductTagSuccess,
  deleteProductTag,
  deleteProductTagSuccess,
} from 'slices/tagProductsSlice';
import { showLoading, hideLoading } from '../slices/commonSlice';

function* productTagSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(fetchProductTagAPI, {
      limit: action.payload.limit,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(fetchProductTagSuccess(data?.productTags));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* addProductTagSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(addProductTagAPI, {
      token: action.payload.token,
      inputs: action.payload.inputs,
    });
    const { data, errors } = res.data;
    if (res?.data?.data?.addProductTag?.ok === true) {
      successToast('Add tag product new success');
      yield put(addProductTagSuccess(data?.addProductTag?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* fetchAllProductTagsByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(fetchAllProductTagsByAdminAPI, {
      limit: action.payload.limit,
      page: action.payload.page,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(fetchAllProductTagsByAdminSuccess(data?.getAllProductTagsByAdmin));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* updateProductTagSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(updateProductTagAPI, {
      id: action.payload.id,
      inputs: action.payload.inputs,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    if (data?.updateProductTag?.ok === true) {
      successToast('Update tag product success');
      yield put(updateProductTagSuccess(data?.updateProductTag?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* deleteProductTagSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(deleteProductTagAPI, {
      id: action.payload.id,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    if (data?.deleteProductTag?.ok === true) {
      successToast('Delete tag product success');
      yield put(deleteProductTagSuccess(data?.deleteProductTag?.data?.id));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
export default function* tagProductSaga() {
  yield takeLatest(fetchProductTag.type, productTagSaga);
  yield takeLatest(addProductTag.type, addProductTagSaga);
  yield takeLatest(fetchAllProductTagsByAdmin.type, fetchAllProductTagsByAdminSaga);
  yield takeEvery(updateProductTag.type, updateProductTagSaga);
  yield takeEvery(deleteProductTag.type, deleteProductTagSaga);
}
