import { all } from 'redux-saga/effects';

import userSaga from '../sagas/userSaga';
import productSaga from '../sagas/productSaga';
import categorySaga from '../sagas/categorySaga';
import tagProductSaga from '../sagas/tagProductsSaga';
import orderSaga from '../sagas/orderSaga';
import blogSaga from '../sagas/blogSaga';
import bannerSaga from '../sagas/bannerSaga';
export default function* rootSaga() {
  yield all([
    userSaga(),
    productSaga(),
    categorySaga(),
    tagProductSaga(),
    orderSaga(),
    blogSaga(),
    bannerSaga(),
  ]);
}
