import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { ENCODE_SECRET_KEY } from '../constants';

export function getToken() {
  if (Cookies.get('EC_ADMIN_TOKEN')) {
    const tokenEncode: string = Cookies.get('EC_ADMIN_TOKEN') as string;
    const bytes = CryptoJS.AES.decrypt(tokenEncode, ENCODE_SECRET_KEY);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);

    return originalText;
  }
}
