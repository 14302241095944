import { Button, Input, Modal, Popconfirm, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ProductReviewByAdminTypes, ProductTypes, TypesProductUpdate } from 'models';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { errorToast, getToken } from 'helpers';
import {
  addProductReviewByAdmin,
  changeReviewStatus,
  cloneProduct,
  deleteProduct,
  deleteProductPreviewByAdmin,
  deleteProductReviewByAdmin,
  fetchProductReviewByAdminList,
  fetchProductsWithFilter,
  fetchSearchProductByAdmin,
  updateProductReviewByAdmin,
  updateProductStatus,
} from 'slices/productSlice';
import * as cts from '../../constants/ui/svg';
import { Link } from 'react-router-dom';
import { URL_UPLOAD } from '../../constants/index';
import ContentTableProduct from './ContentTableProduct';
import ModalConfirm from 'components/Common/ModalConfirm';
interface Props {
  btnSearch: boolean;
  data: any;
  setBtnSearch: React.Dispatch<React.SetStateAction<boolean>>;
  textSearch: string;
  total: any;
  status: string;
  point: any;
  setIdReview: any;
}
const TablePreviewProduct: React.FC<Props> = ({
  total,
  btnSearch,
  textSearch,
  setBtnSearch,
  data,
  status,
  point,
  setIdReview,
}) => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const token = getToken();
  const recordRef = useRef('');
  const [viewSlc, setViewSlc] = useState('');
  const [idRecord, setIdRecord] = useState('');
  const [recordCate, setRecordCate] = useState([]);
  const [recordTag, setRecordTag] = useState([]);
  const [openPop, setOpenPop] = useState(false);
  const [expandedKey, setExpandedKey] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [contentReply, setContentReply] = useState({ id: '', content: '' });
  const [openModalDel, setOpenModalDel] = useState(false);
  const [dataSearch, setDataSearch] = useState<any>({
    count: 0,
    result: [],
  });
  const [productUpdate, setProductUpdate] = useState<any>();
  const pageSize = 10;
  const [pagi, setPagi] = useState<any>({
    dataBefore: [],
    totalPage: total / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });
  const [pagiSearch, setPagiSearch] = useState<any>({
    dataAfter: [],
    totalPageSearch: total / pageSize,
    currentSearch: 1,
    minIndexSearch: 0,
    maxIndexSearch: pageSize,
  });
  const onExpand = (_: any, { key }: any) => setExpandedKey(key);
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ProductReviewByAdminTypes[]) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setIdReview(selectedRows?.map((x: any) => x?.id));
    },
    getCheckboxProps: (record: ProductReviewByAdminTypes) => ({}),
  };
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContentReply({ ...contentReply, content: e.target.value });
  };
  const columns: ColumnsType<ProductReviewByAdminTypes> = [
    {
      title: <div className="flex justify-center w-full ckb_table"></div>,
      key: 'operation',
      fixed: 'left',
      width: 0,
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full"></span>,
      width: 80,
      dataIndex: 'avatar',
      key: 'avatar',
      render: (value: any) => (
        <span className="flex justify-center w-full">
          {cts.iconAvatar}
          {/* <img className="h-[50px] w-[50px]" src={`${URL_UPLOAD}/products/${value}`} /> */}
        </span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">{t('USER')}</span>
      ),
      width: 120,
      dataIndex: 'user',
      key: 'user',
      render: (value: any) => (
        <div className="flex flex-col items-start">
          <span className="text-[12px] mplus1pmedium text-[#0038FF]">({value.name})</span>
          <span className="text-[10px] mplus1pnormal text-[#444444]">{value.id}</span>
        </div>
      ),
    },

    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">{t('REVIEW')}</span>
      ),
      dataIndex: 'content',
      key: 'content',
      width: 400,
      render: (value: any) => <span className="flex justify-center w-full">{value}</span>,
    },
    Table.EXPAND_COLUMN,
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">
          {t('MERCHANDISE')}
        </span>
      ),
      dataIndex: 'productId',
      key: 'productId',
      width: 150,
      render: (value: any) => (
        <span className="flex justify-center w-full text-[#0038FF] mplus1pmedium">
          {value?.name}
        </span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">{t('RATING')}</span>
      ),
      dataIndex: 'point',
      key: 'point',
      width: 150,
      render: (value: any) => (
        <span className=" flex justify-center w-full">
          <span className="flex items-center">
            {(value === 5 && (
              <>
                <span>{cts.icon1Star}</span>
                <span className="mx-[5px]">{cts.icon1Star}</span>
                <span>{cts.icon1Star}</span>
                <span className="mx-[5px]">{cts.icon1Star}</span>
                <span>{cts.icon1Star}</span>
              </>
            )) ||
              (value === 4 && (
                <>
                  <span>{cts.icon1Star}</span>
                  <span className="mx-[5px]">{cts.icon1Star}</span>
                  <span>{cts.icon1Star}</span>
                  <span className="mx-[5px]">{cts.icon1Star}</span>
                  <span>{cts.iconStarDis1}</span>
                </>
              )) ||
              (value === 3 && (
                <>
                  <span>{cts.icon1Star}</span>
                  <span className="mx-[5px]">{cts.icon1Star}</span>
                  <span>{cts.icon1Star}</span>
                  <span className="mx-[5px]">{cts.iconStarDis1}</span>
                  <span>{cts.iconStarDis1}</span>
                </>
              )) ||
              (value === 2 && (
                <>
                  <span>{cts.icon1Star}</span>
                  <span className="mx-[5px]">{cts.icon1Star}</span>
                  <span>{cts.iconStarDis1}</span>
                  <span className="mx-[5px]">{cts.iconStarDis1}</span>
                  <span>{cts.iconStarDis1}</span>
                </>
              )) ||
              (value === 1 && (
                <>
                  <span>{cts.icon1Star}</span>
                  <span className="mx-[5px]">{cts.iconStarDis1}</span>
                  <span>{cts.iconStarDis1}</span>
                  <span className="mx-[5px]">{cts.iconStarDis1}</span>
                  <span>{cts.iconStarDis1}</span>
                </>
              )) ||
              (value <= 0 && (
                <>
                  <span>{cts.iconStarDis1}</span>
                  <span className="mx-[5px]">{cts.iconStarDis1}</span>
                  <span>{cts.iconStarDis1}</span>
                  <span className="mx-[5px]">{cts.iconStarDis1}</span>
                  <span>{cts.iconStarDis1}</span>
                </>
              ))}
          </span>
        </span>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">{t('DATE')}</span>
      ),
      dataIndex: 'createdAt',
      fixed: 'right',
      key: 'createdAt',
      width: 120,
      render: (value: any) => (
        <div className="flex flex-col pl-[10%]">
          <span>{value ? moment(value).format('YYYY/MM/DD') : '--/--/--'}</span>
          <span>{value ? moment(value).format('HH:MM') : '--:--'}</span>
          {/* <span>2021/10/25 at 3:47 pm</span> */}
        </div>
      ),
    },
  ];
  const _renderTable = (record: any) => {
    return (
      <>
        {record?.replies?.length >= 1 && contentReply.content === '' ? (
          <div className=" py-[30px] flex w-full justify-center">
            <div className="w-[70%] flex">
              <div className="w-[30%] flex flex-col">
                <span className="text-[#0038FF] mplus1pmedium">
                  {record?.replies[0]?.customerId?.userName ||
                    record?.replies[0]?.customerId?.lastName}
                </span>
                <span className="text-[#444444] text-[10px] mplus1pnormal">
                  {record?.replies[0]?.customerId?.id}
                </span>
              </div>
              <div className="w-[70%] pl-[5%] flex flex-col">
                <span>{record?.replies[0]?.content}</span>
                <div className="mt-[10px] flex items-center">
                  <span
                    onClick={() => {
                      setContentReply({
                        id: record?.replies[0]?.id,
                        content: record?.replies[0]?.content,
                      });
                    }}
                    className="text-[#3E6100] text-[10px] mplus1pnormal underline cursor-pointer"
                  >
                    編集
                  </span>
                  <span
                    onClick={() => {
                      setOpenModalDel(true);
                      setContentReply({ ...contentReply, id: record?.replies[0]?.id });
                    }}
                    className="text-[#C70000] ml-[20px] text-[10px] mplus1pnormal underline cursor-pointer"
                  >
                    削除
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col py-[30px] w-full items-center">
            <div className="w-[70%]">
              <span>返信を書く</span>
            </div>
            <TextArea
              showCount
              defaultValue={contentReply.content}
              maxLength={250}
              style={{ height: 144, resize: 'none', width: '70%' }}
              onChange={onChange}
              className=" inp_reply"
            />
            <div className="w-[70%] flex items-center mt-[30px]">
              <span
                onClick={() => {
                  if (contentReply.content !== '') {
                    if (record?.replies?.length >= 1) {
                      dispatch(
                        updateProductReviewByAdmin({
                          token,
                          id: contentReply?.id,
                          content: contentReply?.content,
                        })
                      );
                      setContentReply({ id: '', content: '' });
                    } else {
                      dispatch(
                        addProductReviewByAdmin({
                          token,
                          parentId: record?.id,
                          content: contentReply.content,
                        })
                      );
                      setContentReply({ id: '', content: '' });
                    }
                  } else {
                    errorToast('保存する前に返信ボックスを空白のままにしないでください!');
                  }
                }}
                className="h-[39px] !px-[10px] flex mplus1pmedium justify-center items-center bg-[#3E6100] rounded-[10px] text-white cursor-pointer"
              >
                {record?.replies?.length >= 1 ? 'アップデート' : '送信'}
              </span>
              <span
                onClick={() => {
                  setExpandedKey(null);
                }}
                className="h-[39px] !px-[10px] mplus1pmedium flex justify-center items-center text-[#3E6100] border border-[#3E6100] rounded-[10px] cursor-pointer ml-[20px]"
              >
                {t('CANCEL')}
              </span>
            </div>
          </div>
        )}
      </>
    );
  };

  const onDelete = (record: any) => {
    dispatch(deleteProductReviewByAdmin({ token, id: record?.id }));
  };
  const updateTrash = (record: any) => {
    dispatch(
      changeReviewStatus({
        token,
        id: record?.id,
        status: 'trash',
      })
    );
  };
  const onTrash = (record: any) => {
    setViewSlc('remove_product');
    setIdRecord('');
    switch (status) {
      case 'trash':
        setOpenModal(true);
        return;
      case 'public':
        setOpenModal(true);
        return;
      case 'spam':
        setOpenModal(true);
        return;
      default:
        break;
    }
  };
  const _renderButton = (onExpand: any, record: any) => {
    return (
      <div className=" bg-[#DCBA78] py-[5px] flex flex-col rounded-[5px] w-[156px]">
        {status === 'public' && (
          <>
            <span
              onClick={(e) => {
                onExpand(record, e);
                setViewSlc('public');
                setIdRecord('');
                setContentReply({ id: '', content: '' });
              }}
              className={`${
                viewSlc === 'public' && 'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
              } cursor-pointer px-[10px] h-[30px] hover:text-white hover:rounded-[5px] hover:bg-[#3E6100] hover:mx-[2px] mt-[5px] flex items-center text-[14px] mplus1pnormal `}
            >
              {t('WRITE_REPLY')}
            </span>
            <span
              onClick={(e) => {
                setIdRecord('');
                setViewSlc('hide');
                dispatch(changeReviewStatus({ token, id: record?.id, status: 'spam' }));
              }}
              className={`${
                viewSlc === 'hide' && 'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
              } cursor-pointer hover:text-white hover:bg-[#3E6100] hover:mx-[2px] hover:rounded-[5px] px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
            >
              {t('HIDE_REVIEW')}
            </span>
          </>
        )}
        {status !== 'public' && (
          <span
            onClick={(e) => {
              // onExpand(record, e);
              setViewSlc('regain');
              setIdRecord('');
              if (status === 'spam') {
                dispatch(changeReviewStatus({ token, id: record?.id, status: 'public' }));
              } else {
                dispatch(changeReviewStatus({ token, id: record?.id, status: 'spam' }));
              }
            }}
            className={`${
              viewSlc === 'regain' && 'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
            } cursor-pointer px-[10px] h-[30px] hover:text-white hover:rounded-[5px] hover:bg-[#3E6100] hover:mx-[2px] mt-[5px] flex items-center text-[14px] mplus1pnormal `}
          >
            {t('RESTORE')}
          </span>
        )}

        <span
          onClick={() => {
            onTrash(record);
            recordRef.current = record;
          }}
          // onClick={(e) => {
          //   setViewSlc('remove_product');
          //   setOpenModal(true);
          //   recordRef.current = record;
          //   setIdRecord('')
          // }}
          className={`${
            viewSlc === 'remove_product' && 'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
          } cursor-pointer px-[10px] h-[30px] hover:text-white hover:bg-[#3E6100] hover:rounded-[5px] hover:mx-[2px] mt-[5px] flex items-center text-[14px] mplus1pnormal `}
        >
          {status === 'public' ? t('TO_SPAM') : t('DELETE_PERMANENTLY')}
        </span>
      </div>
    );
  };
  const arrNew = data?.map((item: any, idx: any) => {
    return {
      ...item,
      key: idx,
      user: {
        name: item?.customerId?.lastName || item?.customerId?.userName,
        id: item?.customerId?.id,
      },
      avatar: {
        avatar_user: item?.customerId?.avatar,
      },
    };
  });
  useEffect(() => {
    dispatch(
      fetchProductReviewByAdminList({
        token,
        limit: pageSize,
        page: pagi.current,
        status: status,
        point: point,
      })
    );
  }, [dispatch, token, pagi.current, status, point]);

  return (
    <>
      <Table
        className="table_merchandise ckb_brand"
        columns={columns}
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        dataSource={arrNew}
        scroll={{ x: 1400 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (record?.id === idRecord) return;
              setIdRecord(record?.id as string);
            }, // click row
          };
        }}
        expandable={{
          expandedRowRender: (record, index, indent, expanded) => _renderTable(record),
          // rowExpandable: (record) => false,
          expandedRowKeys: [expandedKey],
          onExpand: (expanded, record) => onExpand(expanded, record),
          expandIcon: ({ expanded, onExpand, record }) => (
            <Popconfirm
              key={record.id}
              title="aaaa"
              placement="bottom"
              description={_renderButton(onExpand, record)}
              okText="Yes"
              cancelText="No"
              open={idRecord === record?.id}
              className="w-full flex justify-center"
            >
              <button
                key={record.key}
                onClick={() => {
                  if (idRecord === record?.id) {
                    setIdRecord('');
                  }
                }}
                className="w-full flex justify-center"
              >
                <span className="h-[20px] w-[20px] cursor-pointer rounded-[2px] bg-[#DCBA78] flex justify-center items-center">
                  {cts.iconDot}
                </span>
              </button>
            </Popconfirm>
          ),
        }}
        pagination={{
          showSizeChanger: false,
          total: total,
          pageSize: 10,
          current: btnSearch ? pagiSearch.currentSearch : pagi.current,

          onChange: (page: any) => {
            if (btnSearch) {
              setPagiSearch({
                currentSearch: page,
                minIndexSearch: (page - 1) * pageSize,
                maxIndexSearch: page * pageSize,
              });
            } else {
              setPagi({
                current: page,
                minIndex: (page - 1) * pageSize,
                maxIndex: page * pageSize,
              });
            }
          },
        }}
      />
      <ModalConfirm
        openModal={openModal}
        setOpenModal={setOpenModal}
        onConfirm={status === 'trash' ? onDelete : updateTrash}
        record={recordRef.current}
        text={
          status === 'trash'
            ? '製品プレビューを削除してもよろしいですか?'
            : '製品レビューをゴミ箱に移動してもよろしいですか?'
        }
      />
      <Modal
        mask={false}
        centered
        open={openModalDel}
        className="modal_delete h-[270px] !w-[375px]"
        //   onOk={() => setOpenModal(false)}
        //   onCancel={() => setOpenModal(false)}
      >
        <div className="flex flex-col justify-between items-center h-full">
          <span>{cts.iconWarning}</span>
          <span className="text-[14px] mplus1pmedium">
            この商品レビューの返信を削除してもよろしいですか?
          </span>
          <div className="flex items-center w-full">
            <span
              className="h-[34px] rounded-[5px] flex items-center justify-center bg-[#9B0101] cursor-pointer text-white text-[14px] mplus1pextrabold w-full mr-[5px]"
              onClick={() => {
                dispatch(deleteProductPreviewByAdmin({ token, id: contentReply.id }));

                setOpenModalDel(false);
              }}
            >
              {t('DELETE')}
            </span>
            <span
              className="h-[34px] rounded-[5px] flex items-center justify-center bg-[#3E6100] cursor-pointer text-white text-[14px] mplus1pextrabold w-full ml-[5px]"
              onClick={() => setOpenModalDel(false)}
            >
              {t('CANCEL')}
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default TablePreviewProduct;
