import { useState, useEffect, useCallback, useRef } from 'react';
import {
  Table,
  Input,
  Select,
  Button,
  Checkbox,
  Pagination,
  DatePicker,
  Popconfirm,
  Dropdown,
  Tooltip,
  Radio,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import * as cts from '../../constants/ui/svg';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { PaginationProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  cloneProduct,
  deleteProduct,
  fetchProductInfoByAdmin,
  fetchProductReviewById,
  fetchProductsWithFilter,
  fetchSearchProductByAdmin,
  updateProduct,
} from '../../slices/productSlice';
import { fetchAllCategory } from '../../slices/categorySlice';
import { fetchProductTag } from '../../slices/tagProductsSlice';
import { getToken } from 'helpers';
import { URL_UPLOAD } from '../../constants';
import moment from 'moment';
import { useAppSelector } from 'redux/hooks';
import dayjs from 'dayjs';
import { ProductTypes, TypesProductUpdate } from '../../models';
import AddNewMerchandisePage from './AddNewMerchandise';
import ModalConfirm from 'components/Common/ModalConfirm';
import TableProduct from './TableProduct';
const { Search } = Input;

const onSearch = (value: string) => console.log(value);

const ProductTrashPage = () => {
  const recordRef = useRef('');
  const myRef = useRef<any>();
  const { t } = useTranslation();
  const arrOptionCateRef = useRef<any>([]);
  const arrOptionTagRef = useRef<any>([]);
  const { TextArea } = Input;
  const [openModal, setOpenModal] = useState(false);
  const [productUpdate, setProductUpdate] = useState<TypesProductUpdate>({
    name: '',
    slug: '',
    price: 0,
    marketPrice: 0,
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    reviewAble: false,
    listTag: [],
    categoryId: [],
  });
  // const [state, setState] = useState<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = getToken();
  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const [textSearch, setTextSearch] = useState('');
  const [btnSearch, setBtnSearch] = useState(false);
  const [recordCate, setRecordCate] = useState([]);
  const [recordTag, setRecordTag] = useState([]);
  const [recordCateBefore, setRecordCateBefore] = useState<any>([]);
  const [recordTagBefore, setRecordTagBefore] = useState<any>([]);
  const [idCateSelect, setIdCateSelect] = useState<any>();
  const [idTagSelect, setIdTagSelect] = useState<any>();
  const productWithFilter = useAppSelector((state) => state.products.productList);
  const categories = useAppSelector((state) => state.categories.categories);
  const tagProduct = useAppSelector((state) => state.productTags.productTags);

  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a>
          <Button className="flex items-center shadow-none focus:shadow-none justify-center border-none hover:text-black prev_btn">
            <span className="text-[10px] mplus1pnormal">to previous page</span>
          </Button>
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a>
          <Button className="flex items-center shadow-none justify-center border-none hover:text-black prev_btn">
            <span className="text-[10px] mplus1pnormal">to next page</span>
          </Button>
        </a>
      );
    }
    return originalElement;
  };
  const pageSize = 10;
  const [pagi, setPagi] = useState<any>({
    productWithFilter: [],
    totalPage: productWithFilter?.count / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });

  // const dataNew = data.map((item, ind) => {
  //   if (ind >= pagi.minIndex && ind < pagi.maxIndex) {
  //     return item;
  //   }
  // });
  const handleChangeSelectCate = (value: { value: string; label: React.ReactNode }) => {
    setIdCateSelect(value);
  };
  const handleChangeSelectTag = (value: { value: string; label: React.ReactNode }) => {
    setIdTagSelect(value);
  };

  // console.log('record', recordTag);
  // const arrTag = productUpdate?.listTag
  //   ?.map((x: any) => x?.name)
  //   .reduce((accumulator, currentValue) => accumulator + '#' + currentValue, '');
  // console.log('arrTag', arrTag);
  const onDelete = (record: any) => {
    dispatch(deleteProduct({ token, id: record?._id }));
  };

  useEffect(() => {
    const recordCateMaped = recordCate?.map((item: any) => item?._id);
    const arrCateMaped = recordTag?.map((item: any) => item?.name);

    // console.log('arrCate1', arrCate);
    // if (recordCateBefore?.length > 0) {
    //   setProductUpdate({ ...productUpdate, categoryId: recordCateBefore });
    //   console.log('1213');
    // } else {
    //   setProductUpdate({ ...productUpdate, categoryId: arrCate });
    //   console.log('productUpdate', productUpdate);
    // }
    if (recordCateBefore?.length === 0 && recordTagBefore?.length === 0)
      setProductUpdate({ ...productUpdate, categoryId: recordCateMaped, listTag: arrCateMaped });
  }, [recordCateBefore?.length]);
  // useEffect(() => {
  //   const arrCate = recordTag?.map((item: any) => item?.name);
  //   console.log('arrCate', arrCate);
  //   if (recordTagBefore?.length > 0) {
  //     setProductUpdate({ ...productUpdate, listTag: recordTagBefore });
  //   } else {
  //     setProductUpdate({ ...productUpdate, listTag: arrCate });
  //   }
  // }, [recordTagBefore?.length, recordTag]);
  useEffect(() => {
    if (categories?.length > 0) {
      arrOptionCateRef.current = categories?.map((item: any) => {
        return {
          value: item?._id,
          label: item?.name,
        };
      });
      arrOptionCateRef.current.push({
        value: '',
        label: 'All',
      });
    }
  }, [categories.length]);
  useEffect(() => {
    if (tagProduct?.length > 0) {
      arrOptionTagRef.current = tagProduct?.map((item: any) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });
      arrOptionTagRef.current.push({
        value: '',
        label: 'All',
      });
    }
  }, [tagProduct.length]);

  useEffect(() => {
    dispatch(fetchAllCategory({ token, limit: 100 }));
  }, [dispatch, token]);
  useEffect(() => {
    dispatch(fetchProductTag({ token, limit: 100 }));
  }, [dispatch, token]);
  useEffect(() => {
    const pagi = myRef.current;
  }, []);
  return (
    <div className="pt-[26px] px-[35px] flex flex-col pb-[100px]">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <span className="text-[20px] mplus1pextrabold mr-[54px]">{t('MERCHANDISE')}</span>
          <span
            onClick={() => history.push('/add-new')}
            className="cursor-pointer bg-[#DBB312] h-[40px] w-[82px] flex justify-center items-center text-white border-[0.5px] border-[#3E6100] rounded-[10px] text-[14px] mplus1pextrabold"
          >
            {t('ADD_NEW')}
          </span>
        </div>
        <div className="flex items-center">
          <span
            onClick={() => {
              setBtnSearch(true);
            }}
            className="h-[40px] w-[48px] border-[0.5px] rounded-l-[10px] border-black/50 bg-[rgba(220,186,120,0.5)] flex justify-center items-center"
          >
            {cts.iconSearch}
          </span>
          <Input
            onChange={(e) => setTextSearch(e.target.value)}
            placeholder="SEARCH PRODUCT"
            className="w-[252px] h-[40px] border-[0.5px] border-black/50 rounded-r-[10px] rounded-l-[0px]"
          />
        </div>
      </div>
      <div className="flex mt-[60px] w-full items-center justify-between">
        <div className="flex items-center">
          <span
            onClick={() => history.push('/products')}
            className="underline text-[14px] mplus1pnormal text-[#0038FF] cursor-pointer"
          >
            {t('DISPLAY')}{' '}
            <span className="text-black underline">
              {/* {btnSearch ? {pagiSearch.minIndexSearch + 1} -{' '}
          {pagiSearch?.currentSearch * pageSize - (pageSize - searchProduct?.result?.length)} of{' '}
          {searchProduct?.count}
          :  */}
              {pagi.minIndex + 1} -{' '}
              {pagi?.current * pageSize - (pageSize - productWithFilter?.result?.length)} of{' '}
              {productWithFilter?.count || 0}
            </span>
          </span>
          <span
            onClick={() => history.push('/products')}
            className="text-[#0038FF] cursor-pointer underline text-[14px] ml-[20px]"
          >
            {t('PUBLISH')}
            <span className="text-black underline">({productWithFilter?.publicCount || 0})</span>
          </span>
          <span
            onClick={() => history.push('/product-draft')}
            className="text-[#0038FF] underline text-[14px] ml-[20px] cursor-pointer"
          >
            {t('DRAFT')}
            <span className="text-black underline">({productWithFilter?.draftCount || 0})</span>
          </span>
          <span className="text-[#2E543E] underline text-[14px] ml-[20px] cursor-pointer">
            {t('TO_TRASH')}
            <span className="text-black underline">({productWithFilter?.trashCount || 0})</span>
          </span>
        </div>
        <div className="flex items-center ctn_btn_merchandise w-[70%] pr-[10px] pb-[10px] h-full overflow-auto overflow-y-hidden ">
          <Select
            defaultValue="一括操作"
            className="w-full slc_merchandise mr-[10px]"
            // onChange={handleChange}
            options={[{ value: '一括操作', label: '一括操作' }]}
          />
          {/* <Button className="bg-white h-[30px] border-[0.5px] text-[14px] mplus1pnormal border-black mr-[10px]">
        {t('APPLY')}
      </Button> */}
          <Select
            defaultValue="filter by ranking"
            className="w-full slc_merchandise mr-[10px]"
            // onChange={handleChange}
            options={[{ value: 'filter by ranking', label: 'filter by ranking' }]}
          />
          <Select
            // defaultValue="カテゴリー一覧"
            placeholder="カテゴリー一覧"
            className="w-full slc_merchandise mr-[10px]"
            onChange={handleChangeSelectCate}
            options={arrOptionCateRef.current}
          />
          <Select
            placeholder=" 商品 のタイプでフィルター"
            className="w-full slc_merchandise mr-[10px]"
            onChange={handleChangeSelectTag}
            options={arrOptionTagRef.current}
          />
          <Select
            defaultValue="商品ストックステータスでフィルター "
            className="w-full slc_merchandise"
            // onChange={handleChange}
            options={[
              {
                value: '商品ストックステータスでフィルター  ',
                label: '商品ストックステータスでフィルター ',
              },
            ]}
          />
        </div>
      </div>
      <div className="mt-[25px] mb-[100px]">
        <TableProduct
          btnSearch={btnSearch}
          total={productWithFilter?.count}
          data={productWithFilter?.result}
          idCateSelect={idCateSelect}
          setIdCateSelect={setIdCateSelect}
          idTagSelect={idTagSelect}
          setIdTagSelect={setIdTagSelect}
          setBtnSearch={setBtnSearch}
          textSearch={textSearch}
          status="trash"
          ref={myRef}
        />
      </div>
      <ModalConfirm
        openModal={openModal}
        setOpenModal={setOpenModal}
        onConfirm={onDelete}
        record={recordRef.current}
        text={t('ARE_U_DELETE')}
      />
    </div>
  );
};
export default ProductTrashPage;
