import { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { push } from 'connected-react-router';
import { ENCODE_SECRET_KEY } from '../constants';
import {
  loginAPI,
  userInfoAPI,
  getLogsAPI,
  getAllUserByAdminAPI,
  deleteUserByAdminAPI,
  getUserInfoByAdminAPI,
  getUserAdminAPI,
  getUserAdminInfoAPI,
  addUserAdminAPI,
  deleteUserAdminAPI,
  searchUserAPI,
  exportCSVUsersAPI,
} from '../api';
import { successToast, errorToast, failedToast } from 'helpers';
import {
  fetchUserInfo,
  fetchUserInfoSuccess,
  fetchGetLogs,
  fetchGetLogsSuccess,
  fetchAllUserByAdmin,
  fetchAllUserByAdminSuccess,
  deleteUserByAdmin,
  deleteUserByAdminSuccess,
  fetchUserInfoByAdmin,
  fetchUserInfoByAdminSuccess,
  fetchUserAdmin,
  fetchUserAdminSuccess,
  fetchUserAdminInfo,
  fetchUserAdminInfoSuccess,
  addUserAdmin,
  addUserAdminSuccess,
  deleteUserAdmin,
  deleteUserAdminSuccess,
  searchUser,
  searchUserSuccess,
  exportCSVUsers,
  exportCSVUsersSuccess,
} from 'slices/userSlice';

import { login, loginSuccess } from 'slices/authSlice';

import { showLoading, hideLoading } from '../slices/commonSlice';

function* loginSaga(action: any) {
  try {
    yield put(showLoading('login-loading'));
    const res: AxiosResponse = yield call(loginAPI, { params: action.payload });

    const { data, errors } = res.data;

    if (data.loginAdmin?.ok) {
      successToast('ログインできました');

      Cookies.set(
        'EC_ADMIN_TOKEN',
        CryptoJS.AES.encrypt(data?.loginAdmin?.access_token, ENCODE_SECRET_KEY).toString(),
        {
          expires: action.payload.remember_me ? 365 : 7,
        }
      );
      yield put(push('/products'));
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      yield put(loginSuccess(data?.loginAdmin));
      yield put(hideLoading(false));
    } else {
      errorToast(`${errors[0].message}`);
      yield put(hideLoading(false));
    }
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}

function* userInfoSaga(action: any) {
  try {
    yield put(showLoading('user-info-loading'));
    const res: AxiosResponse = yield call(userInfoAPI, {
      params: action.payload,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(fetchUserInfoSuccess(data?.userAdminInfo));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* getLogSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(getLogsAPI, {
      limit: action.payload.limit,
      token: action.payload.token,
      type: action.payload.type,
    });
    const { data, errors } = res.data;
    yield put(fetchGetLogsSuccess(data?.getLogs));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* getAllUserByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(getAllUserByAdminAPI, {
      limit: action.payload.limit,
      token: action.payload.token,
      page: action.payload.page,
    });
    const { data, errors } = res.data;
    yield put(fetchAllUserByAdminSuccess(data?.getAllUserByAdmin));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* getUserInfoByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(getUserInfoByAdminAPI, {
      token: action.payload.token,
      id: action.payload.id,
    });
    const { data, errors } = res.data;
    yield put(fetchUserInfoByAdminSuccess(data?.userInfoByAdmin));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* deleteUserByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(deleteUserByAdminAPI, {
      token: action.payload.token,
      id: action.payload.id,
    });
    const { data, errors } = res.data;
    if (data?.deleteUserByAdmin?.ok === true) {
      successToast('Delete user info success');
      yield put(deleteUserByAdminSuccess(data?.deleteUserByAdmin?.data?.id));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* getUserAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(getUserAdminAPI, {
      token: action.payload.token,
      limit: action.payload.limit,
      page: action.payload.page,
    });
    const { data, errors } = res.data;
    yield put(fetchUserAdminSuccess(data?.usersAdmin));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* getUserAdminInfoSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(getUserAdminInfoAPI, {
      token: action.payload.token,
      id: action.payload.id,
    });
    const { data, errors } = res.data;
    yield put(fetchUserAdminInfoSuccess(data?.userAdminInfo));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* addUserAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(addUserAdminAPI, {
      token: action.payload.token,
      inputs: action.payload.inputs,
    });
    const { data, errors } = res.data;
    if (data?.addUserAdmin?.ok === true) {
      successToast('Add User Admin new success');
      yield put(addUserAdminSuccess(data?.addUserAdmin?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* deleteUserAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(deleteUserAdminAPI, {
      token: action.payload.token,
      id: action.payload.id,
    });
    const { data, errors } = res.data;
    if (data?.deleteUserAdmin?.ok === true) {
      successToast('Delete User Admin new success');
      yield put(deleteUserAdminSuccess(data?.deleteUserAdmin?.data?.id));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* searchUserSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(searchUserAPI, {
      token: action.payload.token,
      limit: action.payload.limit,
      page: action.payload.page,
      searchText: action.payload.searchText,
    });
    const { data, errors } = res.data;
    yield put(searchUserSuccess(data?.searchUser));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* exportCSVUsersSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(exportCSVUsersAPI, {
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(exportCSVUsersSuccess(data?.exportCSVUsers?.url));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
export default function* userSaga() {
  yield takeEvery(exportCSVUsers.type, exportCSVUsersSaga);
  yield takeLatest(login.type, loginSaga);
  yield takeEvery(fetchUserInfo.type, userInfoSaga);
  yield takeLatest(fetchGetLogs.type, getLogSaga);
  yield takeLatest(fetchAllUserByAdmin.type, getAllUserByAdminSaga);
  yield takeLatest(fetchUserInfoByAdmin.type, getUserInfoByAdminSaga);
  yield takeEvery(deleteUserByAdmin.type, deleteUserByAdminSaga);
  yield takeLatest(fetchUserAdmin.type, getUserAdminSaga);
  yield takeLatest(fetchUserAdminInfo.type, getUserAdminInfoSaga);
  yield takeLatest(addUserAdmin.type, addUserAdminSaga);
  yield takeEvery(deleteUserAdmin.type, deleteUserAdminSaga);
  yield takeLatest(searchUser.type, searchUserSaga);
}
