import { Dropdown, Menu, Tooltip, Button, message, Popconfirm } from 'antd';
import React, { useEffect } from 'react';
import type { MenuProps } from 'antd';
import logo from '../../assets/images/logoHENPS.png';
import * as svg from '../../constants/ui/svg';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getToken } from 'helpers';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { fetchUserInfo } from 'slices/userSlice';
import Cookies from 'js-cookie';

export default function HeaderComponent() {
  const text = '';
  const description = 'Delete the task';
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const confirm = () => {
  //   message.info('Clicked on Yes.');
  // };
  const userInfo = useAppSelector((state) => state.users.user);
  const token = getToken();
  useEffect(() => {
    if (token) {
      dispatch(fetchUserInfo({ token }));
    }
  }, [token]);
  return (
    <div className="bg-[rgba(176,194,132,0.05)] w-full">
      <div className="py-[25px] pl-[45px] pr-[76px] border-b-[2px] border-[#DCBA78] w-full flex items-center justify-between">
        <div className="flex items-center">
          <span onClick={() => history.push('/')}>{svg.iconHome}</span>
          <div className="mx-[35px] h-[50px] w-px bg-black"></div>
          <img src={logo} className="h-[50px] w-[218px]" />
        </div>
        <div className="flex items-center">
          {/* <span className="icon_belll cursor-pointer">{svg.iconBell}</span> */}
          {/* <span className="underline text-[20px] mplus1pmedium ml-[50px] cursor-pointer">
            Hi,Admin
          </span> */}
          <Popconfirm
            placement="bottom"
            title={<span className="hidden title_pop"></span>}
            description={
              <div className="flex flex-col">
                <span className="text-white cursor-pointer text-[16px] px-[10px] bg-[#B0C284] mplus1pmedium h-[40px] flex items-center justify-center">
                  {t('PROFILE')}
                  {t('EDIT')}
                </span>
                <span
                  onClick={() => {
                    // Cookies.remove('EC_TOKEN_ACCESS');
                    Cookies.remove('EC_ADMIN_TOKEN');
                    window.location.href = '/login';
                  }}
                  className="text-white cursor-pointer text-[16px] px-[10px] border-t-[0.5px] h-[40px] border-white bg-[#B0C284] mplus1pmedium flex items-center justify-center"
                >
                  {t('LOGOUT')}
                </span>
              </div>
            }
            // onConfirm={confirm}
            // okText="Yes"
            // cancelText="No"
            className="ml-[50px] pop_confirm hover:text-black"
          >
            <Button
              className="pop_confirm_btn"
              style={{ border: 'none', padding: '0px', boxShadow: 'none' }}
            >
              {' '}
              <span className="underline hover:text-black text-[20px] mplus1pmedium cursor-pointer">
                Hi,{userInfo?.userName}
              </span>
            </Button>
          </Popconfirm>
        </div>
      </div>
    </div>
  );
}
