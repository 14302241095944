import { useEffect, useRef, useState } from 'react';
import * as svg from '../../constants/ui/svg';
import logo from '../../assets/images/logoHENPS.png';
import { Button, Checkbox, Form, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

// Hook
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

// Action
import { login } from 'slices/authSlice';
import { ISSERVER } from '../../constants';

export default function LoginPage() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [show, setShow] = useState(false);
  const linkLocalStorageRef = useRef<any>([]);
  if (!ISSERVER) {
    linkLocalStorageRef.current = localStorage.getItem('account_submit') || null;
  }
  const onFinishSubmit = (values: any) => {
    if (values.username === 'henps' && values.password === 'test') {
      setShow(true);
      localStorage.setItem('account_submit', values.username + values.password);
    }
  };
  useEffect(() => {
    const acc = localStorage.getItem('account_submit');
    if (acc) {
      setShow(true);
    }
  }, [linkLocalStorageRef.current]);
  const [loginState, setLoginState] = useState<any>({
    keyLogin: null,
    password: null,
    // remember_me: false,
  });

  const dispatch = useAppDispatch();

  const onFinish = (values: any) => {
    dispatch(
      login({
        ...loginState,
        keyLogin: values.keyLogin,
        password: values.password,
      })
    );
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <div className="bg-[rgba(176,194,132,0.05)] w-full">
      <div className="py-[25px] pl-[66px] border-b-[2px] border-[#DCBA78] w-full flex items-center">
        <img src={logo} className="h-[50px] w-[218px]" />
        <div className="mx-[45px] h-[50px] w-px bg-black"></div>
        <span className="text-[24px] mplus1pmedium text-[#3E6100]">{t('DASBOARD')}</span>
      </div>
      <div className="w-full flex justify-center pt-[10%] pb-[30%]">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="w-[30%] border bg-white border-black/20 py-[2%] px-[2%] flex flex-col items-center"
        >
          <span className="text-[24px] mplus1pextrabold">{t('LOGIN')}</span>
          <Form.Item
            className="w-full"
            name="keyLogin"
            rules={[
              // {
              //   type: 'email',
              //   message: <span>*{t('EMAIL_FORMAT_INCORRECT')}</span>,
              // },
              {
                required: true,
                message: <span>*{t('PLS_ENTER_YOUR_EMAIL')}</span>,
              },
            ]}
          >
            <Input
              placeholder={t('USER_EMAIL')}
              className="w-full focus:outline-0 h-[45px] pl-[14px] border border-black/30 shadow-[0px_4px_4px_rgba(0,0,0,0.15)] mt-[50px]"
            />
          </Form.Item>

          <Form.Item
            className="w-full mt-[30px]"
            name="password"
            rules={[
              {
                required: true,
                message: <span>*{t('PLS_ENTER_YOUR_PASSWORD')}</span>,
              },
            ]}
          >
            <Input.Password
              placeholder={t('PASSWORD')}
              className="!w-full flex items-center px-[10px] border focus:border-white !h-[45px] border-black/30 shadow-[0px_4px_4px_rgba(0,0,0,0.15)]"
            />
          </Form.Item>
          <Form.Item className="w-full">
            <Button
              type="primary"
              htmlType="submit"
              className="w-full mt-[33px] !hover:bg-[#2D4600] h-[45px] flex justify-center items-center bg-[#2D4600]"
            >
              <span className="text-white text-[18px] mplus1pextrabold">{t('LOGIN')}</span>
            </Button>
          </Form.Item>
          <Form.Item className="mt-[48px]" name="remember" valuePropName="checked">
            <Checkbox className="flex items-center ckb_pass">
              <span className="text-[12px] opacity-60">{t('STAY_LOGGED_IN')}</span>
            </Checkbox>
          </Form.Item>
          <span className="mt-[33px] underline text-[16px] mplus1pmedium">
            ※{t('FORGOT_YOUR_PASSWORD')}
          </span>
        </Form>
      </div>
    </div>
  );
}
