import { Input, Modal, Popconfirm, Select, Spin, Table } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useRef, useState } from 'react';
import type { RcFile } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import * as cts from '../../constants/ui/svg';
import { useDispatch } from 'react-redux';
import { getToken } from 'helpers';
import {
  addCategory,
  deleteCategory,
  fetchAllCategoryByAdmin,
  updateCategory,
} from 'slices/categorySlice';
import { errorToast } from 'helpers';
import { useAppSelector } from 'redux/hooks';
import { CategoryTypes } from '../../models/category';
import { URL_UPLOAD } from '../../constants';
import ModalConfirm from 'components/Common/ModalConfirm';

const CategoryMerchandisePage = () => {
  const { TextArea } = Input;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = getToken();
  const allCategoryByAdmin = useAppSelector((state) => state.categories.getAllCategoryByAdmin);
  const arrCate = allCategoryByAdmin?.result?.map((item: any, idx: any) => {
    return {
      ...item,
      key: idx,
    };
  });
  const [openModal, setOpenModal] = useState(false);
  const [openModalDel, setOpenModalDel] = useState(false);

  const recordRef = useRef<any>('');
  const inputRef = useRef<any>();
  const [viewSlc, setViewSlc] = useState('');
  const [categories, setCategories] = useState<any>();
  const [expandedKey, setExpandedKey] = useState<any>(null);
  const [fileDataURL, setFileDataURL] = useState();
  const pageSize = 10;
  const [pagi, setPagi] = useState<any>({
    allCategoryByAdmin: [],
    totalPage: allCategoryByAdmin?.count / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });

  const [dataCate, setDataCate] = useState({
    name: '',
    slug: '',
    description: '',
  });
  const [idRecord, setIdRecord] = useState('');
  const loading = useAppSelector((state) => state.common.loading);
  const [btnLoad, setBtnLoad] = useState<any>({ load: loading, status: '' });
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: CategoryTypes[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: CategoryTypes) => ({
      // disabled: record.name === 'Disabled User', // Column configuration not to be checked
      // name: record.name,
    }),
  };

  const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
  const [fileUpload, setFileUpload] = useState<any>();
  const [fileUploadAdd, setFileUploadAdd] = useState<any>();
  const [fileDataURLAdd, setFileDataURLAdd] = useState();

  const onDelete = (record: any) => {
    dispatch(deleteCategory({ token, id: record?._id }));
  };
  const columns: ColumnsType<CategoryTypes> = [
    {
      title: <div className="flex justify-center w-full ckb_table"></div>,
      key: 'operation',
      fixed: 'left',
      width: 0,
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full"></span>,
      width: 100,
      dataIndex: 'images',
      key: 'images',
      render: (value: any) => (
        <span className="w-full flex justify-center">
          {' '}
          <img className="h-[50px] w-[50px]" src={`${URL_UPLOAD}/categories/${value}`} />
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal text-start">{t('GIVEN_NAME')}</span>,
      width: 150,
      dataIndex: 'name',
      key: 'name',
      render: (value: any) => (
        <span className="text-[12px] mplus1pmedium text-[#0038FF] items-start">{value}</span>
      ),
    },
    Table.EXPAND_COLUMN,
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">DESCRIPTION</span>
      ),
      dataIndex: 'description',
      key: 'description',
      width: 100,
      render: (value: any) => <span className="flex justify-center w-full">{value}</span>,
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">SLUG</span>,
      dataIndex: 'slug',
      key: 'slug',
      width: 100,
      render: (value: any) => (
        <span className="text-[12px] text-[#3E6100] mplus1pmedium flex justify-center w-full">
          {value}
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">COUNT</span>,
      dataIndex: 'productCount',
      key: 'productCount',
      fixed: 'right',
      width: 100,
      render: (value: any) => <span className=" flex justify-center w-full">{value}</span>,
    },
  ];
  const onValueChange = (value: string, name: string, record: CategoryTypes) => {
    const foundCategory = categories?.find((category: any) => category?._id === record?._id);
    let newObj = { ...foundCategory };
    newObj[name] = value;
    const newArray = categories.map((category: any) =>
      category._id === record._id ? newObj : category
    );
    setCategories(newArray);
  };
  const onAddCate = () => {
    if (
      dataCate.name !== '' &&
      dataCate.description !== '' &&
      dataCate.slug !== '' &&
      fileUploadAdd !== null
    ) {
      setBtnLoad({ ...btnLoad, status: 'add' });
      dispatch(addCategory({ token, inputs: { ...dataCate }, fileUpload: fileUploadAdd }));
      setTimeout(() => {
        setDataCate({
          name: '',
          description: '',
          slug: '',
        });
        setFileUploadAdd(null);
      }, 1000);
    } else {
      errorToast('Please input enough value');
    }
  };
  const onExpand = (_: any, { key }: any) => setExpandedKey(key);
  const changeHandler = (e: any) => {
    const file = e.target.files[0];
    // if (!file?.type?.match(imageMimeType)) {
    //   alert('Image mime type is not valid');
    //   return;
    // }
    setFileUpload(file);
  };
  const changeHandlerAdd = (e: any) => {
    const file = e.target.files[0];
    setFileUploadAdd(file);
  };
  useEffect(() => {
    if (loading === false) {
      setBtnLoad({ ...btnLoad, load: false });
    } else {
      setBtnLoad({ ...btnLoad, load: true });
    }
  }, [loading]);
  useEffect(() => {
    let fileReader = new FileReader(),
      isCancel = false;
    if (fileUpload) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(fileUpload);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileUpload]);
  useEffect(() => {
    let fileReader = new FileReader(),
      isCancel = false;
    if (fileUploadAdd) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURLAdd(result);
        }
      };
      fileReader.readAsDataURL(fileUploadAdd);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileUploadAdd]);
  useEffect(() => {
    if (arrCate?.length > 0) {
      setCategories(arrCate);
    } else {
      setCategories(allCategoryByAdmin?.result);
    }
  }, [arrCate?.length]);

  useEffect(() => {
    dispatch(fetchAllCategoryByAdmin({ token, limit: pageSize, page: pagi.current }));
  }, [dispatch, token, pagi.current]);

  return (
    <div className="pt-[26px] px-[35px] mb-[100px]">
      <span className="text-[20px] mplus1pextrabold">
        {t('ADD_NEW')} {t('CATEGORY')}
      </span>
      <div className="flex mt-[40px] w-full pb-[100px]">
        <div className="w-[30%] flex flex-col pr-[2%]">
          <span className="text-[14px] mplus1pnormal">{t('GIVEN_NAME')}</span>
          <Input
            value={dataCate.name}
            onChange={(e) => setDataCate({ ...dataCate, name: e.target.value })}
            className="h-[39px] rounded-[0px] border border-[rgba(0,0,0,0.5)] w-full mt-[10px]"
          />
          <span className="text-[10px] text-[#444444] mplus1pnormal">
            {t('THIS_IS_NAME_APPEAR')}
          </span>
          <span className="mt-[20px] text-[14px] mplus1pnormal">{t('SLUG')}</span>
          <Input
            value={dataCate.slug}
            onChange={(e) => setDataCate({ ...dataCate, slug: e.target.value })}
            className="h-[39px] rounded-[0px] border border-[rgba(0,0,0,0.5)] w-full mt-[10px]"
          />
          <span className="text-[10px] text-[#444444] mplus1pnormal">{t('TEXT_CATE')}</span>
          <span className="mt-[20px] text-[14px] mplus1pnormal">{t('PARENT_CATE')}</span>
          <Select className="w-[50%] mt-[10px] slc_cate" />
          <span className="text-[10px] text-[#444444] mplus1pnormal">{t('SPECIFY_TEXT')}</span>
          <span className="text-[14px] mplus1pnormal mt-[20px]">{t('EXPLANATION')}</span>
          <TextArea
            value={dataCate.description}
            onChange={(e) => setDataCate({ ...dataCate, description: e.target.value })}
            // value={value}
            // onChange={(e) => setValue(e.target.value)}
            autoSize={{ minRows: 3, maxRows: 5 }}
            className="mt-[10px] rounded-[0px] border border-[rgba(0,0,0,0.5)]"
          />
          <span className="text-[10px] text-[#444444] mplus1pnormal">{t('BY_DEFAULT')}</span>
          <span className="mt-[30px] text-[14px] mplus1pnormal text-[#FF0000]">
            Home page background image
          </span>
          <div className="w-[145px] h-[145px] mt-[10px] bg-white flex justify-center items-center border border-[rgba(0,0,0,0.5)]">
            {fileUploadAdd ? (
              <div className="h-[130px] w-[130px] relative mx-[5px] border border-black/30">
                <img className="h-[130px] w-[130px]  absolute" src={fileDataURLAdd} />

                <div className="absolute h-full w-full pt-[4px] pr-[4px] opacity-0 hover:opacity-100 flex justify-end">
                  <span
                    onClick={() => {
                      setOpenModalDel(true);
                    }}
                    className=" svg_close_img h-[22px] w-[22px] rounded-[11px] flex items-center justify-center bg-[#D9D9D9]/50 border border-black/20"
                  >
                    {cts.iconClose}
                  </span>
                </div>
              </div>
            ) : (
              <div className="relative flex items-center h-[132px] w-[132px]">
                <div className="absolute h-[132px] w-[132px] border border-black/30">
                  <span className="text-[12px]  h-full items-center cursor-pointer mplus1pnormal text-[#0038FF] absolute w-[132px] flex justify-center">
                    + upload new image
                    <Input
                      ref={inputRef}
                      accept=".png, .jpg, .jpeg .webp"
                      type="file"
                      onChange={changeHandlerAdd}
                      className="h-[132px] w-[132px] absolute text-white bg-white inp_upload opacity-0"
                    />
                  </span>
                </div>
              </div>
            )}
          </div>
          <span className="mt-[20px] text-[14px] mplus1pnormal">{t('DISPLAY_TYPE')}</span>
          <Select className="w-[50%] mt-[10px] slc_cate" />
          <div className="relative w-[59px] flex items-center justify-center mt-[30px]">
            {btnLoad.load === true && btnLoad.status === 'add' && (
              <Spin className="absolute w-[59px] h-full items-center flex justify-center" />
            )}
            <span
              onClick={onAddCate}
              className={`${
                btnLoad.load === true && btnLoad.status === 'add' ? ' opacity-20 absolute' : ''
              } h-[39px] border rounded-[10px] border-[#000000] w-[59px] bg-[#3E6100] text-white text-[14px] mplus1pextrabold flex items-center justify-center cursor-pointer`}
            >
              {t('ADDITON')}
            </span>
          </div>
          {/* <span
            onClick={onAddCate}
            className="h-[39px] border rounded-[10px] border-[#000000] w-[59px] bg-[#3E6100] mt-[30px] text-white text-[14px] mplus1pextrabold flex items-center justify-center cursor-pointer"
          >
            {t('ADDITON')}
          </span> */}
        </div>
        <div className="w-[70%] pl-[2%] flex flex-col">
          <div className="flex">
            <Select
              defaultValue="一括操作"
              className="w-[150px] slc_catego"
              options={[{ value: '一括操作', label: '一括操作' }]}
            />
            {/* <span className="h-[39px] ml-[38px] cursor-pointer w-[81px] bg-white text-[14px] mplus1pnormal border text-[#0038FF] border-[#0038FF] rounded-[10px] flex justify-center items-center">
              {t('APPLY')}
            </span> */}
          </div>
          <div className="mt-[17px]">
            <Table
              className="table_merchandise ckb_brand"
              columns={columns}
              scroll={{ x: 500 }}
              pagination={{
                showSizeChanger: false,
                total: allCategoryByAdmin?.count,
                pageSize: 10,
                current: pagi.current,

                onChange: (page: any) => {
                  setExpandedKey(null);
                  setPagi({
                    current: page,
                    minIndex: (page - 1) * pageSize,
                    maxIndex: page * pageSize,
                  });
                },
              }}
              dataSource={arrCate}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (idRecord === record?._id) return;
                    setIdRecord(record?._id as string);
                  }, // click row
                };
              }}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              expandable={{
                expandedRowKeys: [expandedKey],
                onExpand: (expanded, record) => onExpand(expanded, record),
                expandedRowRender: (record) => {
                  return (
                    <div key={record?.key} className="flex flex-col pl-[10%] py-[5%]">
                      <div className="flex items-center">
                        <span className="w-[30%] flex justify-start">カテゴリー名</span>
                        <div className="w-[70%">
                          <Input
                            // value={dataUpdateCate?.name}
                            defaultValue={record.name}
                            onChange={(e) => onValueChange(e.target.value, 'name', record)}
                            className="h-[27px]"
                          />
                        </div>
                      </div>
                      <div className="flex items-center mt-[10px]">
                        <span className="w-[30%] flex justify-start">Slug</span>
                        <div className="w-[70%">
                          <Input
                            defaultValue={record?.slug}
                            onChange={(e) => onValueChange(e.target.value, 'slug', record)}
                            className="h-[27px]"
                          />
                        </div>
                      </div>
                      <div className="flex mt-[10px]">
                        <span className="w-[30%] flex justify-start">背景画像</span>
                        <div className="w-[70%">
                          <img
                            className="h-[130px] w-[130px] opacity-25 absolute"
                            src={
                              fileUpload
                                ? fileDataURL
                                : `${URL_UPLOAD}/categories/${record?.images}`
                            }
                          />
                          <div className="relative flex items-center h-[132px] w-[132px]">
                            <div className="absolute border border-[rgba(0,0,0,0.2)] h-[132px] w-[132px]">
                              <span className="text-[12px] h-full items-center cursor-pointer mplus1pnormal text-[#0038FF] absolute w-[132px] flex justify-center">
                                +画像を変更する
                                <Input
                                  ref={inputRef}
                                  accept=".png, .jpg, .jpeg .webp"
                                  type="file"
                                  onChange={changeHandler}
                                  className="h-[132px] w-[132px] absolute text-white bg-white inp_upload opacity-0"
                                />
                                {/* <img
                                  className="h-[130px] w-[130px] opacity-25 absolute"
                                  src={
                                    // fileUpload
                                    //   ? fileDataURL
                                    //   :
                                    `${URL_UPLOAD}/categories/${record?.images}`
                                  }
                                /> */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-[30px]">
                        <div className="relative w-[54px] flex items-center justify-center">
                          {btnLoad.load === true && btnLoad.status === 'update' && (
                            <Spin className="absolute w-[54px] h-full items-center flex justify-center" />
                          )}
                          <span
                            onClick={() => {
                              setBtnLoad({ ...btnLoad, status: 'update' });

                              const foundCategory = categories?.find(
                                (category: any) => category?._id === record?._id
                              );
                              if (foundCategory?.name !== '' && foundCategory?.slug !== '') {
                                dispatch(
                                  updateCategory({
                                    token,
                                    id: record?._id,
                                    inputs: {
                                      name: foundCategory?.name,
                                      slug: foundCategory?.slug,
                                    },
                                    fileUpload,
                                  })
                                );
                                setTimeout(() => {
                                  setFileUpload(null);
                                }, 1000);
                              } else {
                                errorToast('空白のままにしないでください');
                              }
                            }}
                            className={`${
                              btnLoad.load === true && btnLoad.status === 'update'
                                ? ' opacity-20 absolute'
                                : ''
                            } bg-[#3E6100] cursor-pointer text-white flex items-center justify-center rounded-[10px] h-[39px] w-[54px] text-[14px] mplus1pextrabold`}
                          >
                            {t('UPDATE')}
                          </span>
                        </div>

                        <div
                          onClick={() => {
                            setExpandedKey(null);
                            setFileUpload(null);
                          }}
                          className="border cursor-pointer border-[#3E6100] text-[#3E6100] flex items-center justify-center rounded-[10px] h-[39px] px-[15px] text-[14px] mplus1pmedium ml-[20px] p-[12px]"
                        >
                          {t('CANCEL')}
                        </div>
                      </div>
                    </div>
                  );
                },
                expandIcon: ({ expanded, onExpand, record }) => (
                  <Popconfirm
                    title="aaaa"
                    open={idRecord === record?._id}
                    placement="bottom"
                    description={() => {
                      return (
                        <div className="bg-[#DCBA78] py-[5px] flex flex-col rounded-[5px]">
                          <span
                            onClick={(e) => {
                              setViewSlc('edit');
                              setIdRecord('');
                              onExpand(record, e);
                              setFileUpload(null);
                            }}
                            className={`${
                              viewSlc === 'edit' && 'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] hover:text-white hover:bg-[#3E6100] hover:rounded-[5px] hover:mx-[2px] mt-[5px] flex items-center text-[14px] mplus1pnormal`}
                          >
                            {t('EDIT')}
                          </span>

                          <span
                            onClick={(e) => {
                              setViewSlc('remove_cate');
                              recordRef.current = record;
                              setIdRecord('');
                              setOpenModal(true);
                            }}
                            className={`${
                              viewSlc === 'remove_cate' &&
                              'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] hover:text-white hover:bg-[#3E6100] hover:rounded-[5px] hover:mx-[2px] mt-[5px] flex items-center text-[14px] mplus1pnormal`}
                          >
                            削除する
                          </span>
                        </div>
                      );
                    }}
                    onConfirm={() => {
                      // message.info('Clicked on Yes.');
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button
                      onClick={() => {
                        if (idRecord === record?._id) {
                          setIdRecord('');
                        }
                      }}
                      key={record?.key}
                      className="h-[20px] w-[20px] rounded-[2px] bg-[#DCBA78] flex justify-center items-center"
                    >
                      {cts.iconDot}
                    </button>
                  </Popconfirm>
                ),
              }}
            />
          </div>
        </div>
      </div>
      <ModalConfirm
        openModal={openModal}
        setOpenModal={setOpenModal}
        onConfirm={onDelete}
        record={recordRef.current}
        text="カテゴリを削除してもよろしいですか?"
      />
      <Modal
        mask={false}
        centered
        open={openModalDel}
        className="modal_delete h-[270px] !w-[375px]"
        //   onOk={() => setOpenModal(false)}
        //   onCancel={() => setOpenModal(false)}
      >
        <div className="flex flex-col justify-between items-center h-full">
          <span>{cts.iconWarning}</span>
          <span className="text-[14px] mplus1pmedium">このイメージを削除してもよろしいですか?</span>
          <div className="flex items-center w-full">
            <span
              className="h-[34px] rounded-[5px] flex items-center justify-center bg-[#9B0101] cursor-pointer text-white text-[14px] mplus1pextrabold w-full mr-[5px]"
              onClick={() => {
                setFileUploadAdd(null);
                setOpenModalDel(false);
              }}
            >
              {t('DELETE')}
            </span>
            <span
              className="h-[34px] rounded-[5px] flex items-center justify-center bg-[#3E6100] cursor-pointer text-white text-[14px] mplus1pextrabold w-full ml-[5px]"
              onClick={() => setOpenModalDel(false)}
            >
              {t('CANCEL')}
            </span>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default CategoryMerchandisePage;
