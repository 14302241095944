import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { deleteProductAPI } from 'api';
import Cookies from 'js-cookie';
import {
  ProductTypes,
  TypeProductList,
  ProductReviewTypes,
  TypeProductReviewList,
  ProductBrandTypes,
  ProductReviewByAdminTypes,
  TypesProductReviewByAdminList,
  AllProductBrandTypes,
  TypesAllProductBrandList,
} from 'models';
import { RootState } from 'redux/store';
export interface productsType {
  productList: TypeProductList;
  productSearchList: TypeProductList;
  ProductReviewsByAdminList: [];
  productReviewsById: TypeProductReviewList;
  productInfoByAdmin: {};
  productBrands: ProductBrandTypes[];
  getProductReviewsByAdmin: TypesProductReviewByAdminList;
  getAllProductBrand: TypesAllProductBrandList;
}
const initialState: productsType = {
  productList: {
    count: 0,
    result: [] as ProductTypes[],
  },
  productSearchList: {
    count: 0,
    result: [] as ProductTypes[],
  },
  ProductReviewsByAdminList: [],
  productReviewsById: {
    count: 0,
    avgPoint: 0,
    result: [] as ProductReviewTypes[],
  },
  productInfoByAdmin: {},
  productBrands: [],
  getProductReviewsByAdmin: {
    count: 0,
    spamCount: 0,
    trashCount: 0,
    publicCount: 0,
    result: [] as ProductReviewByAdminTypes[],
  },
  getAllProductBrand: {
    count: 0,
    result: [] as AllProductBrandTypes[],
  },
};

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    fetchProductsWithFilter(state, action) {},
    fetchProductsWithFilterSuccess(state, action) {
      state.productList = action.payload;
    },
    fetchSearchProductByAdmin(state, action) {},
    fetchSearchProductByAdminSuccess(state, action) {
      state.productSearchList = action.payload;
    },
    deleteProduct(state, action) {},
    deleteProductSuccess(state, action) {
      state.productList.result = state.productList.result.filter((x) => x._id !== action.payload);
    },
    cloneProduct(state, action) {},
    cloneProductSuccess(state, action) {
      state.productList.result.push(action.payload);
    },
    updateProduct(state, action) {},
    updateProductSuccess(state, action) {
      state.productList.result = state.productList.result.map((item: any) =>
        item._id === action.payload._id ? action.payload : item
      );
      // state.productInfoByAdmin = action.payload;
    },
    updateProductStatus(state, action) {},
    updateProductStatusSuccess(state, action) {
      state.productList.result = state.productList.result.filter(
        (item: any) => item._id !== action.payload._id
      );
    },
    addProduct(state, action) {},
    addProductSuccess(state, action) {},
    fetchProductReviewByAdmin(state, action) {},
    fetchProductReviewByAdminSuccess(state, action) {
      state.ProductReviewsByAdminList = action.payload;
    },
    fetchProductReviewByAdminList(state, action) {},
    fetchProductReviewByAdminListSuccess(state, action) {
      state.getProductReviewsByAdmin = action.payload;
    },
    changeReviewStatus(state, action) {},
    changeReviewStatusSuccess(state, action) {
      state.getProductReviewsByAdmin.result = state.getProductReviewsByAdmin.result.filter(
        (item: any) => item.id !== action.payload.id
      );
      state.productReviewsById.result = state.productReviewsById.result.filter(
        (x: any) => x.id !== action.payload.id
      );
      state.productReviewsById.count = state.productReviewsById.count - 1;
      state.getProductReviewsByAdmin.count = state.getProductReviewsByAdmin.count - 1;
      if (action.payload?.status === 'spam') {
        state.getProductReviewsByAdmin.spamCount = state.getProductReviewsByAdmin.spamCount + 1;
      }
      if (action.payload?.status === 'trash') {
        state.getProductReviewsByAdmin.trashCount = state.getProductReviewsByAdmin.trashCount + 1;
      } else {
        state.getProductReviewsByAdmin.publicCount = state.getProductReviewsByAdmin.publicCount + 1;
      }
    },
    deleteProductReviewByAdmin(state, action) {},
    deleteProductReviewByAdminSuccess(state, action) {
      state.getProductReviewsByAdmin.result = state.getProductReviewsByAdmin.result.filter(
        (x) => x.id !== action.payload
      );
    },
    changeManyReviews(state, action) {},
    changeManyReviewsSuccess(state, action) {
      state.getProductReviewsByAdmin.result = state.getProductReviewsByAdmin.result.filter(
        function (el, idx) {
          return !action.payload.some((x: any) => el.id === x.id);
        }
      );
    },
    fetchProductReviewById(state, action) {},
    fetchProductReviewByIdSuccess(state, action) {
      state.productReviewsById = action.payload;
    },
    fetchProductInfoByAdmin(state, action) {
      state.productInfoByAdmin = {};
    },
    fetchProductInfoByAdminSuccess(state, action) {
      state.productInfoByAdmin = action.payload;
    },
    fetchProductBrands(state, action) {},
    fetchProductBrandsSuccess(state, action) {
      state.productBrands = action.payload;
    },
    addProductBrand(state, action) {},
    addProductBrandSuccess(state, action) {
      state.productBrands.push(action.payload);
    },
    addProductBrandByAdmin(state, action) {},
    addProductBrandByAdminSuccess(state, action) {
      state.getAllProductBrand.result.push(action.payload);
    },
    updateProductBrand(state, action) {},
    updateProductBrandSuccess(state, action) {
      state.getAllProductBrand.result = state.getAllProductBrand.result.map((item: any) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    deleteProductBrand(state, action) {},
    deleteProductBrandSuccess(state, action) {
      state.getAllProductBrand.result = state.getAllProductBrand.result.filter(
        (x) => x.id !== action.payload
      );
    },
    fetchAllProductBrand(state, action) {},
    fetchAllProductBrandSuccess(state, action) {
      state.getAllProductBrand = action.payload;
    },
    updateProductReviewByAdmin(state, action) {},
    updateProductReviewByAdminSuccess(state, action) {
      state.getProductReviewsByAdmin.result = state.getProductReviewsByAdmin.result.map(
        (item: any) => (item?.id === action.payload.id ? action.payload : item)
      );
    },
    addProductReviewByAdmin(state, action) {},
    addProductReviewByAdminSuccess(state, action) {
      state.getProductReviewsByAdmin.result = state.getProductReviewsByAdmin.result.map(
        (item: any) => (item?.id === action.payload.id ? action.payload : item)
      );
    },
    deleteProductPreviewByAdmin(state, action) {},
    deleteProductPreviewByAdminSuccess(state, action) {
      state.getProductReviewsByAdmin.result = state.getProductReviewsByAdmin.result.map(
        (item: any) => (item?.id === action.payload.id ? action.payload : item)
      );
    },
  },
});

// Actions
export const {
  deleteProductPreviewByAdmin,
  deleteProductPreviewByAdminSuccess,
  updateProductReviewByAdmin,
  updateProductReviewByAdminSuccess,
  addProductReviewByAdmin,
  addProductReviewByAdminSuccess,
  addProductBrandByAdmin,
  addProductBrandByAdminSuccess,
  fetchAllProductBrand,
  fetchAllProductBrandSuccess,
  changeManyReviews,
  changeManyReviewsSuccess,
  deleteProductReviewByAdmin,
  deleteProductReviewByAdminSuccess,
  changeReviewStatus,
  changeReviewStatusSuccess,
  fetchProductReviewByAdminList,
  fetchProductReviewByAdminListSuccess,
  deleteProductBrand,
  deleteProductBrandSuccess,
  updateProductBrand,
  updateProductBrandSuccess,
  addProductBrand,
  addProductBrandSuccess,
  fetchProductBrands,
  fetchProductBrandsSuccess,
  updateProductStatus,
  updateProductStatusSuccess,
  fetchProductsWithFilter,
  fetchProductsWithFilterSuccess,
  fetchSearchProductByAdmin,
  fetchSearchProductByAdminSuccess,
  deleteProduct,
  deleteProductSuccess,
  cloneProduct,
  cloneProductSuccess,
  updateProduct,
  updateProductSuccess,
  addProduct,
  addProductSuccess,
  fetchProductReviewByAdmin,
  fetchProductReviewByAdminSuccess,
  fetchProductReviewById,
  fetchProductReviewByIdSuccess,
  fetchProductInfoByAdmin,
  fetchProductInfoByAdminSuccess,
} = productSlice.actions;
// Selectors
export const selectProduct = (state: RootState) => state.products;

// Reducer
const productReducer = productSlice.reducer;
export default productReducer;
