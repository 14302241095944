export const URL_PRODUCTS_WITH_FILTER = `
  query getProductWithFilter($page: Int!,$limit: Int!,$inputs: QueryWithFilter){
    productsWithFilter(page: $page, limit: $limit,inputs: $inputs){
      count,
      countAll,
      draftCount,
      publicCount,
      trashCount,
      result{
      _id,
      image,
      name,
      code,
      numberInStock,
      stockStatus,
      price,
      categoryId{
        _id,
        name,
        
      },
      tags{
        id,
        name,
       
      },
      numberOfReview,
      orderBy,
      createdAt,
      status,
      slug,
      weight,
      length,
      width,
      height,
      reviewAble,
      marketPrice,
      brandId{
        id,
        name
      }
      }
  }
  }
`;
export const URL_SEARCH_PRODUCTS_BY_ADMIN = `
  query searchProductByAdmin($searchText: String!,$page: Int!,$limit: Int!){
    searchProductByAdmin(searchText: $searchText,page: $page, limit: $limit){
      count,
      result{
      _id,
      image,
      name,
      code,
      numberInStock,
      stockStatus,
      price,
      categoryId{
        _id,
        name,
        
      },
      tags{
        id,
        name,
       
      },
      numberOfReview,
      orderBy,
      createdAt,
      status
      }
  }
  }
`;
export const URL_DELETE_PRODUCT = `
  mutation deleteProduct($id: ID!){
    deleteProduct(id: $id){
      data {
        _id,
      image,
      name,
      code,
      numberInStock,
      stockStatus,
      price,
      orderBy,
      createdAt,
      status
      },
      error,
      ok
    }
  }
`;
export const URL_CLONE_PRODUCT = `
  mutation cloneProduct($id: ID!){
    cloneProduct(id: $id){
      data {
        _id,
        image,
        name,
        code,
        numberInStock,
        stockStatus,
        price,
        categoryId{
          _id,
          name,
          
        },
        tags{
          id,
          name,
         
        },
        numberOfReview,
        orderBy,
        createdAt,
        status,
        slug,
        weight,
        length,
        width,
        height,
        reviewAble,
        marketPrice,
        brandId{
          id,
          name
        }
      },
      error,
      ok
    }
  }
`;
export const URL_UPDATE_PRODUCT = `
mutation updateProduct($id: ID!,
  $inputs:inputUpdateProduct,$fileUpload: Upload, $files: [Upload],$multipleImageDelete:[ID]) {
  updateProduct(id:$id,inputs:$inputs,fileUpload:$fileUpload, files: $files,multipleImageDelete:$multipleImageDelete){
		data{
      _id,
      image,
      name,
      code,
      description,
      content,
      numberInStock,
      stockStatus,
      price,
      categoryId{
        _id,
        name,
        
      },
      tags{
        id,
        name,
       
      },
      numberOfReview,
      orderBy,
      createdAt,
      status,
      slug,
      weight,
      length,
      width,
      height,
      reviewAble,
      salePrice,
    saleStartTime,
    saleEndTime
      marketPrice,
      brandId{
        id,
        name
      }
    },
    error,
    ok
  }
}
`;
export const URL_ADD_PRODUCT = `
mutation addProduct($inputs:inputCreateProduct,$fileUpload: Upload, $files: [Upload]){
  addProduct(inputs:$inputs,fileUpload:$fileUpload, files: $files){
	data{
		_id,
    name,
    multiImages,
    description,
    price,
    code,
    content,
    length,
    width,
    height,
    weight,
    status
  },
    error,
    ok
  }
}
`;
export const URL_PRODUCT_REVIEW_BY_ADMIN = `
query getProductReviewsByAdmin($limit:Int!,$page:Int!,$productId: ID,$point: Float,$status: String){
	getProductReviewsByAdmin(limit:$limit,page:$page,productId:$productId ,point:$point,status:$status){
		count,
    publicCount,
    spamCount,
    trashCount,
    result{
      id,
			point,
      content,
      createdAt,
      replies{
        id
        content
        point
        status
        
        productId{
          name
        }
        customerId{
          id
          userName
          lastName
        }
      }
      productId{
        name
      }
      customerId{
				userName,
        id,
        email,
        firstName,
        lastName
      },

    }
  }
}
`;
export const URL_PRODUCT_REVIEW_BY_ID = `
query productReviewsById($limit:Int!,$page:Int!,$productId: ID){
  productReviewsById(limit:$limit,page:$page,productId:$productId){
		avgPoint,
    count,
    result{
      id,
      status,
			customerId{
				avatar,
        userName,
        firstName,
        lastName,
      },
      point,
      content,
      updatedAt
    }
  }
}
`;
export const URL_PRODUCT_INFO_BY_ADMIN = `
query productInfoByAdmin($id: ID!){
	productInfoByAdmin(id:$id){
    MultipleImageProduct{image,_id,productId{_id}},
		_id,
    numberInStock,
    stockStatus,
    numberOfReview,
    name,
    alias,
    code,
    price,
    marketPrice,
    isHot,
    is_new,
    isTop,
    image,
    description,
    element,
    content,
    orderBy,
    seoTitle,
    seoDescription,
    reviewAble,
    taxAble,
    slug,
    weight,
    length,
    width,
    height,
    status,
    createdAt,
    updatedAt,
    multiImages,
    salePrice,
    saleStartTime,
    saleEndTime,
    brandId{
      id,
      name
    },
    categoryId{
      _id,
      name,
      
    },
    tags{
      id,
      name,
     
    },
  }
}
`;
export const URL_PRODUCT_BRANDS = `
  query productBrands($limit: Int){
    productBrands(limit:$limit){
      id,
      name,
      slug,
      description,
      productCount,
      createdAt,
      updatedAt
    }
  }
`;
export const URL_ADD_PRODUCT_BRAND = `
  mutation addProductBrand($inputs: inputCreateBrand){
    addProductBrand(inputs:$inputs){
      data{
        id,
        name,
        slug,
        description,
        productCount,
        createdAt,
        updatedAt
      }
      ok,
      error
    }
  }
`;
export const URL_UPDATE_PRODUCT_BRAND = `
mutation updateProductBrand($id: ID!,$inputs: inputUpdateBrand){
    updateProductBrand(id:$id,inputs:$inputs){
      data{
        id,
        name,
        slug,
        description,
        productCount,
        createdAt,
        updatedAt
      }
      ok,
      error
    }
  }
`;
export const URL_DELETE_PRODUCT_BRAND = `
  mutation deleteProductBrand($id: ID!){
    deleteProductBrand(id:$id){
      data{
        id,
        name,
        slug,
        description,
        productCount,
        createdAt,
        updatedAt
      }
      ok,
      error
    }
  }
`;
export const URL_CHANGE_PREVIEW_STATUS = `
mutation changeReviewStatus($id: ID!,$status: String!){
  changeReviewStatus(id:$id,status:$status){
    data{
      id,
      customerId{
        id,
        avatar,
        lastName
      },
      content,
      point,
      status,
      createdAt,
      updatedAt
    },
    ok,
    message
  }
}
`;
export const URL_DELETE_PRODUCT_PREVIEW_BY_ADMIN = `
mutation deleteProductReviewByAdmin($id: ID!){
	deleteProductReviewByAdmin(id:$id){
		data{
			id
    },
    message,
    ok
  }
}
`;
export const URL_CHANGE_MANY_REVIEW = `
mutation changeManyReviews($ids: [ID],$status: String!){
	changeManyReviews(ids:$ids,status:$status){
    data{
      id,
      customerId{
        id,
        avatar,
        lastName
      },
      content,
      point,
      status,
      createdAt,
      updatedAt
    },
    ok,
    message
 }
}
`;
export const URL_GET_ALL_PRODUCT_BRAND = `
query getAllProductBrand($limit: Int!,$page: Int!){
	getAllProductBrand(limit:$limit,page:$page){
		count,
    result{
			id,
      name,
      slug,
      description,
      productCount,
      createdAt,
      updatedAt
    }
  }
}
`;
export const URL_ADD_PRODUCT_REVIEW_BY_ADMIN = `

mutation addProductReviewByAdmin($productId: ID,$customerId: ID,$content: String!,$point: Float,$status: String,$parentId: ID){
  addProductReviewByAdmin(productId:$productId,customerId:$customerId,content:$content,point:$point,status:$status,parentId:$parentId){
    data{
      id,
      point,
      content,
      createdAt,
      replies{
        id
        content
        point
        status
        
        productId{
          name
        }
        customerId{
          id
          userName
          lastName
        }
      }
      productId{
        name
      }
      customerId{
        userName,
        id,
        email,
        firstName,
        lastName
      },
    }
    ok,
    message
  }
}

`;
export const URL_UPDATE_PRODUCT_REVIEW_BY_ADMIN = `
mutation updateProductReviewByAdmin($id: ID!,$content: String,$point: Float,$status: String){
    updateProductReviewByAdmin(id:$id,content:$content,point:$point,status:$status){
      data{
        id,
        point,
        content,
        createdAt,
        replies{
          id
          content
          point
          status
          
          productId{
            name
          }
          customerId{
            id
            userName
            lastName
          }
        }
        productId{
          name
        }
        customerId{
          userName,
          id,
          email,
          firstName,
          lastName
        },
      }
      ok,
      message
    }
  }
`;
export const URL_DELETE_PRODUCT_REVIEW_BY_ADMIN = `
mutation deleteProductReviewByAdmin($id: ID!){
    deleteProductReviewByAdmin(id:$id){
      data{
        id,
        point,
        content,
        createdAt,
        replies{
          id
          content
          point
          status
          
          productId{
            name
          }
          customerId{
            id
            userName
            lastName
          }
        }
        productId{
          name
        }
        customerId{
          userName,
          id,
          email,
          firstName,
          lastName
        },
      }
      ok,
      message
    }
  }
`;
