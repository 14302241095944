import request from './axiosServices';
import { uploadFileData } from '../helpers';

import {
  URL_ALL_BLOG_BY_ADMIN,
  URL_UPDATE_BLOG,
  URL_DELETE_BLOG,
  URL_DUPLICATE_BLOG,
  URL_ADD_BLOG,
  URL_ADD_CATEGORY_BLOG,
  URL_ADD_BLOG_TAG,
  URL_ALL_CATEGORY_BLOG,
  URL_BLOGS_TAG,
  URL_GET_BLOG_INFO_BY_ADMIN,
  URL_GET_ALL_BLOG_TAGS_BY_ADMIN,
  URL_DELETE_BLOG_TAG,
  URL_UPDATE_BLOG_TAG,
  URL_UPLOAD_IMAGE,
} from 'graphql';

interface requestTypes {
  token?: string;
  limit?: number;
  page?: number;
  id?: string;
  status?: string;
  fileUpload?: object;
  inputs?: {
    name?: string;
    // image?: string;
    // multiImages?: [];
    content?: string;
    isHot?: boolean;
    listTag?: [];
    status?: boolean;
    slug?: string;
    description?: string;
  };
  folder?: string;
  name?: string;
}
interface dataBlogTypes {
  token?: string;
  limit?: number;
  page?: number;
  id?: string;
  status?: string;
  inputs?: {
    name?: string;
    image?: string;
    multiImages?: [];
    content?: string;
    isHot?: boolean;
    listTag?: [];
    status?: string;
    slug?: string;
    description?: string;
  };
}
export function fetchAllBlogByAdminAPI({ token, limit, page, status }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_ALL_BLOG_BY_ADMIN,
      variables: { limit, page, status },
    },
    token,
  });
}
export function updateBlogAPI({ token, id, inputs, fileUpload }: any) {
  let data: {} = {
    query: URL_UPDATE_BLOG,
    variables: { inputs, id, fileUpload: null },
  };

  data = uploadFileData(data, fileUpload);

  return request({
    method: 'POST',
    data,
    token,
  });
}
export function deleteBlogAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_DELETE_BLOG,
      variables: { id },
    },
    token,
  });
}
export function cloneBlogAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_DUPLICATE_BLOG,
      variables: { id },
    },
    token,
  });
}
export function addBlogAPI({ token, inputs, fileUpload }: any) {
  let data: {} = {
    query: URL_ADD_BLOG,
    variables: { inputs, fileUpload: null },
  };

  data = uploadFileData(data, fileUpload);

  return request({
    method: 'POST',
    data,
    token,
  });
}
export function addCategoryBlogAPI({ token, inputs }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_ADD_CATEGORY_BLOG,
      variables: { inputs },
    },
    token,
  });
}
export function addBlogTagAPI({ token, inputs }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_ADD_BLOG_TAG,
      variables: { inputs },
    },
    token,
  });
}
export function allCategoryBlogAPI({ token, limit }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_ALL_CATEGORY_BLOG,
      variables: { limit },
    },
    token,
  });
}
export function blogsTagAPI({ token, limit }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_BLOGS_TAG,
      variables: { limit },
    },
    token,
  });
}
export function fetchBlogInfoByAdminAPI({ id, token }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_GET_BLOG_INFO_BY_ADMIN,
      variables: { id },
    },
    token,
  });
}
export function fetchAllBlogTagsByAdminAPI({ limit, page, token }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_GET_ALL_BLOG_TAGS_BY_ADMIN,
      variables: { limit, page },
    },
    token,
  });
}
export function deleteBlogTagAPI({ id, token }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_DELETE_BLOG_TAG,
      variables: { id },
    },
    token,
  });
}
export function updateBlogTagAPI({ id, token, inputs }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_UPDATE_BLOG_TAG,
      variables: { id, inputs },
    },
    token,
  });
}
export function uploadImageAPI({ id, token, fileUpload, folder, name }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_UPLOAD_IMAGE,
      variables: { id, fileUpload, name, folder },
    },
    token,
  });
}
