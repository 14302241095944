export const URL_ALL_ORDER_BY_ADMIN = `
    query getAllOrderByAdmin($limit: Int!,$page: Int!){
        getAllOrderByAdmin(limit:$limit,page:$page){
          count,
          result{
            _id
            code,
            price,
            totalPayment,
            status,
            shippingStatus,
            customer{
              id,
              userName,
              lastName,
              email,
              phone,
              addressUser{
                district,
                city,
                address,
                houseBuilding,
                postalCode
              }
            }
            createdAt,
            _id,
            updatedAt
            orderDetail{
              id,
              name,
              amount,
              qty,
              productId{
                name,
                image
              }
            }
          }
    }
    }
`;
export const URL_ALL_ORDER_OF_CUSTOMERS = `
query getAllOrderOfCustomers($limit: Int!
    $page: Int!){
	getAllOrderOfCustomers(limit:$limit,page:$page){
		count,
    result{
      lastOrderDay,
      firstName,
			userName,
      email,
      registerDay,
      numberOfOrder,customerId,
      totalPay,
      avgPayment
    }
  }
}
`;
export const URL_ORDER_INFO_BY_ADMIN = `
query getOrderInfoByAdmin($id: ID!){
	getOrderInfoByAdmin(id:$id){
		_id,
    createdAt,
    status,
    totalPayment,
    shippingStatus,
    code,
    paymentType,
    shippingType,
    orderDetail{
			id,
      name,
      qty,
      productId{
				_id,
        name,
        image,
        price,
      }
    },
    address{
			city,
      district,
      postalCode,
      houseBuilding
    },
    customer{
			id,
      email,
      userName,
      phone
    }
  }
}
`;
export const URL_EXPORT_CSV_ORDER = `
query exportCSVOrder{
  exportCSVOrder{
    ok
    error
    url
  }
}
`;
export const URL_UPDATE_MANY_ORDER = `
mutation updateManyOrder($ids: [ID!],$shippingStatus: String!,$updateAll: Boolean){
  updateManyOrder(ids:$ids,shippingStatus:$shippingStatus,updateAll:$updateAll)
  {
    data{
      _id
      code,
      price,
      status,
      shippingStatus,
      totalPayment,
      customer{
        id,
        userName,
        lastName,
        email,
        phone,
        addressUser{
          district,
          city,
          address,
          houseBuilding,
          postalCode
        }
      }
      createdAt,
      _id,
      updatedAt
      orderDetail{
        id,
        name,
        amount,
        qty,
        productId{
          name,
          image
        }
      }
    }
    ok

  }
}
`;
