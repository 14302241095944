import { useEffect, useRef, useState } from 'react';
import {
  Checkbox,
  Input,
  Select,
  Rate,
  Popconfirm,
  DatePicker,
  Button,
  TimePicker,
  DatePickerProps,
  Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import {
  addProduct,
  addProductBrand,
  fetchProductBrands,
  fetchProductInfoByAdmin,
  updateProduct,
  updateProductStatus,
} from '../../slices/productSlice';
import * as cts from '../../constants/ui/svg';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import type { SelectProps } from 'antd';
import { useDispatch } from 'react-redux';
import { getToken } from 'helpers';
import { TypesProductAdd } from '../../models/products';
import { successToast, errorToast, failedToast } from 'helpers';
import { useAppSelector } from 'redux/hooks';
import { useHistory, useParams } from 'react-router-dom';
import { URL_UPLOAD } from '../../constants';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { addCategory, fetchAllCategory } from 'slices/categorySlice';
import { addProductTag, fetchProductTag } from 'slices/tagProductsSlice';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import moment from 'moment';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
const options: SelectProps['options'] = [];
interface Props {
  // idProduct: any;
  // setViewSlc: React.Dispatch<React.SetStateAction<string>>;
  // setIdProduct: React.Dispatch<React.SetStateAction<string>>;
  // dataProduct: {
  //   name: string;
  //   price: number;
  //   description: string;
  //   content: string;
  //   weight: number;
  //   length: number;
  //   width: number;
  //   height: number;
  // };
  // setDataProduct: React.Dispatch<React.SetStateAction<object>>;
}
interface ParamTypes {
  id: string;
}
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const EditMerchandisePage: React.FC<Props> = ({}) => {
  const { TextArea } = Input;
  dayjs.extend(customParseFormat);
  const dispatch = useDispatch();
  const token = getToken();
  const params = useParams<ParamTypes>();
  const history = useHistory();
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [hoverStar, setHoverStar] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [tabCurrent, setTabCurrent] = useState(1);
  const [valueStar, setValueStar] = useState(3);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [validateName, setValidateName] = useState(false);
  const [validateDescripts, setValidateDescripts] = useState(false);
  const [validatePrice, setValidatePrice] = useState(false);
  const [validateTag, setValidateTag] = useState(false);
  const [validateCate, setValidateCate] = useState(false);
  const [validateBrand, setValidateBrand] = useState(false);
  const [validateImg, setValidateImg] = useState(false);

  const [indxImg, setIndxImg] = useState<any>();
  const productInfoByAdmin = useAppSelector((state) => state.products.productInfoByAdmin);
  const [dataProduct, setDataProduct] = useState<any>(productInfoByAdmin);
  const productBrand = useAppSelector((state) => state.products.productBrands);
  const categories = useAppSelector((state) => state.categories.categories);
  const tagProduct = useAppSelector((state) => state.productTags.productTags);
  const [openForm, setOpenForm] = useState({ open: false, content: '', text: '' });
  const formatHour = 'HH';
  const formatMinute = 'mm';
  const dateFormat = 'YYYY-MM-DD';
  const [valueBrand, setValueBrand] = useState<any>(dataProduct?.brandId?.id);
  const [valueTag, setValueTag] = useState<any>(dataProduct?.tags?.map((i: any) => i?.name));
  const [valueCate, setValueCate] = useState<any>(dataProduct?.categoryId?.map((i: any) => i?._id));
  const loading = useAppSelector((state) => state.common.loading);
  const [btnLoad, setBtnLoad] = useState<any>({ load: loading, status: '' });
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [files, setFiles] = useState<any>([]);
  const [fileUpload, setFileUpload] = useState<any>();
  const [fileDataURL, setFileDataURL] = useState();
  const [idImgDel, setIdImgDel] = useState<any>();
  const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }
  const [timeStart, setTimeStart] = useState<any>({
    date: moment(productInfoByAdmin?.saleStartTime).format('YYYY-MM-DD'),
    hour: moment(productInfoByAdmin?.saleStartTime).format('HH'),
    minute: moment(productInfoByAdmin?.saleStartTime).format('mm'),
  });
  const [timeEnd, setTimeEnd] = useState<any>({
    date: moment(productInfoByAdmin?.saleEndTime).format('YYYY-MM-DD'),
    hour: moment(productInfoByAdmin?.saleEndTime).format('HH'),
    minute: moment(productInfoByAdmin?.saleEndTime).format('mm'),
  });

  const inputRef = useRef<any>();
  const onChangeDateStart: DatePickerProps['onChange'] = (date, dateString) => {
    setTimeStart({ ...timeStart, date: dateString });
  };
  const onChangeTimeHourStart = (time: any, timeString: any) => {
    setTimeStart({ ...timeStart, hour: timeString });
  };
  const onChangeTimeMinStart = (time: any, timeString: any) => {
    setTimeStart({ ...timeStart, minute: timeString });
  };
  const onChangeDateEnd: DatePickerProps['onChange'] = (date, dateString) => {
    setTimeEnd({ ...timeEnd, date: dateString });
  };
  const onChangeTimeHourEnd = (time: any, timeString: any) => {
    setTimeEnd({ ...timeEnd, hour: timeString });
  };
  const onChangeTimeMinEnd = (time: any, timeString: any) => {
    setTimeEnd({ ...timeEnd, minute: timeString });
  };
  const handleChangeSelect = (value: string[]) => {
    console.log(`selected ${value}`);
  };
  const formats = ['header', 'underline', 'bold', 'italic', 'bullet', 'indent'];
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['underline', 'bold', 'italic'],
      [{ indent: '-1' }, { indent: '+1' }],
    ],
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  // const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
  //   setFileList(newFileList);
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList?.length > 0) {
      let filesChange: any = [];
      newFileList.forEach((i: UploadFile) => {
        filesChange.push(i?.originFileObj);
      });
      setFiles(filesChange);
    }
  };
  useEffect(() => {
    if (fileList?.length >= 0) {
      const img1 = fileList[0]?.name;
      //   setDataProduct({
      //     ...dataProduct,
      //     multiImages: fileList?.map((item: any) => item?.name),
      //     image: img1,
      //   });
    }
  }, [fileList]);
  const onChangeBrand = (e: CheckboxChangeEvent) => {
    setValueBrand(e.target.value);
  };
  const onChangeCheckBoxTag = (checkedValues: CheckboxValueType[]) => {
    setValueTag(checkedValues);
  };
  const onChangeCheckBox = (checkedValues: CheckboxValueType[]) => {
    const arr3 = checkedValues
      .map((item: any) => {
        const index = categories?.findIndex((item2: any) => item2?.name === item);
        return {
          id: categories[index]?._id,
        };
      })
      .map((item1: any) => item1?.id);
    setValueCate(arr3);
  };
  const uploadButton = (
    <span className="text-[12px] mplus1pnormal text-[#0038FF]">+ upload new image</span>
  );
  const changeHandler = (e: any) => {
    const file = e.target.files[0];
    setFileUpload(file);
  };
  const arrFilterIdImg = productInfoByAdmin?.MultipleImageProduct?.filter(
    (x: any) => !dataProduct?.MultipleImageProduct?.includes(x)
  );

  const handleDeleImg = () => {
    const newData = { ...dataProduct };

    if (idImgDel) {
      setDataProduct({
        ...newData,
        MultipleImageProduct: newData?.MultipleImageProduct.filter((x: any) => x._id !== idImgDel),
      });
    } else {
      setFileUpload(null);
      setDataProduct({
        ...newData,
        image: null,
      });
    }

    // const newData = { ...dataProduct };
    // const test = newData?.image.filter((img: any, index: any) => index !== indxImg);
    // setDataProduct({
    //   ...newData,
    //   image: test,
    // });
    setOpenModal(false);
  };
  const onUpdateProduct = (status: string) => {
    if (
      dataProduct?.name !== '' &&
      dataProduct?.price !== 0 &&
      dataProduct?.price !== null &&
      dataProduct?.description !== '' &&
      dataProduct?.description !== null &&
      valueTag?.length > 0 &&
      valueCate?.length > 0 &&
      valueBrand !== ''
      // && dataProduct.content !== ''
      //   dataProduct.image !== '' &&
      //   dataProduct.multiImages !== []
    ) {
      setBtnLoad({ ...btnLoad, status: status });
      const time = `${timeStart.date + 'T' + timeStart.hour + ':' + timeStart.minute + ':00.000Z'}`;
      const timeSaleEnd = `${
        timeEnd.date + 'T' + timeEnd.hour + ':' + timeEnd.minute + ':00.000Z'
      }`;

      const param = {
        name: dataProduct?.name,
        description: dataProduct?.description,
        price: dataProduct?.price,
        content: dataProduct?.content,
        length: dataProduct?.length,
        width: dataProduct?.width,
        height: dataProduct?.height,
        numberInStock: dataProduct?.numberInStock,
        weight: dataProduct?.weight,
        brandId: valueBrand ? valueBrand : dataProduct?.brandId?.id,
        listTag: valueTag,
        categoryId: valueCate,
        status: status,
        salePrice: dataProduct?.salePrice,
        saleStartTime:
          timeStart.date !== 'Invalid date' &&
          timeStart.hour !== 'Invalid date' &&
          timeStart.minute !== 'Invalid date'
            ? time
            : null,
        saleEndTime:
          timeEnd.date !== 'Invalid date' &&
          timeEnd.hour !== 'Invalid date' &&
          timeEnd.minute !== 'Invalid date'
            ? timeSaleEnd
            : null,
      };
      dispatch(
        updateProduct({
          token,
          id: params.id,
          inputs: param,
          fileUpload,
          files,
          multipleImageDelete: arrFilterIdImg?.map((x: any) => x?._id),
        })
      );
      setTimeout(() => {
        // history.push('/products');
        window.location.reload();
      }, 1000);
    } else {
      errorToast('Please input enough value');

      if (dataProduct?.name === null) {
        setValidateName(true);
      }
      if (dataProduct?.description === null) {
        setValidateDescripts(true);
      }
      if (dataProduct?.price === null) {
        setValidatePrice(true);
      }
      if (valueTag?.length === 0) {
        setValidateTag(true);
      }
      if (valueCate?.length === 0) {
        setValidateCate(true);
      }
      if (dataProduct?.image === null) {
        setValidateImg(true);
      }
    }
  };
  useEffect(() => {
    if (loading === false) {
      setBtnLoad({ ...btnLoad, load: false });
    } else {
      setBtnLoad({ ...btnLoad, load: true });
    }
  }, [loading]);
  useEffect(() => {
    if (openModal === false) {
      setIdImgDel(undefined);
    }
  }, [openModal]);
  useEffect(() => {
    let fileReader = new FileReader(),
      isCancel = false;
    if (fileUpload) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
          setDataProduct({
            ...dataProduct,
            image: result,
          });
        }
      };
      fileReader.readAsDataURL(fileUpload);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileUpload]);
  useEffect(() => {
    if (dataProduct?.description !== '') {
      setValidateDescripts(false);
    }
    if (dataProduct?.name !== '') {
      setValidateName(false);
    }
    if (dataProduct?.price !== null) {
      setValidatePrice(false);
    }
    if (valueTag?.length > 0) {
      setValidateTag(false);
    }
    if (valueCate?.length > 0) {
      setValidateCate(false);
    }
    if (valueTag?.length === 0) {
      setValidateTag(true);
    }
    if (dataProduct?.image !== null) {
      setValidateImg(false);
    }
  }, [dataProduct, valueTag?.length, valueCate?.length]);
  useEffect(() => {
    if (params.id) {
      dispatch(fetchProductInfoByAdmin({ token, id: params?.id }));
    }
  }, [dispatch, token, params?.id]);

  useEffect(() => {
    setValueBrand(dataProduct?.brandId?.id);
    setValueTag(dataProduct?.tags?.map((i: any) => i?.name));
    setValueCate(dataProduct?.categoryId?.map((i: any) => i?._id));
    setTimeStart({
      date: moment(dataProduct?.saleStartTime).format('YYYY-MM-DD'),
      hour: moment(dataProduct?.saleStartTime).format('HH'),
      minute: moment(dataProduct?.saleStartTime).format('mm'),
    });
    setTimeEnd({
      date: moment(dataProduct?.saleEndTime).format('YYYY-MM-DD'),
      hour: moment(dataProduct?.saleEndTime).format('HH'),
      minute: moment(dataProduct?.saleEndTime).format('mm'),
    });
    if (productInfoByAdmin && params?.id) {
      setDataProduct(productInfoByAdmin);
    }
  }, [productInfoByAdmin, params?.id, dataProduct?.brandId?.id]);
  useEffect(() => {
    dispatch(fetchProductBrands({ token }));
  }, [dispatch, token]);
  useEffect(() => {
    dispatch(fetchAllCategory({ token, limit: 100 }));
  }, [dispatch, token]);
  useEffect(() => {
    dispatch(fetchProductTag({ token, limit: 100 }));
  }, [dispatch, token]);

  return (
    <div className="pt-[26px] px-[35px] flex flex-col pb-[100px]">
      {/* <div className="flex items-center justify-between flex-col"> */}

      <div className="flex w-full mb-[100px] ">
        <div className="w-[70%] mr-[2%]">
          <div className="flex items-center mb-[30px]">
            <span className="text-[20px] mplus1pextrabold mr-[54px]">{t('PRODUCT_EDIT')}</span>
          </div>
          <span className="text-[14px] mplus1pnormal mb-[10px]">{t('PRODUCT_NAME')}</span>
          <Input
            value={dataProduct?.name}
            onChange={(e) => setDataProduct({ ...dataProduct, name: e.target.value })}
            className={`${
              dataProduct?.name === '' || validateName
                ? 'border-[#E06469] focus:border-[#E06469] focus:shadow-[0px_0px_1px_1px_#FF9B9B] hover:border-[#E06469] hover:shadow-[0px_0px_1px_1px_#FF9B9B] shadow-[0px_0px_1px_1px_#FF9B9B]'
                : 'border-[rgba(0,0,0,0.5)] mb-[20px]'
            } w-full h-[44px] rounded-none border mt-[5px]`}
          />
          {dataProduct?.name === '' || validateName ? (
            <p className="text-[10px] text-[#C70000]/60 mb-[10px]">*製品名を入力してください</p>
          ) : null}

          <span className="text-[14px] mplus1pnormal mb-[10px]">
            {t('DESCRIPTION_BRIEF_PRODUCT')}
          </span>
          <Input
            value={dataProduct?.description}
            onChange={(e) => setDataProduct({ ...dataProduct, description: e.target.value })}
            className={`${
              dataProduct?.description === '' || validateDescripts
                ? 'border-[#E06469] focus:border-[#E06469] focus:shadow-[0px_0px_1px_1px_#FF9B9B] hover:border-[#E06469] hover:shadow-[0px_0px_1px_1px_#FF9B9B] shadow-[0px_0px_1px_1px_#FF9B9B]'
                : 'border-[rgba(0,0,0,0.5)] mb-[20px]'
            } w-full h-[60px] rounded-none border mt-[5px]`}
          />
          {dataProduct?.description === '' || validateDescripts ? (
            <p className="text-[10px] text-[#C70000]/60 mb-[10px]">*商品説明を入力してください</p>
          ) : null}
          <span className="text-[14px] mplus1pnormal">{t('DESCRIPTION_OF_ITEM')}</span>
          <div>
            {/* <CKEditor /> */}
            <ReactQuill
              className="form_add_merchandise bg-white mt-[10px] mb-[20px]"
              value={dataProduct?.content}
              onChange={(e) => {
                setDataProduct({ ...dataProduct, content: e });
              }}
              theme="snow"
              formats={formats}
              modules={modules}
            />
          </div>
          <span className="text-[14px] mplus1pnormal">{t('PRODUCT_IMAGE')}</span>
          {fileUpload || dataProduct?.image ? (
            <div className="h-[130px] w-[130px] relative mx-[5px] border border-black/30 bg-white my-[15px]">
              {/* <img className="h-[130px] w-[130px]  absolute" src={fileDataURL} /> */}
              <img
                className="h-[130px] w-[130px] absolute"
                src={fileUpload ? fileDataURL : `${URL_UPLOAD}/products/${dataProduct?.image}`}
              />
              <div className="absolute h-full w-full pt-[4px] pr-[4px] opacity-0 hover:opacity-100 flex justify-end">
                <span
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  className=" svg_close_img h-[22px] w-[22px] rounded-[11px] flex items-center justify-center bg-[#D9D9D9]/50 border border-black/20"
                >
                  {cts.iconClose}
                </span>
              </div>
            </div>
          ) : (
            <div className="relative flex items-center h-[132px] w-[132px] bg-white my-[15px]">
              <div className="absolute h-[132px] w-[132px] border border-black/30">
                <span className="text-[12px]  h-full items-center cursor-pointer mplus1pnormal text-[#0038FF] absolute w-[132px] flex justify-center">
                  + {t('UPLOAD_IMAGE')}
                  <Input
                    ref={inputRef}
                    accept=".png, .jpg, .jpeg .webp"
                    type="file"
                    onChange={changeHandler}
                    className="h-[132px] w-[132px] absolute text-white bg-white inp_upload opacity-0"
                  />
                </span>
              </div>
            </div>
          )}
          <span className="text-[14px] mplus1pnormal">商品説明写真</span>
          <div className="mt-[15px] mb-[20px] overflow-x-auto bg-white py-[8px] px-[7px] flex items-center relative border border-[rgba(0,0,0,0.5)]">
            <>
              {dataProduct?.MultipleImageProduct?.length > 0 && (
                <div className="flex items-center">
                  {dataProduct?.MultipleImageProduct?.map((x: any) => {
                    return (
                      <div className="h-[132px] w-[132px] relative mx-[5px] border border-black/30">
                        <img
                          className="h-[132px] w-[132px] absolute"
                          src={`${URL_UPLOAD}/products/${x?.image}`}
                        />

                        <div className="absolute h-full w-full pt-[4px] pr-[4px] opacity-0 hover:opacity-100 flex justify-end">
                          <span
                            onClick={() => {
                              setOpenModal(true);
                              setIdImgDel(x?._id);
                            }}
                            className=" svg_close_img h-[22px] w-[22px] rounded-[11px] flex items-center justify-center bg-[#D9D9D9]/50 border border-black/20"
                          >
                            {cts.iconClose}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                className="h-[132px] w-[132px] upload_img"
                multiple={true}
              >
                {fileList.length + dataProduct?.MultipleImageProduct?.length >= 10
                  ? null
                  : uploadButton}
              </Upload>
            </>
          </div>
          {validateImg ? (
            <p className="text-[10px] text-[#C70000]/60 mb-[10px]">*商品の写真を選択してください</p>
          ) : null}
          <span className="text-[14px] mplus1pnormal">{t('PRODUCT_DATA')}</span>
          <div className="flex w-full border border-[rgba(0,0,0,0.5)] mt-[10px] mb-[30px]">
            <div className="flex flex-col border-r-[0.5px] border-[rgba(0,0,0,0.6)]">
              <span
                onClick={() => setTabCurrent(1)}
                className={`${
                  tabCurrent === 1
                    ? 'bg-[#3E6100] text-white mplus1pextrabold'
                    : 'bg-[#DCBA78] mplus1pnormal'
                } h-[40px] cursor-pointer text-[14px] px-[25px] border-b-[0.5px] border-[rgba(0,0,0,0.6)] flex items-center`}
              >
                {t('GENERAL')}
              </span>
              <span
                onClick={() => setTabCurrent(2)}
                className={`${
                  tabCurrent === 2
                    ? 'bg-[#3E6100] text-white mplus1pextrabold'
                    : 'bg-[#DCBA78] mplus1pnormal'
                } h-[40px] cursor-pointer text-[14px] px-[25px] mplus1pnormal border-b-[0.5px] border-[rgba(0,0,0,0.6)] flex items-center`}
              >
                {t('STOCK')}
              </span>
              <span
                onClick={() => setTabCurrent(3)}
                className={`${
                  tabCurrent === 3
                    ? 'bg-[#3E6100] text-white mplus1pextrabold'
                    : 'bg-[#DCBA78] mplus1pnormal'
                } h-[40px] cursor-pointer text-[14px] px-[25px] border-b-[0.5px] border-[rgba(0,0,0,0.6)] flex items-center`}
              >
                {t('DELIVERY')}
              </span>
              <span
                onClick={() => setTabCurrent(4)}
                className={`${
                  tabCurrent === 4
                    ? 'bg-[#3E6100] text-white mplus1pextrabold'
                    : 'bg-[#DCBA78] mplus1pnormal'
                } h-[40px] cursor-pointer text-[14px] px-[25px] w-[180px] border-b-[0.5px] border-[rgba(0,0,0,0.6)] flex items-center`}
              >
                LINKED {t('MERCHANDISE')}
                {t('MANAGEMENT')}
              </span>
              <span
                onClick={() => setTabCurrent(5)}
                className={`${
                  tabCurrent === 5
                    ? 'bg-[#3E6100] text-white mplus1pextrabold'
                    : 'bg-[#DCBA78] mplus1pnormal'
                } h-[40px] cursor-pointer text-[14px] px-[25px] flex items-center`}
              >
                ADVANCED
              </span>
            </div>
            <div className="pl-[25px] justify-center flex flex-col bg-white w-full pr-[15px] py-[25px]">
              {(tabCurrent === 1 && (
                <>
                  {' '}
                  <div className="flex w-full items-center">
                    <div className=" w-[30%]">
                      <span className="text-[14px] mplus1pnormal">{t('PRICE')} (¥)</span>
                    </div>
                    <div className=" w-[70%] flex flex-col">
                      <Input
                        type="number"
                        value={dataProduct?.price}
                        onChange={(e) =>
                          setDataProduct({ ...dataProduct, price: Number(e.target.value) })
                        }
                        className={`${
                          validatePrice || dataProduct?.price === 0
                            ? 'border-[#E06469] focus:border-[#E06469] focus:shadow-[0px_0px_1px_1px_#FF9B9B] hover:border-[#E06469] hover:shadow-[0px_0px_1px_1px_#FF9B9B] shadow-[0px_0px_1px_1px_#FF9B9B]'
                            : 'border-[rgba(0,0,0,0.4)]'
                        } h-[27px] border w-[200px]`}
                      />
                      {validatePrice || dataProduct?.price === 0 ? (
                        <p className="text-[10px] text-[#C70000]/60">
                          *商品の価格を入力してください
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex w-full mt-[10px] items-center">
                    <div className=" w-[30%]">
                      <span className="text-[14px] mplus1pnormal">{t('SALE')} (¥)</span>
                    </div>
                    <div className=" w-[70%] flex items-center">
                      <Input
                        value={dataProduct?.salePrice}
                        onChange={(e) =>
                          setDataProduct({ ...dataProduct, salePrice: Number(e.target.value) })
                        }
                        className="h-[27px] border w-[200px] border-[rgba(0,0,0,0.4)]"
                      />
                      <span
                        onClick={() => setOpenSchedule((prev) => !prev)}
                        className="underline text-[12px] mplus1pnormal text-[#0038FF] ml-[20px] cursor-pointer"
                      >
                        {openSchedule ? 'cancel' : 'Schedule'}
                      </span>
                    </div>
                  </div>
                  {openSchedule && (
                    <>
                      <div className="flex w-full mt-[10px]">
                        <div className=" w-[30%]">
                          <span className="text-[14px] mplus1pnormal">セール期間設定</span>
                        </div>
                        <div className=" w-[70%] flex ">
                          <div className="flex-1">
                            <div className="w-[90%] flex-col flex">
                              <span className="text-[14px] mplus1pnormal">開始日</span>
                              <DatePicker
                                onChange={onChangeDateStart}
                                defaultValue={dayjs(
                                  dataProduct?.saleStartTime
                                    ? timeStart.date
                                    : moment().format('YYYY/MM/DD'),
                                  dateFormat
                                )}
                                format={dateFormat}
                                suffixIcon={
                                  <span className="h-[29px] border-l border-[rgba(0,0,0,0.4)] pl-[5px] flex items-center justify-center">
                                    {cts.iconDate}
                                  </span>
                                }
                                className={`${
                                  timeStart?.date === 'Invalid date' && 'none_date'
                                } h-[29px] mt-[5px] border-[rgba(0,0,0,0.4)] !px-[5px] slc_date`}
                              />
                            </div>
                          </div>
                          <div className="flex-1 w-full flex justify-end">
                            <div className="w-[90%] flex-col flex">
                              <span className="text-[14px] mplus1pnormal">終了日</span>
                              <DatePicker
                                onChange={onChangeDateEnd}
                                defaultValue={dayjs(
                                  dataProduct?.saleEndTime
                                    ? timeEnd?.date
                                    : moment().format('YYYY/MM/DD'),
                                  dateFormat
                                )}
                                format={dateFormat}
                                suffixIcon={
                                  <span className="h-[29px] border-l border-[rgba(0,0,0,0.4)] pl-[5px] flex items-center justify-center">
                                    {cts.iconDate}
                                  </span>
                                }
                                className={`${
                                  timeEnd?.date === 'Invalid date' && 'none_date'
                                } h-[29px] mt-[5px] border-[rgba(0,0,0,0.4)] !px-[5px] slc_date`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex w-full mt-[10px]">
                        <div className=" w-[30%]">
                          <span className="text-[14px] mplus1pnormal">セール期間設定</span>
                        </div>
                        <div className=" w-[70%] flex flex-col">
                          <div className="flex">
                            <div className="flex-1 flex-col flex">
                              <span className="text-[14px] mplus1pnormal">開始時間</span>
                              <div className="flex items-center">
                                <TimePicker
                                  onChange={onChangeTimeHourStart}
                                  className={`${
                                    timeStart.hour === 'Invalid date' && 'none_date'
                                  } h-[27px] time_sale mt-[5px] border w-[86px] border-[rgba(0,0,0,0.4)]`}
                                  defaultValue={dayjs(
                                    dataProduct?.saleStartTime ? timeStart.hour : '00:00',
                                    formatHour
                                  )}
                                  format={formatHour}
                                />
                                <span className="ml-[5px]">時</span>
                              </div>
                            </div>
                            <div className="flex-1 flex-col flex">
                              <span className="text-[14px] mplus1pnormal text-white">終了時間</span>
                              <div className="flex items-center">
                                <TimePicker
                                  onChange={onChangeTimeMinStart}
                                  className={`${
                                    timeStart.minute === 'Invalid date' && 'none_date'
                                  } h-[27px] time_sale mt-[5px] border w-[86px] border-[rgba(0,0,0,0.4)]`}
                                  defaultValue={dayjs(
                                    dataProduct?.saleStartTime ? timeStart.minute : '00:00',
                                    formatMinute
                                  )}
                                  format={formatMinute}
                                />
                                <span className="ml-[5px]">分</span>
                              </div>
                            </div>
                          </div>
                          <div className="flex mt-[15px]">
                            <div className="flex-1 flex-col flex">
                              <span className="text-[14px] mplus1pnormal">終了時間</span>
                              <div className="flex items-center">
                                <TimePicker
                                  className={`${
                                    timeEnd.hour === 'Invalid date' && 'none_date'
                                  } h-[27px] time_sale mt-[5px] border w-[86px] border-[rgba(0,0,0,0.4)]`}
                                  onChange={onChangeTimeHourEnd}
                                  defaultValue={dayjs(
                                    dataProduct?.saleEndTime ? timeEnd.hour : '00:00',
                                    formatHour
                                  )}
                                  format={formatHour}
                                />
                                <span className="ml-[5px]">時</span>
                              </div>
                            </div>
                            <div className="flex-1 flex-col flex">
                              <span className="text-[14px] mplus1pnormal text-white">終了時間</span>
                              <div className="flex items-center">
                                <TimePicker
                                  className={`${
                                    timeEnd.minute === 'Invalid date' && 'none_date'
                                  } h-[27px] time_sale mt-[5px] border w-[86px] border-[rgba(0,0,0,0.4)]`}
                                  defaultValue={dayjs(
                                    dataProduct?.saleEndTime ? timeEnd.minute : '00:00',
                                    formatMinute
                                  )}
                                  onChange={onChangeTimeMinEnd}
                                  format={formatMinute}
                                />
                                <span className="ml-[5px]">分</span>
                              </div>
                            </div>
                          </div>
                          <span className="text-[12px] text-black/50 mt-[8px] mb-[10px]">
                            セール期間を計算して表示する
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="flex w-full mt-[10px] items-center">
                    <div className=" w-[30%]">
                      <span className="text-[14px] mplus1pnormal">Tax status </span>
                    </div>
                    <div className=" w-[70%]">
                      <Input className="h-[27px] border w-[200px] border-[rgba(0,0,0,0.4)]" />
                    </div>
                  </div>
                  <div className="flex w-full items-center">
                    <div className=" w-[30%]">
                      <span className="text-[14px] mplus1pnormal">Tax class</span>
                    </div>
                    <div className=" w-[70%]">
                      <Select className="h-[27px] w-[215px] mt-[10px] slc_tax" />
                    </div>
                  </div>
                </>
              )) ||
                (tabCurrent === 2 && (
                  <div>
                    <div className="flex w-full items-center">
                      <div className=" w-[30%]">
                        <span className="text-[14px] mplus1pnormal">{t('STOCK_AMOUNT')}</span>
                      </div>
                      <div className=" w-[70%]">
                        <Input
                          type="number"
                          value={dataProduct?.numberInStock}
                          onChange={(e) =>
                            setDataProduct({
                              ...dataProduct,
                              numberInStock: Number(e.target.value),
                            })
                          }
                          className="h-[27px] border w-[200px] border-[rgba(0,0,0,0.4)]"
                        />
                      </div>
                    </div>
                    <div className="flex w-full mt-[10px] items-center">
                      <div className=" w-[30%]">
                        <span className="text-[14px] mplus1pnormal">
                          Do you want to manage your inventory?
                        </span>
                      </div>
                      <div className=" w-[70%] flex items-center">
                        <Checkbox className="ckb_add flex items-center">
                          Enable inventory management by product
                        </Checkbox>
                      </div>
                    </div>
                    <div className="flex w-full mt-[10px] items-center">
                      <div className=" w-[30%]">
                        <span className="text-[14px] mplus1pnormal">{t('STOCK')} status</span>
                      </div>
                      <div className=" w-[70%]">
                        <Select className="w-[200px] slc_right" />
                      </div>
                    </div>
                    <div className="flex w-full mt-[10px] items-center">
                      <div className=" w-[30%]">
                        <span className="text-[14px] mplus1pnormal">Individual sale</span>
                      </div>
                      <div className=" w-[70%]">
                        <Checkbox className="ckb_add flex items-center">
                          Enable this if you only want to buy one per order
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                )) ||
                (tabCurrent === 3 && (
                  <div>
                    <div className="flex w-full items-center">
                      <div className=" w-[30%]">
                        <span className="text-[14px] mplus1pnormal">Weight (kg)</span>
                      </div>
                      <div className=" w-[70%]">
                        <Input
                          value={dataProduct?.weight}
                          type="number"
                          onChange={(e) =>
                            setDataProduct({ ...dataProduct, weight: Number(e.target.value) })
                          }
                          className="h-[27px] border w-[45%] border-[rgba(0,0,0,0.4)]"
                        />
                      </div>
                    </div>
                    <div className="flex w-full mt-[15px] items-center">
                      <div className=" w-[30%]">
                        <span className="text-[14px] mplus1pnormal">Size (cm)</span>
                      </div>
                      <div className=" w-[70%] flex items-center">
                        <Input
                          value={dataProduct?.length}
                          type="number"
                          onChange={(e) =>
                            setDataProduct({ ...dataProduct, length: Number(e.target.value) })
                          }
                          placeholder="lenght"
                          className="w-[30%] mr-[10px] "
                        />
                        <Input
                          value={dataProduct?.width}
                          type="number"
                          onChange={(e) =>
                            setDataProduct({ ...dataProduct, width: Number(e.target.value) })
                          }
                          placeholder="width"
                          className="w-[30%] mr-[10px] "
                        />
                        <Input
                          value={dataProduct?.height}
                          type="number"
                          onChange={(e) =>
                            setDataProduct({ ...dataProduct, height: Number(e.target.value) })
                          }
                          placeholder="height"
                          className="w-[30%] "
                        />
                      </div>
                    </div>
                    <div className="flex w-full mt-[15px] items-center">
                      <div className=" w-[30%]">
                        <span className="text-[14px] mplus1pnormal">配送 class</span>
                      </div>
                      <div className=" w-[70%]">
                        <Select className="slc_right w-[215px]" />
                      </div>
                    </div>
                  </div>
                )) ||
                (tabCurrent === 4 && (
                  <div>
                    <div className="flex w-full items-center">
                      <div className=" w-[30%]">
                        <span className="text-[14px] mplus1pnormal">{t('UPSELL')}</span>
                      </div>
                      <div className=" w-[70%]">
                        <Input
                          placeholder={t('PRODUCT_SEARCH')}
                          className="h-[27px] border w-[200px] border-[rgba(0,0,0,0.4)]"
                        />
                      </div>
                    </div>
                    <div className="flex w-full mt-[15px] items-center">
                      <div className=" w-[30%]">
                        <span className="text-[14px] mplus1pnormal">{t('CROSS_SELLING')}</span>
                      </div>
                      <div className=" w-[70%] flex items-center">
                        <Input
                          placeholder={t('PRODUCT_SEARCH')}
                          className="h-[27px] border w-[200px] border-[rgba(0,0,0,0.4)]"
                        />
                      </div>
                    </div>
                  </div>
                )) ||
                (tabCurrent === 5 && (
                  <div>
                    <div className="flex w-full items-center">
                      <div className=" w-[30%]">
                        <span className="text-[14px] mplus1pnormal">{t('NOTE')}</span>
                      </div>
                      <div className=" w-[70%]">
                        <Input className="h-[27px] border w-[215px] border-[rgba(0,0,0,0.4)]" />
                      </div>
                    </div>
                    <div className="flex w-full mt-[15px] items-center">
                      <div className=" w-[30%]">
                        <span className="text-[14px] mplus1pnormal">{t('MENU_ORDER')}</span>
                      </div>
                      <div className=" w-[70%] flex items-center">
                        <Input
                          placeholder="0"
                          className="h-[27px] border w-[215px] border-[rgba(0,0,0,0.4)]"
                        />
                      </div>
                    </div>
                    <div className="flex w-full mt-[15px] items-center">
                      <div className=" w-[30%]">
                        <span className="text-[14px] mplus1pnormal">{t('ENABLE_REVIEWS')}</span>
                      </div>
                      <div className=" w-[70%] flex items-center">
                        <Checkbox />
                      </div>
                    </div>
                    <div className="flex w-full mt-[15px] items-center">
                      <div className=" w-[30%]">
                        <span className="text-[14px] mplus1pnormal">{t('PAID_RANKING')}</span>
                      </div>
                      <div className=" w-[70%] flex items-center">
                        <Checkbox />
                        <Select
                          className="ml-[50px] slc_right w-[58px]"
                          defaultValue="1"
                          options={[
                            { value: '1', label: '1' },
                            { value: '2', label: '2' },
                            { value: '3', label: '3' },
                            { value: '4', label: '4' },
                            { value: '5', label: '5' },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="flex items-center">
            <div className="relative w-[114px] flex items-center justify-center mr-[25px]">
              {btnLoad.load === true && btnLoad.status === 'draft' && (
                <Spin className="absolute w-[114px] h-full items-center flex justify-center" />
              )}
              <span
                onClick={() => onUpdateProduct('draft')}
                className={`${
                  btnLoad.load === true && btnLoad.status === 'draft' ? ' opacity-20 absolute' : ''
                } h-[39px] bg-[#3692E7] justify-center rounded-[10px] text-[14px] cursor-pointer flex items-center text-white px-[15px] mplus1pextrabold`}
              >
                下書きで保存
              </span>
            </div>
            <div className="relative w-[110px] flex items-center justify-center mr-[25px]">
              {btnLoad.load === true && btnLoad.status === 'public' && (
                <Spin className="absolute w-[110px] h-full items-center flex justify-center" />
              )}
              <span
                onClick={() => onUpdateProduct('public')}
                className={`${
                  btnLoad.load === true && btnLoad.status === 'public' ? 'opacity-20 absolute' : ''
                } h-[39px] justify-center bg-[#3E6100] rounded-[10px] text-[14px] cursor-pointer flex items-center text-white px-[20px] mplus1pextrabold`}
              >
                {t('SALE_PUBLISH')}
              </span>
            </div>
            <div className="relative w-[86px] flex items-center justify-center">
              {btnLoad.load === true && btnLoad.status === 'trash' && (
                <Spin className="absolute w-[86px] h-full items-center flex justify-center" />
              )}
              <span
                onClick={() => onUpdateProduct('trash')}
                className={`${
                  btnLoad.load === true && btnLoad.status === 'trash' ? 'opacity-20 absolute' : ''
                } h-[39px] bg-[#C70000] justify-center rounded-[10px] text-[14px]  cursor-pointer flex items-center text-white px-[15px] mplus1pextrabold`}
              >
                ゴミ箱へ
              </span>
            </div>
          </div>
        </div>
        <div className="w-[30%] ml-[2%] flex flex-col">
          <div className="w-full flex flex-col border border-[#C1C1C1]">
            <span className="h-[39px] flex justify-center items-center mplus1pmedium text-[14px] bg-[#DCBA78] border-[rgba(0,0,0,0.4)]">
              {t('RELEASE')}
            </span>
            <div className="py-[15px] bg-[#FBF8F1] flex flex-col px-[5%] border border-t-[#C1C1C1]">
              <span className="text-[14px] mplus1pmedium">
                {t('STATUS')} :{' '}
                <span className="underline text-[#0038FF]">
                  {(productInfoByAdmin?.status === 'trash' && 'ごみ') ||
                    (productInfoByAdmin?.status === 'draft' && '下書き') ||
                    (productInfoByAdmin?.status === 'public' && '公共')}
                </span>
              </span>
              <span className="text-[14px] mplus1pmedium">
                {t('PUBLISHED')} : {moment(productInfoByAdmin?.createdAt).format('YYYY年MM月DD日')}{' '}
                {moment(productInfoByAdmin?.createdAt).format('HH:mm')}
                <span className="underline text-[#0038FF]">編集</span>
              </span>
              {/* <div className="flex justify-between items-center mt-[16px]">
                <span className="h-[21px] rounded-[10px] w-[90px] bg-[#C70000] flex cursor-pointer mplus1pmedium items-center text-[12px] justify-center text-white">
                  {t('TO_TRASH')}
                </span>
                <span className="h-[21px] rounded-[10px] w-[90px] bg-[#3692E7] flex cursor-pointer mplus1pmedium items-center text-[12px] justify-center text-white">
                  {t('SAVE_AS_DRAFT')}
                </span>
                <span className="h-[21px] rounded-[10px] w-[90px] bg-[#3E6100] flex cursor-pointer mplus1pmedium items-center text-[12px] justify-center text-white">
                  {t('RELEASE')}
                </span>
              </div> */}
            </div>
          </div>
          <div className="w-full mt-[34px] flex flex-col border border-[#C1C1C1]">
            <span className="h-[39px] flex justify-center items-center mplus1pmedium text-[14px] bg-[#DCBA78] border-[rgba(0,0,0,0.4)]">
              {t('PRODUCT_DETAIL')}
            </span>
            <div className="py-[15px] bg-[#FBF8F1] flex flex-col px-[17px] border border-t-[#C1C1C1]">
              <span className="text-[14px] mplus1pmedium">{t('PRODUCT_CATE')}</span>
              <div className="flex items-center mt-[10px]">
                <Popconfirm
                  title="aaa"
                  placement="bottom"
                  description={() => {
                    return (
                      <div className="bg-white flex flex-col rounded-[5px] px-[1px] py-[2px] border border-[rgba(0,0,0,0.2)]">
                        <Checkbox.Group
                          options={categories?.map((item: any) => item?.name)}
                          defaultValue={dataProduct?.categoryId?.map((i: any) => i?.name)}
                          onChange={onChangeCheckBox}
                          className="flex flex-col ckb_gr ckb_tag_product"
                        />
                      </div>
                    );
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <div
                    className={`${
                      validateCate || valueCate?.length === 0
                        ? 'border-[#E06469] focus:border-[#E06469] focus:shadow-[0px_0px_1px_1px_#FF9B9B] hover:border-[#E06469] hover:shadow-[0px_0px_1px_1px_#FF9B9B] shadow-[0px_0px_1px_1px_#FF9B9B]'
                        : 'border-[rgba(0,0,0,0.5)]'
                    } h-[29px] bg-white px-[2px] border  flex-1 rounded-[5px] flex justify-between items-center`}
                  >
                    <span className="text-[12px] text-[rgba(0,0,0,0.3)]">カテゴリー選択</span>
                    <span>{cts.iconDownRes}</span>
                  </div>
                </Popconfirm>
                <div className="flex-1">
                  <span
                    // onClick={() => setOpenForm({ open: true, content: 'cate', text: '' })}
                    className="text-[12px] ml-[10px] mplus1pnormal text-[#0038FF] cursor-pointer"
                  >
                    + {t('NEW_CATE_ADD')}
                  </span>
                </div>
              </div>
              {validateCate || valueCate?.length === 0 ? (
                <p className="text-[10px] text-[#C70000]/60">*製品カテゴリを選択してください</p>
              ) : null}
              <span className="text-[14px] mplus1pmedium mt-[20px]">
                {t('PRODUCT_CONCERN_TAG')}{' '}
              </span>
              <div className="flex items-center mt-[10px]">
                <Popconfirm
                  title="aaa"
                  placement="bottom"
                  description={() => {
                    return (
                      <div className="bg-white flex flex-col rounded-[5px] px-[1px] py-[2px] border border-[rgba(0,0,0,0.2)]">
                        <Checkbox.Group
                          options={tagProduct?.map((item: any) => item?.name)}
                          defaultValue={dataProduct?.tags?.map((i: any) => i?.name)}
                          onChange={onChangeCheckBoxTag}
                          className="flex flex-col ckb_gr ckb_tag_product"
                        />
                      </div>
                    );
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <div
                    className={`${
                      validateTag
                        ? 'border-[#E06469] focus:border-[#E06469] focus:shadow-[0px_0px_1px_1px_#FF9B9B] hover:border-[#E06469] hover:shadow-[0px_0px_1px_1px_#FF9B9B] shadow-[0px_0px_1px_1px_#FF9B9B]'
                        : 'border-[rgba(0,0,0,0.5)]'
                    } h-[29px] bg-white px-[2px] border  flex-1 rounded-[5px] flex justify-between items-center`}
                  >
                    <span className="text-[12px] text-[rgba(0,0,0,0.3)]">お悩みタグ選択</span>
                    <span>{cts.iconDownRes}</span>
                  </div>
                </Popconfirm>
                <span
                  // onClick={() => setOpenForm({ open: true, content: 'tag', text: '' })}
                  className="text-[12px] ml-[10px] flex-1 mplus1pnormal text-[#0038FF] cursor-pointer"
                >
                  + {t('ADD_NEW_TAG')}
                </span>
              </div>
              {validateTag ? (
                <p className="text-[10px] text-[#C70000]/60">*商品タグを選択してください</p>
              ) : null}
              <span className="text-[14px] mplus1pmedium mt-[20px]">商品ブランド名</span>
              <div className="flex items-center mt-[10px]">
                <Popconfirm
                  title="aaa"
                  placement="bottom"
                  description={() => {
                    return (
                      <div className="bg-white flex flex-col rounded-[5px] px-[1px] py-[2px] border border-[rgba(0,0,0,0.2)]">
                        {productBrand?.map((x: any) => {
                          return (
                            <div className="flex flex-col h-[27px] justify-center ckb_brand">
                              <Checkbox
                                key={x?.id}
                                onChange={onChangeBrand}
                                checked={x?.id == valueBrand}
                                value={x?.id}
                                className="px-[10px] rounded-[2px]"
                              >
                                {x?.name}
                              </Checkbox>
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <div className="h-[29px] bg-white px-[2px] border border-[rgba(0,0,0,0.5)] flex-1 rounded-[5px] flex justify-between items-center">
                    <span className="text-[12px] text-[rgba(0,0,0,0.3)]">ブランド名選択</span>
                    <span>{cts.iconDownRes}</span>
                  </div>
                </Popconfirm>
                <span
                  onClick={() => setOpenForm({ open: true, content: 'brand', text: '' })}
                  className="text-[12px] ml-[10px] flex-1 mplus1pnormal text-[#0038FF] cursor-pointer"
                >
                  + 新規ブランド名追加
                </span>
              </div>
              {openForm.open && (
                <>
                  <Input
                    value={openForm.text}
                    placeholder={`${
                      (openForm.content === 'brand' && 'ブランド名書') ||
                      (openForm.content === 'cate' && 'カテゴリ名ブック') ||
                      (openForm.content === 'tag' && 'タグネームブック')
                    }`}
                    className="h-[39px] w-full mt-[15px]"
                    onChange={(e) =>
                      setOpenForm({
                        open: openForm.open,
                        content: openForm.content,
                        text: e.target.value as string,
                      })
                    }
                  />
                  <div className="w-full flex justify-end">
                    <button
                      onClick={() => {
                        if (openForm.content === 'brand') {
                          dispatch(addProductBrand({ token, inputs: { name: openForm.text } }));
                        }
                        if (openForm.content === 'cate') {
                          dispatch(
                            addCategory({ token, inputs: { name: openForm.text, images: 'img' } })
                          );
                        }
                        if (openForm.content === 'tag') {
                          dispatch(addProductTag({ token, inputs: { name: openForm.text } }));
                        }
                      }}
                      className="h-[21px] text-white rounded-[10px] bg-[#3E6100] text-[10px] px-[20px] mt-[5px]"
                    >
                      {t('ADDITION')}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* <div className="w-full mt-[34px] flex flex-col border border-[#C1C1C1]">
            <span className="h-[39px] flex justify-center items-center mplus1pmedium text-[14px] bg-[#DCBA78] border-[rgba(0,0,0,0.4)]">
              {t('REVIEW')}
            </span>
            <div className="py-[15px] bg-[#FBF8F1] flex flex-col px-[17px] border border-t-[#C1C1C1]">
              <span className="text-[14px] mplus1pnormal">{t('USERNAME')}</span>
              <Input className="h-[39px] mt-[10px] w-[80%] rounded-[0px]" />

              <span className="text-[14px] mplus1pnormal mt-[20px]">{t('STAR')} </span>
              <div className="w-[180px] bg-white border border-[rgba(0,0,0,0.2)] h-[39px] flex justify-center items-center mt-[10px]">
               
                <span>
                  <Rate tooltips={desc} onChange={setValueStar} value={valueStar} />
                  {value ? <span className="ant-rate-text">{desc[valueStar - 1]}</span> : ''}
                </span>
              </div>
              <span className=" text-[14px] mplus1pnormal mt-[20px]">{t('REVIEW')}</span>
              <TextArea
                className="mt-[10px] rounded-[0px] text_area_add"
                showCount
                maxLength={250}
                style={{ height: 200, resize: 'none' }}
              />
              <div className="flex w-full justify-center">
                <span className="bg-[#3E6100] mt-[20px] rounded-[10px] h-[39px] w-[96px] text-[14px] mplus1pextrabold flex items-center justify-center text-white cursor-pointer">
                  {t('SALE_PUBLISH')}
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* </div> */}
      <Modal
        mask={false}
        centered
        open={openModal}
        className="modal_delete h-[270px] !w-[375px]"
        //   onOk={() => setOpenModal(false)}
        //   onCancel={() => setOpenModal(false)}
      >
        <div className="flex flex-col justify-between items-center h-full">
          <span>{cts.iconWarning}</span>
          <span className="text-[14px] mplus1pmedium">このイメージを削除してもよろしいですか?</span>
          <div className="flex items-center w-full">
            <span
              className="h-[34px] rounded-[5px] flex items-center justify-center bg-[#9B0101] cursor-pointer text-white text-[14px] mplus1pextrabold w-full mr-[5px]"
              onClick={handleDeleImg}
            >
              {t('DELETE')}
            </span>
            <span
              className="h-[34px] rounded-[5px] flex items-center justify-center bg-[#3E6100] cursor-pointer text-white text-[14px] mplus1pextrabold w-full ml-[5px]"
              onClick={() => setOpenModal(false)}
            >
              {t('CANCEL')}
            </span>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default EditMerchandisePage;
