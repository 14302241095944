import { getToken } from '../../helpers';
import { Redirect, RouteProps, Route } from 'react-router-dom';

import Header from './Header';
import Sidebar from './Sidebar';
import { useAppSelector } from '../../redux/hooks';
import { useEffect } from 'react';

export function PrivateRoute(props: RouteProps) {
  const tokeGet = getToken();
  // const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);

  // useEffect(() => {
  //   document.head.innerHTML += isMobileDevice
  //     ? `
  //   <meta name="viewport" content="width=device-width, initial-scale=1" />`
  //     : `<meta name="viewport" content="initial" />`;
  // }, [isMobileDevice]);

  if (!tokeGet) return <Redirect to="/login" />;

  return (
    <>
      <div className="wrapper overflow-hidden">
        <Header />
        <div className={`article article-full`}>
          <div className="setup-page flex w-full">
            <div className="left-side w-[20%] bg-[#2D4600] h-screen overflow-auto flex flex-col justify-start">
              <div className="overflow-auto content-left pb-[20%]">
                <Sidebar />
              </div>
            </div>
            <div className="w-[80%] h-screen right-side overflow-auto bg-[rgba(176,194,132,0.05)]">
              <div className="content">
                <Route {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

    // <div className="wrapper overflow-hidden">
    //   <Header isMobileDevice={isMobileDevice} />
    //   <div className={`article article-full`}>
    //     <div className="setup-page tablet:h-screen">
    //       <Sidebar
    //         isMobileDevice={isMobileDevice}
    //       />
    //       <div
    //         className={`${
    //           isShowSidebar.IsShowSidebar && isMobileDevice ? 'tablet:brightness-[0.4] ' : ''
    //         }right-side overflow-auto bg-[#f4f5f8] tablet:!w-screen`}
    //       >
    //         {/* <div className="setup-breadcrumb">{_renderBreadcrumb()}</div> */}
    //         <div className="daily-wrap_content h-screen mt-8 mx-8">
    //           <Route {...props} />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
