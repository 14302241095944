import { Button, Checkbox, Image, Input, Radio, Select, Space, Table, Upload } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { ColumnsType } from 'antd/es/table';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as cts from '../../constants/ui/svg';
import type { RadioChangeEvent } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import ModalQuickView from './ModalQuickView';
import { fetchAllOrderByAdmin, fetchOrderInfoByAdmin, updateManyOrder } from 'slices/orderSlice';
import { useAppSelector } from 'redux/hooks';
import { errorToast, getToken } from 'helpers';
import { useDispatch } from 'react-redux';
import { OrderTypes } from '../../models';
import { Link, useHistory } from 'react-router-dom';
interface DataType {
  key: React.Key;
  order: string;
  name: any;
  quick_view: any;
  day: any;
  state: any;
  amount_money: any;
}

const { Search } = Input;
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const onSearch = (value: string) => console.log(value);

const Order = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [dataQuickView, setDataQuickView] = useState<any>(null);
  const [dataDetail, setDataDetail] = useState<any>(false);
  const date = moment().format('MM-DD-YYYY');

  const allOrderByAdmin = useAppSelector((state) => state.orders.orderByAdminList);
  const orderInfoByAdmin = useAppSelector((state) => state.orders.getOrderInfoByAdmin);
  const token = getToken();
  const dispatch = useDispatch();
  const [idSelect, setIdSelect] = useState<any>([]);
  const [statusOrder, setStatusOrder] = useState<any>('');
  const [tabCurrent, setTabCurrent] = useState(1);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: OrderTypes[]) => {
      setIdSelect(selectedRows?.map((x: any) => x?._id));
    },
    getCheckboxProps: (record: OrderTypes) => ({
      // disabled: record.name === 'Disabled User', // Column configuration not to be checked
      // name: record.name,
    }),
  };
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <span className="text-[12px] mplus1pnormal text-[#0038FF]">+ upload new image</span>
  );
  const columns: ColumnsType<OrderTypes> = [
    // {
    //   title: <Checkbox disabled={true} className="flex justify-center w-full ckb_table"></Checkbox>,
    //   key: 'operation',
    //   fixed: 'left',
    //   width: 80,
    //   render: () => (
    //     <>
    //       <Checkbox className="flex justify-center w-full ckb_table" onChange={onChange}></Checkbox>
    //     </>
    //   ),
    // },
    {
      title: <div className="flex justify-center w-full ckb_table"></div>,
      key: 'operation',
      fixed: 'left',
      width: 0,
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-start w-full">{t('ORDER')}</span>
      ),
      width: 120,
      dataIndex: 'order',
      key: 'order',
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            <Link to={`/order/${record?._id}`} />;
          },
        };
      },
      render: (value: any, record) => (
        <Link to={`/order/${record?._id}`} className="w-full flex justify-start text-[#0038FF]">
          #{value?.code} {value?.name}
        </Link>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">
          {t('QUICK_VIEW')}
        </span>
      ),
      dataIndex: '_id',
      key: '_id',
      width: 80,
      render: (value, record) => (
        <div className="w-[auto] flex justify-center">
          <button
            className="flex cursor-pointer justify-center w-[30px]"
            onClick={() => {
              // setDataQuickView(record?._id);
              dispatch(fetchOrderInfoByAdmin({ token, id: record?._id }));
              setOpen(true);
            }}
          >
            {cts.iconEye}
          </button>
        </div>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">{t('DAY')}</span>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 180,
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            <Link to={`/order/${record?._id}`} />;
          },
        };
      },
      render: (value: any, record) => (
        <Link
          to={`/order/${record?._id}`}
          className="text-[12px] mplus1pmedium flex justify-center w-full"
        >
          {moment(value).format('MM月DD日') + '  ' + moment(value).format('YYYY年')}
        </Link>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-start w-full">{t('STATE')}</span>
      ),
      dataIndex: 'shippingStatus',
      key: 'shippingStatus',
      width: 100,
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            <Link to={`/order/${record?._id}`} />;
          },
        };
      },
      render: (value: any, record) => (
        <Link to={`/order/${record?._id}`} className=" flex justify-start w-full">
          {reanderState(value)}
        </Link>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center ">
          {t('AMOUNT_OF_MONEY')}
        </span>
      ),
      dataIndex: 'totalPayment',
      key: 'totalPayment',
      width: 100,
      fixed: 'right',
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            <Link to={`/order/${record?._id}`} />;
          },
        };
      },
      render: (value: any, record) => (
        <Link
          to={`/order/${record?._id}`}
          className="text-[12px] mplus1pnormal flex text-start justify-center"
        >
          ¥{Number(value).toLocaleString()}
        </Link>
      ),
    },
  ];
  const arr = allOrderByAdmin?.result?.map((item: any, idx: any) => {
    return {
      ...item,
      key: idx,
      order: {
        name: item?.customer[0]?.userName || item?.customer[0]?.lastName,
        code: item?.code,
      },
    };
  });

  const reanderState = (value: string) => {
    switch (value) {
      case 'predelivery':
        return (
          <div className="bg-[#78C4DC]/50 p-[10px] rounded-[10px] text-[#043241]/50">
            <span>{t('PREPARING_FOR_DELIVERY')}</span>
          </div>
        );
      case 'delivery':
        return (
          <div className="bg-[#DCBA78]/50 p-[10px] rounded-[10px] text-[#6C5805]">
            <span>{t('SHIPPING')}</span>
          </div>
        );
      case 'delivered':
        return (
          <div className="bg-[#B0C284]/50 p-[10px] rounded-[10px] text-[#2D4600]">
            <span>{t('ARRIVED')}</span>
          </div>
        );
      case 'cancel':
        return (
          <div className="bg-[#C70000]/20 p-[10px] rounded-[10px] text-[#C70000]">
            <span>{t('CANCEL')}</span>
          </div>
        );
      default:
        break;
    }
  };

  const pageSize = 10;
  const [pagi, setPagi] = useState<any>({
    allOrderByAdmin: [],
    totalPage: allOrderByAdmin?.count / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });
  const handleChangeStatus = (value: { value: string; label: React.ReactNode }) => {
    setStatusOrder(value);
    if (idSelect?.length > 0) {
      dispatch(updateManyOrder({ token, ids: idSelect, shippingStatus: value }));
    } else {
      errorToast('配送状況を更新する前に注文ボックスにチェックを入れてください');
    }
    setTimeout(() => {
      setIdSelect([]);
    }, 500);
  };
  useEffect(() => {
    dispatch(fetchAllOrderByAdmin({ token, limit: pageSize, page: pagi.current }));
  }, [token, dispatch, pagi.current]);
  // useEffect(() => {
  //   if (dataDetail) {
  //     console.log('dataDetail', dataDetail);

  //     dispatch(fetchOrderInfoByAdmin({ token, id: dataDetail }));
  //   }
  // }, [dispatch, token, dataDetail]);

  return (
    <div className="pt-[26px] px-[35px] flex flex-col pb-[100px]">
      <div>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-[20px] mplus1pextrabold mr-[54px]">{t('THE_ORDER')}</span>
          </div>
          <div className="flex items-center">
            {/* <span className="h-[40px] w-[48px] border-[0.5px] rounded-l-[10px] border-black/50 bg-[rgba(220,186,120,0.5)] flex justify-center items-center">
              {cts.iconSearch}
            </span>
            <Input
              placeholder="SEARCH PRODUCT"
              className="w-[252px] h-[40px] border-[0.5px] border-black/50 rounded-r-[10px] rounded-l-[0px]"
            /> */}
          </div>
        </div>
        <div className="flex mt-[60px] w-full items-center justify-between">
          <div className="flex items-center">
            <span className="underline text-[14px] mplus1pnormal text-[#0038FF]">
              showing{' '}
              <span className="text-black underline">
                {pagi.minIndex + 1} -{' '}
                {pagi?.current * pageSize - (pageSize - allOrderByAdmin?.result?.length)} of{' '}
                {allOrderByAdmin?.count}
              </span>
            </span>
            <span className="text-[#0038FF] underline text-[14px] ml-[20px]">draft</span>
          </div>
          <div className="flex items-center ctn_btn_merchandise w-[70%] pr-[10px] pb-[10px] h-full overflow-auto overflow-y-hidden ">
            <Select
              defaultValue="一括操作"
              className="w-full slc_merchandise mr-[10px]"
              // onChange={handleChange}
              options={[{ value: '一括操作', label: '一括操作' }]}
            />
            {/* <Button className="bg-white h-[30px] border-[0.5px] text-[14px] mplus1pnormal border-black mr-[10px]">
                {t('APPLY')}
              </Button> */}
            <Select
              placeholder="アプライ"
              className=" slc_merchandise slc_status w-[243px] mr-[10px]"
              onChange={handleChangeStatus}
              options={[
                { value: 'predelivery', label: 'ステータスを配送準備中に変更する' },
                { value: 'delivery', label: 'ステータスを配送中に変更する' },
                { value: 'delivered', label: 'ステータスを到着済みに変更する' },
                { value: 'cancel', label: 'ステータスをキャンセルに変更する' },
              ]}
            />
            <Select
              defaultValue="カテゴリー一覧"
              className="w-full slc_merchandise mr-[10px]"
              // onChange={handleChange}
              options={[{ value: 'カテゴリー一覧 ', label: 'カテゴリー一覧' }]}
            />
            <Select
              defaultValue=" 商品 のタイプでフィルター"
              className="w-full slc_merchandise mr-[10px]"
              // onChange={handleChange}
              options={[
                { value: ' 商品 のタイプでフィルター ', label: ' 商品 のタイプでフィルター' },
              ]}
            />
            <Select
              defaultValue="商品ストックステータスでフィルター "
              className="w-full slc_merchandise"
              // onChange={handleChange}
              options={[
                {
                  value: '商品ストックステータスでフィルター  ',
                  label: '商品ストックステータスでフィルター ',
                },
              ]}
            />
          </div>
        </div>

        <div className="mt-[25px] mb-[100px]">
          <Table
            className="table_merchandise ckb_brand"
            columns={columns}
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            dataSource={arr}
            scroll={{ x: 1000 }}
            pagination={{
              showSizeChanger: false,
              total: allOrderByAdmin?.count,
              pageSize: 10,
              current: pagi.current,

              onChange: (page: any) => {
                setPagi({
                  current: page,
                  minIndex: (page - 1) * pageSize,
                  maxIndex: page * pageSize,
                });
              },
            }}
          />
          <ModalQuickView
            open={open}
            setOpen={setOpen}
            dataQuickView={dataQuickView}
            setDataQuickView={setDataQuickView}
            state={reanderState}
          />
        </div>
      </div>
    </div>
  );
};
export default Order;
