import request from './axiosServices';
import {
  URL_GET_PRODUCT_TAGS,
  URL_ADD_PRODUCT_TAG,
  URL_GET_ALL_PRODUCT_TAG_BY_ADMIN,
  URL_UPDATE_PRODUCT_TAG,
  URL_DELETE_PRODUCT_TAG,
} from 'graphql';

interface requestTypes {
  token?: string;
  limit?: number;
  inputs?: {
    name?: string;
    slug?: string;
    description?: string;
  };
  id?: string;
  page?: string;
}

export function fetchProductTagAPI({ token, limit }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_GET_PRODUCT_TAGS,
      variables: { limit },
    },
    token,
  });
}
export function addProductTagAPI({ token, inputs }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_ADD_PRODUCT_TAG,
      variables: { inputs },
    },
    token,
  });
}
export function fetchAllProductTagsByAdminAPI({ token, limit, page }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_GET_ALL_PRODUCT_TAG_BY_ADMIN,
      variables: { limit, page },
    },
    token,
  });
}
export function updateProductTagAPI({ token, id, inputs }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_UPDATE_PRODUCT_TAG,
      variables: { id, inputs },
    },
    token,
  });
}
export function deleteProductTagAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_DELETE_PRODUCT_TAG,
      variables: { id },
    },
    token,
  });
}
