import { URL_UPLOAD } from '../../constants';
import { getToken } from 'helpers';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { fetchBlogInfoByAdmin, updateBlog } from 'slices/blogSlice';
import img from '../../assets/images/blog_img.png';
import img1 from '../../assets/images/blog_1.png';
import img2 from '../../assets/images/blog_2.png';
import img3 from '../../assets/images/blog_3.png';

import * as cts from '../../constants/ui/svg';

interface ParamTypes {
  id: string;
}
const BlogDetailPage = () => {
  const { t } = useTranslation();
  const params = useParams<ParamTypes>();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = getToken();
  const blogInfoByAdmin = useAppSelector((state) => state.blogs.getBlogInfoByAdmin);
  useEffect(() => {
    dispatch(fetchBlogInfoByAdmin({ token, id: params.id }));
  }, [token, dispatch, params.id]);
  return (
    <div className="flex flex-col pt-[26px] px-[35px] mb-[100px] pb-[100px]">
      <span className="mplus1pextrabold text-[20px]">{t('BLOG_PREVIEW')}</span>
      <div className="mt-[24px] bg-white border border-[rgba(0,0,0,0.3)] w-[70%] flex flex-col pt-[40px] pl-[5%] pr-[10%] pb-[8%]">
        {/* <img
          className="h-[500px] w-full"
          src={`${
            blogInfoByAdmin?.id === '64882b92df5475201774e138'
              ? img2
              : blogInfoByAdmin?.id === '64882af3df5475201774e12e'
              ? img1
              : blogInfoByAdmin?.id === '64882c18df5475201774e142'
              ? img3
              : img
          }`}
        /> */}
        <img className="h-[500px] w-full" src={`${URL_UPLOAD}/blogs/${blogInfoByAdmin?.image}`} />
        {/* <div className="w-full">
          {blogInfoByAdmin?.tags?.map((x: any, y: any) => {
            return (
              <span
                key={y}
                className="h-[35px] text-[16px] mplus1pextrabold rounded-[5px] px-[10px] bg-[#B0C284] text-[#3E6100] flex items-center mr-[10px]"
              >
                {x?.name}
              </span>
            );
          })}
        </div> */}
        <div className="flex justify-end mt-[40px]">
          <div className="flex flex-wrap">
            {blogInfoByAdmin?.tags?.map((x: any, y: any) => {
              return (
                <span
                  key={y}
                  className="h-[35px] mt-[5px] text-[16px] mplus1pextrabold rounded-[5px] px-[10px] bg-[#B0C284] text-[#3E6100] flex items-center mr-[10px]"
                >
                  {x?.name}
                </span>
              );
            })}
          </div>

          <span className="flex items-center">
            <span className="mr-[5px]">{cts.iconClock}</span>
            <span>{moment(blogInfoByAdmin?.createdAt).format('YYYY/MM/DD')}</span>
          </span>
        </div>
        <span className="mt-[52px] mplus1pextrabold text-[24px]">{blogInfoByAdmin?.name}</span>
        <span
          dangerouslySetInnerHTML={{ __html: blogInfoByAdmin?.content }}
          className="text-[16px] mplus1pmedium"
        ></span>
      </div>
      <div className="mt-[82px] flex items-center">
        <span
          onClick={() => {
            dispatch(updateBlog({ token, id: params.id, inputs: { status: 'draft' } }));
            history.push('/blog-draft');
          }}
          className="h-[39px] px-[10px] cursor-pointer rounded-[10px] flex items-center justify-center text-white bg-[#3692E7] text-[14px] mplus1pextrabold mr-[50px]"
        >
          {t('PRESERVE')}
        </span>
        <span
          onClick={() => {
            dispatch(updateBlog({ token, id: params.id, inputs: { status: 'public' } }));
            history.push('/blog-public');
          }}
          className="h-[39px] px-[20px] cursor-pointer rounded-[10px] flex items-center justify-center text-white bg-[#3E6100] text-[14px] mplus1pextrabold mr-[50px]"
        >
          {t('RELEASE')}
        </span>
        <div
          onClick={() => history.push('/posts')}
          className="h-[39px] cursor-pointer text-[#3E6100] px-[10px] rounded-[10px] mr-[50px] border border-[#3E6100] flex items-center mplus1pextrabold text-[14px]"
        >
          {t('CANCEL')}
        </div>
      </div>
    </div>
  );
};
export default BlogDetailPage;
