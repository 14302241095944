import { useEffect, useState } from 'react';
import { Table, Input, Select, Button, Checkbox, Pagination, DatePicker, Modal, Dropdown, Space } from 'antd';
import moment, { Moment } from 'moment';
import type { ColumnsType } from 'antd/es/table';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import * as cts from '../../constants/ui/svg';
import type { PaginationProps, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import Cropper from 'components/Common/Cropper';

interface DataType {
    key: React.Key;
    user_management_name: string;
    name: any;
    quick_view: any;
    email: string;
    review: any;
    role: string;
}

const { Search } = Input;

const onSearch = (value: string) => console.log(value);
const Coupon = () => {
    const { t } = useTranslation();
    const [openForm, setOpenForm] = useState(false);
    const [dataQuickView, setDataQuickView] = useState();
    const date = moment().format('MM-DD-YYYY');

    const onChange = (e: CheckboxChangeEvent) => {
        console.log(`checked = ${e.target.checked}`);
    };

    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
    };








    return (
        <div className="pt-[26px] px-[35px] flex flex-col pb-[100px]">
            <div>
                <div className="flex flex-col items-center justify-between">
                    <div className="flex items-center">
                        <span className="text-[20px] mplus1pextrabold mr-[54px]">{t('COUPON')}</span>
                    </div>

                </div>
            </div>
        </div>
    );
};
export default Coupon;
