import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllOrderByAdminAPI } from 'api';
import Cookies from 'js-cookie';
import {
  OrderTypes,
  TypeOrderList,
  OrderOfCustomerTypes,
  TypeOrderOfCustomerList,
} from '../models';
import { RootState } from 'redux/store';
export interface orderType {
  orderByAdminList: TypeOrderList;
  orderOfCustomerList: TypeOrderOfCustomerList;
  getOrderInfoByAdmin: {};
  exportCSVOrderURL: string;
}
const initialState: orderType = {
  orderByAdminList: {
    count: 0,
    result: [] as OrderTypes[],
  },
  orderOfCustomerList: {
    count: 0,
    result: [] as OrderOfCustomerTypes[],
  },
  getOrderInfoByAdmin: {},
  exportCSVOrderURL: '',
};

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    fetchAllOrderByAdmin(state, action) {},
    fetchAllOrderByAdminSuccess(state, action) {
      state.orderByAdminList = action.payload;
    },
    updateManyOrder(state, action) {},
    updateManyOrderSuccess(state, action) {
      state.orderByAdminList.result = state.orderByAdminList.result.map((item: any) =>
        action.payload.map((x: any) => x._id).includes(item._id)
          ? action.payload.find((y: any) => y._id === item._id)
          : item
      );
    },
    fetchOrderOfCustomerList(state, action) {},
    fetchOrderOfCustomerListSuccess(state, action) {
      state.orderOfCustomerList = action.payload;
    },
    fetchOrderInfoByAdmin(state, action) {},
    fetchOrderInfoByAdminSuccess(state, action) {
      state.getOrderInfoByAdmin = action.payload;
    },
    exportCSVOrder(state, action) {},
    exportCSVOrderSuccess(state, action) {
      state.exportCSVOrderURL = action.payload;
    },
  },
});

// Actions
export const {
  updateManyOrder,
  updateManyOrderSuccess,
  fetchAllOrderByAdmin,
  fetchAllOrderByAdminSuccess,
  fetchOrderOfCustomerList,
  fetchOrderOfCustomerListSuccess,
  fetchOrderInfoByAdmin,
  fetchOrderInfoByAdminSuccess,
  exportCSVOrder,
  exportCSVOrderSuccess,
} = orderSlice.actions;
// Selectors
export const selectOrder = (state: RootState) => state.orders;

// Reducer
const orderReducer = orderSlice.reducer;
export default orderReducer;
