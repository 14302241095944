import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import './assets/scss/index.scss';
import { MasterRouter } from 'features/master';
import { PrivateRoute } from 'components/Common';
import HomePage from 'features/home';
import LoginPage from 'features/auth/LoginPage';
import MerchandisePage from 'features/products/Merchandise';
import AddNewMerchandisePage from 'features/products/AddNewMerchandise';
import CategoryMerchandisePage from 'features/products/CategoryMerchandise';
import TagMerchandisePage from 'features/products/TagMerchandise';
import Order from 'features/sales-management/Order';
import Client from 'features/sales-management/Client';
import User from 'features/user-management/User';
import Coupon from 'features/marketing/Coupon';
import Banner from 'features/marketing/Banner';
import PostPage from 'features/blog/PostPage';
import BlogAddPage from 'features/blog/BlogAddPage';
import BlogCatePage from 'features/blog/BlogCatePage';
import BlogTagPage from 'features/blog/BlogTagPage';
import ShopListPage from 'features/shop-management/ShopListPage';
import RankingControlPage from 'features/marketing/RankingControlPage';
import ProfileAdminPage from 'features/settings/ProfileAdmin';
import { getToken } from './helpers';
import AddNewAdminPage from 'features/settings/AddNewAdmin';
import EditMerchandisePage from 'features/products/EditMerchandise';
import OrderDetailPage from 'features/sales-management/OrderDetail';
import UserDetailPage from 'features/user-management/UserDetail';
import EditBlogPage from 'features/blog/EditBlogPage';
import BlogDetailPage from 'features/blog/BlogDetailPage';
import BlogPublicPage from 'features/blog/BlogPublicPage';
import BlogDraftPage from 'features/blog/BlogDraftPage';
import BlogTrashPage from 'features/blog/BlogTrashPage';
import ProductTrashPage from 'features/products/ProductTrashPage';
import ProductDraftPage from 'features/products/ProductDraftPage';
import PreviewProductPage from 'features/products/PreviewProductPage';
import PreviewProductSpamPage from 'features/products/PreviewProductSpam';
import PreviewProductTrashPage from 'features/products/PreviewProductTrash';
import ProductBrandPage from 'features/products/ProductBrand';
import EditBannerPage from 'features/marketing/EditBannerPage';

function App() {
  const history = useHistory();

  const tokeGet = getToken();

  const pathName = history.location.pathname;

  useEffect(() => {
    if (!tokeGet) {
      history.push('/login');
    } else if (pathName == '/login') {
      history.push('/');
    } else {
      history.push(pathName);
    }
  }, [pathName]);
  return (
    <>
      <Switch>
        <Route exact path="/login" component={() => <LoginPage />} />
        <PrivateRoute path="/products" component={() => <MerchandisePage />} />
        <PrivateRoute path="/product-draft" component={() => <ProductDraftPage />} />
        <PrivateRoute path="/brand" component={() => <ProductBrandPage />} />
        <PrivateRoute path="/product-trash" component={() => <ProductTrashPage />} />
        <PrivateRoute path="/product/:id" children={<EditMerchandisePage />} />
        <PrivateRoute path="/add-new" component={() => <AddNewMerchandisePage />} />
        <PrivateRoute path="/category" component={() => <CategoryMerchandisePage />} />
        <PrivateRoute path="/tag" component={() => <TagMerchandisePage />} />
        <PrivateRoute path="/posts" component={() => <PostPage />} />
        <PrivateRoute path="/blog-public" component={() => <BlogPublicPage />} />
        <PrivateRoute path="/blog-draft" component={() => <BlogDraftPage />} />
        <PrivateRoute path="/blog-trash" component={() => <BlogTrashPage />} />
        <PrivateRoute path="/post/:id" component={() => <BlogDetailPage />} />
        <PrivateRoute path="/edit-post/:id" children={<EditBlogPage />} />
        <PrivateRoute path="/blog-add" component={() => <BlogAddPage />} />
        <PrivateRoute path="/blog-cate" component={() => <BlogCatePage />} />
        <PrivateRoute path="/blog-tag" component={() => <BlogTagPage />} />
        <PrivateRoute path="/shop-list" component={() => <ShopListPage />} />
        <PrivateRoute path="/orders" component={() => <Order />} />
        <PrivateRoute path="/order/:id" children={<OrderDetailPage />} />
        <PrivateRoute path="/client" component={() => <Client />} />
        <PrivateRoute path="/users" component={() => <User />} />
        <PrivateRoute path="/user/:id" children={<UserDetailPage />} />
        <PrivateRoute path="/coupon" component={() => <Coupon />} />
        <PrivateRoute path="/banner" component={() => <Banner />} />
        <PrivateRoute path="/edit-banner/:id" children={() => <EditBannerPage />} />

        <PrivateRoute path="/paid-ranking-mana" component={() => <RankingControlPage />} />
        <PrivateRoute path="/profile-admin" component={() => <ProfileAdminPage />} />
        <PrivateRoute path="/add-new-admin" component={() => <AddNewAdminPage />} />
        <PrivateRoute path="/preview-products" component={() => <PreviewProductPage />} />
        <PrivateRoute path="/preview-products-spam" component={() => <PreviewProductSpamPage />} />
        <PrivateRoute
          path="/preview-products-trash"
          component={() => <PreviewProductTrashPage />}
        />

        <PrivateRoute path="/" component={() => <HomePage />} />
        {/* <Route path="/" element={<HomePage />} /> */}
      </Switch>
    </>
  );
}

export default App;
