import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { RootState } from 'redux/store';
import {
  BlogTypes,
  TypeBlogList,
  AllBlogTagsByAdminTypes,
  TypeAllBlogTagsByAdminList,
} from '../models';

export interface blogType {
  blogByAdminList: TypeBlogList;
  getAllCategoryBlog: [];
  blogTags: [];
  getBlogInfoByAdmin: {};
  getAllBlogTagsByAdmin: TypeAllBlogTagsByAdminList;
  uploadImage: {};
}
const initialState: blogType = {
  blogByAdminList: {
    count: 0,
    countAll: 0,
    countDraft: 0,
    countPublic: 0,
    countTrash: 0,
    result: [] as BlogTypes[],
  },
  getAllCategoryBlog: [],
  blogTags: [],
  getBlogInfoByAdmin: {},
  getAllBlogTagsByAdmin: {
    count: 0,
    result: [] as AllBlogTagsByAdminTypes[],
  },
  uploadImage: {},
};

const blogSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    fetchAllBlogByAdmin(state, action) {},
    fetchAllBlogByAdminSuccess(state, action) {
      state.blogByAdminList = action.payload;
    },
    updateBlog(state, action) {},
    updateBlogSuccess(state, action) {
      state.blogByAdminList.result = state.blogByAdminList.result.map((item: any) =>
        item.id === action.payload.id ? action.payload : item
      );
      // state.blogByAdminList.result = state.blogByAdminList.result.filter(
      //   (item: any) => item?.status !== 'trash'
      // );
      // state.blogByAdminList.result = state.blogByAdminList.result.filter(
      //   (item: any) => item?.status !== 'public'
      // );
      // state.blogByAdminList.result = state.blogByAdminList.result.filter(
      //   (item: any) => item?.status !== 'draft'
      // );
    },
    updateBlogStatus(state, action) {},
    updateBlogStatusSuccess(state, action) {
      state.blogByAdminList.result = state.blogByAdminList.result.filter(
        (item: any) => item.id !== action.payload.id
      );
      if (action.payload?.status === 'draft') {
        state.blogByAdminList.countDraft += 1;
        // state.blogByAdminList.countAll += 1;
      }
      if (action.payload?.status === 'public') {
        state.blogByAdminList.countPublic += 1;
        // state.blogByAdminList.countAll += 1;
      } else {
        state.blogByAdminList.countTrash += 1;
        state.blogByAdminList.countAll -= 1;
      }
      // state.blogByAdminList.result = state.blogByAdminList.result.filter(
      //   (item: any) => item?.status !== 'trash'
      // );
      // state.blogByAdminList.result = state.blogByAdminList.result.filter(
      //   (item: any) => item?.status !== 'public'
      // );
      // state.blogByAdminList.result = state.blogByAdminList.result.filter(
      //   (item: any) => item?.status !== 'draft'
      // );
    },
    deleteBlog(state, action) {},
    deleteBlogSuccess(state, action) {
      state.blogByAdminList.result = state.blogByAdminList.result.filter(
        (x) => x.id !== action.payload
      );
      state.blogByAdminList.countTrash -= 1;
    },
    cloneBlog(state, action) {},
    cloneBlogSuccess(state, action) {
      state.blogByAdminList.result.push(action.payload);
    },
    addBlog(state, action) {},
    addBlogSuccess(state, action) {
      state.blogByAdminList.result.push(action.payload);
      // state.blogByAdminList.countAll += 1;
      // if (action.payload?.status === 'draft') {
      //   state.blogByAdminList.countDraft += 1;
      // }
      // if (action.payload?.status === 'public') {
      //   state.blogByAdminList.countPublic += 1;
      // }
    },
    addCategoryBlog(state, action) {},
    addCategoryBlogSuccess(state, action) {},
    addBlogTag(state, action) {},
    addBlogTagSuccess(state, action) {
      state.getAllBlogTagsByAdmin.result.push(action.payload);
    },
    fetchAllCategoryBlog(state, action) {},
    fetchAllCategoryBlogSuccess(state, action) {
      state.getAllCategoryBlog = action.payload;
    },
    fetchBlogsTag(state, action) {},
    fetchBlogsTagSuccess(state, action) {
      state.blogTags = action.payload;
    },
    fetchBlogInfoByAdmin(state, action) {},
    fetchBlogInfoByAdminSuccess(state, action) {
      state.getBlogInfoByAdmin = action.payload;
    },
    fetchAllBlogTagsByAdmin(state, action) {},
    fetchAllBlogTagsByAdminSuccess(state, action) {
      state.getAllBlogTagsByAdmin = action.payload;
    },
    deleteBlogTag(state, action) {},
    deleteBlogTagSuccess(state, action) {
      state.getAllBlogTagsByAdmin.result = state.getAllBlogTagsByAdmin.result.filter(
        (x) => x.id !== action.payload
      );
    },
    updateBlogTag(state, action) {},
    updateBlogTagSuccess(state, action) {
      state.getAllBlogTagsByAdmin.result = state.getAllBlogTagsByAdmin.result.map((item: any) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    uploadImage(state, action) {},
    uploadImageSuccess(state, action) {
      state.uploadImage = action.payload;
    },
  },
});

// Actions
export const {
  updateBlogStatus,
  updateBlogStatusSuccess,
  updateBlogTag,
  updateBlogTagSuccess,
  deleteBlogTag,
  deleteBlogTagSuccess,
  fetchAllBlogTagsByAdmin,
  fetchAllBlogTagsByAdminSuccess,
  fetchAllBlogByAdmin,
  fetchAllBlogByAdminSuccess,
  updateBlog,
  updateBlogSuccess,
  deleteBlog,
  deleteBlogSuccess,
  cloneBlog,
  cloneBlogSuccess,
  addBlog,
  addBlogSuccess,
  addCategoryBlog,
  addCategoryBlogSuccess,
  addBlogTag,
  addBlogTagSuccess,
  fetchAllCategoryBlog,
  fetchAllCategoryBlogSuccess,
  fetchBlogsTag,
  fetchBlogsTagSuccess,
  fetchBlogInfoByAdmin,
  fetchBlogInfoByAdminSuccess,
} = blogSlice.actions;
// Selectors
export const selectBlog = (state: RootState) => state.blogs;

// Reducer
const blogReducer = blogSlice.reducer;
export default blogReducer;
