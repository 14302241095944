import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Table,
  Input,
  Select,
  Button,
  Checkbox,
  Pagination,
  DatePicker,
  Modal,
  Dropdown,
  Space,
} from 'antd';
import moment, { Moment } from 'moment';
import type { ColumnsType } from 'antd/es/table';
import * as cts from '../../constants/ui/svg';
import type { PaginationProps, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getToken } from 'helpers';
import { useAppSelector } from 'redux/hooks';
import {
  deleteUserByAdmin,
  exportCSVUsers,
  fetchAllUserByAdmin,
  fetchUserInfoByAdmin,
  searchUser,
} from 'slices/userSlice';
import { UserByAdminTypes } from 'models';
import { Link } from 'react-router-dom';
import ModalConfirm from 'components/Common/ModalConfirm';

interface DataType {
  key: React.Key;
  user_management_name: string;
  firstName: any;
  lastName: any;
  quick_view: any;
  email: string;
  review: any;
  role: string;
  dateOfBirth: string;
  gender: string;
  postCode: string;
  address: string;
  inquiryPhoneNumber: string;
}

const { Search } = Input;

const onSearch = (value: string) => console.log(value);
const User = () => {
  const { t } = useTranslation();
  const date = moment().format('MM-DD-YYYY');
  const dispatch = useDispatch();
  const token = getToken();
  const [idRecord, setIdRecord] = useState<any>();
  const [record, setRecord] = useState<any>();
  const [btnSearch, setBtnSearch] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const recordRef = useRef('');

  const userByAdmin = useAppSelector((state) => state.users.getAllUserByAdmin);
  const searchUserByAdmin = useAppSelector((state) => state.users.searchUser);
  const urlExport = useAppSelector((state) => state.users.exportCSVUsersURL);

  const [searchText, setSearchText] = useState('');
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Link to={`/user/${idRecord}`}>view customer</Link>,
    },
    {
      key: '2',
      label: <span>send password reset</span>,
    },
    {
      key: '3',
      label: (
        <span
          onClick={() => {
            setOpenModal(true);
            recordRef.current = record;
          }}
        >
          delete customer
        </span>
      ),
    },
  ];
  const onDelete = () => {
    dispatch(deleteUserByAdmin({ token, id: idRecord }));
  };
  const columns: ColumnsType<UserByAdminTypes> = [
    {
      title: <span></span>,
      key: 'operation',
      fixed: 'left',
      width: 0,
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-start w-full ml-[20px]">
          {t('USER_MANAGEMENT_NAME')}
        </span>
      ),
      width: 180,
      dataIndex: 'user',
      key: 'user',
      render: (value: any, record) => (
        <div className="flex items-center pl-[20px]">
          {cts.avtUser}
          <Link to={`/user/${record?.id}`}>
            <span className="w-full flex justify-start cursor-pointer text-[#0038FF] text-[14px] ml-[20px] mplus1pextrabold">
              {value?.name || '--'}
            </span>
          </Link>
        </div>
      ),
    },

    {
      title: <span></span>,
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (value, record) => (
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          placement="bottomLeft"
          className="bg-[#DCBA78]/50 flex justify-center items-center"
        >
          <Button
            className="w-[20px] h-[20px] rounded-[2px] p-0 "
            onClick={() => {
              setIdRecord(record?.id);
              setRecord(record);
            }}
          >
            <span className="!flex items-center mb-[6px]">...</span>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">
          {t('GIVEN_NAME')}
        </span>
      ),
      dataIndex: 'lastName',
      key: 'lastName',
      width: 200,
      render: (value: any) => <span className="flex justify-center w-full">{value || '--'}</span>,
    },

    {
      title: <span className="text-[14px] mplus1pnormal flex justify-start w-full">E-MAIL</span>,
      dataIndex: 'email',
      key: 'email',
      width: 220,
      render: (value: any) => (
        <span className="text-[12px] mplus1pmedium flex justify-start w-full">{value}</span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-start w-full">ROLE</span>,
      dataIndex: 'role',
      key: 'role',
      width: 100,
      render: (value: any) => <span className=" flex justify-start w-full">{value || '--'}</span>,
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center ">REVIEW</span>,
      dataIndex: 'numberOfReview',
      key: 'numberOfReview',
      fixed: 'right',
      width: 150,
      render: (value: any) => (
        <span className="text-[12px] mplus1pnormal flex text-start justify-center">
          {value || 0}
        </span>
      ),
    },
  ];
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: UserByAdminTypes[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: UserByAdminTypes) => ({
      // disabled: record.name === 'Disabled User', // Column configuration not to be checked
      // name: record.name,
    }),
  };
  const arr = userByAdmin?.result?.map((item: any, idx: any) => {
    return {
      ...item,
      key: idx,
      user: {
        avatar: item?.avatar,
        name: item?.userName,
      },
    };
  });
  const arrSearch = searchUserByAdmin?.result?.map((item: any, idx: any) => {
    return {
      ...item,
      key: idx,
      user: {
        avatar: item?.avatar,
        name: item?.userName,
      },
    };
  });
  const pageSize = 10;
  const [pagi, setPagi] = useState<any>({
    userByAdmin: [],
    totalPage: userByAdmin?.count / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });
  const [pagiSearch, setPagiSearch] = useState<any>({
    searchUserAdmin: [],
    totalPage: searchUserByAdmin?.count / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });
  useEffect(() => {
    dispatch(exportCSVUsers({ token }));
  }, [dispatch, token]);
  useEffect(() => {
    if (searchText === '') {
      setBtnSearch(false);
    }
  }, [searchText]);
  useEffect(() => {
    if (btnSearch) {
      dispatch(
        searchUser({ token, limit: pageSize, page: pagiSearch.current, searchText: searchText })
      );
    } else {
      dispatch(fetchAllUserByAdmin({ token, limit: pageSize, page: pagi.current }));
    }
  }, [btnSearch, token, pagi.current, pagiSearch.current]);

  return (
    <div className="pt-[26px] px-[35px] flex flex-col pb-[100px]">
      <div>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-[20px] mplus1pextrabold mr-[54px]">ユーザー管理</span>
          </div>
        </div>
        <div className="flex justify-end">
          {/* <button className="p-[10px] bg-[#DBB312] rounded-[10px] text-[white] text-[14px] mplus1pbold">
              {t('ADD_NEW')}
            </button> */}
          <div className="flex items-center mb-[30px]">
            <button
              onClick={() => setBtnSearch(true)}
              className="h-[40px] w-[48px] border rounded-l-[10px] border-black/50 bg-[#DCBA78] flex justify-center items-center"
            >
              {cts.iconSearch}
            </button>
            <Input
              onChange={(e) => setSearchText(e.target.value)}
              className="w-[252px] h-[40px] border-[0.5px] border-black/50 rounded-r-[10px] rounded-l-[0px]"
            />
          </div>
        </div>
        <div className="flex items-center w-full justify-between">
          <div className="flex items-center">
            <span className="underline text-[14px] mplus1pnormal text-[#0038FF]">
              {t('ALL')}{' '}
              <span className="text-black underline">
                ( {btnSearch ? searchUserByAdmin?.count : userByAdmin?.count})
              </span>
            </span>
          </div>
          {/* <div className="pl-[255px] ">
              <span className="text-[#0038FF] text-[14px] mplus1pnormal">CUSTOMER</span>(
              {numberCustomer()})
            </div> */}
          <div className="flex items-center">
            <Button
              onClick={() => window.open(urlExport)}
              className="bg-[#3692E7] flex mr-[40px] btn-download"
              type="primary"
              icon={cts.iconCLoud}
              size={'large'}
            >
              {t('DOWNLOAD')}
            </Button>
            <Select
              defaultValue="一括操作"
              className=" slc_merchandise mr-[10px]"
              // onChange={handleChange}
              options={[{ value: '一括操作', label: '一括操作' }]}
            />
            {/* <Button className="bg-white h-[30px] border-[0.5px] !border-[#0038FF] text-[14px] mplus1pnormal border-black ">
                                {t('APPLY')}
                            </Button> */}
            {/* <Button className="bg-white h-[30px] border-[0.5px] !border-[#0038FF] text-[#0038FF] text-[14px] mplus1pnormal mr-[10px]">
                CHANGE
              </Button> */}
          </div>
        </div>

        <div className="mt-[25px] mb-[100px]">
          <Table
            className="table_merchandise table-order ckb_brand"
            columns={columns}
            dataSource={btnSearch ? arrSearch : arr}
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            scroll={{ x: 1000 }}
            pagination={{
              showSizeChanger: false,
              total: btnSearch ? searchUserByAdmin?.count : userByAdmin?.count,
              pageSize: 10,
              current: btnSearch ? pagiSearch.current : pagi.current,

              onChange: (page: any) => {
                if (btnSearch) {
                  setPagiSearch({
                    current: page,
                    minIndex: (page - 1) * pageSize,
                    maxIndex: page * pageSize,
                  });
                } else {
                  setPagi({
                    current: page,
                    minIndex: (page - 1) * pageSize,
                    maxIndex: page * pageSize,
                  });
                }
              },
            }}
          />
        </div>
      </div>
      <ModalConfirm
        openModal={openModal}
        setOpenModal={setOpenModal}
        onConfirm={onDelete}
        record={recordRef.current}
        text="このユーザーを削除してもよろしいですか?"
      />
    </div>
  );
};
export default User;
