import { Checkbox, Input, Popconfirm, Select, Table, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState, useEffect, useRef } from 'react';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { useTranslation } from 'react-i18next';
import * as cts from '../../constants/ui/svg';
import type { ColumnsType } from 'antd/es/table';
import { errorToast, getToken } from 'helpers';
import { useDispatch } from 'react-redux';
import {
  addBlogTag,
  deleteBlogTag,
  fetchAllBlogTagsByAdmin,
  updateBlogTag,
} from 'slices/blogSlice';
import { AllBlogTagsByAdminTypes } from 'models';
import { useAppSelector } from 'redux/hooks';
import ModalConfirm from 'components/Common/ModalConfirm';
interface DataType {
  key: React.Key;
  name: string;
  description: any;
  slug: any;
  count: any;
}
const BlogTagPage = () => {
  const { t } = useTranslation();
  const token = getToken();
  const recordRef = useRef<any>('');
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [idRecord, setIdRecord] = useState('');
  const [viewSlc, setViewSlc] = useState('');
  const [blogTag, setBlogTag] = useState<any>();
  const [expandedKey, setExpandedKey] = useState<any>(null);

  const BlogTagsByAdmin = useAppSelector((state) => state.blogs.getAllBlogTagsByAdmin);
  const [dataBlogCate, setDataBlogCate] = useState({
    name: '',
    slug: '',
    description: '',
  });
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: AllBlogTagsByAdminTypes[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: AllBlogTagsByAdminTypes) => ({}),
  };
  const onExpand = (_: any, { key }: any) => setExpandedKey(key);

  const pageSize = 10;
  const [pagi, setPagi] = useState<any>({
    tagByAdmin: [],
    totalPage: BlogTagsByAdmin?.count / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });
  const columns: ColumnsType<AllBlogTagsByAdminTypes> = [
    {
      title: <div className="flex justify-center w-full ckb_table"></div>,
      key: 'operation',
      fixed: 'left',
      width: 0,
    },
    {
      title: <span className="text-[14px] mplus1pnormal text-start">{t('GIVEN_NAME')}</span>,
      width: 150,
      dataIndex: 'name',
      key: 'name',
      render: (value: any) => (
        <span className="text-[12px] mplus1pmedium text-[#0038FF]">{value || '--'}</span>
      ),
    },
    Table.EXPAND_COLUMN,

    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">DESCRIPTION</span>
      ),
      dataIndex: 'description',
      key: 'description',
      width: 100,
      render: (value: any) => <span className="flex justify-center w-full">{value || '--'}</span>,
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">SLUG</span>,
      dataIndex: 'slug',
      key: 'slug',
      width: 100,
      render: (value: any) => (
        <span className="text-[12px] text-[#3E6100] mplus1pmedium flex justify-center w-full">
          {value || '--'}
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">COUNT</span>,
      dataIndex: 'blogCount',
      key: 'blogCount',
      width: 100,
      fixed: 'right',
      render: (value: any) => <span className=" flex justify-center w-full">{value || 0}</span>,
    },
  ];

  const blogTags = BlogTagsByAdmin?.result?.map((x: any, y: any) => {
    return {
      ...x,
      key: y,
    };
  });
  const _renderTable = () => {
    return <div>aaaa</div>;
  };
  const onValueChange = (value: string, name: string, record: AllBlogTagsByAdminTypes) => {
    const foundBlogTags = blogTag?.find((tag: any) => tag?.id === record?.id);
    let newObj = { ...foundBlogTags };
    newObj[name] = value;
    const newArray = blogTag.map((tag: any) => (tag.id === record.id ? newObj : tag));
    setBlogTag(newArray);
  };
  const onSaveBlogTag = () => {
    if (dataBlogCate.name !== '' && dataBlogCate.description !== '' && dataBlogCate.slug) {
      dispatch(addBlogTag({ token, inputs: { ...dataBlogCate } }));
    } else {
      errorToast('Please input enough value');
    }
  };
  const onDelete = (record: any) => {
    dispatch(deleteBlogTag({ token, id: record?.id }));
  };
  useEffect(() => {
    if (blogTags?.length > 0) {
      setBlogTag(blogTags);
    } else {
      setBlogTag(BlogTagsByAdmin?.result);
    }
  }, [blogTags?.length]);
  useEffect(() => {
    dispatch(fetchAllBlogTagsByAdmin({ token, limit: pageSize, page: pagi.current }));
  }, [dispatch, token, pagi.current]);
  return (
    <div className="pt-[26px] px-[35px] mb-[100px]">
      <span className="text-[20px] mplus1pextrabold">タグ新規追加 </span>
      <div className="flex mt-[40px] w-full pb-[100px]">
        <div className="w-[30%] flex flex-col pr-[2%]">
          <span className="text-[14px] mplus1pnormal">{t('GIVEN_NAME')}</span>
          <Input
            value={dataBlogCate.name}
            onChange={(e) => setDataBlogCate({ ...dataBlogCate, name: e.target.value })}
            className="h-[39px] rounded-[0px] border border-[rgba(0,0,0,0.5)] w-full mt-[10px]"
          />
          <span className="text-[10px] text-[#444444] mplus1pnormal">
            {t('THIS_IS_NAME_APPEAR')}
          </span>
          <span className="mt-[20px] text-[14px] mplus1pnormal">{t('SLUG')}</span>
          <Input
            value={dataBlogCate.slug}
            onChange={(e) => setDataBlogCate({ ...dataBlogCate, slug: e.target.value })}
            className="h-[39px] rounded-[0px] border border-[rgba(0,0,0,0.5)] w-full mt-[10px]"
          />
          <span className="text-[10px] text-[#444444] mplus1pnormal">{t('TEXT_CATE')}</span>
          <span className="text-[14px] mplus1pnormal mt-[20px]">{t('EXPLANATION')}</span>
          <TextArea
            value={dataBlogCate.description}
            onChange={(e) => setDataBlogCate({ ...dataBlogCate, description: e.target.value })}
            autoSize={{ minRows: 3, maxRows: 5 }}
            className="mt-[10px] rounded-[0px] border border-[rgba(0,0,0,0.5)]"
          />
          <span className="text-[10px] text-[#444444] mplus1pnormal">{t('BY_DEFAULT')}</span>

          <span
            onClick={onSaveBlogTag}
            className="h-[39px] border rounded-[10px] border-[#000000] w-[112px] bg-[#3E6100] mt-[50px] text-white text-[14px] mplus1pextrabold flex items-center justify-center cursor-pointer"
          >
            {t('ADD_NEW')}
          </span>
        </div>
        <div className="w-[70%] pl-[2%] flex flex-col">
          <div className="flex">
            <Select
              defaultValue="一括操作"
              className="w-[112px] slc_catego"
              options={[{ value: '一括操作', label: '一括操作' }]}
            />
            {/* <span className="h-[39px] cursor-pointer ml-[38px] w-[81px] bg-white text-[14px] mplus1pnormal border text-[#0038FF] border-[#0038FF] rounded-[10px] flex justify-center items-center">
              {t('APPLY')}
            </span> */}
          </div>
          <div className="mt-[17px]">
            <Table
              className="table_merchandise tbl_cate ckb_brand"
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              scroll={{ x: 500 }}
              columns={columns}
              pagination={{
                showSizeChanger: false,
                total: BlogTagsByAdmin?.count,
                pageSize: 10,
                current: pagi.current,

                onChange: (page: any) => {
                  setPagi({
                    current: page,
                    minIndex: (page - 1) * pageSize,
                    maxIndex: page * pageSize,
                  });
                },
              }}
              dataSource={blogTags}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (record?.id === idRecord) return;
                    setIdRecord(record?.id as string);
                  }, // click row
                };
              }}
              expandable={{
                expandedRowRender: (record, index, indent, expanded) => {
                  return (
                    <div key={record?.key} className="flex flex-col pl-[10%] py-[5%]">
                      <div className="flex items-center">
                        <span className="w-[30%] flex justify-start">{t('TAG_NAME')}</span>
                        <div className="w-[70%]">
                          <Input
                            // value={dataUpdateCate?.name}
                            defaultValue={record.name}
                            onChange={(e) => onValueChange(e.target.value, 'name', record)}
                            className="h-[27px] w-[60%]"
                          />
                        </div>
                      </div>
                      <div className="flex items-center mt-[10px]">
                        <span className="w-[30%] flex justify-start">{t('SLUG_TAG')}</span>
                        <div className="w-[70%]">
                          <Input
                            defaultValue={record?.slug}
                            onChange={(e) => onValueChange(e.target.value, 'slug', record)}
                            className="h-[27px] w-[60%]"
                          />
                        </div>
                      </div>
                      <div className="flex items-start mt-[10px]">
                        <span className="w-[30%] flex justify-start">{t('DESCRIPTION_TAG')}</span>
                        <div className="w-[70%]">
                          <TextArea
                            defaultValue={record?.description}
                            onChange={(e) => onValueChange(e.target.value, 'description', record)}
                            rows={4}
                            className="w-[60%]"
                          />
                          {/* <Input
                            defaultValue={record?.description}
                            onChange={(e) => onValueChange(e.target.value, 'description', record)}
                            className="h-[27px]"
                          /> */}
                        </div>
                      </div>
                      <div className="flex mt-[30px]">
                        <div
                          onClick={() => {
                            const foundBlogTags = blogTag?.find(
                              (tag: any) => tag?.id === record?.id
                            );
                            dispatch(
                              updateBlogTag({
                                token,
                                id: record?.id,
                                inputs: {
                                  name: foundBlogTags?.name,
                                  slug: foundBlogTags?.slug,
                                  description: foundBlogTags?.description,
                                },
                              })
                            );
                          }}
                          className="bg-[#3E6100] cursor-pointer text-white flex items-center justify-center rounded-[10px] h-[39px] px-[20px] text-[14px] mplus1pextrabold"
                        >
                          {t('UPDATE')}
                        </div>
                        <div
                          onClick={() => setExpandedKey(null)}
                          className="border cursor-pointer border-[#3E6100] text-[#3E6100] flex items-center justify-center rounded-[10px] h-[39px] px-[10px] text-[14px] mplus1pmedium ml-[20px] p-[12px]"
                        >
                          {t('CANCEL')}
                        </div>
                      </div>
                    </div>
                  );
                },
                expandedRowKeys: [expandedKey],
                onExpand: (expanded, record) => onExpand(expanded, record),
                expandIcon: ({ expanded, onExpand, record }) => (
                  <Popconfirm
                    title="aaaa"
                    open={idRecord === record?.id}
                    placement="bottom"
                    description={() => {
                      return (
                        <div className="bg-[#DCBA78] py-[5px] flex flex-col rounded-[5px]">
                          <span
                            onClick={(e) => {
                              setViewSlc('edit');
                              setIdRecord('');
                              onExpand(record, e);
                            }}
                            className={`${
                              viewSlc === 'edit' && 'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] hover:text-white hover:bg-[#3E6100] hover:rounded-[5px] hover:mx-[2px] mt-[5px] flex items-center text-[14px] mplus1pnormal`}
                          >
                            {t('EDIT')}
                          </span>

                          <span
                            onClick={(e) => {
                              setViewSlc('remove_cate');
                              setIdRecord('');
                              setOpenModal(true);
                              recordRef.current = record;
                            }}
                            className={`${
                              viewSlc === 'remove_cate' &&
                              'bg-[#3E6100] text-white rounded-[5px] mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] hover:text-white hover:bg-[#3E6100] hover:rounded-[5px] hover:mx-[2px] mt-[5px] flex items-center text-[14px] mplus1pnormal`}
                          >
                            {t('DELETE_BLOG_TAG')}
                          </span>
                        </div>
                      );
                    }}
                    onConfirm={() => {
                      // message.info('Clicked on Yes.');
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button
                      onClick={() => {
                        if (idRecord === record?.id) {
                          setIdRecord('');
                        }
                      }}
                      key={record?.key}
                      className="h-[20px] w-[20px] rounded-[2px] bg-[#DCBA78] flex justify-center items-center"
                    >
                      {cts.iconDot}
                    </button>
                  </Popconfirm>
                ),
              }}
              // expandedRowKeys={[expended]}
            />
          </div>
        </div>
      </div>
      <ModalConfirm
        openModal={openModal}
        setOpenModal={setOpenModal}
        onConfirm={onDelete}
        record={recordRef.current}
        text={t('ARE_U_DELETE_TAG')}
      />
    </div>
  );
};
export default BlogTagPage;
