import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AuthSate,
  UserTypes,
  UserByAdminTypes,
  TypeUserByAdminList,
  TypeUserAdminList,
  UserAdminTypes,
} from 'models';

export interface LoginPayload {
  email: string;
  password: string;
  role: number;
  remember_me: boolean;
}

// const initialState: UserTypes = {
//   id: 0,
//   name: '',
//   email: '',
//   avatar: '',
//   phone: '',
//   nick_name: '',
//   address: '',
// };
export interface userTypes {
  user: {};
  getLogs: [];
  getAllUserByAdmin: TypeUserByAdminList;
  userInfoByAdmin: {};
  usersAdmin: TypeUserAdminList;
  userAdminInfo: {};
  searchUser: TypeUserByAdminList;
  exportCSVUsersURL: string;
}
const initialState: userTypes = {
  user: {} as UserTypes,
  getLogs: [],
  getAllUserByAdmin: {
    count: 0,
    result: [] as UserByAdminTypes[],
  },
  userInfoByAdmin: {},
  usersAdmin: {
    count: 0,
    result: [] as UserAdminTypes[],
  },
  userAdminInfo: {},
  searchUser: {
    count: 0,
    result: [] as UserByAdminTypes[],
  },
  exportCSVUsersURL: '',
};
const userSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    fetchUserInfo(state, action: PayloadAction<{ token: string | undefined }>) {
      //   state.logging = true;
    },

    fetchUserInfoSuccess(state, action: PayloadAction<UserTypes>) {
      // const { id, name, email, avatar, phone, address, nick_name } = action.payload;
      // console.log('action.payload', action.payload);
      // return { ...state, id, name, email, avatar, phone, address, nick_name };
      state.user = action.payload;
    },
    fetchGetLogs(state, action) {},
    fetchGetLogsSuccess(state, action) {
      state.getLogs = action.payload;
    },
    fetchAllUserByAdmin(state, action) {},
    fetchAllUserByAdminSuccess(state, action) {
      state.getAllUserByAdmin = action.payload;
    },
    fetchUserInfoByAdmin(state, action) {},
    fetchUserInfoByAdminSuccess(state, action) {
      state.userInfoByAdmin = action.payload;
    },
    deleteUserByAdmin(state, action) {},
    deleteUserByAdminSuccess(state, action) {
      state.getAllUserByAdmin.result = state.getAllUserByAdmin.result.filter(
        (x: any) => x.id !== action.payload
      );
    },
    fetchUserAdmin(state, action) {},
    fetchUserAdminSuccess(state, action) {
      state.usersAdmin = action.payload;
    },
    fetchUserAdminInfo(state, action) {},
    fetchUserAdminInfoSuccess(state, action) {
      state.userAdminInfo = action.payload;
    },
    addUserAdmin(state, action) {},
    addUserAdminSuccess(state, action) {
      state.usersAdmin.result.push(action.payload);
    },
    deleteUserAdmin(state, action) {},
    deleteUserAdminSuccess(state, action) {
      state.usersAdmin.result = state.usersAdmin.result.filter((x: any) => x.id !== action.payload);
    },
    searchUser(state, action) {},
    searchUserSuccess(state, action) {
      state.searchUser = action.payload;
    },
    exportCSVUsers(state, action) {},
    exportCSVUsersSuccess(state, action) {
      state.exportCSVUsersURL = action.payload;
    },
  },
});

// Actions
export const {
  exportCSVUsers,
  exportCSVUsersSuccess,
  fetchUserInfo,
  fetchUserInfoSuccess,
  fetchGetLogs,
  fetchGetLogsSuccess,
  fetchAllUserByAdmin,
  fetchAllUserByAdminSuccess,
  deleteUserByAdmin,
  deleteUserByAdminSuccess,
  fetchUserInfoByAdmin,
  fetchUserInfoByAdminSuccess,
  fetchUserAdmin,
  fetchUserAdminSuccess,
  fetchUserAdminInfo,
  fetchUserAdminInfoSuccess,
  addUserAdmin,
  addUserAdminSuccess,
  deleteUserAdmin,
  deleteUserAdminSuccess,
  searchUser,
  searchUserSuccess,
} = userSlice.actions;

// Selectors
export const selectUserInfo = (state: any) => state.userInfo;

// Reducer
const userReducer = userSlice.reducer;
export default userReducer;
