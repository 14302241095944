const IS_DEV = process.env.NODE_ENV !== 'development' ? false : false; // true : dev, false: production

export const API_URL_BASE = IS_DEV
  ? 'http://192.168.1.14:4000/ec-graphql'
  : 'https://api.henps.jp/ec-graphql';

export const URL_UPLOAD = IS_DEV
  ? 'http://192.168.1.14:4000/uploads'
  : 'https://api.henps.jp/uploads';

export const ENCODE_SECRET_KEY = '99af1167a567418fa036f0b425a6aefa';
export const ISSERVER = typeof window === 'undefined';
