import { useEffect, useState } from 'react';
import { Table, Input, Select, Button, Checkbox, Pagination, DatePicker, Modal } from 'antd';
import moment, { Moment } from 'moment';
import type { ColumnsType } from 'antd/es/table';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import * as cts from '../../constants/ui/svg';
import type { PaginationProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import { useAppSelector } from 'redux/hooks';
import { fetchOrderInfoByAdmin } from 'slices/orderSlice';
import { useDispatch } from 'react-redux';
import { getToken } from 'helpers';

interface Props {
  dataQuickView: any;
  setDataQuickView: React.Dispatch<React.SetStateAction<any>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;

  state: any;
}

const { Search } = Input;

const ModalQuickView: React.FC<Props> = ({
  open,
  setOpen,
  dataQuickView,
  state,
  setDataQuickView,
}: any) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const date = moment().format('MM-DD-YYYY');

  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const showModal = (value: any) => {
    setOpen(true);
  };
  const dispatch = useDispatch();
  const handleOk = () => {
    setOpen(false);
    setDataQuickView(null);
  };
  const token = getToken();
  const handleCancel = () => {
    setOpen(false);
  };
  const orderInfoByAdmin = useAppSelector((state) => state.orders.getOrderInfoByAdmin);
  // useEffect(() => {
  //   if (dataQuickView) {
  //     dispatch(fetchOrderInfoByAdmin({ token, id: dataQuickView }));
  //   }
  // }, [dispatch, token, dataQuickView]);
  return (
    <Modal
      mask={false}
      centered
      className="modal-quick-view"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="">
        <div className="border-b border-[#DBB312] flex justify-between items-center pr-[45px] pl-[20px] py-[10px]">
          <span className="text-[#DBB312] text-[14px] mplus1pmedium">
            {t('THE_ORDER')} # {orderInfoByAdmin?.code}
          </span>
          <div>{state(orderInfoByAdmin?.shippingStatus)}</div>
        </div>
        <div className="flex justify-between pt-[20px] px-[40px] py-[20px] w-[100%] text-[14px] mplus1pnormal">
          <div className="w-[49%] flex flex-col pr-[2%]">
            <span className="flex justify-center pb-[20px] text-[14px] mplus1pmedium">
              {t('Payment_Information')}
            </span>
            <div className="flex flex-col">
              <span>
                {orderInfoByAdmin?.address?.postalCode} {orderInfoByAdmin?.address?.city || ''}{' '}
                {orderInfoByAdmin?.address?.district || ''}
              </span>
              <span>{orderInfoByAdmin?.address?.houseBuilding}</span>
              <span className="mt-[15px]">{t('EMAIL_ONLY')} </span>
              <span>{orderInfoByAdmin?.customer && orderInfoByAdmin?.customer?.email}</span>
              <span className="mt-[15px]">{t('TELEPHONE_NUMBER')} </span>
              <span>{orderInfoByAdmin?.customer && orderInfoByAdmin?.customer?.phone}</span>
              <span className="mt-[15px]">{t('METHOD_OF_PAYMENT')} </span>
              <span>{orderInfoByAdmin?.paymentType}</span>
            </div>
          </div>
          <div className="w-[49%] flex flex-col pl-[2%]">
            <span className="flex justify-center pb-[10px] text-[14px] mplus1pmedium">
              {t('Shipping_Information')}
            </span>
            <div className="flex flex-col">
              <span>{t('SHIPPING_ADDRESS')}</span>
              <span>
                {orderInfoByAdmin?.address?.postalCode} {orderInfoByAdmin?.address?.city || ''}{' '}
                {orderInfoByAdmin?.address?.district || ''}
              </span>
              <span className="mt-[15px]">{t('SHIPPING_METHOD')}</span>
              <span>{orderInfoByAdmin?.shippingType} </span>
            </div>
          </div>
        </div>
        <div>
          <table className="w-[100%] mt-[20px]">
            <thead>
              <tr className="border-b border-[#DBB312] ">
                <th className="pl-[40px] w-[50%] text-left">{t('MERCHANDISE')}</th>
                <th>{t('AMOUNT')}</th>
                <th>{t('PRICE')}</th>
                <th>{t('TAX')}</th>
                <th>{t('TOTAL')}</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {orderInfoByAdmin?.orderDetail?.length > 0 ? (
                <>
                  {orderInfoByAdmin?.orderDetail?.map((item: any, idx: any) => {
                    return (
                      <tr key={idx}>
                        <td className="pr-[20px] pl-[40px] text-left py-[10px] text-[#0038FF]">
                          {item?.productId[0]?.name}
                        </td>
                        <td>{item?.qty}</td>
                        <td>¥{Number(item?.productId[0]?.price || 0).toLocaleString()}</td>
                        <td>0</td>
                        <td>
                          ¥{Number(item?.qty * item?.productId[0]?.price || 0).toLocaleString()}
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr key={0}>
                  <td className="pr-[20px] pl-[40px] text-left py-[10px] text-[#0038FF]"></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};
export default ModalQuickView;
