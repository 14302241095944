import { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { push } from 'connected-react-router';
import { ENCODE_SECRET_KEY } from '../constants';
import {
  fetchAllCategoryAPI,
  addCategoryAPI,
  updateCategoryAPI,
  getAllCategoryByAdminAPI,
  deleteCategoryAPI,
} from '../api';
import { successToast, errorToast, failedToast } from 'helpers';
import {
  fetchAllCategory,
  fetchAllCategorySuccess,
  addCategory,
  addCategorySuccess,
  fetchAllCategoryByAdmin,
  fetchAllCategoryByAdminSuccess,
  updateCategory,
  updateCategorySuccess,
  deleteCategory,
  deleteCategorySuccess,
} from 'slices/categorySlice';
import { showLoading, hideLoading } from '../slices/commonSlice';

function* AllCategorySaga(action: any) {
  try {
    const res: AxiosResponse = yield call(fetchAllCategoryAPI, {
      limit: action.payload.limit,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(fetchAllCategorySuccess(data?.categories));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* addCategorySaga(action: any) {
  try {
    yield put(showLoading(true));
    const res: AxiosResponse = yield call(addCategoryAPI, action.payload);
    const { data, errors } = res.data;
    if (res?.data?.data?.addCategory?.ok === true) {
      successToast('Add category new success');
    yield put(addCategorySuccess(data?.addCategory?.data));

      yield put(showLoading(false));
    } else {
      errorToast(res?.data?.data?.addCategory?.error);
      yield put(showLoading(false));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* getAllCategoryByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(getAllCategoryByAdminAPI, {
      token: action.payload.token,
      limit: action.payload.limit,
      page: action.payload.page,
    });
    const { data, errors } = res.data;
    yield put(fetchAllCategoryByAdminSuccess(data?.getAllCategoryByAdmin));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* updateCategorySaga(action: any) {
  try {
    // const res: AxiosResponse = yield call(updateCategoryAPI, {
    //   token: action.payload.token,
    //   inputs: action.payload.inputs,
    //   id: action.payload.id,
    // });
    yield put(showLoading(true));
    const res: AxiosResponse = yield call(updateCategoryAPI, action.payload);
    const { data, errors } = res.data;
    if (res?.data?.data?.updateCategory?.ok === true) {
      successToast('Update category success');
    yield put(updateCategorySuccess(data?.updateCategory?.data));

      yield put(showLoading(false));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* deleteCategorySaga(action: any) {
  try {
    const res: AxiosResponse = yield call(deleteCategoryAPI, {
      token: action.payload.token,
      id: action.payload.id,
    });
    const { data, errors } = res.data;
    if (res?.data?.data?.deleteCategory?.ok === true) {
      successToast('Delete category success');
    yield put(deleteCategorySuccess(data?.deleteCategory?.data?._id));

    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
export default function* categorySaga() {
  yield takeLatest(fetchAllCategory.type, AllCategorySaga);
  yield takeLatest(addCategory.type, addCategorySaga);
  yield takeLatest(fetchAllCategoryByAdmin.type, getAllCategoryByAdminSaga);
  yield takeEvery(updateCategory.type, updateCategorySaga);
  yield takeEvery(deleteCategory.type, deleteCategorySaga);
}
