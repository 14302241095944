import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { RootState } from 'redux/store';
import { CategoryTypes, TypeCategoryList } from '../models/category';

export interface categoriesType {
  categories: CategoryTypes[];
  getAllCategoryByAdmin: TypeCategoryList;
}
const initialState: categoriesType = {
  categories: [],
  getAllCategoryByAdmin: {
    count: 0,
    result: [] as CategoryTypes[],
  },
};

const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    fetchAllCategory(state, action) {},
    fetchAllCategorySuccess(state, action) {
      state.categories = action.payload;
    },
    addCategory(state, action) {},
    addCategorySuccess(state, action) {
      state.getAllCategoryByAdmin.result.push(action.payload);
      state.categories.push(action.payload);
    },
    fetchAllCategoryByAdmin(state, action) {},
    fetchAllCategoryByAdminSuccess(state, action) {
      state.getAllCategoryByAdmin = action.payload;
    },
    updateCategory(state, action) {},
    updateCategorySuccess(state, action) {
      state.getAllCategoryByAdmin.result = state.getAllCategoryByAdmin.result.map((item: any) =>
        item._id === action.payload._id ? action.payload : item
      );
    },
    deleteCategory(state, action) {},
    deleteCategorySuccess(state, action) {
      state.getAllCategoryByAdmin.result = state.getAllCategoryByAdmin.result.filter(
        (x) => x._id !== action.payload
      );
    },
  },
});

// Actions
export const {
  fetchAllCategory,
  fetchAllCategorySuccess,
  addCategory,
  addCategorySuccess,
  fetchAllCategoryByAdmin,
  fetchAllCategoryByAdminSuccess,
  updateCategory,
  updateCategorySuccess,
  deleteCategory,
  deleteCategorySuccess,
} = categorySlice.actions;
// Selectors
export const selectCategory = (state: RootState) => state.categories;

// Reducer
const categoryReducer = categorySlice.reducer;
export default categoryReducer;
