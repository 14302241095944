// export const URL_LOGIN = `
//   query loginAdmin($keyLogin: String!, $password: String!) {
//     loginAdmin(keyLogin: $keyLogin, password: $password) {
//       data { id, email,userName,firstName,lastName,avatar,status}
//       access_token,
//       ok
//       error
//     }
//   }
// `;
export const URL_USER_INFO = `
  query {
    userAdminInfo {
      id,
      firstName,
      lastName,
      userName,
      avatar,
      role,
      email,
      orderBy,
      status
    }
  }
`;
export const URL_GET_LOGS = `
query getLogs($limit:Int,$type: String){
	getLogs(limit:$limit,type:$type){
		id,
    type,
    content{
			adminInfo,
      objectType,
      objectInfo
    },
    createdAt
  }
}
`;
export const URL_ALL_USER_BY_ADMIN = `
query getAllUserByAdmin(
  $limit: Int!
  $page: Int!
  ){
	getAllUserByAdmin(limit:$limit,page:$page){
		count,
    result{
			id,
      firstName,
      lastName,
      userName,
      email,
      role,
      avatar,
      numberOfReview
    }
  }
}
`;
export const URL_SEARCH_USER = `
query searchUser(
  $searchText: String!
  $limit: Int!
  $page: Int!
  ){
	searchUser(searchText:$searchText,limit:$limit,page:$page){
		count,
    result{
      id,
      firstName,
      lastName,
      userName,
      email,
      role,
      avatar,
      numberOfReview
  }
  }
}
`;
export const URL_DELETE_USER_BY_ADMIN = `
mutation deleteUserByAdmin($id:ID!){
	deleteUserByAdmin(id:$id){
		data{
      id,
      email,
      firstName,
      lastName,
      role,
      phone
    },
    error,
    ok
  }
}
`;

export const URL_LOGIN = `
  mutation loginAdmin($keyLogin: String!, $password: String!) {
    loginAdmin(keyLogin: $keyLogin, password: $password) {
      data { id, email,userName,firstName,lastName,avatar,status}
      access_token,
      ok
      error
    }
  }
`;

export const URL_RESET_USER_PASSWORD_BY_ADMIN = `
mutation resetUserPasswordByAdmin($id:ID!){
	resetUserPasswordByAdmin(id:$id){
		data{
			id,
      email,
      firstName,
      lastName,
      role,
      phone
    },
    error,
    ok
  }
}
`;
export const URL_USER_INFO_BY_ADMIN = `
query userInfoByAdmin(
  $id: ID!
  ){
	userInfoByAdmin(id:$id){
		id,
    firstName,
    lastName,
    userName,
    avatar,
    email,
    birthday,
    gender
    phone,
    transLastName,
    transFirstName,
    addressUser{
			postalCode,
      houseBuilding,
      city,
      district,
      address
    }
  }
}
`;
export const URL_USER_ADMIN = `
query UserAdmin(
  $limit: Int!
  $page: Int!
  ){
	usersAdmin(limit:$limit,page:$page){
		count,
    result{
			id,
      phone,
      email,
      role,
      lastName,
      userName
    }
  }
}
`;
export const URL_USER_ADMIN_INFO = `
query userAdminInfo($id:ID){
  userAdminInfo(id:$id){
    id,
    firstName,
    lastName,userName
    avatar,
    role,
    email
    postalCode,
    phone,
    birthday,
    gender,
    address
  }
}
`;
export const URL_ADD_USER_ADMIN = `
mutation addUserAdmin($inputs: inputUserAdmin){
	addUserAdmin(inputs:$inputs){
	data{
    id,
      phone,
      email,
      role,
      lastName,
      userName
  },
    error,
    ok
  }
}
`;
export const URL_DELETE_USER_ADMIN = `
mutation deleteUserAdmin($id:ID!){
	deleteUserAdmin(id:$id){
		data{
			id
    },
    error,
    ok
  }
}
`;
export const URL_EXPORT_CSV_USER = `
query exportCSVUsers{
  exportCSVUsers{
    ok
    error
    url
  }
}
`;
