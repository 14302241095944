export const uploadFileData = (data: any, fileUpload: any, files?:any) => {
  const formData = new FormData();
  const operations = JSON.stringify(data);
  formData.append("operations", operations);

  let map:any = {};

  if(fileUpload){
    map = {"0": ["variables.fileUpload"]}
  }

  if(files?.length > 0){
    for(const [index, file ] of files.entries() ){
      if(fileUpload){
        map = {...map, [`${index + 1}`]: [`variables.files.${index + 1}`]}
      }else {
        map = {...map, [`${index}`]: [`variables.files.${index}`]}
      }
    }
  }

  formData.append("map", JSON.stringify(map));

  if(files?.length > 0){
    for(const [index, file ] of files.entries() ){
      if(fileUpload){
        formData.append(`${index + 1}`, file);
      }else {
        formData.append(`${index}`, file);
      }
    }
  }

  if(fileUpload){
    formData.append("0", fileUpload);
  }

  return formData;
}