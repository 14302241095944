import request from './axiosServices';
import { URL_ALL_BANNER, URL_BANNER_BY_ID, URL_UPDATE_BANNER, URL_DELETE_BANNER } from '../graphql';
import { uploadFileData } from '../helpers';

interface requestTypes {
  token?: string;
  limit?: number;
  page?: number;
  id?: string;
  inputs?: {
    title?: string;
    image?: string;
    location?: string;
    size?: string;
    presentation?: string;
    status?: boolean;
  };
}
export function getAllBannerAPI({ token, limit, page }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_ALL_BANNER,
      variables: { limit, page },
    },
    token,
  });
}
export function getBannerByIdAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_BANNER_BY_ID,
      variables: { id },
    },
    token,
  });
}
export function updateBannerAPI({ token, id, inputs, fileUpload }: any) {
  let data: {} = {
    query: URL_UPDATE_BANNER,
    variables: { inputs, id, fileUpload: null },
  };

  data = uploadFileData(data, fileUpload);

  return request({
    method: 'POST',
    data,
    token,
  });
}
export function deleteBannerAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_DELETE_BANNER,
      variables: { id },
    },
    token,
  });
}
