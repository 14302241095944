import request from './axiosServices';
import { uploadFileData } from '../helpers';

import {
  URL_PRODUCTS_WITH_FILTER,
  URL_SEARCH_PRODUCTS_BY_ADMIN,
  URL_DELETE_PRODUCT,
  URL_CLONE_PRODUCT,
  URL_UPDATE_PRODUCT,
  URL_ADD_PRODUCT,
  URL_PRODUCT_REVIEW_BY_ADMIN,
  URL_PRODUCT_REVIEW_BY_ID,
  URL_PRODUCT_INFO_BY_ADMIN,
  URL_PRODUCT_BRANDS,
  URL_ADD_PRODUCT_BRAND,
  URL_UPDATE_PRODUCT_BRAND,
  URL_DELETE_PRODUCT_BRAND,
  URL_CHANGE_PREVIEW_STATUS,
  URL_DELETE_PRODUCT_PREVIEW_BY_ADMIN,
  URL_CHANGE_MANY_REVIEW,
  URL_GET_ALL_PRODUCT_BRAND,
  URL_ADD_PRODUCT_REVIEW_BY_ADMIN,
  URL_UPDATE_PRODUCT_REVIEW_BY_ADMIN,
  URL_DELETE_PRODUCT_REVIEW_BY_ADMIN,
} from 'graphql';
interface requestTypes {
  token?: string;
  limit?: number;
  page?: number;
  [key: string]: any;
  id?: string;
  ids?: [];
  type?: string;
  searchText?: string;
  productId?: string;
  point?: number;
  status?: string;
  customerId?: string;
  content?: string;
  parentId?: string;
  inputs?: {
    name?: string;
    categoryId?: [];
    listTag?: [];
    price?: number;
    marketPrice?: number;
    reviewAble?: boolean;
    slug?: string;
    weight?: number;
    length?: number;
    width?: number;
    height?: number;
    status?: string;
    description?: string;
    brandId?: string;
    saleStartTime?: string;
    saleEndTime?: string;
    salePrice?: number;
    numberInStock?: number;
  };
}
interface queryProductTypes {
  token?: string;
  limit?: number;
  page?: number;
  inputs?: {
    tagId?: string;
    categoryId?: string;
    searchText?: string;
    status?: string;
  };
}
interface requestAddProductTypes {
  token?: string;
  inputs?: {
    name?: string;
    description?: string;
    price?: number;
    content?: string;
    length?: number;
    width?: number;
    height?: number;
    weight?: number;
    image?: string;
    multiImages?: [];
    categoryId?: [];
    listTag?: [];
    brandId?: string;
    saleEndTime?: string;
    saleStartTime?: string;
    salePrice?: number;
    numberInStock?: number;
  };
}
export function productWithFilterAPI({ token, limit, page, inputs }: queryProductTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_PRODUCTS_WITH_FILTER,
      variables: { limit, page, inputs },
    },
    token,
  });
}
export function searchProductByAdminAPI({ token, searchText, limit, page }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_SEARCH_PRODUCTS_BY_ADMIN,
      variables: { searchText, limit, page },
    },
    token,
  });
}
export function deleteProductAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_DELETE_PRODUCT,
      variables: { id },
    },
    token,
  });
}
export function cloneProductAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_CLONE_PRODUCT,
      variables: id,
    },
    token,
  });
}
export function updateProductAPI({
  token,
  id,
  inputs,
  fileUpload,
  files,
  multipleImageDelete,
}: any) {
  let filesMap = null;
  if (files?.length > 0) {
    filesMap = files.map((i: any) => {
      return null;
    });
  }
  let data: {} = {
    query: URL_UPDATE_PRODUCT,
    variables: { inputs, id, fileUpload: null, files: filesMap, multipleImageDelete },
  };

  data = uploadFileData(data, fileUpload, files);

  return request({
    method: 'POST',
    data,
    token,
  });
}
export function addProductAPI({ token, inputs, fileUpload, files }: any) {
  let filesMap = null;
  if (files?.length > 0) {
    filesMap = files.map((i: any) => {
      return null;
    });
  }

  let data: {} = {
    query: URL_ADD_PRODUCT,
    variables: { inputs, fileUpload: null, files: filesMap },
  };

  data = uploadFileData(data, fileUpload, files);

  return request({
    method: 'POST',
    data,
    token,
  });
}
export function productReviewsByAdminAPI({
  token,
  limit,
  page,
  productId,
  point,
  status,
}: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_PRODUCT_REVIEW_BY_ADMIN,
      variables: { limit, page, productId, point, status },
    },
    token,
  });
}
export function productReviewsByIdAPI({ token, limit, page, productId }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_PRODUCT_REVIEW_BY_ID,
      variables: { limit, page, productId },
    },
    token,
  });
}
export function productInfoByAdminAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_PRODUCT_INFO_BY_ADMIN,
      variables: { id },
    },
    token,
  });
}
export function productBrandsAPI({ token, limit }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_PRODUCT_BRANDS,
      variables: { limit },
    },
    token,
  });
}
export function addProductBrandAPI({ token, inputs }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_ADD_PRODUCT_BRAND,
      variables: { inputs },
    },
    token,
  });
}
export function updateProductBrandAPI({ token, inputs, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_UPDATE_PRODUCT_BRAND,
      variables: { inputs, id },
    },
    token,
  });
}
export function deleteProductBrandAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_DELETE_PRODUCT_BRAND,
      variables: { id },
    },
    token,
  });
}
export function changeReviewStatusAPI({ token, id, status }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_CHANGE_PREVIEW_STATUS,
      variables: { id, status },
    },
    token,
  });
}
export function deleteProductReviewByAdminAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_DELETE_PRODUCT_PREVIEW_BY_ADMIN,
      variables: { id },
    },
    token,
  });
}
export function changeManyReviewsAPI({ token, ids, status }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_CHANGE_MANY_REVIEW,
      variables: { ids, status },
    },
    token,
  });
}
export function getAllProductBrandAPI({ token, limit, page }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_GET_ALL_PRODUCT_BRAND,
      variables: { limit, page },
    },
    token,
  });
}
export function updateProductReviewByAdminAPI({ token, id, content, point, status }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_UPDATE_PRODUCT_REVIEW_BY_ADMIN,
      variables: { id, content, point, status },
    },
    token,
  });
}
export function addProductReviewByAdminAPI({
  token,
  productId,
  customerId,
  content,
  point,
  status,
  parentId,
}: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_ADD_PRODUCT_REVIEW_BY_ADMIN,
      variables: { productId, customerId, content, point, status, parentId },
    },
    token,
  });
}
export function deleteProductPreviewByAdminAPI({ token, id }: requestTypes) {
  return request({
    method: 'POST',
    data: {
      query: URL_DELETE_PRODUCT_REVIEW_BY_ADMIN,
      variables: { id },
    },
    token,
  });
}
