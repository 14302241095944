import { URL_UPLOAD } from '../../constants';
import { getToken } from 'helpers';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { fetchBlogInfoByAdmin, fetchBlogsTag, updateBlog } from 'slices/blogSlice';
import * as cts from '../../constants/ui/svg';
import img from '../../assets/images/blog_img.png';
import img1 from '../../assets/images/blog_1.png';
import img2 from '../../assets/images/blog_2.png';
import img3 from '../../assets/images/blog_3.png';
import { Spin } from 'antd';
interface Props {
  setPreviewBlog: React.Dispatch<React.SetStateAction<boolean>>;
  dataBlog: { name: ''; content: ''; listTag: []; createdAt: '' };
  load: any;
  loadStatus: any;
  onSaveBlog: (status: string) => void;
  fileDataURL: any;
}
const BlogPreviewPage: React.FC<Props> = ({
  setPreviewBlog,
  dataBlog,
  load,
  loadStatus,
  onSaveBlog,
  fileDataURL,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = getToken();
  const blogInfoByAdmin = useAppSelector((state) => state.blogs.getBlogInfoByAdmin);
  const tagBlog = useAppSelector((state) => state.blogs.blogTags);
  useEffect(() => {
    dispatch(fetchBlogsTag({ token }));
  }, [dispatch, token]);

  const arr = dataBlog?.listTag
    .map((item: any) => {
      const index = tagBlog?.findIndex((item2: any) => item2?.id === item);
      return {
        name: tagBlog[index]?.name,
      };
    })
    .map((item1: any) => item1?.name);
  return (
    <div className="flex flex-col pt-[26px] px-[35px] mb-[100px] pb-[100px]">
      <span className="mplus1pextrabold text-[20px]">{t('BLOG_PREVIEW')}</span>
      <div className="mt-[24px] bg-white border border-[rgba(0,0,0,0.3)] w-[70%] flex flex-col pt-[40px] pl-[5%] pr-[10%] pb-[8%]">
        {/* <img
          className="h-[500px] w-full"
          src={`${
            blogInfoByAdmin?.id === '64882b92df5475201774e138'
              ? img2
              : blogInfoByAdmin?.id === '64882af3df5475201774e12e'
              ? img1
              : blogInfoByAdmin?.id === '64882c18df5475201774e142'
              ? img3
              : img
          }`}
        /> */}
        <img className="h-[500px] w-full" src={fileDataURL} />
        {/* <div className="w-full">
          {blogInfoByAdmin?.tags?.map((x: any, y: any) => {
            return (
              <span
                key={y}
                className="h-[35px] text-[16px] mplus1pextrabold rounded-[5px] px-[10px] bg-[#B0C284] text-[#3E6100] flex items-center mr-[10px]"
              >
                {x?.name}
              </span>
            );
          })}
        </div> */}
        <div className="flex justify-end mt-[40px]">
          <div className="flex flex-wrap">
            {arr?.map((x: any, y: any) => {
              return (
                <span
                  key={y}
                  className="h-[35px] mt-[5px] text-[16px] mplus1pextrabold rounded-[5px] px-[10px] bg-[#B0C284] text-[#3E6100] flex items-center mr-[10px]"
                >
                  {x}
                </span>
              );
            })}
          </div>
          <span className="flex items-center">
            <span className="mr-[5px]">{cts.iconClock}</span>
            <span>{moment(dataBlog?.createdAt).format('YYYY/MM/DD')}</span>
          </span>
        </div>
        <span className="mt-[52px] mplus1pextrabold text-[24px]">{dataBlog?.name}</span>
        <span
          dangerouslySetInnerHTML={{ __html: dataBlog?.content }}
          className="text-[16px] mplus1pmedium"
        ></span>
      </div>
      <div className="mt-[82px] flex items-center">
        <div className="relative w-[104px] flex items-center justify-center mr-[50px]">
          {load === true && loadStatus === 'draft' && (
            <Spin className="absolute w-[104px] h-full items-center flex justify-center" />
          )}
          <span
            onClick={() => onSaveBlog('draft')}
            className={`${
              load === true && loadStatus === 'draft' ? ' opacity-20 absolute' : ''
            } h-[39px] px-[10px] cursor-pointer rounded-[10px] flex items-center justify-center text-white bg-[#3692E7] text-[14px] mplus1pextrabold`}
          >
            {t('PRESERVE')}
          </span>
        </div>
        <div className="relative w-[68px] flex items-center justify-center mr-[50px]">
          {load === true && loadStatus === 'public' && (
            <Spin className="absolute w-[68px] h-full items-center flex justify-center" />
          )}
          <span
            onClick={() => onSaveBlog('public')}
            className={`${
              load === true && loadStatus === 'public' ? 'opacity-20 absolute' : ''
            } h-[39px] px-[20px] cursor-pointer rounded-[10px] flex items-center justify-center text-white bg-[#3E6100] text-[14px] mplus1pextrabold`}
          >
            {t('RELEASE')}
          </span>
        </div>
        {/* <span
          onClick={() => onSaveBlog('draft')}
          className="h-[39px] px-[10px] cursor-pointer rounded-[10px] flex items-center justify-center text-white bg-[#3692E7] text-[14px] mplus1pextrabold "
        >
          {t('PRESERVE')}
        </span> */}
        {/* <span
          onClick={() => onSaveBlog('public')}
          className="h-[39px] px-[20px] cursor-pointer rounded-[10px] flex items-center justify-center text-white bg-[#3E6100] text-[14px] mplus1pextrabold mr-[50px]"
        >
          {t('RELEASE')}
        </span> */}
        <div
          onClick={() => setPreviewBlog(false)}
          className="h-[39px] cursor-pointer text-[#3E6100] px-[10px] rounded-[10px] mr-[50px] border border-[#3E6100] flex items-center mplus1pextrabold text-[14px]"
        >
          {t('CANCEL')}
        </div>
      </div>
    </div>
  );
};
export default BlogPreviewPage;
