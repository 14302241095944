export const URL_GET_PRODUCT_TAGS = `
  query getProductTags($limit: Int){
    productTags(limit:$limit){
		id,
    name,
    description,
    slug
    }
  }
`;
export const URL_ADD_PRODUCT_TAG = `
  mutation addProductTag($inputs:inputProductTag){
    addProductTag(inputs:$inputs) {
      data {
        name,
        description
      },
      ok,
      error
    }
  }
`;
export const URL_GET_ALL_PRODUCT_TAG_BY_ADMIN = `
query getAllProductTagsByAdmin($limit: Int,$page: Int){
	getAllProductTagsByAdmin(limit:$limit,page:$page){
		count,
    result{
			id,
      name,
      description,
      slug,
      productCount,
      createAt,
      updateAt
    }
  }
}
`;
export const URL_UPDATE_PRODUCT_TAG = `
mutation updateProductTag($id: ID!,$inputs: inputProductTag){
	updateProductTag(id:$id,inputs:$inputs){
		data{
			id,
      name,
      description,
      slug,
      productCount,
      createAt,
      updateAt
    },
    error,
    ok
  }
}
`;
export const URL_DELETE_PRODUCT_TAG = `
mutation deleteProductTag($id: ID!){
	deleteProductTag(id:$id){
		data{	
      id,
    	name
    },
    error,
    ok
  }
}
`;
