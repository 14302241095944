import { Button, Checkbox, DatePicker, Input, Popconfirm, Select, Table } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as cts from '../../constants/ui/svg';
import type { ColumnsType } from 'antd/es/table';
import TextArea from 'antd/es/input/TextArea';
import ViewProfileShopPage from '../shop-management/ViewProfileShopPage';
import ProductListOfShopPage from '../shop-management/ProductListOfShop';
interface DataType {
  key: React.Key;
  img: any;
  shop: string;
  display: any;
  mail: any;
  role: any;
  business_address: any;
}
const ShopListPage = () => {
  const { t } = useTranslation();
  const [viewSlc, setViewSlc] = useState('');
  const [openPop, setOpenPop] = useState(false);
  const [openViewPage, setOpenViewPage] = useState('shop-page');
  const [openViewPageProductList, setOpenViewPageProductList] = useState(false);

  const columns: ColumnsType<DataType> = [
    {
      title: <Checkbox disabled={true} className="flex justify-center w-full ckb_table"></Checkbox>,
      key: 'operation',
      fixed: 'left',
      width: 50,
      render: () => <Checkbox className="flex justify-center w-full ckb_table"></Checkbox>,
    },
    {
      title: <span className="text-white">a</span>,
      key: 'img',
      dataIndex: 'img',
      width: 50,
      render: (value: any) => (
        <span className="flex justify-center w-full">
          <span className="bg-[#c1c1c1] w-[30px]">{cts.iconHome1}</span>
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">SHOP 名</span>,
      width: 100,
      dataIndex: 'shop',
      key: 'shop',
      render: (value: any) => (
        <span className="w-full text-[#0038FF] text-[14px] mplus1pextrabold flex justify-center">
          {value}
        </span>
      ),
    },
    Table.EXPAND_COLUMN,
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">DISPLAY 名</span>
      ),
      width: 100,
      dataIndex: 'display',
      key: 'display',
      render: (value: any) => (
        <span className="text-[14px] flex justify-center mplus1pnormal">{value}</span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">E-MAIL</span>,
      dataIndex: 'mail',
      key: 'mail',
      width: 100,
      render: (value: any) => (
        <span className="flex justify-center mplus1pnormal text-[#0038FF] text-[12px] w-full">
          {value}
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">ROLE</span>,
      dataIndex: 'role',
      key: 'role',
      width: 100,
      render: (value: any) => (
        <span className="text-[12px] mplus1pnormal text-[#0038FF] flex justify-center w-full">
          {value}
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal">BUSINESS ADDRESS</span>,
      dataIndex: 'business_address',
      key: 'business_address',
      fixed: 'right',
      width: 250,
      render: (value: any) => (
        <div className="flex flex-col">
          <span>{value}</span>
          <span>神奈川県横浜市中区相生町２丁目５２泰生ポーチ404</span>
        </div>
      ),
    },
  ];
  const data: DataType[] = [
    {
      key: 1,
      img: 'img',
      shop: 'Shop 1',
      display: 'SEVEN ',
      mail: 'seven@gmail.com',
      role: 'ショップ管理',
      business_address: '〒231-0012',
    },
    {
      key: 2,
      img: 'img',
      shop: 'Shop 2',
      display: 'BAKERY ',
      mail: 'yamazaki@gmail.com',
      role: 'ショップ管理',
      business_address: '〒231-0012',
    },
    {
      key: 3,
      img: 'img',
      shop: 'Shop 3',
      display: 'SEVEN ',
      mail: 'seven@gmail.com',
      role: 'ショップ管理',
      business_address: '〒231-0012',
    },
    {
      key: 4,
      img: 'img',
      shop: 'Shop 4',
      display: 'BAKERY ',
      mail: 'yamazaki@gmail.com',
      role: 'ショップ管理',
      business_address: '〒231-0012',
    },
  ];
  const arrCheckbox = [
    { title: '飲料' },
    { title: 'お菓子' },
    { title: '化粧品' },
    { title: '日用 雜貨' },
    { title: 'スキンケア' },
    { title: 'アルコール' },
    { title: 'バス・ボディ' },
    { title: 'インスタント・レトルト' },
  ];
  const _renderTable = () => {
    return <div>aaaa</div>;
  };
  const pageSize = 10;

  const [pagi, setPagi] = useState<any>({
    data: [],
    totalPage: data?.length / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });
  return (
    <div className="pt-[26px] px-[35px] mb-[100px]">
      {(openViewPage === 'shop-page' && (
        <>
          {' '}
          <span className="text-[20px] mplus1pextrabold">
            {t('SHOP')}
            {t('MANAGEMENT')}
          </span>
          <div className="flex items-center mt-[30px]">
            <span className="text-[14px] mplus1pnormal underline text-[#0038FF]">
              {t('ALL')}
              <span className="text-black underline">(4)</span>{' '}
            </span>
            <span className="ml-[14px] mr-[22px] w-px h-[20px] bg-black"></span>
            <span className="text-[14px] mplus1pnormal underline text-[#0038FF]">
              ADMINISTRATOR
              <span className="text-black underline">(0)</span>{' '}
            </span>
            <span className="ml-[22px] mr-[17px] w-px h-[20px] bg-black"></span>
            <span className="text-[14px] mplus1pnormal underline text-[#0038FF]">
              {t('SHOP')}
              <span className="text-black underline">(4)</span>{' '}
            </span>
            <span className="ml-[16px] w-px h-[20px] bg-black"></span>
          </div>
          <div className="flex mt-[20px] w-full items-center justify-between">
            <div className="flex items-center ctn_btn_merchandise w-[70%] pr-[10px] pb-[10px] h-full overflow-auto overflow-y-hidden ">
              <Select
                defaultValue="一括操作"
                className="w-[112px] slc_merchandise mr-[10px]"
                options={[
                  { value: '一括操作', label: '一括操作' },
                  { value: 'Yiminghe', label: 'yiminghe' },
                ]}
              />
              {/* <Button className="bg-white h-[30px] border-[0.5px] text-[14px] mplus1pnormal border-black mr-[10px]">
                {t('APPLY')}
              </Button> */}
              <Select
                defaultValue="CHANGE ROLE TO---"
                className="w-[167px] slc_merchandise mr-[10px]"
                options={[
                  { value: 'CHANGE ROLE TO---', label: 'CHANGE ROLE TO---' },
                  { value: 'Yiminghe', label: 'yiminghe' },
                ]}
              />
              <Button className="bg-white h-[30px] border-[0.5px] text-[14px] mplus1pnormal border-black mr-[10px]">
                CHANGE
              </Button>
            </div>
            <div className="w-[30%] pl-[2%] pb-[10px]">
              <div className="flex items-center justify-end">
                <span className="h-[40px] w-[48px] border-[0.5px] rounded-l-[10px] border-black/50 bg-[#D9D9D9] flex justify-center items-center">
                  {cts.iconSearch}
                </span>
                <Input
                  placeholder="SEARCH ユーザー管理S"
                  className="w-[70%] h-[40px] border-[0.5px] border-black/50 rounded-r-[10px] rounded-l-[0px]"
                />
              </div>
            </div>
          </div>
          <div className="mt-[12px] mb-[100px]">
            <Table
              className="table_merchandise"
              columns={columns}
              dataSource={data}
              scroll={{ x: 800 }}
              // expandable={{
              //   expandedRowRender: (record) => _renderTable(),
              //   rowExpandable: (record) => record.name !== 'Not Expandable',
              // }}
              // rowSelection={{}}
              expandable={{
                expandedRowRender: (record) => _renderTable(),
                expandIcon: ({ expanded, onExpand, record }) => (
                  <Popconfirm
                    title="aaaa"
                    // open={openPop}
                    placement="bottom"
                    description={() => {
                      return (
                        <div className=" bg-[#DCBA78] flex flex-col rounded-[5px]">
                          <span
                            onClick={(e) => {
                              setViewSlc('edit');
                              setOpenViewPage('view-profile');
                              setOpenPop(false);
                            }}
                            className={`${
                              viewSlc === 'edit' &&
                              'bg-[#3E6100] text-white rounded-[5px] mt-[5px] mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                          >
                            view shop profile
                          </span>
                          <span
                            onClick={(e) => {
                              setViewSlc('quick_edit');
                              // onExpand(record, e);
                              setOpenViewPage('view-product-list');
                              setOpenPop(false);
                            }}
                            className={`${
                              viewSlc === 'quick_edit' &&
                              'bg-[#3E6100] text-white rounded-[5px] mt-[5px] mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                          >
                            view shop product
                          </span>
                          <span
                            onClick={(e) => {
                              setViewSlc('duplicate_product');
                              // onExpand(record, e);
                              setOpenPop(false);
                            }}
                            className={`${
                              viewSlc === 'duplicate_product' &&
                              'bg-[#3E6100] text-white rounded-[5px] mt-[5px] mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                          >
                            delete
                          </span>
                          <span
                            onClick={(e) => {
                              setViewSlc('see_product');
                              // onExpand(record, e);
                              setOpenPop(false);
                            }}
                            className={`${
                              viewSlc === 'see_product' &&
                              'bg-[#3E6100] text-white rounded-[5px] mt-[5px]  mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                          >
                            send password reset
                          </span>
                          <span
                            onClick={(e) => {
                              setViewSlc('remove_product');
                              // onExpand(record, e);
                              setOpenPop(false);
                            }}
                            className={`${
                              viewSlc === 'remove_product' &&
                              'bg-[#3E6100] text-white rounded-[5px] my-[5px] mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                          >
                            contact
                          </span>
                        </div>
                      );
                    }}
                    onConfirm={() => {
                      // message.info('Clicked on Yes.');
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button
                      key={record.key}
                      onClick={() => {
                        // setViewSlc('');

                        setOpenPop((prev) => !prev);
                      }}
                      className="h-[20px] w-[20px] rounded-[2px] bg-[#DCBA78] flex justify-center items-center"
                    >
                      {cts.iconDot}
                    </button>
                  </Popconfirm>
                ),
              }}
              pagination={{
                showSizeChanger: false,
                total: data.length,
                pageSize: 10,
                current: pagi.current,

                onChange: (page: any) => {
                  setPagi({
                    current: page,
                    minIndex: (page - 1) * pageSize,
                    maxIndex: page * pageSize,
                  });
                },
              }}
            />
          </div>
        </>
      )) ||
        (openViewPage === 'view-profile' && (
          <ViewProfileShopPage setOpenViewPage={setOpenViewPage} />
        )) ||
        (openViewPage === 'view-product-list' && <ProductListOfShopPage />)}
    </div>
  );
};
export default ShopListPage;
