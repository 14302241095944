import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { API_URL_BASE } from '../constants';

declare module 'axios' {
  export interface AxiosRequestConfig {
    noNeedToken?: boolean;
    isFormData?: boolean;
    token?: string;
    url?: string;
  }
}

const config = {
  baseURL: API_URL_BASE,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
};

const axiosServices = axios.create(config);

axiosServices.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.data = config.data || [];

    config.params = config.params || {};

    if (!config.noNeedToken) {
      config.headers = {
        'ec-admin-token': `${config.token}`,
      };
    }

    if (config.isFormData) {
      config.headers = {
        'Content-Type': 'multipart/form-data',
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      Cookies.remove('EC_ADMIN_TOKEN');
      const win: Window = window;
      win.location = '/';
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosServices;
