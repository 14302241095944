export const URL_ALL_BANNER = `
query getAllBanner(
    $limit: Int!
    $page: Int!
    ){
	getAllBanner(limit:$limit,page:$page){
		count,
    result{
			id,
      title,
      image,
      location,
      size,
      presentation,
      status,
      updateBy{
        firstName,
        lastName,
        userName
      },
      updatedAt
    }
  }
}
`;
export const URL_BANNER_BY_ID = `
query getBannerById($id:ID!){
	getBannerById(id:$id){
		id,
    image,
    size,
    status,
    presentation,
    title,
    location
  }
}
`;
export const URL_UPDATE_BANNER = `
mutation updateBanner($id:ID!,$inputs:inputBanner,$fileUpload: Upload){
    updateBanner(id:$id,inputs:$inputs,fileUpload:$fileUpload){
       data{
        id,
        image,
        size,
        status,
        presentation,
        title,
        location
        },
        error,
        ok
    }
}
`;
export const URL_DELETE_BANNER = `
mutation deleteBanner($id:ID!){
	deleteBanner(id:$id){
		data{
			id
    },
    error,
    ok
  }
}
`;
