import { Checkbox, Input, Popconfirm, Select, Table, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState, useEffect } from 'react';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { useTranslation } from 'react-i18next';
import * as cts from '../../constants/ui/svg';
import type { ColumnsType } from 'antd/es/table';
import { getToken } from 'helpers';
import { useDispatch } from 'react-redux';
import { addCategoryBlog } from 'slices/blogSlice';
interface DataType {
  key: React.Key;
  name: string;
  description: any;
  slug: any;
  count: any;
}
const BlogCatePage = () => {
  const { t } = useTranslation();
  const [viewSlc, setViewSlc] = useState('');
  const token = getToken();
  const dispatch = useDispatch();
  const [openPop, setOpenPop] = useState(false);
  const [dataBlogCate, setDataBlogCate] = useState({
    name: '',
    slug: '',
    description: '',
  });
  const columns: ColumnsType<DataType> = [
    {
      title: <Checkbox disabled={true} className="flex justify-center w-full ckb_table"></Checkbox>,
      key: 'operation',
      fixed: 'left',
      width: 50,
      render: () => <Checkbox className="flex justify-center w-full ckb_table"></Checkbox>,
    },
    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">
          {t('GIVEN_NAME')}
        </span>
      ),
      width: 100,
      dataIndex: 'name',
      key: 'name',
      render: (value: any) => (
        <span className="w-full text-[14px] flex mplus1pextrabold text-[#0038FF] justify-center">
          {value}
        </span>
      ),
    },
    Table.EXPAND_COLUMN,

    {
      title: (
        <span className="text-[14px] mplus1pnormal flex justify-center w-full">DESCRIPTION</span>
      ),
      dataIndex: 'description',
      key: 'description',
      width: 100,
      render: (value: any) => <span className="flex justify-center w-full">{value}</span>,
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">SLUG</span>,
      dataIndex: 'slug',
      key: 'slug',
      width: 100,
      render: (value: any) => (
        <span className="text-[12px] text-black/50 mplus1pmedium flex justify-center w-full">
          {value}
        </span>
      ),
    },
    {
      title: <span className="text-[14px] mplus1pnormal flex justify-center w-full">COUNT</span>,
      dataIndex: 'count',
      key: 'count',
      width: 80,
      fixed: 'right',
      render: (value: any) => (
        <span className=" flex mplus1pnormal text-[12px] justify-center w-full">{value}</span>
      ),
    },
  ];
  const data: DataType[] = [
    {
      key: 1,
      name: 'Food',
      description: '---',
      slug: 'food',
      count: '2',
    },
    {
      key: 2,
      name: 'Fashion',
      description: '---',
      slug: 'fashion',
      count: '4',
    },
    {
      key: 3,
      name: 'Health',
      description: '---',
      slug: 'health',
      count: '10',
    },
    {
      key: 4,
      name: 'Skin care',
      description: '---',
      slug: 'skin care',
      count: '12',
    },
  ];
  const _renderTable = () => {
    return <div>aaaa</div>;
  };
  return (
    <div className="pt-[26px] px-[35px] mb-[100px]">
      <span className="text-[20px] mplus1pextrabold">
        {t('ADD_NEW')} {t('CATEGORY')}
      </span>
      <div className="flex mt-[40px] w-full pb-[100px]">
        <div className="w-[30%] flex flex-col pr-[2%]">
          <span className="text-[14px] mplus1pnormal">{t('GIVEN_NAME')}</span>
          <Input
            value={dataBlogCate.name}
            onChange={(e) => setDataBlogCate({ ...dataBlogCate, name: e.target.value })}
            className="h-[39px] rounded-[0px] border border-[rgba(0,0,0,0.5)] w-full mt-[10px]"
          />
          <span className="text-[10px] text-[#444444] mplus1pnormal">
            {t('THIS_IS_NAME_APPEAR')}
          </span>
          <span className="mt-[20px] text-[14px] mplus1pnormal">{t('SLUG')}</span>
          <Input
            value={dataBlogCate.slug}
            onChange={(e) => setDataBlogCate({ ...dataBlogCate, slug: e.target.value })}
            className="h-[39px] rounded-[0px] border border-[rgba(0,0,0,0.5)] w-full mt-[10px]"
          />
          <span className="text-[10px] text-[#444444] mplus1pnormal">{t('TEXT_CATE')}</span>
          <span className="mt-[20px] text-[14px] mplus1pnormal">{t('PARENT_CATE')}</span>
          <Select
            className="w-[50%] mt-[10px] slc_cate"
            defaultValue="なし"
            options={[{ value: 'なし', label: 'なし' }]}
          />
          <span className="text-[10px] text-[#444444] mplus1pnormal">{t('SPECIFY_TEXT')}</span>

          <span className="text-[14px] mplus1pnormal mt-[20px]">{t('EXPLANATION')}</span>
          <TextArea
            value={dataBlogCate.description}
            onChange={(e) => setDataBlogCate({ ...dataBlogCate, description: e.target.value })}
            autoSize={{ minRows: 3, maxRows: 5 }}
            className="mt-[10px] rounded-[0px] border border-[rgba(0,0,0,0.5)]"
          />
          <span className="text-[10px] text-[#444444] mplus1pnormal">{t('BY_DEFAULT')}</span>

          <span
            onClick={() => dispatch(addCategoryBlog({ token, inputs: { ...dataBlogCate } }))}
            className="h-[39px] border rounded-[10px] border-[#000000] w-[157px] bg-[#3E6100] mt-[50px] text-white text-[14px] mplus1pextrabold flex items-center justify-center cursor-pointer"
          >
            {t('ADD_NEW')} {t('CATEGORY')}
          </span>
        </div>
        <div className="w-[70%] pl-[2%] flex flex-col">
          <div className="flex">
            <Select
              defaultValue="一括操作"
              className="w-[112px] slc_catego"
              options={[{ value: '一括操作', label: '一括操作' }]}
            />
            {/* <span className="h-[39px] cursor-pointer ml-[38px] w-[81px] bg-white text-[14px] mplus1pnormal border text-[#0038FF] border-[#0038FF] rounded-[10px] flex justify-center items-center">
              {t('APPLY')}
            </span> */}
          </div>
          <div className="mt-[17px]">
            <Table
              className="table_merchandise"
              columns={columns}
              dataSource={data}
              scroll={{ x: 800 }}
              // expandable={{
              //   expandedRowRender: (record) => _renderTable(),
              //   rowExpandable: (record) => record.name !== 'Not Expandable',
              // }}
              // rowSelection={{}}
              expandable={{
                expandedRowRender: (record) => _renderTable(),
                expandIcon: ({ expanded, onExpand, record }) => (
                  <Popconfirm
                    title="aaaa"
                    // open={openPop}
                    placement="bottom"
                    description={() => {
                      return (
                        <div className=" bg-[#DCBA78] flex flex-col rounded-[5px]">
                          <span
                            onClick={(e) => {
                              setViewSlc('edit');
                              // onExpand(record, e);
                              setOpenPop(false);
                            }}
                            className={`${
                              viewSlc === 'edit' &&
                              'bg-[#3E6100] text-white rounded-[5px] mt-[5px] mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                          >
                            {t('EDIT')}
                          </span>
                          <span
                            onClick={(e) => {
                              setViewSlc('quick_edit');
                              // onExpand(record, e);
                              setOpenPop(false);
                            }}
                            className={`${
                              viewSlc === 'quick_edit' &&
                              'bg-[#3E6100] text-white rounded-[5px] mt-[5px] mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                          >
                            {t('QUICK')}
                            {t('EDIT')}
                          </span>
                          <span
                            onClick={(e) => {
                              setViewSlc('duplicate_product');
                              // onExpand(record, e);
                              setOpenPop(false);
                            }}
                            className={`${
                              viewSlc === 'duplicate_product' &&
                              'bg-[#3E6100] text-white rounded-[5px] mt-[5px] mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                          >
                            {t('DUPLICATE_PRODUCT')}
                          </span>
                          <span
                            onClick={(e) => {
                              setViewSlc('see_product');
                              // onExpand(record, e);
                              setOpenPop(false);
                            }}
                            className={`${
                              viewSlc === 'see_product' &&
                              'bg-[#3E6100] text-white rounded-[5px] mt-[5px]  mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                          >
                            {t('SEE_PRODUCT_REVIEW')}
                          </span>
                          <span
                            onClick={(e) => {
                              setViewSlc('remove_product');
                              // onExpand(record, e);
                              setOpenPop(false);
                            }}
                            className={`${
                              viewSlc === 'remove_product' &&
                              'bg-[#3E6100] text-white rounded-[5px] my-[5px] mx-[2px]'
                            } cursor-pointer px-[10px] h-[30px] flex items-center text-[14px] mplus1pnormal `}
                          >
                            {t('REMOVE_PRODUCT')}
                          </span>
                        </div>
                      );
                    }}
                    onConfirm={() => {
                      // message.info('Clicked on Yes.');
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button
                      key={record.key}
                      onClick={() => {
                        // setViewSlc('');

                        setOpenPop((prev) => !prev);
                      }}
                      className="h-[20px] w-[20px] rounded-[2px] bg-[#DCBA78] flex justify-center items-center"
                    >
                      {cts.iconDot}
                    </button>
                  </Popconfirm>
                  // <button
                  //   className="h-[20px] w-[20px] rounded-[2px] bg-[#DCBA78] flex justify-center items-center"
                  //   onClick={(e) => {
                  //     onExpand(record, e);
                  //   }}
                  // >
                  //   {cts.iconDot}
                  // </button>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlogCatePage;
