import { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { push } from 'connected-react-router';
import { ENCODE_SECRET_KEY } from '../constants';
import {
  fetchAllBlogByAdminAPI,
  updateBlogAPI,
  deleteBlogAPI,
  cloneBlogAPI,
  addBlogAPI,
  addCategoryBlogAPI,
  addBlogTagAPI,
  allCategoryBlogAPI,
  blogsTagAPI,
  fetchBlogInfoByAdminAPI,
  fetchAllBlogTagsByAdminAPI,
  deleteBlogTagAPI,
  updateBlogTagAPI,
} from '../api';
import { successToast, errorToast, failedToast } from 'helpers';
import {
  fetchAllBlogByAdmin,
  fetchAllBlogByAdminSuccess,
  updateBlog,
  updateBlogSuccess,
  deleteBlog,
  deleteBlogSuccess,
  cloneBlog,
  cloneBlogSuccess,
  addBlog,
  addBlogSuccess,
  addCategoryBlog,
  addCategoryBlogSuccess,
  addBlogTag,
  addBlogTagSuccess,
  fetchAllCategoryBlog,
  fetchAllCategoryBlogSuccess,
  fetchBlogsTag,
  fetchBlogsTagSuccess,
  fetchBlogInfoByAdmin,
  fetchBlogInfoByAdminSuccess,
  updateBlogTag,
  updateBlogTagSuccess,
  deleteBlogTag,
  deleteBlogTagSuccess,
  fetchAllBlogTagsByAdmin,
  fetchAllBlogTagsByAdminSuccess,
  updateBlogStatus,
  updateBlogStatusSuccess,
} from 'slices/blogSlice';
import { showLoading, hideLoading } from '../slices/commonSlice';

function* allBlogByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(fetchAllBlogByAdminAPI, {
      limit: action.payload.limit,
      page: action.payload.page,
      status: action.payload.status,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(fetchAllBlogByAdminSuccess(data?.getAllBlogByAdmin));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* updateBlogSaga(action: any) {
  try {
    yield put(showLoading(true));
    const res: AxiosResponse = yield call(updateBlogAPI, action.payload);
    const { data, errors } = res.data;

    if (data?.updateBlog?.error !== '') {
      successToast(data?.updateBlog?.error);
      yield put(updateBlogSuccess(data?.updateBlog?.data));
      yield put(updateBlogStatusSuccess(data?.updateBlog?.data));
      yield put(showLoading(false));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* deleteBlogSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(deleteBlogAPI, {
      id: action.payload.id,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    if (data?.deleteBlog?.ok === true) {
      successToast('Delete blogs success');
      yield put(deleteBlogSuccess(data?.deleteBlog?.data?.id));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* cloneBlogSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(cloneBlogAPI, {
      id: action.payload.id,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    if (data?.cloneBlog?.ok === true) {
      successToast('Duplicate blogs success');
      yield put(cloneBlogSuccess(data?.cloneBlog?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* addBlogSaga(action: any) {
  const { history } = action.payload;
  try {
    yield put(showLoading(true));
    const res: AxiosResponse = yield call(addBlogAPI, action.payload);
    const { data, errors } = res.data;
    if (data?.addBlog?.ok === true) {
      successToast('Add blogs new success');
      yield put(addBlogSuccess(data?.addBlog?.data));

      yield put(showLoading(false));
      if (data?.addBlog?.data?.status === 'public') {
        history.push('/blog-public');
      } else {
        history.push('/blog-draft');
      }
    } else {
      errorToast(data?.addBlog?.error);
      yield put(showLoading(false));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* addCategoryBlogSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(addCategoryBlogAPI, {
      inputs: action.payload.inputs,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    if (data?.addCategoryBlog?.ok === true) {
      successToast('Add Category Blog Success');
      yield put(addCategoryBlogSuccess(data?.addCategoryBlog));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* addBlogTagSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(addBlogTagAPI, {
      inputs: action.payload.inputs,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    if (data?.addBlogTag?.ok === true) {
      successToast('Add tag blogs new success');
      yield put(addBlogTagSuccess(data?.addBlogTag?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* allCategoryBlogSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(allCategoryBlogAPI, {
      limit: action.payload.limit,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(fetchAllCategoryBlogSuccess(data?.getAllCategoryBlog));

    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* blogsTagSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(blogsTagAPI, {
      limit: action.payload.limit,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(fetchBlogsTagSuccess(data?.blogTags));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* fetchBlogInfoByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(fetchBlogInfoByAdminAPI, {
      id: action.payload.id,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(fetchBlogInfoByAdminSuccess(data?.getBlogInfoByAdmin));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* fetchAllBlogTagsByAdminSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(fetchAllBlogTagsByAdminAPI, {
      limit: action.payload.limit,
      page: action.payload.page,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    yield put(fetchAllBlogTagsByAdminSuccess(data?.getAllBlogTagsByAdmin));
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* deleteBlogTagSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(deleteBlogTagAPI, {
      id: action.payload.id,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    if (data?.deleteBlogTag?.ok === true) {
      successToast(`Delete tag ${data?.deleteBlogTag?.data?.name}  success`);
      yield put(deleteBlogTagSuccess(data?.deleteBlogTag?.data?.id));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
function* updateBlogTagSaga(action: any) {
  try {
    const res: AxiosResponse = yield call(updateBlogTagAPI, {
      id: action.payload.id,
      inputs: action.payload.inputs,
      token: action.payload.token,
    });
    const { data, errors } = res.data;
    if (data?.updateBlogTag?.ok === true) {
      successToast('Update blogs tag success');
      yield put(updateBlogTagSuccess(data?.updateBlogTag?.data));
    }
    yield put(hideLoading(false));
  } catch (error: any) {
    yield put(hideLoading(false));
    errorToast(error?.message);
  }
}
export default function* blogSaga() {
  yield takeLatest(fetchAllBlogByAdmin.type, allBlogByAdminSaga);
  yield takeLatest(updateBlog.type, updateBlogSaga);
  yield takeLatest(updateBlogStatus.type, updateBlogSaga);
  yield takeEvery(deleteBlog.type, deleteBlogSaga);
  yield takeEvery(cloneBlog.type, cloneBlogSaga);
  yield takeLatest(addBlog.type, addBlogSaga);
  yield takeLatest(addCategoryBlog.type, addCategoryBlogSaga);
  yield takeLatest(addBlogTag.type, addBlogTagSaga);
  yield takeLatest(fetchAllCategoryBlog.type, allCategoryBlogSaga);
  yield takeLatest(fetchBlogsTag.type, blogsTagSaga);
  yield takeLatest(fetchBlogInfoByAdmin.type, fetchBlogInfoByAdminSaga);
  yield takeLatest(fetchAllBlogTagsByAdmin.type, fetchAllBlogTagsByAdminSaga);
  yield takeEvery(deleteBlogTag.type, deleteBlogTagSaga);
  yield takeEvery(updateBlogTag.type, updateBlogTagSaga);
}
