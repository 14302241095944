import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { RootState } from 'redux/store';
import { BannerTypes, TypeBannerList } from '../models/banner';
export interface bannerType {
  getAllBanner: TypeBannerList;
  getBannerById: {};
}
const initialState: bannerType = {
  getAllBanner: {
    count: 0,
    result: [] as BannerTypes[],
  },
  getBannerById: {},
};

const bannerSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    fetchAllBanner(state, action) {},
    fetchAllBannerSuccess(state, action) {
      state.getAllBanner = action.payload;
    },
    fetchBannerById(state, action) {},
    fetchBannerByIdSuccess(state, action) {
      state.getBannerById = action.payload;
    },
    updateBanner(state, action) {},
    updateBannerSuccess(state, action) {
      state.getBannerById = action.payload;
    },
    deleteBanner(state, action) {},
    deleteBannerSuccess(state, action) {
      state.getAllBanner.result = state.getAllBanner.result.filter((x) => x.id !== action.payload);
    },
  },
});

// Actions
export const {
  fetchAllBanner,
  fetchAllBannerSuccess,
  fetchBannerById,
  fetchBannerByIdSuccess,
  updateBanner,
  updateBannerSuccess,
  deleteBanner,
  deleteBannerSuccess,
} = bannerSlice.actions;
// Selectors
export const selectBanner = (state: RootState) => state.banners;

// Reducer
const bannerReducer = bannerSlice.reducer;
export default bannerReducer;
