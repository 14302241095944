export const URL_GET_ALL_CATEGORY = `
  query getAllCategory($limit: Int){
    categories(limit: $limit){
        _id,
        name,
        icon,
        images,
        parentId,
        description,
        orderBy,
        seoTitle,
        slug,
        seoDescription,
        status
    }
  }
`;
export const URL_ADD_CATEGORY = `
  mutation addCategory($inputs: inputCategory,$fileUpload: Upload){
    addCategory(inputs:$inputs,fileUpload:$fileUpload){
      data {
        _id,
        name,
        images,
        description,
        slug,
        productCount
      },
      ok,
      error
    }
  }
`;
export const URL_GET_ALL_CATEGORY_BY_ADMIN = `
query getAllCategoryByAdmin($limit: Int!,$page: Int!){
	getAllCategoryByAdmin(limit:$limit,page:$page){
		count,
    result{
			_id,
      name,
      images,
      description,
      slug,
      productCount
    }
  }
}
`;
export const URL_UPDATE_CATEGORY = `
mutation updateCategory(
    $id: ID!,
    $inputs: inputCategory, 
    $fileUpload: Upload
  )
  {
	updateCategory(id:$id, inputs:$inputs, fileUpload: $fileUpload){
		data{
				_id,
      name,
      images,
      description,
      slug,
      productCount
    },
    error,
    ok
  }
}
`;
export const URL_DELETE_CATEGORY = `
mutation deleteCategory($id: ID!){
	deleteCategory(id:$id){
		data{
			_id
    },
    error,
    ok
  }
}
`;
