import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import * as cts from '../../constants/ui/svg';
interface Props {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  onConfirm: (record: any) => void;
  record: any;
  text: any;
}
const ModalConfirm: React.FC<Props> = ({ text, record, setOpenModal, openModal, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal
      mask={false}
      centered
      open={openModal}
      className="modal_delete h-[270px] !w-[375px]"
      //   onOk={() => setOpenModal(false)}
      //   onCancel={() => setOpenModal(false)}
    >
      <div className="flex flex-col justify-between items-center h-full">
        <span>{cts.iconWarning}</span>
        <span className="text-[14px] mplus1pmedium">{text}</span>
        <div className="flex items-center w-full">
          <span
            className="h-[34px] rounded-[5px] flex items-center justify-center bg-[#9B0101] cursor-pointer text-white text-[14px] mplus1pextrabold w-full mr-[5px]"
            onClick={() => {
              onConfirm(record);
              setOpenModal(false);
            }}
          >
            {t('DELETE')}
          </span>
          <span
            className="h-[34px] rounded-[5px] flex items-center justify-center bg-[#3E6100] cursor-pointer text-white text-[14px] mplus1pextrabold w-full ml-[5px]"
            onClick={() => setOpenModal(false)}
          >
            {t('CANCEL')}
          </span>
        </div>
      </div>
    </Modal>
  );
};
export default ModalConfirm;
